import React, { useContext, useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Button, Form, Input, Select, Spin, Table, Tag, Tooltip, TreeSelect, Typography } from "antd";
import axios from "axios";
import { CheckCircleOutlined, CloseOutlined, ExclamationCircleOutlined, FontSizeOutlined, Loading3QuartersOutlined, SyncOutlined } from "@ant-design/icons";
import { EanType, PicturesType } from "../../../types/type";
import showMessage from "../../../../../shared/MessagesInfo/message";
import openNotification from "../../../../../shared/MessagesInfo/WarningBox";
import { CateogoryL3L4 } from "../../../../../shared/FormElements/CategorySelect";
import { capitalizeFirstLetter } from "../../../../attributes/helpers/checkIsIncludes";
import { TreeSelectCategoryType } from "../../../../../shared/Api/category";

export const filterDetails = ["id", "model", "category", "brand", "mpn", "ean", "product_name"];

type FormInstance = any;

const EditableContext = React.createContext<FormInstance | null>(null);

interface Item {
    name: string;
    value: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    productId: number | undefined;
    brandList: {
        id: number;
        name: string;
    }[];
    categories: CateogoryL3L4[];
    loadingInfo: boolean;
    eans: EanType[];
    loadingDeleteEan: number | undefined;
    setLoadingDeleteEan: React.Dispatch<React.SetStateAction<number | undefined>>;
    setEans: React.Dispatch<React.SetStateAction<EanType[]>>;
    currentCountryName: string;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    productId,
    brandList,
    categories,
    loadingInfo,
    eans,
    loadingDeleteEan,
    setLoadingDeleteEan,
    setEans,
    currentCountryName,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    const [loading, setLoading] = useState<"model" | "brand" | "category" | "mpn" | "product_name" | undefined>();

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            if (record.name === "mpn" || record.name === "model" || record.name === "product_name") {
                if (record.name === "mpn") {
                    if (values.value && values.value.trim().length < 4) {
                        openNotification("Mpn text has to be longer than 3 characters");
                        toggleEdit();
                        return;
                    }
                }
                if (record.value === values.value?.trim()) {
                    toggleEdit();
                    return;
                }

                const obj = {
                    id: productId,
                    [record.name === "product_name" ? `name_${currentCountryName}` : record.name]: values.value,
                };

                setLoading(record.name);

                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, obj);
                showMessage(data.message);

                toggleEdit();
                handleSave({ ...record, ...values });
                setLoading(undefined);
            }

            if (record.name === "brand") {
                const findName = brandList.find((b) => b.id === values.value)?.name as string;

                const obj = {
                    id: productId,
                    brand_id: values.value,
                };

                setLoading(record.name);

                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, obj);
                showMessage(data.message);

                toggleEdit();
                handleSave({ ...record, value: findName });
                setLoading(undefined);
            }

            if (record.name === "category") {
                let selectedCategory: { name: string; id: number } | Record<string, string | number> = {};
                categories.forEach((c3: any) => {
                    if (c3.id === values.value) {
                        selectedCategory["name"] = c3.name;
                        selectedCategory["id"] = c3.id;
                        return;
                    }
                    c3.children.forEach((c4: any) => {
                        if (c4.id === values.value) {
                            selectedCategory["name"] = c4.name;
                            selectedCategory["id"] = c4.id;
                            return;
                        }
                    });
                });

                const obj = {
                    id: productId,
                    category_l3_id: values.value,
                };

                setLoading("category");

                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, obj);
                showMessage(data.message);

                toggleEdit();
                handleSave({ ...record, value: selectedCategory.name.toString() });
                setLoading(undefined);
            }
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    const saveOnBlur = () => {
        toggleEdit();
    };
    // delete EAN

    const removeEan = async (ean: EanType) => {
        setLoadingDeleteEan(ean.id);
        // setEans((curr) => curr.filter((eanItem) => eanItem.id !== ean_id));
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/ean/delete-product?code=${ean.code}&product_id=${productId}`);
            setEans((curr) => curr.filter((eanItem) => eanItem.id !== ean.id));
            showMessage(data.message);
            setLoadingDeleteEan(undefined);
        } catch (err) {
            console.log(err);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex} /*rules={[{ /*required: true, message: `${title} is required.` }]}*/>
                {record.name === "model" || record.name === "mpn" || record.name === "product_name" ? (
                    <Input
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        suffix={record.name === loading ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <span />}
                    />
                ) : record.name === "brand" ? (
                    <Select
                        //ref={inputRef}
                        onChange={save}
                        // onBlur={save}
                        onBlur={saveOnBlur}
                        autoFocus
                        defaultOpen
                        showSearch
                        filterOption={(input: string, option?: { label: string; value: number }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={brandList?.map((b) => ({ label: b.name, value: b.id }))}
                        loading={record.name === loading}
                    />
                ) : record.name === "category" ? (
                    <TreeSelect
                        showSearch
                        style={{ width: "100%" }}
                        // value={record.name}
                        dropdownStyle={{ overflow: "auto" }}
                        placeholder="Please select"
                        treeDefaultExpandAll
                        onChange={save}
                        onBlur={saveOnBlur}
                        autoFocus
                        defaultOpen
                        treeNodeFilterProp="title"
                        loading={record.name === loading}
                        treeData={categories.map((c3: any) => ({
                            value: c3.id,
                            title: `${c3.id}: ${c3.name} (${c3.l2_name})`,
                            /* <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}> ({c3.l2_name}) </span> */
                            children: c3.children.map((c4: any) => ({
                                value: c4.id,
                                title: `${c4.id}: ${c4.name}`,
                            })),
                        }))}
                        // onPopupScroll={onPopupScroll}
                    />
                ) : null}
            </Form.Item>
        ) : record.name === "ean" ? (
            <div className="product-eans-container">
                {eans.map((ean) => (
                    <Tag
                        color={ean.checked ? "blue" : "magenta"}
                        style={{ fontSize: "13px", paddingTop: "2px", margin: 0 }}
                        key={ean.id}
                        icon={ean.id === loadingDeleteEan ? <SyncOutlined spin /> : ean.checked ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />} /*icon={<SyncOutlined spin />*/
                    >
                        {ean.code}
                        <Tooltip title="Delete Ean">
                            <CloseOutlined className="product-delete-ean-icon" style={{ opacity: ean.id === loadingDeleteEan ? 0.6 : 1, marginLeft: "0.6rem" }} onClick={() => removeEan(ean)} />
                        </Tooltip>
                    </Tag>
                ))}
            </div>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingInlineEnd: 24, minHeight: "32px", borderRadius: "8px", opacity: record.name === "category" && loadingInfo ? 0.4 : 1 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    name: string;
    value: string | PicturesType[];
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

interface EditableTableType {
    productInfo: {
        name: string;
        value: string | PicturesType[];
    }[];
    setProductInfo: React.Dispatch<
        React.SetStateAction<
            {
                name: string;
                value: string | PicturesType[];
            }[]
        >
    >;
    productId: number | undefined;
    brandList: {
        id: number;
        name: string;
    }[];
    categories: TreeSelectCategoryType[];
    loadingInfo: boolean;
    eans: EanType[];
    loadingDeleteEan: number | undefined;
    setLoadingDeleteEan: React.Dispatch<React.SetStateAction<number | undefined>>;
    setEans: React.Dispatch<React.SetStateAction<EanType[]>>;
    currentCountryName: string;
}

const EditableTable = (props: EditableTableType) => {
    const { productInfo, setProductInfo, productId, brandList, categories, loadingInfo, eans, loadingDeleteEan, setLoadingDeleteEan, setEans, currentCountryName } = props;
    const [loadingName, setLoadingName] = useState(false);

    const smallLetterInName = async (record: any) => {
        const wordToArray = record.value.toString().split(" ");
        const makeSmallLetter = wordToArray
            .map((word: string) => {
                if ((/^[a-zA-ZŠšČčĆćŽžĐđ]+$/.test(word) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(word)) && !/\d/g.test(word)) {
                    return word.toLocaleLowerCase();
                } else if (parseInt(word)) {
                    return word;
                } else {
                    return word;
                }
            })
            .join(" ");

        const obj = {
            id: productId,
            [`name_${currentCountryName}`]: makeSmallLetter,
        };

        console.log(obj);
        setLoadingName(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, obj);
            showMessage(data.message);
            setProductInfo((curr) => curr.map((data) => (data.name === "product_name" ? { ...data, value: makeSmallLetter } : data)));
            setLoadingName(false);
        } catch (err) {
            console.log(err);
        }
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "",
            dataIndex: "name",
            width: "50px",
            // className: "edit-product-mpn-remove-mpn-left",
            render: (text, record) => (
                <div style={{ display: "flex" }}>
                    {capitalizeFirstLetter(text === "product_name" ? "name" : text)}
                    {text === "product_name" && (
                        <Tooltip title="Format Name">
                            <Button loading={loadingName} style={{ marginLeft: "10px" }} size="small" icon={<FontSizeOutlined onClick={() => smallLetterInName(record)} />} />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "value",
            editable: true,
            className: "edit-product-mpn-remove-mpn-left product-editable-cell-overflow",
        },
        {
            title: "",
            dataIndex: "value",
            width: "10px",
            align: "center" as "center",
            render: (text, record: any) => record.name !== "ean" && <Typography.Text copyable={{ text: record.value, tooltips: [` Copy ${record.name === "product_name" ? "name" : record.name}`] }} />,
        },
    ];

    const handleSave = (row: DataType) => {
        const newData = [...productInfo];
        const index = newData.findIndex((item) => row.name === item.name);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setProductInfo(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                productId: productId,
                brandList: brandList,
                categories: categories,
                loadingInfo: loadingInfo,
                eans: eans,
                loadingDeleteEan: loadingDeleteEan,
                setLoadingDeleteEan: setLoadingDeleteEan,
                setEans: setEans,
                currentCountryName: currentCountryName,
                handleSave,
            }),
        };
    });

    return (
        <div>
            <Table
                components={components}
                rowClassName={() => "editable-row"}
                style={{ marginTop: "1rem" }}
                bordered
                dataSource={[...productInfo, { name: "ean", value: "test" }]
                    .filter((p) => filterDetails.includes(p.name) && p.name !== "id")
                    .sort((a, b) => {
                        if (a.name === "product_name") return -1; //name will be on first position in array
                        if (b.name === "product_name") return 1;
                        return 0;
                    })}
                columns={columns as ColumnTypes}
                pagination={false}
                showHeader={false}
                rowKey={(record) => record["name" as keyof typeof record]}
                size="small"
            />
        </div>
    );
};

export default EditableTable;
