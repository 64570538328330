import { CloseOutlined } from "@ant-design/icons";
import { Col, Divider, Layout, Row, Form, Input, Button, Select } from "antd";
import axios from "axios";
import "../style/UserTaskSettings.css";
import React, { useEffect, useState } from "react";
import showMessage from "../../../shared/MessagesInfo/message";

type WorkInervalType = {
    id: number;
    time_end: string;
    time_start: string;
};

type AssignmentType = {
    id: number;
    name: string;
};

type StatusType = {
    id: number;
    name: string;
};

let arr: string[] = [];

Array.from({ length: 12 }, (_, i) => i + 1).forEach((data: number) => {
    arr.push(`${data + 6 < 10 ? "0" : ""}${data + 6}:00`, `${data + 6 < 10 ? "0" : ""}${data + 6}:30`, `${data + 6 < 10 ? "0" : ""}${data + 6}:30`, `${data + 7 < 10 ? "0" : ""}${data + 7}:00`);
});

let newArr = arr.filter((data, i) => i < 24).map((data, i) => (i === 0 ? `${arr[i]} - ${arr[i + 1]}` : `${arr[i * 2]} - ${arr[i * 2 + 1]}`));
//console.log(newArr)

const sortAssignments = (a: string, b: string) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
};

const UserTaskSettings = () => {
    const [workIntervals, setWorkIntervals] = useState<WorkInervalType[]>([]);
    const [interval, setInterval] = useState(null);
    const [assignments, setAssigments] = useState<AssignmentType[]>([]);
    const [assignmentText, setAssignmentText] = useState<string>("");
    const [statuses, setStatuses] = useState<StatusType[]>([]);
    const [statusText, setStatusText] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getIntervals = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/work-intervals/list`);
                setWorkIntervals(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        const getAssigments = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/assignment/list`);
                setAssigments(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        const getStatuses = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shop/get-user-shop-statuses`);
                setStatuses(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getIntervals();
        getAssigments();
        getStatuses();
    }, []);

    const [form] = Form.useForm();

    const saveInterval = async (e: any) => {
        const intervals = e.interval.split("-");
        console.log(intervals[0]);
        const objToSend = {
            time_start: intervals[0],
            time_end: intervals[1],
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/logger/work-intervals/create`, objToSend);
            setWorkIntervals((curr) => [...curr, { id: data.data.id, time_start: intervals[0], time_end: intervals[1] }]);
            showMessage(data.message);
            form.resetFields();
            setInterval(null);
        } catch (err) {
            console.log(err);
        }
    };

    const removeInterval = async (id: number) => {
        setLoading(true);
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/logger/work-intervals/delete?id=${id}`);

            setWorkIntervals((curr) => curr.filter((data) => data.id !== id));
            showMessage(data.message);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const formatDate = (date: string) => {
        const parseDate = date.split(":");
        return Number(parseDate[0] + parseDate[1]);
    };

    const saveAssignment = async (e: { assignment: string }) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/logger/assignment/create`, { name: assignmentText });
            setAssigments((curr) => [
                ...curr,
                {
                    id: data.data.id,
                    ...(assignmentText.trim() !== "" ? { name: assignmentText } : { name: "" }),
                },
            ]);
            showMessage(data.message);
            setAssignmentText("");
        } catch (err) {
            console.log(err);
        }
    };

    const saveStatus = async (e: { assignment: string }) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/shop/add-user-shop-statuses`, { name: statusText });
            setStatuses((curr) => [
                ...curr,
                {
                    id: data.data.id,
                    ...(statusText.trim() !== "" ? { name: statusText } : { name: "" }),
                },
            ]);
            showMessage(data.message);
            setStatusText("");
        } catch (err) {
            console.log(err);
        }
    };

    const removeAssignment = async (id: number) => {
        setLoading(true);
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/logger/assignment/delete?id=${id}`);

            setAssigments((curr) => curr.filter((data) => data.id !== id));
            showMessage(data.message);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const removeStatus = async (id: number) => {
        setLoading(true);
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/shop/delete-user-shop-statuses?id=${id}`);

            setStatuses((curr) => curr.filter((data) => data.id !== id));
            showMessage(data.message);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    return (
        <Row justify="center">
            <Col span={22} style={{ backgroundColor: "white" }}>
                <Divider>User task settings</Divider>
                <Layout style={{ margin: "0.6rem 1rem", height: "85vh" }}>
                    {/* <Header className='product-img-header'><span> List of Mpn </span> </Header> */}
                    <Row justify="center">
                        <Col
                            span={5}
                            style={{
                                border: "1px solid lightgray",
                                height: "80vh",
                                marginTop: "0.5rem",
                            }}
                        >
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                // onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                style={{
                                    border: "1px solid darkgray",
                                    borderRadius: "3px",
                                    margin: "2rem",
                                    padding: "1rem",
                                }}
                                form={form}
                                onFinish={saveInterval}
                            >
                                <Form.Item label="Interval" name="interval" rules={[{ /*required: true,*/ message: "Please select interval!" }]}>
                                    <Select
                                        placeholder="Select interval"
                                        //style={{ textAlign: "center" }}
                                        onChange={(e) => setInterval(e)}
                                        value={interval}
                                        allowClear
                                    >
                                        {newArr.map((data) => (
                                            <Select.Option key={data} style={{ textAlign: "center" }}>
                                                {" "}
                                                {data}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit" disabled={interval ? false : true}>
                                        Add
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                {workIntervals
                                    .sort((a: WorkInervalType, b: WorkInervalType) => formatDate(a.time_start) - formatDate(b.time_start))
                                    .map((data, index) => (
                                        <div key={index} className="interval-list-item">
                                            <span>
                                                {data.time_start} - {data.time_end}
                                            </span>
                                            <CloseOutlined className="remove-interval" onClick={() => !loading && removeInterval(data.id)} />
                                        </div>
                                    ))}
                            </div>
                        </Col>

                        <Col
                            span={5}
                            style={{
                                border: "1px solid lightgray",
                                height: "80vh",
                                marginLeft: "0.3rem",
                                marginTop: "0.5rem",
                            }}
                        >
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                //initialValues={{ remember: true }}
                                // onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                //autoComplete="off"
                                style={{
                                    border: "1px solid darkgray",
                                    borderRadius: "3px",
                                    margin: "2rem",
                                    padding: "1rem",
                                }}
                                //form={form}
                                onFinish={(e) => saveAssignment(e)}
                            >
                                <div style={{ display: "flex" }}>
                                    <span
                                        style={{
                                            color: "red",
                                            marginTop: "0.3rem",
                                            fontSize: "18px",
                                            marginRight: "2px",
                                        }}
                                    >
                                        {" "}
                                        *
                                    </span>
                                    <label style={{ marginRight: "0.3rem", marginTop: "0.3rem" }}>Assignment:</label>
                                    <Input placeholder="Enter Assignment" value={assignmentText ? assignmentText : ""} onChange={(e) => setAssignmentText(e.target.value)} />
                                </div>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: "2rem" }} disabled={assignmentText?.trim() === "" || !assignmentText ? true : false}>
                                        Add
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "58vh",
                                    overflowY: "auto",
                                }}
                            >
                                {assignments
                                    .sort((a: AssignmentType, b: AssignmentType) => sortAssignments(a.name, b.name))
                                    .map((data, index) => (
                                        <div key={index} className="interval-list-item">
                                            <span>{data.name}</span>
                                            <CloseOutlined className="remove-interval" onClick={() => !loading && removeAssignment(data.id)} />
                                        </div>
                                    ))}
                            </div>
                        </Col>
                        <Col
                            span={5}
                            style={{
                                border: "1px solid lightgray",
                                height: "80vh",
                                marginLeft: "0.3rem",
                                marginTop: "0.5rem",
                            }}
                        >
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                //initialValues={{ remember: true }}
                                // onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                //autoComplete="off"
                                style={{
                                    border: "1px solid darkgray",
                                    borderRadius: "3px",
                                    margin: "2rem",
                                    padding: "1rem",
                                }}
                                //form={form}
                                onFinish={(e) => saveStatus(e)}
                            >
                                <div style={{ display: "flex" }}>
                                    <span
                                        style={{
                                            color: "red",
                                            marginTop: "0.3rem",
                                            fontSize: "18px",
                                            marginRight: "2px",
                                        }}
                                    >
                                        {" "}
                                        *
                                    </span>
                                    <label style={{ marginRight: "0.3rem", marginTop: "0.3rem" }}>Status:</label>
                                    <Input placeholder="Enter Status" value={statusText ? statusText : ""} onChange={(e) => setStatusText(e.target.value)} />
                                </div>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: "2rem" }} disabled={statusText?.trim() === "" || !statusText ? true : false}>
                                        Add
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "58vh",
                                    overflowY: "auto",
                                }}
                            >
                                {statuses
                                    .sort((a: AssignmentType, b: AssignmentType) => sortAssignments(a.name, b.name))
                                    .map((data: any, index: any) => (
                                        <div key={index} className="interval-list-item">
                                            <span>{data.name}</span>
                                            <CloseOutlined className="remove-interval" onClick={() => !loading && removeStatus(data.id)} />
                                        </div>
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </Layout>
            </Col>
        </Row>
    );
};

export default UserTaskSettings;
