import axios from "axios";
import showMessage from "../MessagesInfo/message";

export type BrandType = {
    id: number;
    name: string;
    products_count: number;
    logo_url: string | null;
    mapping_brand_name: string;
    redirect_brand_name: string;
    //////
    checkedForMap: boolean; // because of All brands page
};

export const getBrands = async (): Promise<BrandType[]> => {
    const { data } = await axios.get<{ data: BrandType[] }>(`${process.env.REACT_APP_URL_API}/product/brand/list`);
    return data.data.map((b) => ({ ...b, logo_url: b.logo_url, mapping_brand_name: b.mapping_brand_name, redirect_brand_name: b.redirect_brand_name, checkedForMap: false }));
};

export const deleteBrand = async (id: number) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/brand/delete?id=${id}`);
    showMessage(data.message);
    return data.data;
};
