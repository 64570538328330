import { Row, Col, Divider, Switch } from "antd";
import React, { useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import DailyReportUser from "../components/DailyReportUser";
import FeedHelthToday from "../components/FeedHelthToday";
import NewShopProducts from "../components/NewShopProducts";
import ShopStatus from "../components/ShopStatus";
import UncategorizedProduct from "../components/UncategorizedProduct";
import UserDateReportInfo from "../components/UserDateReportInfo";
import "../style/DailyReport.css";
import AttributeStatistics from "../components/AttributeStatistics";
import { useAuth } from "../../../shared/Context/AuthContext";

const DailyReport = () => {
    const { currentCountry } = useAuth();
    const [sfActive, setSfActive] = useState(1);
    // const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;
    const onSwitchChange = (e: boolean) => {
        if (e) {
            setSfActive(1);
        } else {
            setSfActive(0);
        }
    };
    return (
        <div style={{ backgroundColor: "whitesmoke", height: "160vh" }}>
            <HelmetTitle title="Data Entry App - Daily report" />
            <Row justify="center" style={{ paddingBottom: "3rem" }}>
                <div className="report-user-info-box">
                    <UserDateReportInfo />
                    <Switch defaultChecked onChange={onSwitchChange} checkedChildren="SF active" unCheckedChildren="All shops" style={{ marginTop: "6rem", zIndex: 50 }} />
                    <ShopStatus currentCountryId={currentCountry.id} />
                </div>
                <Divider>Daily Reports</Divider>
                <Col span={22}>
                    <div className="daily-report-box">
                        <div className="daily-report-user-box" style={{ marginBottom: "2rem" }}>
                            <DailyReportUser />
                        </div>
                        <div className="daily-report-user-box uncategorized-products-chart uncategorized-box-transiton">
                            <UncategorizedProduct sfActive={sfActive} currentCountryId={currentCountry.id} />
                        </div>
                        <div className="new-shop-products-chart daily-report-user-box new-products-box-transiton">
                            <NewShopProducts sfActive={sfActive} currentCountryId={currentCountry.id} />
                        </div>
                        <div className="feed-health-today-chart daily-report-user-box">
                            <FeedHelthToday sfActive={sfActive} currentCountryId={currentCountry.id} />
                        </div>
                        <div style={{ margin: "3rem 0 0 0" }} className="daily-report-user-box">
                            <AttributeStatistics />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DailyReport;
