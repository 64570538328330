import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NavBar from "./shared/Navigation/NavBar";
import "antd/dist/reset.css";
import "./App.css";
import Routes from "./Routes/Routes";
import { useEffect } from "react";
import axios from "axios";
import showMessage from "./shared/MessagesInfo/message";
import { Button, Divider, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAuth } from "./shared/Context/AuthContext";

require("dotenv").config();

const App: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [error, setError] = React.useState({ message: "", errorText: "", status: "" });
    const { logOut } = useAuth();

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const responseSuccessHandler = (response: any) => {
        return response;
    };

    const responseErrorHandler = (error: any) => {
        if (error.response.status > 250 && error.response.status !== 401 && error.response.status !== 403 && error.response.config.url.includes(process.env.REACT_APP_URL_API)) {
            setError({
                message: error.response.data.message,
                errorText: error.response?.status > 404 ? ` Line: ${error.response.data.line}, File: ${error.response.data.file} ` : error.response.data.errors[0],
                status: error.response.status,
            });
            setIsModalVisible(true);
        }
        if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("token");
            localStorage.removeItem("countries");
            localStorage.removeItem("currentCountry");
            localStorage.removeItem("tokenExpirationDate");
            //dispatch(userLogout());
            logOut();
        }

        return Promise.reject(error);
    };

    axios.interceptors.response.use(
        (response) => responseSuccessHandler(response),
        (error) => responseErrorHandler(error)
    );
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    axios.defaults.headers.common["withCredentials"] = false;

    const loginDateFromLS = localStorage.getItem("tokenExpirationDate");
    if (loginDateFromLS) {
        const d = new Date(JSON.parse(loginDateFromLS));
        let ms = d.getTime();
        const timeDifference = ms - new Date().getTime();
        if (timeDifference < 0) {
            // dispatch(userLogout());
            logOut();
            /////////
            //dispatch(removeLSCurrentCountry());
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const loginDateFromLS = localStorage.getItem("tokenExpirationDate");
            if (loginDateFromLS) {
                const d = new Date(JSON.parse(loginDateFromLS));
                let ms = d.getTime();
                const timeDifference = ms - new Date().getTime();
                if (timeDifference < 0) {
                    logOut();
                    showMessage("Logged in Time is expired!");
                }
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [logOut]); // ??? check here

    return (
        <Router>
            <Modal
                title={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", position: "relative" }}>
                            <p>Error Occured </p>
                            <ExclamationCircleOutlined style={{ position: "relative", bottom: "-2px", left: "3px", color: "darkred" }} />
                        </div>
                        <p style={{ marginRight: "1.5rem", marginTop: "0.3rem" }}>Status: {error.status}</p>
                    </div>
                }
                open={isModalVisible}
                onCancel={handleCancel}
                footer={false}
                width={800}
            >
                <h5>{error.message}</h5>
                <p style={{ textAlign: "center" }}>{error.errorText}</p>
                <Divider style={{ borderTop: "1px solid #dc3545" }} />
                <Button type="primary" onClick={() => setIsModalVisible(false)} danger style={{ marginLeft: "90%" }}>
                    Ok
                </Button>
            </Modal>
            <NavBar />
            <Routes />
        </Router>
    );
};
export default App;
