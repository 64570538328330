import { TreeSelect } from "antd";
import { TreeSelectCategoryType } from "../Api/category";

interface CategorySelectProps {
    currentCountryName: string;
    onCategoryChange: (e: number) => void;
    allCategories: TreeSelectCategoryType[];
    selectedCategory?: number | undefined;
}

const CategoryTreeSelect = (props: CategorySelectProps) => {
    const { currentCountryName, onCategoryChange, allCategories, selectedCategory } = props;
    const treeData = allCategories.map((l3) => ({
        value: `${l3.id}`,
        title: (
            <span>
                {`${l3.id}: ${l3[`name_${currentCountryName}` as keyof typeof l3]}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>({l3.l2_name})</span>{" "}
            </span>
        ),
        l2_name: l3.l2_name,
        children: l3.children.map((l4) => ({
            value: `${l4.id}`,
            title: `${l4.id}: ${l4[`name_${currentCountryName}` as keyof typeof l4]}`,
        })),
    }));

    return (
        <TreeSelect
            showSearch
            style={{ width: "400px", textAlign: "start" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Select Category"
            allowClear
            onChange={onCategoryChange}
            treeData={treeData}
            treeDefaultExpandAll={false}
            value={selectedCategory}
            filterTreeNode={(search: string, item: any) => {
                if (typeof item.title === "string" && item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                    return true;
                } else if (item.title?.props?.children[0] && item.title.props.children[0].toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }}
        />
    );
};

export default CategoryTreeSelect;
