import { SettingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Layout, Row, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import SingleDatePicker from "../components/SingleDatePicker";
import "../style/UsersTaskList.css";
import { getAllUsers } from "../../../shared/Api/Users";

const formatDate = (date: string) => {
    const parseDate = date.split(" - ");
    return Number(parseDate[0].replace(":", "")) + Number(parseDate[1].replace(":", ""));
};
const UsersTaskList = () => {
    const [users, setUsers] = useState<any>();
    const [tasksList, setTaskList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        getAllUsers(true, "data-entry").then((resp) => {
            setUsers(resp);
        });
    }, []);

    useEffect(() => {
        const getWorkInterval = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/assignment-log/list?date=${moment(selectedDate).format("YYYY-MM-DD")}`);

                const result = data.data.reduce((acc: any, d: any) => {
                    const found = acc.find((a: any) => a.interval_id === d.interval_id);
                    //const value = { name: d.name, val: d.value };
                    if (!found) {
                        //acc.push(...value);
                        acc.push({ interval_id: d.interval_id, interval: `${d.time_start} - ${d.time_end}`, data: [d] }); // not found, so need to add data property
                    } else {
                        found.data.push(d); // if found, that means data property exists, so just push new element to found.data.
                    }
                    return acc;
                }, []);

                setTaskList(result.sort((a: any, b: any) => formatDate(a.interval) - formatDate(b.interval)));
            } catch (err) {
                console.log(err);
            }
        };
        getWorkInterval();
    }, [selectedDate]);

    const columns =
        users?.users.data && users?.users.data
            ? [
                  {
                      title: "",
                      dataIndex: "interval",
                      align: "center" as "center",
                      width: "8%",
                      //fixed: 'left',
                      //render:(record:string) =>
                      //render:(record:string) =><div style={{ width:'90px'}}> <p style={{ color:'#264e70', fontWeight:'bold', marginTop:'0.6rem'}}>{record}</p></div>
                  },
                  ...users?.users.data.map((user: any, index: number) => {
                      //console.log(user.id)
                      return {
                          title: `${user.first_name} ${user.last_name}`,
                          dataIndex: "interval_id",
                          align: "center" as "center",
                          width: "8%",
                          render: (text: string, record: any, index: number) => {
                              const findData = record.data.find((item: any) => {
                                  return item.user_id === user.id;
                              }); //UserType is not corect here, to check it later
                              return findData ? (
                                  <span style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                                      <span>{findData.assignment_name ? `${findData.assignment_name} ` : "-"}</span>{" "}
                                      <span style={{ width: "30px", color: "#38598b" }}>{`${findData.assignment_name ? (findData.comment ? `(${findData.comment})` : "") : ""}`}</span>{" "}
                                  </span>
                              ) : (
                                  <span style={{ width: "100px" }}> - </span>
                              );
                          },
                      };
                  }),
              ]
            : [];

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Users Task List" />
            <Row justify="center" style={{ margin: "0 1rem" }}>
                <Col span={24} className="user-assignments-header">
                    <Divider> Users Task List</Divider>
                    <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center" }}>
                        {/* <DatePickerFromToReports date={date} setDate={setDate} /> */}
                        <span style={{ marginLeft: "7.3rem" }}>
                            <SingleDatePicker selectedDate={selectedDate} className="sdp-users-task-list" setSelectedDate={setSelectedDate} />
                        </span>
                        <Link to="/user-task-settings">
                            <Button style={{ position: "absolute", right: "40px", marginTop: "5px", color: "gray" }} size="small">
                                Settings
                                <SettingOutlined />
                            </Button>
                        </Link>
                    </div>
                </Col>
                <Col span={24}>
                    <Layout style={{ backgroundColor: "white", paddingBottom: "4rem", overflowX: "auto" }}>
                        <Table
                            rowKey={(record: any) => record.interval}
                            columns={columns}
                            dataSource={tasksList}
                            loading={users?.users.data.length === 0}
                            className="users-task-list user-assignments-schedule-table"
                            pagination={false}
                            bordered
                        />
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UsersTaskList;
