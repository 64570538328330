import React, { useEffect } from "react";
import { Col, Divider, Row, Layout, Skeleton } from "antd";
import { HotTable } from "@handsontable/react";
import { useState } from "react";
import { CellChange, ChangeSource } from "handsontable/common";
import "../style/UserTasks.css";
import "handsontable/dist/handsontable.full.css";
import axios from "axios";
import moment from "moment";
import showMessage from "../../../shared/MessagesInfo/message";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import { registerAllModules } from "handsontable/registry";
import FadeIn from "react-fade-in";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { getUser } from "../../../shared/Api/Users";

const { Header } = Layout;

type AssignmentType = {
    created_at: string;
    id: number;
    name: string;
    updated_at: string;
};

type WorkIntervalType = {
    created_at: string;
    id: number;
    time_end: string;
    time_start: string;
    updated_at: string;
    comment: number;
};

type UserTasksType = [string, string | null, number | null, number, number | null];

type UserDailyReportType = {
    key: string;
    count: number;
};

const formatDate = (date: string) => {
    const parseDate = date.split(":");
    return Number(parseDate[0] + parseDate[1]);
};

registerAllModules();

const UserTasks = () => {
    const [user, setUser] = useState<any>();
    const [userTasks, setUserTasks] = useState<UserTasksType[]>([]); //     [interval, action, interval_id, assignment_id]
    const [assignments, setAssignment] = useState<AssignmentType[]>([]);
    const [workInterval, setWorkInterval] = useState<WorkIntervalType[]>([]);
    const [userDailyReport, setUserDailyReport] = useState<UserDailyReportType[]>([]);
    const windowSize = useWindowSize();

    useEffect(() => {
        getUser().then((resp) => {
            setUser(resp);
        });
        const getAssignments = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/assignment/list`);
                setAssignment(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getAssignments();

        const getWorkInterval = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/work-intervals/list`);
                setWorkInterval(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getWorkInterval();
    }, []);

    useEffect(() => {
        const getUserTasks = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/assignment-log/list-by-interval?user_id=${user?.data?.id}`);
                const newData: UserTasksType[] = workInterval
                    .sort((a: WorkIntervalType, b: WorkIntervalType) => formatDate(a.time_start) - formatDate(b.time_start))
                    .map((interval) => {
                        const findTask = data?.data?.find((item: WorkIntervalType) => item.time_start === interval.time_start && item.time_end === interval.time_end);
                        return [
                            `${interval.time_start} - ${interval.time_end}`,
                            findTask?.data[0] ? findTask?.data[0]?.assignment_name : null,
                            findTask?.data[0] ? findTask?.data[0]?.comment : null,
                            interval.id,
                            findTask?.data[0] ? findTask?.data[0]?.assignment_id : null,
                        ];
                    });
                setUserTasks(newData);
            } catch (err) {
                console.log(err);
            }
        };
        workInterval.length > 0 && user?.data?.id && getUserTasks();

        const getUserDailyReport = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/list-user-activity`);
                let arrOfTasks = [];
                for (let key in data.data) {
                    if (key !== "id" && key !== "first_name" && key !== "last_name") {
                        arrOfTasks.push({ key: key.replaceAll("_", " "), count: data.data[key] });
                    }
                }
                setUserDailyReport(arrOfTasks);
            } catch (err) {
                console.log(err);
            }
        };

        workInterval.length > 0 && user?.data?.id && getUserDailyReport();
    }, [workInterval, user?.data?.id]);

    const setCell = async (changes: CellChange[] | null, source: ChangeSource) => {
        if (changes) {
            if (source === "Autofill.fill" && ((changes[0][1] === 2 && typeof changes[0][3] === "string") || (changes[0][1] === 1 && typeof changes[0][3] === "number"))) {
                if (changes.length === 1) {
                    const row = changes[0][0];
                    setUserTasks((curr) =>
                        curr.map((ut: any, i) => {
                            if (i === row) {
                                ut[1] = null;
                                ut[2] = null;
                                openNotification(`Error. User task for peritod ${ut[0]} is deleted. Try again `);
                                return ut;
                            } else {
                                return ut;
                            }
                        })
                    );
                } else {
                    const rows = changes.map((c) => c[0]);
                    setUserTasks((curr) =>
                        curr.map((ut: any, i) => {
                            if (rows.includes(i)) {
                                ut[1] = null;
                                ut[2] = null;
                                return ut;
                            } else {
                                return ut;
                            }
                        })
                    );
                    openNotification(`Error. User task for periods ${rows.map((r) => `${userTasks[r][0]}, `)} is deleted. Try again `);
                }
            }
        }

        const action = (a: string) => assignments.find((data) => data.name === a)?.id;

        const objToSend = {
            user_id: user?.data?.id,
            intervals: userTasks.map((task) => {
                return {
                    interval_id: task[3],
                    assignment_id: task[1] ? action(task[1]) : null,
                    date: moment(new Date()).format("YYYY-MM-DD"),
                    comment: task[2],
                };
            }),
        };
        if (source === "edit" || source === "Autofill.fill") {
            try {
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/logger/assignment-log/upsert`, objToSend);
                showMessage(data.message);
                const row = changes && changes[0][0];
                //const col = changes &&  changes[0][1]
                if (source === "edit") {
                    setUserTasks((curr) =>
                        curr.map((data, index) => {
                            if (index === row) {
                                return curr[row];
                            }
                            return data;
                        })
                    );
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const columns = [
        {
            title: "Period",
            readOnly: true,
            className: "uset-tasks-first-column",
        },
        {
            title: "Action",
            editor: "select",
            selectOptions: assignments.map((data) => data.name),
            className: "uset-tasks-first-column",
        },
        {
            title: "Comment",
            type: "numeric",
            className: "user-tasks-comment-column",
        },
    ];

    return (
        <Row justify="center">
            <Divider>User Tasks</Divider>
            <Col span={windowSize.width > 1670 ? 13 : 18} style={{ backgroundColor: "white" }}>
                <Layout style={{ margin: "0.6rem 1rem", height: "80vh" }}>
                    <Header
                        className="product-img-header"
                        style={{ display: "flex", justifyContent: "space-between", color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px" }}
                    >
                        <span>
                            {" "}
                            List of Tasks for <i> {user?.data && userTasks.length > 0 ? `${user?.data.first_name} ${user?.data.last_name}` : ""}</i>{" "}
                        </span>
                        <span> {`${moment(new Date()).format("DD MMM YYYY")}, ${moment(new Date()).format("HH:mm")}`}</span>
                    </Header>
                    <Divider></Divider>
                    <Row justify="center" style={{ padding: "3rem" }}>
                        <Col span={windowSize.width > 1200 ? 16 : 18} style={{ padding: "2rem", backgroundColor: "white" }}>
                            {userTasks.length > 0 && userDailyReport.length > 0 ? (
                                <HotTable
                                    style={{ marginRight: "10%", width: "100%" }}
                                    settings={{
                                        data: userTasks,
                                        colHeaders: true,
                                        rowHeaders: false,
                                        height: "auto",
                                        licenseKey: "non-commercial-and-evaluation",
                                        columns: columns,
                                    }}
                                    afterChange={(changes: CellChange[] | null, source: ChangeSource) => setCell(changes, source)}
                                />
                            ) : (
                                <div style={{ width: "520px", marginBottom: "0.6rem" }}>
                                    <Skeleton active paragraph={{ rows: 12 }} title={{ width: "100%" }} style={{ border: "none" }} />
                                </div>
                            )}
                        </Col>
                        <Col span={6} style={{ backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            {userDailyReport.length > 0 && userTasks.length > 0 ? (
                                <div style={{ marginTop: "1rem", marginBottom: "12px", backgroundColor: "white", border: "1px solid gray", padding: "0.5rem" }}>
                                    {userDailyReport.map((data) => (
                                        <FadeIn delay={0} transitionDuration={1500} key={data.key}>
                                            <div
                                                style={{
                                                    border: "1px solid #53a8b6",
                                                    borderRadius: "2px",
                                                    marginBottom: "3px",
                                                    padding: "0.1rem 0.6rem",
                                                    //width:'80%' ,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    minWidth: "160px",
                                                    width: "220px",
                                                    backgroundColor: data.count > 0 ? "#f5f4e8" : "",
                                                }}
                                                key={data.key}
                                            >
                                                <span style={{ fontSize: "13px", textTransform: "capitalize" }}>{data.key}:</span>
                                                <span style={{ fontSize: "13px", color: "gray" }}>{data.count}</span>
                                            </div>
                                        </FadeIn>
                                    ))}
                                    <span style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <span style={{ marginRight: "8px" }}>
                                            Sum:{" "}
                                            {userDailyReport.reduce((accumulator, a) => {
                                                return accumulator + a.count;
                                            }, 0)}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    <Skeleton.Button active style={{ width: "230px", height: "460px", margin: "20px 30px 20px 20px", position: "relative", bottom: "0rem", left: "0px" }} block />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Layout>
            </Col>
        </Row>
    );
};

export default UserTasks;
