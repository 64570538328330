import { Col, Divider, Layout, Row, Select, Statistic } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { PrimaryAttributesType } from "./TopProductAttributes";
import MappingTable from "../components/TopAttributeMapping/MappingTable";
import numberWithCommas from "../../../shared/utils/numberUtils";
import { getAllCategories, TreeSelectCategoryType } from "../../../shared/Api/category";
import CategoryTreeSelect from "../../../shared/FormElements/CategoryTreeSelect";
import { useAuth } from "../../../shared/Context/AuthContext";
const { Header } = Layout;

export type MapType = {
    attribute_allowed_value_id: number | null;
    id: number;
    attribute_id: number;
    category_l3_id: number;
    value: string;
    include: string;
    exclude: string;
    search: string;
    or: false;
    attribute_name: string;
    category_name: string;
    attribute_value: null;
    attribute_type: "range" | "select" | "multiselect" | "yesno";
};

export type GroupedItem = {
    attributeName: string;
    data: MapType[];
};

const TopAttributeMapping = () => {
    const { currentCountry } = useAuth();
    const [categories, setCategories] = useState<TreeSelectCategoryType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [selectedAttriubte, setSelectedAttribute] = useState<number | undefined>();
    const [primaryAttributes, setPrimaryAttributes] = useState<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>([]);
    const [count, setCount] = useState(0);
    const [mapList, setMapList] = useState<MapType[]>([]);
    const [page, setPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        setCategories([]);
        getAllCategories(currentCountry.id, currentCountry.name).then((resp) => {
            setCategories(resp);
        });
    }, [currentCountry]);

    useEffect(() => {
        const getPrimaryAttributes = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/get-primary-attributes-by-l3?l3_id=${selectedCategory}&country_id=${currentCountry.id}`);
                setPrimaryAttributes(data.data);
            } catch (err) {
                console.log(err);
            }
        };

        selectedCategory && getPrimaryAttributes();
    }, [selectedCategory, currentCountry.id]);

    useEffect(() => {
        const getTopAttributes = async () => {
            setLoadingData(true);
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/list?category_l3_id=${selectedCategory}&page=${page}${
                        selectedAttriubte ? `&attribute_id=${selectedAttriubte}` : ""
                    }`
                );
                const datas = data.data as MapType[];
                setMapList(datas.sort((a, b) => a.attribute_name.localeCompare(b.attribute_name)));
                setLoadingData(false);
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getTopAttributes();
    }, [selectedCategory, selectedAttriubte, page]);

    useEffect(() => {
        const getCount = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/count?category_l3_id=${selectedCategory}${selectedAttriubte ? `&attribute_id=${selectedAttriubte}` : ""}`
                );
                setCount(data.data.count);
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getCount();
    }, [selectedCategory, selectedAttriubte]);

    const onChangeCategory = (e: number) => {
        setSelectedCategory(e);
        setSelectedAttribute(undefined);
        setPage(1);
    };

    const onAttributeChange = (e: number) => {
        setSelectedAttribute(e);
        setPage(1);
    };

    const onPageChange = (e: number) => {
        setPage(e);
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Top Attribute Mapping overview" />
            <Col span={23}>
                <div style={{ border: "1px solid lightgray", padding: "2rem", minHeight: "88vh" }} id="feed-files-box">
                    <Divider>Top Attribute Mapping</Divider>
                    <div style={{ padding: "1rem 2rem", backgroundColor: "#ebeced", border: "1px solid lightgray", borderRadius: "3px", marginBottom: "1.3rem" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", position: "relative" }}>
                            <CategoryTreeSelect allCategories={categories} currentCountryName={currentCountry.name} onCategoryChange={onChangeCategory} selectedCategory={selectedCategory} />
                            <Select
                                showSearch
                                placeholder="Select Attribute"
                                optionFilterProp="label"
                                options={primaryAttributes
                                    .sort((a, b) => a[`name_${currentCountry.name}`]?.toString().localeCompare(b[`name_${currentCountry.name}`]?.toString()))
                                    .map((attr) => ({ label: attr[`name_${currentCountry.name}`], value: attr.id }))}
                                style={{ width: "400px", marginLeft: "1rem" }}
                                showArrow={false}
                                onChange={onAttributeChange}
                                value={selectedAttriubte}
                                disabled={!selectedCategory}
                                allowClear
                            />
                            <Statistic
                                title="Total"
                                value={count && numberWithCommas(String(count).replace(".", ","))}
                                valueStyle={{ color: "green" }}
                                style={{ position: "absolute", right: "10px", top: "-15px" }}
                            />
                        </div>
                    </div>
                    <Layout style={{ margin: "0.6rem 1rem" }}>
                        <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px", position: "relative" }}>
                            <span> Primary Attributes </span>
                        </Header>
                        {selectedCategory && (
                            <MappingTable
                                mapList={mapList}
                                primaryAttributes={primaryAttributes}
                                currentCountryId={currentCountry.id}
                                setMapList={setMapList}
                                loadingData={loadingData}
                                onPageChange={onPageChange}
                            />
                        )}
                    </Layout>
                </div>
            </Col>
        </Row>
    );
};

export default TopAttributeMapping;
