import { TreeSelect } from "antd";

interface CategorySelectProps {
    currentCountryName: string;
    onCategoryChange: (e: string) => void;
    allCategories: TreeSelectType[];
}

type TreeSelectType = {
    id: number;
    l2_name: string;
    children: { [key: string]: string | number }[];
    [key: string]: string | number | { [key: string]: string | number }[];
};

const CategorySelect = (props: CategorySelectProps) => {
    const { currentCountryName, onCategoryChange, allCategories } = props;
    const treeData = allCategories.map((l3) => ({
        value: `${l3.id}::${l3[`name_${currentCountryName}` as keyof typeof l3]}`,
        title: (
            <span>
                {`${l3.id}: ${l3[`name_${currentCountryName}` as keyof typeof l3]}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>({l3.l2_name})</span>{" "}
            </span>
        ),
        l2_name: l3.l2_name,
        children: l3.children.map((l4) => ({
            value: `${l4.id}::${l4[`name_${currentCountryName}` as keyof typeof l4]}`,
            title: `${l4.id}: ${l4[`name_${currentCountryName}` as keyof typeof l4]}`,
        })),
    }));

    return (
        <TreeSelect
            showSearch
            style={{ width: "400px", margin: "0.5rem 0 0.5rem 0.5rem", textAlign: "start" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Select Category"
            allowClear
            onChange={onCategoryChange}
            treeData={treeData}
            treeDefaultExpandAll={false}
            filterTreeNode={(search: string, item: any) => item.value.toLowerCase().indexOf(search.toLowerCase()) >= 0}
        />
    );
};

export default CategorySelect;
