import { Col, Form, Modal, Row } from "antd";
import React, { useState } from "react";
import ApplyToAllList from "./ApplyToAllList";
import ApplyToAllForm from "./ApplyToAllForm";
import { applyToAll } from "../../api/applyToAll";
import { ApplyToAllModalProps } from "../../types/modals";

const ApplyToAllModal: React.FC<ApplyToAllModalProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleCancel = () => {
        props.setIsModalApplyToAll(false);
    };

    const data = [
        props.search,
        props.selectedShops.map((s) => s.split("::")[1]).join(", "),
        props.selectedCategories?.id ? props.selectedCategories?.id.split("::")[1] : "",
        props.selectedBrands.map((b) => b.split("::")[1]).join(", "),
        props.xml ? "YES" : "ALL",
        props.selectedNew === undefined ? "ALL" : props.selectedNew === "1" ? "NEW" : "OUTLET",
        props.user ? "YES" : "NO",
        props.currentCountryName.toString().toUpperCase(),
    ];

    const brand = Form.useWatch("brand", form);
    const category = Form.useWatch("category", form);

    const save = () => {
        setLoading(true);
        applyToAll(
            props.selectedShops,
            props.selectedCategories,
            props.selectedBrands,
            props.user,
            props.selectedNew,
            props.xml,
            brand,
            category,
            props.search,
            props.currentCountryName,
            props.currentCountryId
        ).then(() => {
            const brand = form.getFieldValue("brand");
            const category = form.getFieldValue("category");
            if (category) {
                props.setProducts([]);
            } else {
                const wordToArr = brand?.split("::");
                props.setProducts((curr) =>
                    curr.map((p) => ({
                        ...p,
                        ...(brand && { brand_id: Number(wordToArr[0]) }),
                        ...(brand && { brand_name: wordToArr[1] }),
                        ...(brand && { brand: `${wordToArr[0]}::${wordToArr[1]}` }),
                    }))
                );
            }
            form.resetFields();
            setLoading(false);
        });
    };

    return (
        <Modal title="Apply to All" open={props.isModalApplyToAll} onCancel={handleCancel} width={1200} footer={false} destroyOnClose={true}>
            <Row>
                <Col span={12}>
                    <ApplyToAllList count={props.count} data={data} />
                </Col>
                <Col span={12}>
                    <ApplyToAllForm
                        currentCountryId={props.currentCountryId}
                        currentCountryName={props.currentCountryName}
                        form={form}
                        count={props.count}
                        save={save}
                        brand={brand}
                        category={category}
                        loading={loading}
                        brands={props.brands}
                        categories={props.categories}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default ApplyToAllModal;
