//----------get transform----------- Price and products

import axios from "axios";
const baseUrl = process.env.REACT_APP_URL_API;

export const getTransform = async (id: any, setLoading: any, setIsModalTransformVisible: any) => {
    setLoading(true);

    const { data } = await axios.get(`${baseUrl}/maps/transform?shop_id=${id}`);
    setLoading(false);
    typeof setIsModalTransformVisible !== "string" && setIsModalTransformVisible(true);
    return data;
};
// download feed

export const feedParser = (path: any) => {
    const ext = path.substring(path.length - 3, path.length);
    try {
        axios({
            url: `${process.env.REACT_APP_URL_API}/feed-parser/feed-download?feed_file=${path}`, //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `file.${ext}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    } catch (err) {
        console.log(err);
    }
};
