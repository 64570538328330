import React, { useState } from "react";
import { Button, Modal, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import {
    UserOutlined,
    ShoppingOutlined,
    BarChartOutlined,
    BookOutlined,
    SnippetsOutlined,
    ContainerOutlined,
    LogoutOutlined,
    BarcodeOutlined,
    ReconciliationOutlined,
    EuroOutlined,
    ToolOutlined,
    FileWordOutlined,
    EditOutlined,
} from "@ant-design/icons";
import "./NavBar.css";
import usePathname from "../hooks/pathName";
import NavInfoBtn from "./components/NavInfoBtn";
import FadeIn from "react-fade-in";
import CreateInfoText from "./components/CreateInfoText";
import axios from "axios";
import showMessage from "../MessagesInfo/message";
import logoImage from "../../images/logo2.png";
import { capitalizeFirstLetter } from "../../screens/attributes/helpers/checkIsIncludes";
import { useAuth } from "../Context/AuthContext";
import CountrySelect from "./components/CountrySelect";
import { CountryType } from "../Context/type";
const { Text } = Typography;

interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = () => {
    const [showInfoText, setShowInfoText] = useState(false);
    const [editorText, setEditorText] = useState("");
    const [descriptionInit, setDescriptionInit] = useState("");
    const [saveInfoLoading, setSaveInfoLoading] = useState(false);

    const path = usePathname();
    const { isLoggedIn, logOut, loadingData, setCurrentCountry } = useAuth();

    const handleCancel = () => {
        setShowInfoText(false);
        setDescriptionInit("");
        setEditorText("");
    };
    const serverType = process.env.REACT_APP_URL_API === "https://editor.eponuda.com/api" ? "LIVE" : process.env.REACT_APP_URL_API === "https://editorbeta.eponuda.com/api" ? "BETA" : "TEST";

    function toggleMenu(id: number) {
        let nav = document.getElementById("main-nav") as HTMLElement;
        if (id === 1) {
            nav.style.left = "0%";
        } else {
            nav.style.left = "-100%";
        }
    }

    const navLinks = [
        ["/user-list", "/add-user", "/user-assignments", "/user-assignments-schedule", "/user-task-settings", "/users-task-list"],
        ["/shop-list", "/shops/add-new-shop", "/shop-rules", "/matching-seller-category", "/append-replace-xml-data"],
        ["/main-map", "/create-shop-map/0", "/shop-map", "/xml-merged"],
        ["/daily-report", "/feed-health-from-to", "/prices-products-report", "/all-feeds", "/feed-checker", "/mpn-check"],
        [
            "/product-list",
            "/product-name-mass-editing",
            "/prices-difference",
            "/comments-list",
            "/product-images",
            "/edit-product-mpn",
            "/ean-cleaning",
            "/ean-overview",
            "/automatched-product-product",
            "/automatched-offer-product",
            "/description-review",
            "/description-ai",
            "/product",
        ],
        ["/brand-list", "/cleanup-brands", "/shop-brands"],
        ["/category-list", "/level-3-add-new", "/re-categorizing", "/category-prefix-suffix", "/frequently-asked-questions"],

        ["/attributes-overview", "/attribute-mapping", "/attribute-mapping-rules", "/top-product-attributes", "/top-attribute-mapping"],

        ["/user-logger"],
        ["/word-replacement", "/universal-scraper", "scrapers-overview"],
        ["/shoktok-pages"],
        ["/price-list"],
        ["/user-tasks", "/my-account", "/edit-user"],
        ["/create-catalog", "/catalog-list"],
        ["/all-products"],
    ];

    const activeLink = (arr: string[]) => {
        if (arr.length === 1) {
            if (arr[0] === path) {
                return true;
            }
        } else {
            if (arr.includes(path)) {
                return true;
            }
        }
    };

    const saveInfoPageText = async () => {
        const objToSend = {
            content: editorText,
            link: path === "/" ? "/" : path.substring(1),
        };
        setSaveInfoLoading(true);
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/intelligence/upsert-app-info`, objToSend);
            showMessage(data.message);
            setShowInfoText(false);
            setSaveInfoLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const openCreateInfoText = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/intelligence/get-app-info?link=${path === "/" ? "/" : path.substring(1)}`);
            if (data.data?.content) {
                setDescriptionInit(data.data?.content);
            }
            setShowInfoText(true);
        } catch (err) {
            console.log(err);
        }
    };

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const selectCountry = (e: number) => {
        const findCountry = localStorageCountries.find((c) => c.id === e)!;
        localStorage.setItem("currentCountry", JSON.stringify({ id: findCountry.id, name: findCountry.name }));
        setCurrentCountry({ id: findCountry.id, name: findCountry.name });
    };

    return (
        <>
            {!loadingData && (
                <nav className="bg-dark">
                    <Link to="/">
                        <img src={logoImage} alt="eponuda-logo" width={84} style={{ marginLeft: "0.7rem", marginTop: "2px" }} />
                    </Link>
                    <Text
                        id="text-info-navbar"
                        style={{
                            color: serverType === "LIVE" ? "green" : serverType === "BETA" ? "#ff3838" : "#ff3838",
                            width: "55px",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {serverType}
                    </Text>
                    <div className="container" style={{ maxWidth: "78%" }}>
                        <div className="mobile-nav">
                            <button className="mobile-btn" /*onclick="toggleMenu(1)"*/ onClick={() => toggleMenu(1)}>
                                Menu
                            </button>
                        </div>

                        {isLoggedIn && (
                            <ul className="nav" id="main-nav" style={{ marginTop: "0.4rem", display: "flex", flexWrap: "wrap" }}>
                                <li className="close-item">
                                    <button className="mobile-btn" /*onclick="toggleMenu(0)"*/ onClick={() => toggleMenu(0)}>
                                        Close &times;
                                    </button>
                                </li>

                                <li className="nav-item" style={{ zIndex: 165 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[0]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <UserOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />
                                        <span style={{ whiteSpace: "nowrap" }}>User</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/user-list" className={`dp-link ${activeLink(["/user-list"]) ? "nav-link-active" : ""}`}>
                                                User List <i className="fa fa-caret-right"></i>
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/user-assignments-schedule" className={`dp-link ${activeLink(["/user-assignments-schedule"]) ? "nav-link-active" : ""}`}>
                                                User Assignments Schedule
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/users-task-list" className={`dp-link ${activeLink(["/users-task-list"]) ? "nav-link-active" : ""}`}>
                                                Users Task List
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item" style={{ zIndex: 165 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[1]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <ShoppingOutlined className="nav-icon" style={{ marginRight: "0.5rem" }} />
                                        <span style={{ whiteSpace: "nowrap" }}>Shops</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/shop-list" className={`dp-link ${activeLink(["/shop-list"]) ? "nav-link-active" : ""}`}>
                                                Shops list
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/matching-seller-category" className={`dp-link ${activeLink(["/matching-seller-category"]) ? "nav-link-active" : ""}`}>
                                                Matching seller category
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/append-replace-xml-data" className={`dp-link ${activeLink(["/append-replace-xml-data"]) ? "nav-link-active" : ""}`}>
                                                Append/replace XML Data
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item" style={{ zIndex: 65 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[2]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <BookOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Maps</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/main-map" className={`dp-link ${activeLink(["/main-map"]) ? "nav-link-active" : ""}`}>
                                                Main maps
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/xml-merged" className={`dp-link ${activeLink(["/xml-merged"]) ? "nav-link-active" : ""}`}>
                                                XML Merged
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item" style={{ zIndex: 165 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[3]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <BarChartOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Reports </span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/daily-report" className={`dp-link ${activeLink(["/daily-report"]) ? "nav-link-active" : ""}`}>
                                                Daily report
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/user-logger" className={`dp-link ${activeLink(["/user-logger"]) ? "nav-link-active" : ""}`}>
                                                User Logger
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/feed-health-from-to" className={`dp-link ${activeLink(["/feed-health-from-to"]) ? "nav-link-active" : ""}`}>
                                                Feed health - From to
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/prices-products-report" className={`dp-link ${activeLink(["/prices-products-report"]) ? "nav-link-active" : ""}`}>
                                                Prices and products
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/all-feeds" className={`dp-link ${activeLink(["/all-feeds"]) ? "nav-link-active" : ""}`}>
                                                Feeds
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/feed-checker" className={`dp-link ${activeLink(["/feed-checker"]) ? "nav-link-active" : ""}`}>
                                                Feed Checker
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/mpn-check" className={`dp-link ${activeLink(["/mpn-check"]) ? "nav-link-active" : ""}`}>
                                                Mpn Check
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item" style={{ zIndex: 165 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[4]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <SnippetsOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Products</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/prices-difference" className={`dp-link ${activeLink(["/prices-difference"]) ? "nav-link-active" : ""}`}>
                                                Prices difference
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/product-name-mass-editing" className={`dp-link ${activeLink(["/product-name-mass-editing"]) ? "nav-link-active" : ""}`}>
                                                Product name mass editing
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/comments-list" className={`dp-link ${activeLink(["/comments-list"]) ? "nav-link-active" : ""}`}>
                                                Comments
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/product-images" className={`dp-link ${activeLink(["/product-images"]) ? "nav-link-active" : ""}`}>
                                                Product images
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/automatched-offer-product" className={`dp-link ${activeLink(["/automatched-offer-product"]) ? "nav-link-active" : ""}`}>
                                                AM Offer - Product
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/automatched-product-product" className={`dp-link ${activeLink(["/automatched-product-product"]) ? "nav-link-active" : ""}`}>
                                                AM Product - Product
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/edit-product-mpn" className={`dp-link ${activeLink(["/edit-product-mpn"]) ? "nav-link-active" : ""}`}>
                                                Edit Product Mpn
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/ean-cleaning" className={`dp-link ${activeLink(["/ean-cleaning"]) ? "nav-link-active" : ""}`}>
                                                Ean Cleaning
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/ean-overview" className={`dp-link ${activeLink(["/ean-overview"]) ? "nav-link-active" : ""}`}>
                                                Ean Overview
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/description-review" className={`dp-link ${activeLink(["/description-review"]) ? "nav-link-active" : ""}`}>
                                                Description and Review
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/description-ai" className={`dp-link ${activeLink(["/description-ai"]) ? "nav-link-active" : ""}`}>
                                                Description AI
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/product" className={`dp-link ${activeLink(["/product"]) ? "nav-link-active" : ""}`}>
                                                Product
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item" style={{ zIndex: 65 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[5]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <BarcodeOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Brands </span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/brand-list" className={`dp-link ${activeLink(["/brand-list"]) ? "nav-link-active" : ""}`}>
                                                Brands List
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/cleanup-brands" className={`dp-link ${activeLink(["/cleanup-brands"]) ? "nav-link-active" : ""}`}>
                                                Cleanup Brands
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/shop-brands" className={`dp-link ${activeLink(["/shop-brands"]) ? "nav-link-active" : ""}`}>
                                                Shop Brands
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item" style={{ zIndex: 65 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[6]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <ContainerOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Category</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/category-list" className={`dp-link ${activeLink(["/category-list"]) ? "nav-link-active" : ""}`}>
                                                Category list
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/re-categorizing" className={`dp-link ${activeLink(["/re-categorizing"]) ? "nav-link-active" : ""}`}>
                                                Re-categorizing
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/category-prefix-suffix" className={`dp-link ${activeLink(["/category-prefix-suffix"]) ? "nav-link-active" : ""}`}>
                                                Category suffix
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/frequently-asked-questions" className={`dp-link ${activeLink(["/frequently-asked-questions"]) ? "nav-link-active" : ""}`}>
                                                FAQ
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item" style={{ zIndex: 65 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[7]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <ReconciliationOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Attributes </span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/attributes-overview" className={`dp-link ${activeLink(["/attributes-overview"]) ? "nav-link-active" : ""}`}>
                                                Attributes Overview
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/attribute-mapping" className={`dp-link ${activeLink(["/attribute-mapping"]) ? "nav-link-active" : ""}`}>
                                                Attribute Mapping
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/attribute-mapping-rules" className={`dp-link ${activeLink(["/attribute-mapping-rules"]) ? "nav-link-active" : ""}`}>
                                                Attribute value cleaning rules
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/top-product-attributes" className={`dp-link ${activeLink(["/top-product-attributes"]) ? "nav-link-active" : ""}`}>
                                                Top product attributes
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/top-attribute-mapping" className={`dp-link ${activeLink(["/top-attribute-mapping"]) ? "nav-link-active" : ""}`}>
                                                Top Attribute Mapping
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/attributes-analytics" className={`dp-link ${activeLink(["/attributes-analytics"]) ? "nav-link-active" : ""}`}>
                                                Attribute analytics
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item" style={{ zIndex: 65 }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[9]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <ToolOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Tools</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/word-replacement" className={`dp-link ${activeLink(["/word-replacement"]) ? "nav-link-active" : ""}`}>
                                                Word Replacement
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/universal-scraper" className={`dp-link ${activeLink(["/universal-scraper"]) ? "nav-link-active" : ""}`}>
                                                Universal Scraper
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/scrapers-overview" className={`dp-link ${activeLink(["/scrapers-overview"]) ? "nav-link-active" : ""}`}>
                                                Scrapers Overview
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[10]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <FileWordOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Shoptok pages</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/shoktok-pages" className={`dp-link ${activeLink(["/shoktok-pages"]) ? "nav-link-active" : ""}`}>
                                                Pages
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[13]) ? "nav-item-link-active" : ""}`} style={{ pointerEvents: "none", display: "flex" }}>
                                        <FileWordOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>Catalogs</span>
                                    </Link>
                                    <ul className="dp-menu level-1">
                                        <li className="dp-item">
                                            <Link to="/create-catalog" className={`dp-link ${activeLink(["/create-catalog"]) ? "nav-link-active" : ""}`}>
                                                Create Catalog
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/catalog-list" className={`dp-link ${activeLink(["/catalog-list"]) ? "nav-link-active" : ""}`}>
                                                Catalog List
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/all-products" className={`nav-link ${activeLink(navLinks[14]) ? "nav-item-link-active" : ""}`} style={{ display: "flex" }}>
                                        <EuroOutlined className="nav-icon" />
                                        <span style={{ whiteSpace: "nowrap" }}>All Products</span>
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </div>
                    <ul className="nav" style={{ position: "relative", marginTop: "0.4rem" }}>
                        {isLoggedIn ? (
                            <React.Fragment>
                                <li style={{ marginTop: "0.3rem", marginRight: "0.2rem" }}>
                                    <Tooltip placement="bottom" title={"Create page info"} color="#113f67" /*overlayInnerStyle={{ marginTop:'0.5rem'}}*/>
                                        <EditOutlined
                                            style={{
                                                color: "lightblue",
                                                cursor: "pointer",
                                                paddingBottom: "0.6rem",
                                                marginTop: "0.5rem",
                                            }}
                                            onClick={openCreateInfoText}
                                        />
                                    </Tooltip>
                                </li>
                                <li style={{ marginRight: "0.5rem" }}>
                                    <NavInfoBtn path={path} />
                                </li>
                                <div style={{ width: "100px" }}>
                                    {" "}
                                    <CountrySelect selectCountry={selectCountry} />
                                </div>

                                <li className="nav-item" style={{ margin: "0 1.5rem", right: "12px", position: "relative", bottom: "5px" }}>
                                    <Link to="#" className={`nav-link ${activeLink(navLinks[12]) ? "nav-item-link-active" : ""}`} style={{ borderRadius: "50%" }}>
                                        <UserOutlined style={{ position: "relative", bottom: "2px", fontSize: "22px" }} />
                                    </Link>
                                    <ul className="dp-menu level-1" style={{ left: "-5rem" }}>
                                        <li className="dp-item">
                                            <Link to="/user-tasks" className={`dp-link ${activeLink(["/user-tasks"]) ? "nav-link-active" : ""}`}>
                                                Tasks
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <Link to="/edit-user" className={`dp-link ${activeLink(["/edit-user"]) ? "nav-link-active" : ""}`}>
                                                Edit Profile
                                            </Link>
                                        </li>
                                        <li className="dp-item">
                                            <div onClick={() => logOut()} className={`dp-link ${activeLink(["/shoktok-pages"]) ? "nav-link-active" : ""}`} style={{ cursor: "pointer" }}>
                                                <LogoutOutlined className="logout-icon" />
                                                <span style={{ whiteSpace: "nowrap" }}>Logout</span>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <li className="nav-item">
                                    <Link to="/login" className="nav-link">
                                        Login
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/create" className="nav-link">
                                        Register
                                    </Link>
                                </li>
                            </React.Fragment>
                        )}
                    </ul>
                </nav>
            )}
            <div
                style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "lightgray",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            ></div>
            <Modal
                title={`Create info text for page - ${capitalizeFirstLetter(`${path === "/" ? "home" : path.substring(1)}`.replaceAll("-", " "))}`}
                open={showInfoText}
                onOk={() => setShowInfoText(true)}
                onCancel={handleCancel}
                footer={false}
                width={1000}
                style={{ height: "600px!important" }}
            >
                <FadeIn delay={300} transitionDuration={800}>
                    <CreateInfoText setEditorText={setEditorText} descriptionInit={descriptionInit} />
                </FadeIn>
                <Button type="primary" style={{ marginTop: "0.5rem" }} onClick={saveInfoPageText} loading={saveInfoLoading}>
                    Save description
                </Button>
            </Modal>
        </>
    );
};

export default NavBar;
