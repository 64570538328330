import React from "react";
import { Row, Col, Form, Divider } from "antd";
import InputE from "../../../shared/FormElements/Input";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import "../style/Login.css";
import { useAuth } from "../../../shared/Context/AuthContext";

const Login: React.FC = () => {
    const [form] = Form.useForm();
    const windowSize = useWindowSize();
    const { login } = useAuth();

    return (
        <Row justify="center">
            <Col span={windowSize.width > 1830 ? 5 : windowSize.width > 1270 ? 8 : 10}>
                <Divider>Login</Divider>
                <Form form={form} name="login-user-form" onFinish={(e) => login(e)} className="login-user-box">
                    <InputE label="E-mail" name="email" rules={[{ required: true, message: "Missing email" }]} />
                    <InputE label="Password" name="password" rules={[{ required: true, message: "Missing password" }]} />
                    <Divider></Divider>
                    <button className="u-button" type="submit" style={{ padding: "5px 10px" }}>
                        Login
                    </button>
                </Form>
            </Col>
        </Row>
    );
};

export default Login;
