// get main map

import axios from "axios";
import showMessage from "../MessagesInfo/message";

const baseUrl = process.env.REACT_APP_URL_API;

export const getMainMap = async () => {
    const { data } = await axios.get(`${baseUrl}/maps/main-map`);
    const respData = data.data;
    const newData = respData.map((itemData: any) => {
        return { ...itemData, key: itemData.id };
    });

    return { ...data, data: newData };
};

export const mainMapUpdateField = async (obj: any) => {
    const { data } = await axios.put(`${baseUrl}/maps/main-map/update-field`, obj);
    showMessage(data.message);
    return data;
};

export const mainMapAddField = async (obj: any, options: any, setOptions: any, setIsModalVisible: any, form: any) => {
    const { data } = await axios.post(`${baseUrl}/maps/main-map/add-field`, obj);
    setOptions([...options, { id: data.data.id, value: data.data.key_name, label: data.data.key_name }]);
    form.resetFields();
    setIsModalVisible(false);
    showMessage(data.message);
    return data;
};

export const shopMapAddNewField = async (obj: any, setAllId: any, setIsModalVisible: any) => {
    const { data } = await axios.post(`${baseUrl}/maps/shop-map/add-field`, { ...obj, multiple_tags: obj.multiple_tags ? true : false });
    showMessage(data.message);
    setAllId((prev: any) => [...prev, data.data.main_key_id]);
    setIsModalVisible(false);
    return data;
};

export const getMainMapOptions = async (setOptions: any, setOptionsInit: any) => {
    const { data } = await axios.get(`${baseUrl}/maps/main-map`);
    const mainMapList = data.data.map((map: any) => {
        return {
            id: map.id,
            value: map.key_name,
            label: map.key_name,
            shopKeyText: "",
        };
    });
    setOptions(mainMapList);
    setOptionsInit(mainMapList);
};

//--------------show shop maps---------

export const shopMapById = async (id: number | undefined, setLoading: React.Dispatch<React.SetStateAction<boolean>>, newField: boolean) => {
    !newField && setLoading(true);

    const { data } = await axios.get(`${baseUrl}/maps/shop-map/show-map?shop_id=${id}`);
    !newField && setLoading(false);
    return data;
};

export const addNewFieldToShopMap = async (shop_id: any, tempObjects: any, headers: any, history: any, handleModalShopMapOk: () => void) => {
    const { data } = await axios.post(`${baseUrl}/maps/shop-map/add-bulk-fields?shop_id=${shop_id}`, tempObjects, headers);
    showMessage(data.message);
    handleModalShopMapOk();
    return data;
};

export const shopMapsUpdateField = async (id: any, row: any, index: any, newData: any, setEditingKey: any) => {
    const { data } = await axios.put(`${baseUrl}/maps/shop-map/update-field`, { id: id, key: row.key, tag_delimiter: row.tag_delimiter, multiple_tags: row.multiple_tags });
    showMessage(data.message);
    if (data.status) {
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            //setData(newData);
            setEditingKey("");
        } else {
            newData.push(row);
            //setData(newData);
            setEditingKey("");
        }
    }
    return data;
};
