import { Button, Divider, Form, Input, Modal } from "antd";
import axios from "axios";
import React, { useState } from "react";
import openNotification from "../../../../../shared/MessagesInfo/WarningBox";
import showMessage from "../../../../../shared/MessagesInfo/message";
import { CountryType } from "../../../../../shared/Context/type";
import { AttributeListType } from "../../../types/type";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

type CreateNewValuesType = {
    openModal: boolean;
    newAttributeValue: { name: string; attribute_id: number } | null;
    handleCancel: () => void;
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeListType[]>>;
    currentCountryName: string;
    attributeList: AttributeListType[];
};

const CreateNewValues: React.FC<CreateNewValuesType> = ({ openModal, handleCancel, newAttributeValue, setAttributeList, currentCountryName, attributeList }) => {
    const [form] = Form.useForm();
    const [loadingNewValue, setLoadingNewValue] = useState(false);

    const saveValueForAttribute = async (newId: any, newValue: string) => {
        const findAttribute = attributeList.find((a) => a.attribute_id === newAttributeValue?.attribute_id);
        try {
            const objToSend = findAttribute?.attribute_allowed_value_id
                ? {
                      product_id: findAttribute?.product_id,
                      type: findAttribute?.attribute_type,
                      product_attribute_id: findAttribute?.product_attribute_id,
                      attribute_allowed_value_id: newId, /////
                      value: null,
                  }
                : {
                      attribute_id: findAttribute?.attribute_id,
                      product_id: findAttribute?.product_id,
                      attribute_allowed_value_id: newId,
                      value: null,
                  };
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/${findAttribute?.attribute_allowed_value_id ? "update" : "assign"}-product-attribute`, objToSend);
            showMessage(data.message);
            setAttributeList((curr) => curr.map((a) => (a.attribute_id === newAttributeValue?.attribute_id ? { ...a, [`value_${currentCountryName}`]: newValue } : a)));
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    const saveNewAttributeValues = async (e: any) => {
        setLoadingNewValue(true);
        const objToSend = {
            ...e,
            attribute_id: newAttributeValue?.attribute_id,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute-value`, objToSend);
            if (data.data.already_exists) {
                openNotification("Attribute already exists");
            } else {
                setAttributeList((curr) =>
                    curr.map((a) =>
                        a.attribute_id === newAttributeValue?.attribute_id
                            ? { ...a, all_values: [...a.all_values, { allowed_value_id: data.data.values, value: e[`value_${currentCountryName}`] }] }
                            : a
                    )
                );
                saveValueForAttribute(data.data.values, e[`value_${currentCountryName}`]);
                handleCancel();
                form.resetFields();
                setLoadingNewValue(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];
    return (
        <Modal
            title={
                <div>
                    <p style={{ textAlign: "center" }}>
                        New Value for <span style={{ color: "darkred" }}>{newAttributeValue?.name}</span>
                    </p>
                </div>
            }
            open={openModal}
            onCancel={handleCancel}
            footer={false}
        >
            <Divider />
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={saveNewAttributeValues}
                autoComplete="off"
                form={form}
            >
                {localStorageCountries.map((c) => (
                    <Form.Item<FieldType>
                        label={c.name.toUpperCase()}
                        name={`value_${c.name}`}
                        rules={[{ required: true, message: `Please input your ${c.name.toUpperCase()} translate!` }]}
                        key={c.name}
                    >
                        <Input placeholder={`Attribute Value ${c.name.toUpperCase()}`} />
                    </Form.Item>
                ))}
                <Form.Item<FieldType> label={"EN"} name={`value_en`} rules={[{ required: true, message: `Please input your ${"EN"} translate!` }]} key={"en"}>
                    <Input placeholder={`Attribute Value ${"EN"}`} />
                </Form.Item>
                <Divider />
                <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="primary" htmlType="submit" loading={loadingNewValue}>
                        Save New Value
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateNewValues;
