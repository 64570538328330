import { Descriptions } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import "../style/UserDateReportInfo.css";
import { getUser } from "../../../shared/Api/Users";

const UserDateReportInfo = () => {
    const [user, setUser] = useState<any>();

    useEffect(() => {
        getUser().then((resp) => {
            setUser(resp);
        });
    }, []);

    return (
        <div style={{ width: "300px", marginTop: "3rem" }}>
            {user?.data && (
                <div className="user-date-description">
                    <Descriptions bordered size="small">
                        <Descriptions.Item label="Operater" style={{ backgroundColor: "white" }}>
                            <span className="report-name-date-info">{`${user?.data.first_name} ${user?.data.last_name}`}</span>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size="small" style={{ marginTop: "3px" }}>
                        <Descriptions.Item label="Date" style={{ backgroundColor: "white" }}>
                            <span className="report-name-date-info">{`${moment(new Date()).format("DD MMM YYYY")} ${moment(new Date()).format("HH:mm")}`}</span>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            )}
        </div>
    );
};

export default UserDateReportInfo;
