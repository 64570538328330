import { Input, Select, Popover, Radio, Form, Checkbox, Button, Badge, Popconfirm } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined, CheckOutlined } from "@ant-design/icons";
import "../style/AllPricesColumns.css";
import { useEffect, useState } from "react";
import InputE from "../../../shared/FormElements/Input";
import ButtonE from "../../../shared/FormElements/Button";
import axios from "axios";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import showMessage from "../../../shared/MessagesInfo/message";
import numberWithCommas from "../../../shared/utils/numberUtils";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import CategorySelect from "../../../shared/FormElements/CategorySelect";
import { AllPricesType } from "../types/AllPricesTypes";
import { mappedColumns } from "./AllPricesEditableCell";
import { TreeSelectCategoryType } from "../../../shared/Api/category";
const { Option } = Select;

export const usePricesColumns = (
    handleBrandChange: any,
    brands: any,
    onCategoryChange: any,
    categoryL3Options: TreeSelectCategoryType[],
    checkedSpan: any,
    setCheckedSpan: any,
    allPrices: any,
    setAllPrices: any,
    currentCountry: string,
    categoryPreDef: any,
    noCategory: any,
    handleIsNewProduct: any,
    currentPage: number,
    pricesFirstInit: any
    //setPricesFirstInit:any
) => {
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [insertInput, setInsertInput] = useState(false);
    const [preDef, setPreDef] = useState(false);
    const [preDefText, setPreDefText] = useState([]);
    const [predefTxt, setPreDefTxt] = useState("");
    const [newSeconImage, setNewSecondImage] = useState("");
    const [form] = Form.useForm();
    const windowSize: any = useWindowSize();
    const handleVisibleChange = (visible: any) => {
        setPopoverVisible(visible);
        if (!visible) {
            setPreDef(false);
        }
    };
    const addText = (e: any) => {
        setPopoverVisible(false);
        setPreDef(false);
        let newName: any = "";
        const addTexToModel: any = allPrices.data.data.map((singleData: any) => {
            if (singleData.isChecked === true) {
                switch (e.modelPosition) {
                    case "1":
                        newName = `${e.name_text} ${singleData[`name_${currentCountry}`]}`;
                        break;
                    case "2":
                        newName = `${singleData[`name_${currentCountry}`]} ${e.name_text}`;
                        break;
                    case "3":
                        newName = singleData[`name_${currentCountry}`].replace(e.name_text_old, e.name_text);
                        break;
                    case "4":
                        newName = `${e.name_text}`;
                        break;
                }
                return {
                    ...singleData,
                    //isChanged:true,
                    [`name_${currentCountry}`]: newName,
                };
            } else {
                return singleData;
            }
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: addTexToModel } });
        setInsertInput(false);
        form.resetFields();
    };
    const preDefChange = (e: any) => {
        setPreDef(e.target.checked);
    };
    useEffect(() => {
        const getPredefinedText = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/predefined-word/list?l3_l4_id=${categoryPreDef}`);
                setPreDefText(data.data);
            } catch (err: any) {
                console.log(err);
            }
        };
        if (preDef) {
            getPredefinedText();
        }
    }, [preDef, categoryPreDef]);

    const onPreDefChange = (value: any) => {
        const findText: any = preDefText.find((text: any) => text.id === value);
        if (findText) {
            let addPreDefText: string | number = "";
            addPreDefText = `${findText[`name_${currentCountry}`]}`;
            form.setFieldsValue({ name_text: addPreDefText });
            setTimeout(() => {
                setPreDef(false);
            }, 300);
        }
    };

    const createPredefinedText = async (obj: any) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/predefined-word/create?country_id=1&l3_l4_id=${obj.level_id}&${`name_${currentCountry}`}=${obj.name}`, obj);
            showMessage(data.message);
            form.resetFields();
        } catch (err: any) {
            console.log(err);
        }
    };

    const addNewPredefinedText = () => {
        if (!categoryPreDef) {
            openNotification("Category must be determinated");
        } else if (!predefTxt) {
            openNotification("Predeftext must be determinated");
        } else {
            const objToSend = {
                name: predefTxt,
                level_id: categoryPreDef,
            };
            createPredefinedText(objToSend);
            setTimeout(() => {
                setPreDef(false);
            }, 300);
        }
    };

    const modalContent = (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Form onFinish={addText} initialValues={{ modelPosition: "1" }} form={form}>
                {insertInput && <InputE label="Old Text" name="name_text_old" rules={[{ required: true, message: "Missing old model text" }]} />}
                <InputE label="New Text" name="name_text" rules={[{ required: true, message: "Missing model text" }]} className="inupt-old-text" />
                <Checkbox checked={preDef} onChange={preDefChange} id="preDef" />{" "}
                <label htmlFor="preDef" style={{ marginRight: "5px" }}>
                    Predefined text
                </label>
                {preDef && (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "220px", marginBottom: "0.5rem" }}
                        placeholder="Choose predefined text"
                        defaultValue={""}
                        onKeyUp={(e: any) => setPreDefTxt(e.target.value)}
                        onChange={onPreDefChange}
                        filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {preDefText &&
                            preDefText.map(
                                (option: any) => (
                                    <Option key={option.id} value={option.id}>
                                        {option[`name_${currentCountry}`]}
                                    </Option>
                                ) //option.translate &&  option.id!==1 &&    ovo da se vrati
                            )}
                    </Select>
                )}
                {preDef && (
                    <Button type="primary" size="small" shape="circle" style={{ marginLeft: "5px" }} onClick={addNewPredefinedText}>
                        S
                    </Button>
                )}
                <Form.Item key="modelPosition" label="Position" name="modelPosition">
                    <Radio.Group name="radiogroup" onChange={(e) => setInsertInput(e.target.value === "3" ? true : false) /*setInsertInput ()*/}>
                        <Radio value={"1"}>Start</Radio>
                        <Radio value={"2"}>End</Radio>
                        <Radio value={"3"}>Replace</Radio>
                        <Radio value={"4"}>Replace All</Radio>
                    </Radio.Group>
                </Form.Item>
                <ButtonE type="primary" htmlType="submit" title="Add text" wrapperCol={{ offset: 9, span: 8 }} />
            </Form>
        </div>
    );

    const onSearch = (e: any) => {
        const findPricesByModel = pricesFirstInit.data.data /*.slice(100 * ( currentPage -1 ), 100 * ( currentPage - 1) + 100)*/
            .filter((price: any) => price[`name_${currentCountry}`] && price[`name_${currentCountry}`].toLowerCase().includes(e.target.value));
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: findPricesByModel } });
    };

    const checkItem = (id: number) => {
        const checedItem = allPrices.data.data.map((price: any) => {
            if (price.id === id) {
                return {
                    ...price,
                    isChecked: !price.isChecked,
                };
            }
            return price;
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: checedItem } });
    };

    const handleCheckedPrices = (e: any) => {
        if (e.target.checked) {
            const checedItem = allPrices.data.data.map((price: any) => {
                return {
                    ...price,
                    isChecked: true,
                };
            });
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: checedItem } });
        } else {
            const checedItem = allPrices.data.data.map((price: any) => {
                return {
                    ...price,
                    isChecked: false,
                };
            });
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: checedItem } });
        }
    };

    type ImageSecond = {
        id: number | null;
        product_id: number | null;
        name: string | null;
    };
    const [imageSecond, setImageSecond] = useState<ImageSecond>({ id: null, product_id: null, name: "" });

    const setSecondImage = (record: any, imagePrice: any) => {
        const findPrice = allPrices.data.data.map((price: any) => {
            if (price.id === record.id) {
                const findImage = record.images.map((img: any) => {
                    if (img.name === imagePrice) {
                        setImageSecond({ ...img, product_id: price.product_id, id: price.id });
                        return {
                            ...img,
                            isChechedImg: true,
                        };
                    }
                    return {
                        ...img,
                        isChechedImg: false,
                    };
                });
                if (findImage) {
                    return {
                        ...price,
                        images: findImage,
                    };
                }

                return price;
            }
            const unCheckImages = price.images.map((img: any) => {
                return {
                    ...img,
                    isChechedImg: false,
                };
            });
            return {
                ...price,
                images: unCheckImages,
            };
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: findPrice } });
    };

    const saveNewSecondImage = async () => {
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/image/set-default-image?product_id=${imageSecond.product_id}&name=${imageSecond.name}`);
            showMessage(data.message);
            const findPrice = allPrices.data.data.map((price: any) => {
                if (price.product_id === imageSecond.product_id) {
                    // to check price_id or product_id
                    const filteredImages = price.images.filter((img: any) => img.name !== imageSecond.name);
                    return {
                        ...price,
                        isChechedImg: false,
                        images:
                            filteredImages.length === 0
                                ? price.default_image_url
                                    ? [{ name: price.default_image_url.slice(3) }]
                                    : []
                                : [...filteredImages, ...(price.default_image_url && { name: price.default_image_url.slice(3) })],
                        default_image_url: data.data.name && `${data.data.name.slice(0, 2)}/${data.data.name}`,
                    };
                }
                return price;
            });
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: findPrice } });
            setImageSecond({ id: null, product_id: null, name: "" });
        } catch (err) {
            console.log(err);
        }
    };
    const deleteSecondImage = async () => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/image/delete-image?product_id=${imageSecond.product_id}&name=${imageSecond.name}`);
            showMessage(data.message);
            const findPrice = allPrices.data.data.map((price: any) => {
                if (price.product_id === imageSecond.product_id) {
                    const findImage = price.images.filter((img: any) => img.name !== imageSecond.name);
                    if (findImage) {
                        if (price.product_id === imageSecond.product_id) {
                            return {
                                ...price,
                                images: findImage,
                            };
                        }
                    }
                    return price;
                }
                return price;
            });
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: findPrice } });
            setImageSecond({ id: null, product_id: null, name: "" });
        } catch (err) {
            console.log(err);
        }
    };

    const addNewSecondImage = async (record: any) => {
        const obj = {
            product_id: record.product_id,
            image: newSeconImage,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/image/add-image`, obj);
            const findImage = allPrices.data.data.map((price: any) => {
                if (price.id === record.id) {
                    const unCheckedImages = price.images.map((i: any) => {
                        return { ...i, isChechedImg: false };
                    });
                    const newImages = [...unCheckedImages, { ...data.data, name: data.data.name.slice(3), isChechedImg: false }];
                    return {
                        ...price,
                        images: newImages,
                    };
                }
                return price;
            });
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: findImage } });
            showMessage(data.message);
            setNewSecondImage("");
        } catch (err) {
            console.log(err);
        }
    };

    const sellerContent = (record: AllPricesType) => (
        <div>
            {record.seller_image_url ? <img style={{ width: "40px" }} alt="seller-img" src={record.seller_image_url} /> : <p>Seller Image: - </p>}
            <br />
            {
                /*uncategorized ||*/ !noCategory ? (
                    record.disabled === 1 ? (
                        ""
                    ) : (
                        ""
                    )
                ) : (
                    <Badge style={{ position: "relative", top: "0px", left: "0px", backgroundColor: "#1d9696" }} count={record.prices_count} />
                )
            }{" "}
            <br />
            Brand: {record.product_manufacturer} <br />
            Model: {record.product_model}
            <br />
            Product Name: <b>{record.product_name}</b> <br />
            Category: <b>{record.seller_category}</b>
            <br />
            SKU: <b>{record.sku}</b>
            <br />
            Price: <b>{numberWithCommas(String(record.price).replace(".", ","))}</b>
            <br />
            {/* uncategorized || !noCategory ? record.disabled ===1   ? <CloseOutlined style={{position:'absolute', bottom:'4px',top:'10px',right:'10px', color:'red'}}  />:<CheckOutlined style={{position:'absolute', bottom:'4px',top:'10px',right:'10px', color:'green'}} />:'' */}
            {
                /*!uncategorized && */ noCategory && (
                    <p>
                        {" "}
                        Status: <b>{record.status}</b>
                    </p>
                )
            }
        </div>
    );

    const defaultColumns: any = [
        {
            title: (
                <Checkbox onChange={handleCheckedPrices} checked={allPrices.data && (allPrices.data.data.find((price: any) => !price.isChecked) || allPrices.data.data.length === 0) ? false : true} />
            ),
            dataIndex: "id",
            key: "id",
            align: "center" as "center",
            render: (text: number, record: any, index: any) => (
                <div style={{ cursor: "pointer", width: "100%", height: "100%", padding: "10px" }} /*onDoubleClick={() => ignorePrice(text)}*/ onClick={() => checkItem(text)}>
                    {<CheckOutlined style={{ opacity: record.isChecked ? "1" : "0", color: record.condition > 1 ? "red" : "" }} />}
                </div>
            ),
            width: "3%",
        },
        {
            title: (
                <div>
                    <span>Product Id</span>
                    <span style={{ position: "absolute", top: "45px", right: "32px" }}></span>
                </div>
            ),
            dataIndex: ``,
            key: "",
            children: [
                {
                    title: <Badge count={allPrices.data && allPrices.data.data.filter((price: any) => price.isChecked === true).length} overflowCount={100} style={{ backgroundColor: "#1d9696" }} />,
                    dataIndex: "product_id",
                    key: "product_id",
                    width: "5%",
                    render: (text: any, record: any, index: any) => (
                        <div style={{ display: "flex", flexDirection: "column-reverse", justifyContent: "center", alignItems: "center" }}>
                            {record.condition > 1 && <div style={{ fontSize: "18px", width: "8px", height: "8px", borderRadius: "4px", backgroundColor: "#1f4d7e" }} />}
                            <span
                                className={`product-id-cell ${index === checkedSpan[0] && "checked-item"}`}
                                style={{
                                    color: record.isNewProduct === true ? "red" : "",
                                    textDecorationLine: record.isNewProduct === true ? "underline" : "",
                                    backgroundColor: record.isChanged === true ? "lightgreen" : "",
                                }}
                                onClick={() => setCheckedSpan([...checkedSpan, index])}
                            >
                                {text}{" "}
                            </span>
                            {index === checkedSpan[0] && <CheckCircleOutlined className="checked-first-item" />}
                        </div>
                    ),
                },
            ],
        },
        {
            title: `Image`,
            dataIndex: ``,
            key: "",
            children: [
                {
                    title: "",
                    dataIndex: "default_image_url",
                    key: "default_image_url",
                    align: "center" as "center",
                    width: "4%",
                    render: (text: any, record: any, index: any) => {
                        const content = (
                            <div style={{ width: "560px" }}>
                                <div style={{ display: "flex" }}>
                                    <Input onChange={(e) => setNewSecondImage(e.target.value)} value={newSeconImage} className="second-image-input" placeholder="New Image Url" />
                                    <Button type="dashed" style={{ marginLeft: "5px" }} onClick={() => addNewSecondImage(record)} disabled={newSeconImage ? false : true}>
                                        Save
                                    </Button>
                                </div>
                                <img
                                    alt="Product Img"
                                    style={{ height: "500px", width: "100%", margin: "10px 0" }}
                                    src={
                                        record.new_image_url
                                            ? record.new_image_url
                                            : record.default_image_url
                                            ? `https://img.ep-cdn.com/images/500/500/${record.default_image_url}.webp`
                                            : record.data_entry_image_url
                                            ? record.data_entry_image_url
                                            : record.seller_image_url
                                    }
                                />
                                <hr />
                                <div className="second-images-galery">
                                    <div className="second-image-scroll">
                                        {record.images &&
                                            record.images.length > 0 &&
                                            record.images.map((secondImage: any) => (
                                                <img
                                                    key={secondImage.name}
                                                    src={`https://img.ep-cdn.com/images/160/160/${secondImage.name.slice(0, 2)}/${secondImage.name}.webp`}
                                                    onClick={() => setSecondImage(record, secondImage.name)}
                                                    style={{ border: secondImage.isChechedImg ? "1px solid red" : "" }}
                                                    className="second_image"
                                                    alt="second img"
                                                />
                                            ))}
                                    </div>

                                    <div>
                                        <Popconfirm placement="top" title={"Are you sure?"} onConfirm={saveNewSecondImage} okText="Yes" cancelText="No">
                                            <Button type="primary" size="small" disabled={record.images.find((i: any) => i.isChechedImg) ? false : true}>
                                                Set
                                            </Button>
                                        </Popconfirm>
                                        <Popconfirm placement="top" title={"Are you sure?"} onConfirm={deleteSecondImage} okText="Yes" cancelText="No">
                                            <Button danger style={{ marginLeft: "5px" }} size="small" disabled={record.images.find((i: any) => i.isChechedImg) ? false : true}>
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            </div>
                        );
                        return (
                            <Popover placement="rightTop" content={content} arrowPointAtCenter>
                                <img
                                    style={{ width: "39px", height: "39px", border: (record.new_image_url || record.new_image_url === "wrong_image") && "1px solid red" }}
                                    alt="Product Img"
                                    src={
                                        record.new_image_url
                                            ? record.new_image_url
                                            : record.default_image_url
                                            ? `https://img.ep-cdn.com/images/160/160/${record.default_image_url}.webp`
                                            : record.data_entry_image_url
                                            ? record.data_entry_image_url
                                            : record.seller_image_url
                                    }
                                />
                            </Popover>
                        );
                    },
                },
            ],
        },
        {
            title: "Brand",
            dataIndex: `brand_name`,

            key: "brand_name",
            children: [
                {
                    title: (
                        <Select
                            showSearch
                            //allowClear={undefined}
                            style={{ width: "100%", marginBottom: "0.5rem" }}
                            placeholder="Select brand"
                            defaultActiveFirstOption
                            onSelect={handleBrandChange}
                            className="all-prices-column-brand"
                            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {brands &&
                                brands.map((brand: any) => (
                                    <Option key={brand.id} value={brand.id} style={{ display: !brand.name ? "none" : "" }}>
                                        {brand.name}
                                    </Option>
                                ))}
                        </Select>
                    ),
                    dataIndex: "brand_name",
                    key: "brand_name",
                    align: "center" as "center",
                    width: "11%",
                    render: (text: any, record: any, index: any) => <strong style={{ color: record.isNewProduct ? "red" : "" }}>{text}</strong>,
                },
            ],
        },
        {
            title: (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Popover content={modalContent} style={{ paddingTop: "20px" }} onOpenChange={handleVisibleChange} open={popoverVisible} placement="bottom" title="Change model" trigger="click">
                        <span style={{ cursor: "pointer" }}>Editable name</span>
                    </Popover>

                    <Input placeholder="Filter editable name" onChange={onSearch} style={{ width: "260px", marginBottom: "0.4rem" }} id="input-editable-name" />
                </div>
            ),
            dataIndex: `name_${currentCountry}`,
            key: `name_${currentCountry}`,
            width: "35%",
            editable: true,
            className: "all-prices-editable-name-td all-prices-editable-cell",
        },
        {
            title: "",
            dataIndex: "id",
            width: "35px",
            align: "center" as "center",
            className: "all-prices-editable-info-icon-td",
            render: (name: string, record: AllPricesType, index: number) => (
                <Popover content={sellerContent(record)}>
                    <InfoCircleOutlined
                        onClick={() => /*!uncategorized ||*/ !noCategory && handleIsNewProduct(record.id)}
                        style={{ color: !record.isNewProduct ? "rgba(0,0,0,.45)" : "red", position: "relative", bottom: "3px" }}
                    />
                </Popover>
            ),
        },
        {
            title: (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
                    <span style={{ position: "relative", top: "-20px" }}>MPN</span>
                </div>
            ),
            dataIndex: "mpn",
            key: "mpn",
            className: "all-prices-editable-cell",
            editable: true,
            //render: (text:any, record:any, index:any) => <Input value={ text } style={{width:'100%'}}  onChange={onInputChange("mpn", index)} className={`mpn-input ${record.isChanged === true && 'changed-item'} ${ record.isNewProduct === true && 'input-color-new'}`}  />  //: text
        },

        {
            title: `Seller MPN`,
            dataIndex: ``,
            width: windowSize.width < 2000 ? "10%" : "",
            key: "",
            children: [
                {
                    title: "",
                    dataIndex: "product_mpn",
                    key: "product_mpn",
                    render: (record: string) => <span style={{ marginLeft: "10px" }}>{record}</span>,
                },
            ],
        },
        {
            title: "Category",
            dataIndex: `category_name_${currentCountry}`,
            key: `category_name_${currentCountry}`,
            children: [
                {
                    title: <CategorySelect onCategoryChange={onCategoryChange} categoryL3Options={categoryL3Options} currentCountry={currentCountry} />,
                    dataIndex: `category_name_${currentCountry}`,
                    key: `category_name_${currentCountry}`,
                    align: "center" as "center",
                    width: "17%",
                    render: (text: any, record: any, index: any) =>
                        text === undefined ? (
                            <span style={{ color: "red" }}>NONE</span>
                        ) : (
                            <strong style={{ color: record.category_l3_id === 0 ? "#038cfc" : record.isNewProduct ? "red" : "" }}>{record.category_l3_id === 0 ? "Categorized" : text}</strong>
                        ),
                },
            ],
        },
        {
            title: `Link`,
            dataIndex: ``,
            key: "",
            children: [
                {
                    title: "",
                    dataIndex: `url_${currentCountry}`,
                    key: `url_${currentCountry}`,
                    align: "center" as "center",
                    width: "4%",
                    render: (text: any, record: any, index: any) => (
                        <>
                            <a
                                href={record.product_url}
                                onClick={() => window.open(record.product_url, "", "width=900,height=1080")}
                                className="shop-link"
                                style={{ color: "#01b0f5", textDecoration: "underline" }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Shop
                            </a>{" "}
                            /{" "}
                            <a
                                target="_blank"
                                className="front-link"
                                style={{ color: "#01b0f5", textDecoration: "underline" }}
                                onClick={() => window.open(record[`url_${currentCountry}`], "", "width=900, height=1080, top=0, left=960")}
                                rel="noreferrer"
                                href={record[`url_${currentCountry}`]}
                            >
                                Front
                            </a>
                        </>
                    ),
                },
            ],
        },
        {
            title: `Shop`,
            dataIndex: "",
            key: "",
            children: [
                {
                    title: "",
                    dataIndex: "shop_name",
                    key: "shop_name",
                    align: "center" as "center",
                    width: "6%",
                },
            ],
        },
    ];
    //////////////////////////////
    const handleSave = (row: AllPricesType & { key: string }) => {
        const newData = [...allPrices.data.data];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        //setDataSource(newData);
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: newData } });
    };

    const columns = mappedColumns(defaultColumns, handleSave);

    //////////////////////////////////////////
    return { columns };
};
