import React from "react";
import { Row, Col, Divider, Select, Table, Checkbox, AutoComplete, /*Pagination,*/ Switch, Modal, Popconfirm, Radio, Statistic, Spin, Button } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import "../style/AllPrices.css";
import { CaretDownOutlined, LoadingOutlined, SaveOutlined /*, RightCircleOutlined, LeftCircleOutlined*/ } from "@ant-design/icons";
import { useAllPrices } from "../hooks/useGetAllPrices";
import { usePricesColumns } from "../components/allPricesColumns";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import numberWithCommas from "../../../shared/utils/numberUtils";
import AllPricesMpnModal from "../components/AllPricesMpnModal";
import useKeyEventAllPrices from "../hooks/useKeyEventAllPrices";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import CretePredefinedTextModal from "../components/CretePredefinedTextModal";
import SkuModal from "../components/skuModal";
import CorrectWordModal from "../components/CorrectWordModal";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
//import NewPagination from '../../../shared/Pagination/Pagination'
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import OurCategories from "../components/OurCategories";
import { useCountAllPrices } from "../hooks/useCountAllPrices";
import { editableCellcomponents } from "../components/AllPricesEditableCell";
import { ShopType, TreeCategoryType } from "../types/AllPricesTypes";
import ApplyToAllModal from "../components/ApplyToAllModal";
import { getAllCategories, TreeSelectCategoryType } from "../../../shared/Api/category";
import { useAuth } from "../../../shared/Context/AuthContext";

let notShowPrices: any = [];

const AllPrices: React.FC = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    const { currentCountry } = useAuth();
    const [darkMode, setDarkMode] = useState(false);
    //state
    const [allPrices, setAllPrices] = useState<any>([]);
    const [pricesFirstInit, setPricesFirstInit] = useState<any>({});
    //init state
    //const [ initPrices, setInitPrices ] = useState([])
    ///
    const [noCategory, setNoCategory] = useState(true);
    //shop list
    const [shopList, setShopList] = useState<ShopType[][]>([]);
    //const [ country, setCountry ] = useState(1)
    const [selectedShops, setSelectedShops] = useState("");
    //for search field
    const [searchField, setSeatchField] = useState("");
    //for sort
    const [sortArr, setSortArr] = useState(null);
    //for shop-category
    const [fillSelectSC, setFillSelectSC] = useState([]);
    //multi select
    const [multySelected, setMultySelected] = useState<any>([]);

    //products in modal
    const [modalProduct, setModalProduct] = useState<any>([]);

    //  mpn item
    const [mpnItem, setMpnItem] = useState<any>({});
    const [mpnObj, setMpnObj] = useState<any>({
        price_id: "",
        brand_id: 0,
        mpn: "",
        model: "",
        type: "",
        condition: "",
        product_id: "",
    });

    const [modalProductLoading, setModalProductLoading] = useState(false);

    const [categoryPreDef, setCategoryPreDef] = useState<number | string>("");

    //categorized - auto-matching

    //const [ uncategorized, setUncategorized ] = useState(true)

    //for reload page with the same parameters

    const [sameParam, setSameParam] = useState(0);

    //for opening drawer

    //const [ visibleDrawer, setVisibleDrawer ] = useState(false);

    //for shortcut modal
    const [modalShortcuts, setModalShortcuts] = useState(false);

    // for type of search - key event

    const [searchType, setSearchType] = useState<string | number>("");

    //modal for predefined text

    const [isModalPredefinedText, setIsModalPredefinedText] = useState(false);

    //------show sku modal
    const [isModalSku, setIsModalSku] = useState(false);
    const showSkuModal = () => {
        setIsModalSku(true);
    };
    //------mpnModal
    const [mpnModal, setMpnModal] = useState<any>({
        visible: false,
        disabled: true,
        bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    });
    //------correct latin word
    const [correctWordModal, setCorrectWordModal] = useState(false); //--------------------------------------------------------------------------------------
    //-----count item---

    const [countDone, setCountDone] = useState(0);

    /////////////////////////////////////
    const [condition, setCondition] = useState("0");
    const handlePriceStatus = (e: string) => {
        setCondition(e);
    };

    const [unMatched, setUnmatched] = useState(0);

    const [userFilter, setUserFilter] = useState(false);

    //------window size------     ++++++++++++++++++++++++++++++++ to check
    const windowSize = useWindowSize();

    //--------our coategories------------

    const [searchLoading, setSearchLoading] = useState(false);

    const [levelCategories, setLevelCategories] = useState<TreeCategoryType[]>([]);
    const [ourCateogry, setOurCategory] = useState<{ level: string; id: string } | null>(null);

    useEffect(() => {
        const getlevelCategories = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-categories?country_id=${currentCountry.id}`);
                const newData = data.data.map((level1: TreeCategoryType) => ({
                    value: `1::${level1.id}::${level1[`name_${currentCountry.name}`]}`,
                    title: `(L1) ${level1.id}: ${level1[`name_${currentCountry.name}`]}`,
                    children: level1.children.map((level2) => ({
                        value: `2::${level2.id}::${level2[`name_${currentCountry.name}`]}`,
                        title: `(L2) ${level2.id}: ${level2[`name_${currentCountry.name}`]}`,
                        children: level2.children.map((level3) => ({
                            value: `3::${level3.id}::${level3[`name_${currentCountry.name}`]}`,
                            title: `(L3) ${level3.id}: ${level3[`name_${currentCountry.name}`]}`,
                            children: level3.children.map((level4) => ({
                                value: `4::${level4.id}::${level4[`name_${currentCountry.name}`]}`,
                                title: `(L4) ${level4.id}: ${level4[`name_${currentCountry.name}`]}`,
                            })),
                        })),
                    })),
                }));

                setLevelCategories(newData);
            } catch (err) {
                console.log(err);
            }
        };
        !noCategory && getlevelCategories();
    }, [currentCountry.id, currentCountry.name, noCategory]);

    //*******for skus */
    const [skuTextArea, setSkuTextArea] = useState<any>("");

    //****************FIRST INIT PRICES******************/

    const [currentPage, setCurrentPage] = useState(1);

    //const [ pricePage, setPricePage ] = useState(1)

    const [existInXml, setExistInXML] = useState(false);

    const [sfActive, setSfActive] = useState(0);

    /*const onPaginateChange = (page:any) => {
        /////////////----------------console.log for degug----------------------/////////////////
        console.log('page', page)
        console.log('initPrices', initPrices)
        console.log('allPrices', allPrices.data.data)
        console.log('currentPage', currentPage)
        //////////////////////////////////////////////////
        setCurrentPage(page)
        const sliceDataPrices = pricesFirstInit.data.data.slice(100 * ( page -1 ), 100 * ( page - 1) + 100).filter((price:any) => !notShowPrices.includes(price.id))
        ///////////////////////////////----------------console.log for degug----------------------/////////////////
        console.log('sliceDataPrices', sliceDataPrices)
        //////////////////////////////
        setAllPrices({...allPrices,data:{ ...allPrices.data, data: sliceDataPrices}})
    }*/
    const { Option } = Select;

    //------hooks------
    const { getPricesByShop } = useAllPrices(
        selectedShops,
        noCategory,
        searchField,
        sortArr,
        fillSelectSC,
        setSearchLoading,
        //uncategorized,
        sameParam,
        setCountDone,
        setAllPrices,

        currentPage, ///////////////////// here to change
        skuTextArea,
        existInXml,
        ourCateogry,
        condition,
        sfActive,
        unMatched,
        userFilter,
        setPricesFirstInit
    );

    const [countPrices, setCountPrices] = useState<number | null>(null);
    const [isLoadingCount, setIsLoadingCount] = useState(false);

    const { getAllPricesCount } = useCountAllPrices(
        selectedShops,
        noCategory,
        searchField,
        sortArr,
        fillSelectSC,
        setSearchLoading,
        //uncategorized,
        sameParam,
        setCountDone,
        setAllPrices,

        currentPage, ////--------------  here to change
        skuTextArea,
        existInXml,
        ourCateogry,
        condition,
        sfActive,
        unMatched,
        userFilter,
        allPrices,
        setCountPrices,
        setIsLoadingCount,
        currentPage
    );
    //-----     prevent go back    ------//
    useEffect(() => {
        window.onbeforeunload = function (evt: any) {
            var message = "Are you sure you want to leave?";
            if (typeof evt == "undefined") {
                evt = window.event;
            }
            if (evt) {
                evt.returnValue = message;
            }
            return message;
        };
    }, []);

    const [selectTemp, setSelectTemp] = useState(0);

    //**********************    MATCH   ********************/

    //------Match    type:0, matching all
    const mpnMultiSelect = () => {
        setSearchType(0);
        const filteredSelected = allPrices.data.data.filter((price: any) => price.isChecked === true /*&& price.isNewProduct === false*/);
        setMultySelected(filteredSelected);
        oneSelect(filteredSelected[0]);
    };

    //------Match    type:4, matching MODEL
    const matchModel = () => {
        setSearchType(4);
        const filteredSelected = allPrices.data.data.filter((price: any) => price.isChecked === true && price.isNewProduct === false);
        setMultySelected(filteredSelected);
        oneSelect(filteredSelected[0]);
    };

    //--------Match type:3, matching Brand_id and Model

    const matchBrandModel = () => {
        setSearchType(3);
        const filteredSelected = allPrices.data.data.filter((price: any) => price.isChecked === true && price.isNewProduct === false);
        setMultySelected(filteredSelected);
        oneSelect(filteredSelected[0]);
    };

    //---------Match type:2, matching mpn

    const matchMpn = () => {
        const filteredSelected = allPrices.data.data.filter((price: any) => price.isChecked === true && price.isNewProduct === false);
        if (filteredSelected[0].mpn !== null && filteredSelected[0].mpn !== "" && filteredSelected[0].mpn.trim().length > 0) {
            setSearchType(2);
            setMultySelected(filteredSelected);
            oneSelect(filteredSelected[0]);
        } else {
            openNotification("Mpn is not defined.");
        }
    };

    //---------Match type:1, matching brand_id and mpn

    const matchBrandMpn = () => {
        const filteredSelected = allPrices.data.data.filter((price: any) => price.isChecked === true && price.isNewProduct === false);
        if (filteredSelected[0].mpn !== null && filteredSelected[0].mpn !== "" && filteredSelected[0].mpn.trim().length > 0) {
            setSearchType(1);
            setMultySelected(filteredSelected);
            oneSelect(filteredSelected[0]);
        } else {
            openNotification("Mpn is not defined.");
        }
    };
    const oneSelect = (selectPrice: any) => {
        if (selectTemp < multySelected.length + 1 && selectPrice) {
            setMpnObj({
                price_id: selectPrice.id,
                brand_id: selectPrice.brand_id,
                mpn:
                    selectPrice.mpn === null || selectPrice.mpn.trim() === "" || (selectPrice.mpn !== null && (selectPrice.mpn.trim() === "" || selectPrice.mpn.trim() === undefined))
                        ? null
                        : selectPrice.mpn,
                model: selectPrice[`name_${currentCountry.name}`],
                type: 0,
                condition: selectPrice.condition,
                product_id: selectPrice.product_id,
            });
            setMpnItem(selectPrice);
            setSelectTemp((prev: number) => prev + 1);
            setModalProduct([]);
        }
    };
    const skipPrice = (selectPrice: any) => {
        if (selectTemp < multySelected.length) {
            oneSelect(multySelected[selectTemp]);
        }

        if (selectTemp === multySelected.length) {
            setSelectTemp(0);
            setMultySelected([]);
        }
        const newChecked = allPrices.data.data.map((price: any) => {
            if (price.id === mpnItem.id) {
                return {
                    ...price,
                    isChecked: false,
                };
            }
            return price;
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: newChecked } });
        setMultySelected((prev: any) => prev.filter((data: any) => data.id !== mpnItem.id));
        setSelectTemp((prev: number) => prev - 1);
    };

    //for new product

    const handleIsNewProduct = (id: any) => {
        const newProducts = allPrices.data.data.map((price: any) => {
            if (price.id === id) {
                return {
                    ...price,
                    isNewProduct: !price.isNewProduct,
                };
            }
            return price;
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: newProducts } });
    };

    //for modal

    const handleOk = () => {
        setModalShortcuts(false);
    };

    const handleCancel = () => {
        setModalShortcuts(false);
    };

    //for mpn modal

    const handleMpnModalOk = () => {
        setMpnModal({
            ...mpnModal,
            visible: false,
        });
        setMpnObj({
            price_id: "",
            brand_id: 0,
            mpn: "",
            model: "",
            type: "",
            condition: "",
            product_id: "",
        });
        setModalProduct([]);
        setSelectTemp(0);
        setMultySelected([]);

        const newChecked = allPrices.data.data.map((price: any) => {
            if (price.id === mpnItem.id) {
                return {
                    ...price,
                    isChecked: false,
                };
            }
            return price;
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: newChecked } });
    };

    ////////////////////
    const [checkedSpan, setCheckedSpan] = useState([]);

    useKeyEventAllPrices(
        allPrices,
        setAllPrices,
        currentCountry.id,
        currentCountry.name,
        mpnMultiSelect,
        //mpnItem,
        setMpnItem,
        //onSelectChange,
        setSameParam,
        //setVisibleDrawer,
        //uncategorized,
        noCategory,
        matchModel,
        matchBrandModel,
        matchMpn,
        matchBrandMpn,
        setIsModalPredefinedText,
        showSkuModal,
        setModalShortcuts,
        //addSpecification,
        notShowPrices,
        mpnModal,

        setDarkMode,
        setCorrectWordModal
    );
    //check all checkbox

    //--------get all shops
    useEffect(() => {
        if (checkedSpan.length === 2) {
            const checkItems = allPrices.data.data.map((item: any, index: any) => {
                if (index > checkedSpan[0] - 1 && index < checkedSpan[1] + 1)
                    return {
                        ...item,
                        isChecked: true,
                    };
                else {
                    return item;
                }
            });
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: checkItems } });
            //setTimeout(() => {
            setCheckedSpan([]);
            //}, 300)
        }
    }, [checkedSpan, allPrices, setAllPrices]);

    useEffect(() => {
        const getShops = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/shop/list-user-shops?active=${sfActive}${currentCountry.id ? `&country_id=${currentCountry.id}` : ""}&user_filter=${userFilter ? 1 : 0}`
                );
                //dispatch({ type:SHOP_ALL,payload:data})
                //console.log(data.data)

                let activeShops: ShopType[] = [];
                let inactiveShops: ShopType[] = [];
                data.data.forEach((shop: ShopType) => {
                    if (shop.active === 1) {
                        activeShops.push({ ...shop, label: `${shop.name} (${shop.prices_count})`, value: shop.id });
                    } else {
                        inactiveShops.push({ ...shop, label: `${shop.name} (${shop.prices_count})`, value: shop.id });
                    }
                });

                // console.log(activeShops)
                // console.log(inactiveShops)

                // const newData = [
                //     {
                //         label: 'Active Shops',
                //         options: activeShops
                //     },
                //     {
                //         label: 'Inactive Shops',
                //         options: inactiveShops
                //     },
                // ]

                // console.log(newData)

                setShopList([activeShops, inactiveShops]);
            } catch (err) {
                //errorMessage(err.response.data.message)
                console.log(err);
            }
        };
        getShops();
    }, [currentCountry.id, sfActive, userFilter]);

    // useEffect(()=>{
    //     dispatch(getAllShops(sfActive, currentCountryId))  //  ??????????????????????
    // }, [ dispatch, sfActive, currentCountryId ])

    //---------set Currrent page

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedShops, setCurrentPage]);

    // const handleSelectedShops = (value:any) => {
    //     setSkuTextArea('')
    //     setSelectedShops(value)
    //     setCurrentPage(1)
    //     //setPricePage(1)
    // }

    ///////////////////////////////////////////////////////////
    const [categories, setCategories] = useState<TreeSelectCategoryType[]>([]);
    useEffect(() => {
        getAllCategories(currentCountry.id, currentCountry.name).then((resp) => {
            setCategories(resp);
        });
    }, [currentCountry.id, currentCountry.name]);

    const onCategoryChange = (value: number) => {
        ////////////////   choosen item on first place
        const findCategoryL3 = categories.find((category3) => category3.id === value);
        let oneCategory: typeof findCategoryL3;
        if (findCategoryL3) {
            oneCategory = findCategoryL3;
        } else {
            categories.forEach((category3) => {
                category3.children &&
                    category3.children.forEach((category4) => {
                        if (category4.id === value) {
                            oneCategory = category3;
                        }
                    });
            });
        }
        const filteredCategories = categories.filter((category) => category.id !== oneCategory?.id);
        oneCategory && setCategories([oneCategory, ...filteredCategories]); //set category on first place on arr

        ////////////////////////////////////

        const allCateogies: { id: number; value: string; children?: { id: number; value: string }[] }[] = [];
        categories &&
            categories.forEach((data: any) => {
                allCateogies.push({
                    id: data.id,
                    value: data[`name_${currentCountry.name}`],
                });
                if (data.children) {
                    data.children.forEach((childData: { id: number; value: string }) => {
                        allCateogies.push({
                            id: childData.id,
                            value: childData[`name_${currentCountry.name}` as keyof { value: string }],
                        });
                    });
                }
            });
        const findCategory = allCateogies.find((category) => Number(category.id) === Number(value));
        if (findCategory) {
            setCategoryPreDef(findCategory.id);
        }
        const addCatgegory =
            allPrices.data &&
            allPrices.data.data.map((category: { id: number; isChecked: boolean; value: string; children?: { id: number; value: string }[] }) => {
                if (category.isChecked === true) {
                    return {
                        ...category,
                        isChanged: true,
                        category_l3_id: value && findCategory?.id,
                        [`category_name_${currentCountry.name}`]: value && findCategory && findCategory.value,
                    };
                } else {
                    return category;
                }
            });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: addCatgegory } });
    };

    const [brandList, setBrandList] = useState<any>([]);

    useEffect(() => {
        const getBrands = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/list-valid-brands`);
                const filteredData = data.data.filter((d: any) => d.id !== 3621); //3621
                filteredData.unshift(data.data.find((d: any) => d.id === 3621));
                setBrandList(filteredData);
            } catch (err) {
                console.log(err);
            }
        };
        getBrands();
    }, []);

    // useEffect(() => {
    //     //if (brandList.length === 0) {
    //         setBrandList(state.brands)
    //    // }
    // }, [ state.brands ])

    // const setBrandInLIst = useCallback(() =>{
    //     setBrandList(state.brands.data && state.brands.data.sort((a:any,b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
    // },[ state.brands ])

    // useEffect(() => {

    //     setBrandInLIst()
    // },[ setBrandInLIst ])

    //--------------for brends------------
    const handleBrandChange = (value: any) => {
        const addBrand =
            allPrices.data &&
            allPrices.data.data.map((brand: any) => {
                if (brand.isChecked === true) {
                    const findBrand = brandList.find((brand: any) => brand.id === value);
                    return {
                        ...brand,
                        //isChanged:true,
                        brand_id: value ? findBrand.id : null,
                        brand_name: findBrand.name, //? findBrand.name :'N.N.'
                    };
                } else {
                    return brand;
                }
            });
        ///////

        const newBrandData = brandList.filter((brand: any) => brand.id !== value);
        newBrandData.unshift(brandList.find((brand: any) => brand.id === value));
        setBrandList([...newBrandData]);

        /////
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: addBrand } });
    };

    const { columns } = usePricesColumns(
        handleBrandChange,
        brandList,
        onCategoryChange,
        categories,
        checkedSpan,
        setCheckedSpan,
        allPrices,
        setAllPrices,
        currentCountry.name,
        categoryPreDef,
        //uncategorized,
        noCategory,
        handleIsNewProduct,
        currentPage,
        pricesFirstInit
        //setPricesFirstInit
    );

    const [loadingSaveBtn, setLoadingSaveBtn] = useState(false);

    const updatePrices = async (obj: any, filteredDataFalse: any) => {
        setLoadingSaveBtn(true);
        setSearchLoading(true);
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/item/update-multiple-products`, obj);
            if (data.data.length > 0) {
                showMessage(data.message);
            }
            noCategory && setCountDone((prev: any) => prev + data.data.length); ///////////////////////////////////////////
            setAllPrices({ ...allPrices, data: { ...allPrices.data, data: filteredDataFalse } });
            setLoadingSaveBtn(false);
            setSearchLoading(false);
            ///////////////////////////////////////////////////////////////
            //-------------here change page ---------------

            // if (filteredDataFalse.length === 0) {
            //     setCallPricesDb( curr => curr +1)
            // }

            // setCurrentPage(prev =>{
            //     if (Math.ceil(Number(countPrices)/100) === prev && filteredDataFalse.length === 0 ) {
            //         setCountDb(prev => prev +1)
            //         return prev - 1
            //     }

            //     //return typeof countPrices === 'number' && countPrices - obj.length > ( prev - 1 ) * 100 ? prev : prev - 1
            //     return prev
            // })
            // if (filteredDataFalse.length === 0) {
            //     setCallPricesDb( prev => prev + 1)
            // }
            /////////////////////////////////////////////////////////////////

            if (filteredDataFalse.length === 0 && Math.ceil(Number(countPrices) / 100) !== currentPage) {
                setCountDone(0);
                getPricesByShop();
                getAllPricesCount();
            }

            //////////////////////////////////////////////////////////////////////
        } catch (err: any) {
            console.log(err);
            setLoadingSaveBtn(false);
            setSearchLoading(false);
        }
    };

    const save = () => {
        const fincChecked = allPrices.data.data.some((price: any) => price.isChanged === true || price.isNewProduct);
        const findUndefinedCategories = allPrices.data && allPrices.data.data.some((price: any) => price.isChanged === true && (price.category_l3_id === undefined || price.category_l3_id === 1));
        const findCheckedAndChangeDisable = allPrices.data && allPrices.data.data.some((price: any) => price.isChanged === true && price.isDisabledChanged === true);
        const findUndefinedBrands = allPrices.data && allPrices.data.data.some((price: any) => price.isChanged === true && price.brand_id === null);

        if (!fincChecked) {
            openNotification("There is no changed prices!");
        } else if (findUndefinedCategories && !findCheckedAndChangeDisable) {
            if (!findCheckedAndChangeDisable) {
                openNotification("There is no (disable) changed items. ");
            }
            if (findUndefinedCategories) {
                openNotification("Category must be determined. ");
            }
        } else if (findUndefinedBrands) {
            openNotification("Brand must be determined. ");
        } else {
            const filteredData = allPrices.data.data.filter((singleData: any) => singleData.isChanged === true || singleData.isNewProduct === true /*&& singleData.ignorePrice === false*/);
            const filteredDataFalse = allPrices.data.data.filter((singleData: any) => singleData.isChanged === false && singleData.isNewProduct === false /*|| singleData.ignorePrice === true*/);

            const newAllPrices = filteredData.map((singleData: any) => {
                if (singleData.isNewProduct === true) {
                    return {
                        price_id: singleData.id,
                        old_product_id: singleData.product_old_id,
                        ...(singleData.isMpnChange && {
                            mpn:
                                singleData.mpn === null || singleData.mpn.trim() === "" || (singleData.mpn !== null && (singleData.mpn.trim() === "" || singleData.mpn.trim() === undefined))
                                    ? null
                                    : singleData.mpn,
                        }),
                        product_id: singleData.product_id,
                        brand_id: singleData.brand_id,
                        [`name_${currentCountry.name}`]: singleData.product_model,
                        category_l3_id: singleData.category_l3_id,
                        marked_for_insert: singleData.isNewProduct,
                        new_image_url: singleData.new_image_url,
                        model: singleData[`name_${currentCountry.name}`],
                        disabled: singleData.disabled,
                        shop_id: singleData.shop_id,
                        country_id: singleData.country_id,
                        status: singleData.status,
                        group_id: singleData.group_id,
                    };
                } else if (singleData.isChanged === true) {
                    return {
                        price_id: singleData.id,
                        old_product_id: singleData.product_old_id,
                        ...(singleData.isMpnChange && {
                            mpn:
                                singleData.mpn === null || singleData.mpn.trim() === "" || (singleData.mpn !== null && (singleData.mpn.trim() === "" || singleData.mpn.trim() === undefined))
                                    ? null
                                    : singleData.mpn,
                        }),
                        product_id: singleData.product_id,
                        brand_id: singleData.brand_id,
                        [`name_${currentCountry.name}`]: singleData[`name_${currentCountry.name}`] ? singleData[`name_${currentCountry.name}`] : singleData.model,
                        category_l3_id: singleData.category_l3_id,
                        marked_for_insert: singleData.isNewProduct,
                        new_image_url: singleData.new_image_url,
                        disabled: singleData.disabled,
                        shop_id: singleData.shop_id,
                        country_id: singleData.country_id,
                        status: singleData.status,
                        group_id: singleData.group_id,
                        ...(singleData.merge_product === 1 && { merge_product: 1 }),
                    };
                }
                return singleData;
            });

            const fiteredSavedPrices = allPrices.data.data.filter((singleData: any) => singleData.isChanged === true || singleData.isNewProduct === true);

            const filteredFalseIds = fiteredSavedPrices.map((data: { id: number }) => data.id);

            notShowPrices = [...notShowPrices, ...filteredFalseIds];
            filteredData.length > 0 && updatePrices(newAllPrices, filteredDataFalse);
            const brandSpan: any = document.querySelector("#brand-select-prices .ant-select-selection-item");
            const brandPlaceholder: any = document.querySelectorAll(".ant-select-selection-placeholder");

            if (brandSpan && brandPlaceholder) {
                brandSpan.title = "";
                brandSpan.innerHTML = "";
            }

            const categorySpan: any = document.querySelector("#category-select-prices .ant-select-selection-item");
            const categoryPlaceholder: any = document.querySelectorAll(".ant-select-selection-placeholder");
            if (categorySpan && categoryPlaceholder) {
                categorySpan.title = "";
                categorySpan.innerHTML = "";
            }
        }
    };
    ///////////////////

    let delayTimer: any;
    const handleSearchChange = (value: any) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setSkuTextArea("");
            setSeatchField(value);
            setCurrentPage(1);
            //setPricePage(1)
        }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
    };

    /////////////for select country
    //const [ userCountries, setUserCountries ] = useState<any>([])

    // useEffect(()=>{
    //     if ( state.loggedInUser.user ){
    //         setUserCountries( state.loggedInUser.user.data && state.loggedInUser.user.data.countries)
    //     }
    // }, [ state.loggedInUser.user ])

    // const countryArr = ['rs','me','al','ba','hr','si','ko','mk']

    //--------sort array-------
    const handleSort = (e: any) => {
        setSkuTextArea("");
        setSortArr(e);
        setCurrentPage(1);
        //setPricePage(1)
    };

    //--------------for seller (shop) category----------
    const [shopCategory, setShopCateogry] = useState<any>([]);

    useEffect(() => {
        const getShopCategories = async (selectedShops: any) => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shop/list-seller-categories?shop_ids=[${selectedShops}]${!existInXml ? "&available=1" : ""}`);
                setShopCateogry(data);
            } catch (err: any) {
                console.log(err);
            }
        };
        typeof selectedShops !== "string" && getShopCategories(selectedShops);
        if (selectedShops.length === 0) {
            setShopCateogry([]);
            //setFillSelectSC([])
        }
    }, [selectedShops, existInXml]);

    // useEffect(() => {
    //     if(  Array.isArray(selectedShops)  && selectedShops.length === 0 ) {
    //         setFillSelectSC([])
    //     }
    // }, [ selectedShops])

    const handleSelectedShopsCategory = (e: any) => {
        const newData = e.length > 0 && e.map((item: any) => shopCategory.data[item].seller_category);
        setFillSelectSC(newData);
        setCurrentPage(1);
        //setPricePage(1)
    };

    // const handleSwitchNoCategory = (e:any) => {
    //     setSkuTextArea('')
    //     //setUncategorized(e)
    //     //setPricePage(1)
    //     setCurrentPage(1)
    //     setUnmatched(0)
    // }

    const text = "Are you sure?";
    // const loadNextPage = () => {
    //     //setPricePage((prev:any)=>prev+1)
    //     setCurrentPage(1)
    // }
    // const loadPrewPage = () => {
    //    // setPricePage((prev:any)=>prev -1)
    //     setCurrentPage(1)
    // }
    // const next500Block = () => {
    //     return
    // }
    const [correctLatinWord, setCorrectLatinWord] = useState({
        correct: "",
        uncorrect: "",
    });
    const saveCorrectWord = async () => {
        // let countryId;
        //  countryArr.forEach((c:string,i:number) => {
        //     if ( c === currentCountryName ) {
        //         countryId = i + 1
        //     }
        // })
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_URL_API}/prices/word-replacement/add-word?country_id=${currentCountry.id}&misspelled_word=${correctLatinWord.uncorrect
                    .trim()
                    .toLowerCase()}&correct_word=${correctLatinWord.correct.trim().toLowerCase()}&country_id=${currentCountry.id}`
            );
            showMessage(data.message);
            setCorrectLatinWord({
                correct: "",
                uncorrect: "",
            });
            setCorrectWordModal(false);
        } catch (err) {
            console.log(err);
        }
    };
    const closeCorrectWordModal = () => {
        setCorrectWordModal(false);
    };

    const handleSwitchSF = (e: any) => {
        setSfActive(e === true ? 0 : 1);
    };

    // const onChangeTableParameters = (pagination:any, filters:any, sorter:any, extra:any) => {
    //     //console.log(pagination.current)

    //     setCurrentPage(pagination.current)
    //     const sliceDataPrices = pricesFirstInit.data.data.slice(100 * ( pagination.current -1 ), 100 * ( pagination.current - 1) + 100).filter((price:any) => !notShowPrices.includes(price.id))
    //     ///////////////////////////////----------------console.log for degug----------------------/////////////////
    //     //console.log('sliceDataPrices', sliceDataPrices)
    //     //////////////////////////////
    //     setAllPrices({...allPrices,data:{ ...allPrices.data, data: sliceDataPrices}})
    // }
    // const onPaginateChange = () => {
    //     console.log('test')
    // }
    // console.log(initPrices.length >0 && Math.ceil(initPrices.length/100))

    // const changeNewPaginate = (page:number) => {
    //     setCurrentPage(page)
    //     const sliceDataPrices = pricesFirstInit.data.data.slice(100 * ( page -1 ), 100 * ( page - 1) + 100).filter((price:any) => !notShowPrices.includes(price.id))
    //     setAllPrices({...allPrices,data:{ ...allPrices.data, data: sliceDataPrices}})
    // }
    const handleCategorizedCheckbox = () => {
        setSkuTextArea("");
        setNoCategory(!noCategory);
        //setPricePage(1)
        setCurrentPage(1);
        setUnmatched(0);
        setOurCategory(null);
    };

    useEffect(() => {
        //document.body.scrollTop = 0;
        const pricesTable = document.querySelector("#table-all-prices .ant-table-body") as HTMLElement;
        if (pricesTable && pricesTable.scrollTop) pricesTable.scrollTop = 0;
    }, [currentPage]);

    const setUnmatchedOption = (value: any) => {
        setUnmatched(Number(value.target.value));
    };

    //console.log(allPrices.data)
    //console.log(noCategory)

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setCountDone(0);
    };

    const handleSelectedShop = (e: any) => {
        //console.log(e)
        setSkuTextArea("");
        setSelectedShops(e);
        setCurrentPage(1);
    };

    const [applyToAll, setApplyToAll] = useState(false);

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - All prices" />
            <Row className={darkMode ? "dark-mode" : ""}>
                <Col span={24} id={allPrices.data && `all-prices-box`} style={{ height: "92.5vh", marginTop: "1rem" }}>
                    <Divider>All prices</Divider>
                    <AutoComplete
                        placeholder="Product, brand, model, ID,sku"
                        dropdownMatchSelectWidth={252}
                        style={{ width: 300, marginLeft: "5px" }}
                        onSearch={handleSearchChange}
                        id="search-input-autocomplete"
                    ></AutoComplete>
                    <Switch style={{ marginLeft: "1.5rem", width: "40px" }} onChange={handleSwitchSF} checkedChildren="All" unCheckedChildren="SF" defaultChecked={true} size="small" />
                    {/* <Select
                        suffixIcon={<CaretDownOutlined />}
                        showSearch
                        mode="multiple"
                        allowClear
                        style={{ width: '190px', marginLeft:'30px' }}
                        placeholder="Select shop"
                        value={undefined}
                        onChange={handleSelectedShops}
                        filterOption={(input, option:any) =>
                            String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        {shopList.length > 0 && shopList.sort((a:any,b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((brand:any)=>
                            <Option key={brand.id} value={brand.id}>{ brand.name } ({brand.prices_count})</Option>
                        )
                        }
                    </Select>{' '} */}
                    <Select
                        //defaultValue="lucy"
                        suffixIcon={<CaretDownOutlined />}
                        showSearch
                        mode="multiple"
                        allowClear
                        placeholder="Select a Shop"
                        style={{ width: "190px", marginLeft: "30px" }}
                        optionFilterProp="label"
                        onChange={handleSelectedShop}
                        filterOption={(input, option: any) =>
                            String(option?.children?.props.children ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                    >
                        {shopList.map(
                            (shops, i) =>
                                shops.length > 0 && (
                                    <Select.OptGroup key={i} label={i === 0 ? "Active Shop" : "Inactive Shop"}>
                                        {shops.map((shop) => (
                                            <Option key={shop.id} value={shop.value}>
                                                <span
                                                    style={{
                                                        color: i === 0 ? "#273746" : "gray",
                                                        fontWeight: i === 0 ? "bold" : "",
                                                    }}
                                                >
                                                    {`${shop.label} `}
                                                </span>
                                            </Option>
                                        ))}
                                    </Select.OptGroup>
                                )
                        )}
                    </Select>
                    <Select
                        suffixIcon={<CaretDownOutlined />}
                        showSearch
                        mode="multiple"
                        allowClear
                        style={{ width: "380px", marginLeft: "5px" }}
                        placeholder="Search shop category"
                        onChange={handleSelectedShopsCategory}
                        filterOption={(input, option: any) => option.children && String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {shopCategory.data &&
                            shopCategory.data
                                .sort((a: any, b: any) => (a > b ? 1 : b > a ? -1 : 0))
                                .map((sc: any, index: any) => {
                                    return (
                                        sc && (
                                            <Option key={index} value={index}>
                                                {sc.prices_count} - {sc.seller_category}{" "}
                                            </Option>
                                        )
                                    );
                                })}
                    </Select>{" "}
                    <Popconfirm placement="top" title={text} onConfirm={save} okText="Yes" cancelText="No">
                        <button className="save-btn">
                            Save
                            {loadingSaveBtn ? <LoadingOutlined style={{ position: "relative", bottom: "3px", left: "6px" }} /> : <SaveOutlined style={{ position: "relative", left: "6px" }} />}
                        </button>
                    </Popconfirm>
                    <Checkbox className="check-ncategorized" checked={noCategory} onChange={handleCategorizedCheckbox}></Checkbox>
                    <Select className="prices-sort-select" defaultValue="Sort" onChange={handleSort} style={{ width: "160px" }}>
                        <Option value="1">Shop Category (A - Z)</Option>
                        <Option value="5">Shop Category (Z - A)</Option>
                        <Option value="2">Insert Date (A - Z)</Option>
                        <Option value="6">Insert Date (Z - A)</Option>
                        <Option value="3">Product Name (A - Z)</Option>
                        <Option value="4">Product Name (Z - A)</Option>
                        <Option value="7">Eponuda (A - Z)</Option>
                        <Option value="8">Eponuda (Z - A)</Option>

                        <Option value="10">Click (asc)</Option>
                        <Option value="11">Click (desc)</Option>

                        <Option value="12">Prices (asc)</Option>
                        <Option value="13">Prices (desc)</Option>
                    </Select>
                    <Switch
                        style={{ marginLeft: "1.5rem" }}
                        onChange={() => {
                            setSkuTextArea("");
                            setExistInXML(!existInXml);
                            /*setPricePage(1);*/ setCurrentPage(1);
                        }}
                        checkedChildren="xml"
                        unCheckedChildren="All"
                        className="all-prices-xml-switch"
                        defaultChecked
                        size="small"
                    />
                    <Select className="prices-sort-select" defaultValue={"0"} onChange={handlePriceStatus} style={{ width: "100px" }}>
                        <Option value="0">All</Option>
                        <Option value="1">New</Option>
                        <Option value="2">Refurbished</Option>
                        <Option value="3">Used</Option>
                    </Select>
                    <Checkbox
                        //className='check-ncategorized'
                        style={{ marginLeft: "1rem" }}
                        checked={userFilter}
                        //onChange={handleCategorizedCheckbox}
                        onChange={(e) => setUserFilter(e.target.checked)}
                    >
                        User Filter
                    </Checkbox>
                    <Button type="default" onClick={() => setApplyToAll(true)} size="small">
                        Apply to All
                    </Button>
                    <ApplyToAllModal
                        applyToAll={applyToAll}
                        setApplyToAll={setApplyToAll}
                        selectedShops={selectedShops}
                        noCategory={noCategory}
                        searchField={searchField}
                        //sortArr={ sortArr }
                        fillSelectSC={fillSelectSC}
                        //uncategorized={ uncategorized }
                        sortArr={sortArr}
                        currentPage={currentPage}
                        //skuTextArea={ skuTextArea }
                        existInXml={existInXml}
                        ourCateogry={ourCateogry}
                        condition={condition}
                        sfActive={sfActive}
                        unMatched={unMatched}
                        userFilter={userFilter}
                        brandList={brandList}
                        //setPricesFirstInit={ setPricesFirstInit }
                        currentCountryName={currentCountry.name}
                        currentCountryId={currentCountry.id}
                        shopList={shopList}
                        dataLength={numberWithCommas(String(Number(countPrices) - countDone).replace(".", ","))}
                        setSameParam={setSameParam}
                        categories={categories}
                    />
                    <div className="all-prices-pagination-box">
                        {isLoadingCount ? (
                            <Spin style={{ marginTop: "1rem" }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        ) : (
                            <Statistic title="Count Prices" value={numberWithCommas(String(Number(countPrices) - countDone).replace(".", ","))} />
                        )}
                    </div>{" "}
                    {searchLoading && (
                        <React.Fragment>
                            <div className="loading"> Loading&#8230;</div>
                        </React.Fragment>
                    )}
                    <SkuModal
                        setAllPrices={setAllPrices}
                        isModalSku={isModalSku}
                        setIsModalSku={setIsModalSku}
                        currentCountryName={currentCountry.name}
                        setCountDone={setCountDone}
                        //setPricesFirstInit={setPricesFirstInit}
                        setSearchLoading={setSearchLoading}
                        //uncategorized={uncategorized}
                        noCategory={noCategory}
                        skuTextArea={skuTextArea}
                        setSkuTextArea={setSkuTextArea}
                    />
                    <AllPricesMpnModal
                        mpnItem={mpnItem}
                        allPrices={allPrices}
                        setAllPrices={setAllPrices}
                        mpnObj={mpnObj}
                        setMpnObj={setMpnObj}
                        multySelected={multySelected}
                        oneSelect={oneSelect}
                        selectTemp={selectTemp}
                        setSelectTemp={setSelectTemp}
                        modalProduct={modalProduct}
                        setModalProduct={setModalProduct}
                        setMpnItem={setMpnItem}
                        skipPrice={skipPrice}
                        currentCountry={currentCountry.name}
                        currentCountryId={currentCountry.id}
                        setMultySelected={setMultySelected}
                        searchType={searchType}
                        //uncategorized={uncategorized}
                        noCategory={noCategory}
                        mpnModal={mpnModal}
                        setMpnModal={setMpnModal}
                        setSearchType={setSearchType}
                        modalProductLoading={modalProductLoading}
                        setModalProductLoading={setModalProductLoading}
                        handleMpnModalOk={handleMpnModalOk}
                        handleCancel={handleCancel}
                        brands={brandList}
                        categoryL3Options={categories}
                        unMatched={unMatched}
                    />
                    <CretePredefinedTextModal
                        isModalPredefinedText={isModalPredefinedText}
                        setIsModalPredefinedText={setIsModalPredefinedText}
                        categories={categories}
                        currentCountryName={currentCountry.name}
                    />
                    <Modal footer={false} title="Shortcuts" open={modalShortcuts} onOk={handleOk} onCancel={handleCancel} width={1000}>
                        <img src="https://cdn.eponuda.com/eponuda/precice.png" alt="shortcuts" />
                    </Modal>
                    <CorrectWordModal
                        correctWordModal={correctWordModal}
                        correctLatinWord={correctLatinWord}
                        setCorrectLatinWord={setCorrectLatinWord}
                        saveCorrectWord={saveCorrectWord}
                        closeCorrectWordModal={closeCorrectWordModal}
                    />
                    {/*--------------------------------- */}
                    {!noCategory && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px solid lightgray",
                                borderRadius: "2px",
                                padding: "0.5rem",
                                margin: "0.8rem 0.3rem 0.3rem 0.3rem",
                                backgroundColor: "whitesmoke",
                            }}
                        >
                            <OurCategories levelCategories={levelCategories} setOurCategory={setOurCategory} setCurrentPage={setCurrentPage} />
                            <Radio.Group size="small" buttonStyle="outline" onChange={setUnmatchedOption} value={unMatched} optionType="button" style={{ marginRight: "0.5rem", borderRadius: "6px" }}>
                                <Radio.Button value={0}>All</Radio.Button>
                                <Radio.Button value={1}>Auto-inserted</Radio.Button>
                            </Radio.Group>
                        </div>
                    )}
                    {/*--------------------------------- */}
                    {allPrices.data && (
                        <Table
                            id="table-all-prices"
                            components={editableCellcomponents}
                            bordered={true}
                            style={{ marginTop: "22px" }}
                            columns={columns as any}
                            dataSource={allPrices.data.data}
                            scroll={{
                                y: !noCategory ? "60vh" : windowSize.height > 1650 ? `${(windowSize.height * 72) / 1321}vh` : "65vh",
                            }}
                            rowKey={(record) => record && record.id}
                            rowClassName={(record) => ` ${record.isChecked ? "checked-price" : ""} ${record.checkToFlag ? "flag-checked-price-match" : ""}`}
                            //pagination={{ pageSize:100, total: 10000*(initPrices.length / 10000),/* onChange:onPaginateChange,*/ current:currentPage, showSizeChanger: false }}
                            pagination={
                                !countPrices
                                    ? false
                                    : {
                                          pageSize: 100,
                                          showSizeChanger: false,
                                          total: countPrices ? countPrices : undefined,
                                          current: currentPage,
                                          onChange: handlePageChange,
                                      }
                            }
                            //onShowSizeChange={true}
                            //onChange={onChangeTableParameters}
                        />
                    )}
                    {/* initPrices.length >0 &&
                        <NewPagination
                            data={initPrices}
                            pageLimit={5}
                            dataLimit={10}
                            currentPage={currentPage} 
                            setCurrentPage={setCurrentPage}
                            allPrices={allPrices}
                            setAllPrices={setAllPrices}
                            pricesFirstInit={pricesFirstInit}
                            notShowPrices={notShowPrices}
                            initPrices={initPrices}
                    /> */}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AllPrices;
