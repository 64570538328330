import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import showMessage from "../../../../shared/MessagesInfo/message";
import "../../style/Names.css";
import { EditCateogyType } from "../EditCategory";
import Spinner from "../../../../images/spinner-products.gif";
import { CategoryType } from "../../pages/AllCategories";
import { ColumnTypes, editableCellcomponents, mappedColumns } from "../../../../shared/FormElements/EditableCell";

interface DataType {
    key: React.Key;
    id: number | undefined;
    name: string;
    value: string | undefined;
    parent_id: any;
    level_id: number;
    isLoadingName: boolean;
    isLoadingShortName: boolean;
    name_short: string | any;
}

interface NamesProps {
    data: EditCateogyType | null | any;
    selectedCateogry: CategoryType | null;
    setEditCateogry: React.Dispatch<React.SetStateAction<EditCateogyType | null>>;
    countries: string[];
}

const Names: React.FC<NamesProps> = ({ data, setEditCateogry, selectedCateogry, countries }) => {
    const [category, setCategory] = useState<DataType[]>([]);
    useEffect(() => {
        let arr: DataType[] = [];
        for (let el in data?.translate) {
            if (countries.map((country) => `name_${country}`).includes(el)) {
                arr.push({
                    name: el,
                    value: data?.translate[el],
                    id: data?.id,
                    key: uuidv4(),
                    parent_id: Number(selectedCateogry?.type[1]) === 1 ? 0 : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry?.type[1]) - 1}_id` as keyof CategoryType],
                    level_id: Number(selectedCateogry?.type[1]),
                    isLoadingName: false,
                    isLoadingShortName: false,
                    name_short: data?.translate ? data.translate[`name_short_${el.slice(5)}`] : "",
                });
            }
        }
        setCategory(arr);
    }, [data?.translate, data?.id, selectedCateogry, countries]);

    //const countriesArr =  ['rs', 'si','hr']

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "Country",
            dataIndex: "name_rs",
            width: "120px",
            align: "center" as "center",
            render: (text, record, index) => `Name ${countries[index]}`,
        },
        {
            title: <span style={{ marginLeft: "0.9rem" }}>Name</span>, //<div style={{ display:'flex', justifyContent:'center' }}> <span>Name</span></div>,
            dataIndex: "value",
            width: "320px",
            editable: true,
            render: (text: string, record: any, index: number) =>
                record.isLoadingName ? (
                    <div style={{ height: "22px" }}>
                        <img src={Spinner} style={{ position: "absolute", marginLeft: "18px", top: "14px" }} alt="Spinner" width={46} />
                    </div>
                ) : (
                    text
                ),
        },
        {
            title: <span style={{ marginLeft: "0.9rem" }}>Short Name</span>, //<div style={{ display:'flex', justifyContent:'center' }}> <span>Name</span></div>,
            dataIndex: "name_short",
            width: "320px",
            editable: true,
            render: (text: string, record: any, index: number) =>
                record.isLoadingShortName ? (
                    <div style={{ height: "22px" }}>
                        <img src={Spinner} style={{ position: "absolute", marginLeft: "18px", top: "14px" }} alt="Spinner" width={46} />
                    </div>
                ) : (
                    text
                ),
        },
    ];

    const handleSave = async (row: DataType) => {
        setCategory((curr) =>
            curr.map((d) => {
                /*console.log(d);*/ return d.key === row.key ? { ...d, [`isLoading${category.find((d) => d.name === row.name)?.value === row.value ? "Short" : ""}Name`]: true } : d;
            })
        ); ////////////////////////////here

        const objToSend = {
            id: row.id,
            level_id: row.level_id,
            parent_id: row.parent_id,
            [`${row.name}`]: row.value,
            [`name_short_${row.name.slice(5)}`]: row.name_short,
        };

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, objToSend);
            showMessage(data.message);
            const newData = [...category];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });

            setEditCateogry((curr) => {
                return curr ? { ...curr, translate: { ...curr.translate, [`name_short_${row.name.slice(5)}`]: row.name_short, [`name_${row.name.slice(5)}`]: row.value } } : null;
            }); // check here
            setCategory(newData);
            setCategory((curr) => curr.map((d) => ({ ...d, isLoadingName: false, isLoadingShortName: false })));
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <React.Fragment>
            <Table
                components={editableCellcomponents}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={category}
                columns={mappedColumns(defaultColumns, handleSave, true) as ColumnTypes}
                style={{ marginTop: "4rem" }}
                pagination={false}
                loading={category.length === 0 ? true : false}
            />
        </React.Fragment>
    );
};

export default Names;
