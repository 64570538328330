import axios from "axios";
import showMessage from "../MessagesInfo/message";

const baseUrl = process.env.REACT_APP_URL_API;

export const showShopRulesById = async (id: any, form: any, setBooleans: any, setLoading: any, /*setIsRulesMessage:any,*/ setFileType: any) => {
    setLoading(true);

    const { data } = await axios.get(`${baseUrl}/maps/shop-rules/show-shop-rules?shop_id=${id}`);
    setBooleans({
        parse_price: data.data.parse_price,
        price_required: data.data.price_required,
        trim: data.data.trim,
        relax: data.data.relax,
        skip_empty_lines: data.data.skip_empty_lines,
        skip_lines_with_error: data.data.skip_lines_with_error,
        has_multiple_images: data.data.has_multiple_images,
        has_attributes: data.data.has_attributes,
    });
    form.setFieldsValue(data.data);
    form.setFieldsValue({
        parse_price: String(data.data.parse_price),
        price_required: String(data.data.price_required),
        trim: String(data.data.trim),
        relax: String(data.data.relax),
        skip_empty_lines: String(data.data.skip_empty_lines),
        skip_lines_with_error: String(data.data.skip_lines_with_error),
        attribute_array: data.data.attribute_array ? JSON.parse(data.data.attribute_array) : [],
    });
    //setFileType(data.file_type)
    setFileType(data.data.file_type);
    setLoading(false);
    return data;
};

export const editShopRules = async (objectUpdate: any, setCounter: React.Dispatch<React.SetStateAction<number>>, handleModalRulesOk: () => void) => {
    const { data } = await axios.put(`${baseUrl}/maps/shop-rules/update-shop-rules`, objectUpdate);
    showMessage(data.message);
    setCounter((curr) => curr + 1);
    handleModalRulesOk();
};

export const createShopRules = async (objectToSend: any, form: any, setLoading: any, handleModalRulesOk: () => void, setCounter: React.Dispatch<React.SetStateAction<number>>) => {
    const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/maps/shop-rules/add-new-shop-rules`, {
        ...objectToSend,
        price_key_name: "price",
    });
    showMessage(data.message);
    form.resetFields();
    setLoading(false);
    setCounter((curr) => curr + 1);
    handleModalRulesOk();
    return data;
};
