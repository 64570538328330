import axios from "axios";
import { useCallback, useEffect } from "react";
import { useAuth } from "../../../shared/Context/AuthContext";

//let pricesFirstInit:any=[]         //chenged because paginate

export const useCountAllPrices = (
    selectedShops: any,
    noCategory: any,
    searchField: any,
    sortArr: any,
    fillSelectSC: string[],
    setSearchLoading: any,
    //uncategorized:any,
    sameParam: number,
    setCountDone: any,
    setAllPrices: any,
    //setPricesFirstInit:any,
    pricePage: number,
    skuTextArea: any,
    existInXml: any,
    ourCateogry: { level: string; id: string } | null,
    condition: string,
    sfActive: number,
    unMatched: number,
    userFilter: boolean,
    allPrices: any,
    setCountPrices: React.Dispatch<React.SetStateAction<number | null>>,
    setIsLoadingCount: React.Dispatch<React.SetStateAction<boolean>>,
    currentPage: number
) => {
    const { currentCountry } = useAuth();

    const getAllPricesCount = useCallback(async () => {
        setIsLoadingCount(true);
        const selectSCToStirng = fillSelectSC ? JSON.stringify(fillSelectSC.map((d) => encodeURIComponent(d))) : [];

        try {
            //setSearchLoading((prev:any)=>true)
            const response = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/count-all-prices?shop_ids=[${selectedShops}]&skus=${JSON.stringify(skuTextArea)}&limit=100&page=${
                    //pricePage
                    "1"
                }&search=${searchField}${sortArr ? `&sort=${sortArr}` : ""}&seller_categories=${selectSCToStirng}&country_id=${currentCountry.id}${existInXml ? "" : "&available=1"}&uncategorised=${
                    !noCategory ? "" : 1 //uncategorized ? 1 :2
                }${ourCateogry && `&l${ourCateogry.level}_id=${ourCateogry.id}`}&condition=${condition}&user_filter=${userFilter ? 1 : 0}&sf_active=${sfActive}${
                    !noCategory && unMatched === 1 ? `&unmatched=1` : ""
                }`
            );
            //authCheckStatus(response.status)
            setCountPrices(response.data.data.total);
            // const data = response.data
            // const newData = data.data.data.sort((a:any,b:any) =>{

            //     if ( !uncategorized && noCategory ) {
            //         return (a.category_l3_id > b.category_l3_id) ? -1 : ((b.category_l3_id > a.category_l3_id) ? 1 : 0)
            //     }
            //     else {
            //         return (a.brand_name > b.brand_name) ? 1 : ((b.brand_name > a.brand_name) ? -1 : 0)
            //     }

            // }
            //     ).map((singleData:any)=>{
            //         const mapImages = singleData.images.map((image:any)=>{
            //             return {
            //                 ...image,
            //                 isChechedImg:false
            //             }
            //         })
            //     return {
            //         ...singleData,
            //         key:singleData.id,
            //         isChanged:false,
            //         isChecked:false,
            //         isNewProduct:false,
            //         new_image_url:false,
            //         toggleImage:false,
            //         wrongAutoChecked:false,
            //         isDisabledChanged:false,
            //         ignorePrice:false,
            //         isMpn: singleData.mpn ? true : false,
            //         [`name_${currentCountry}`]: singleData[`name_${currentCountry}`] ? singleData[`name_${currentCountry}`] : singleData.model,
            //         initName:singleData[`name_${currentCountry}`] ? singleData[`name_${currentCountry}`] : singleData.model,
            //         selected:false,
            //         product_old_id:singleData.product_id,
            //         images:mapImages,
            //         expandImgSettings:false,
            //         isMpnChange:false,
            //         ////////// for automatched F7
            //         isAMBrand:false,
            //         isAMCategory:false,
            //         isAMName:false,
            //         //////// for matchingmodal ctrl + shift
            //         checkToFlag:false // -------> for prices marked in table

            //     }
            // })
            // setInitPrices(newData)
            // setCountDone(0)
            // setPricesFirstInit({...data,data:{...data.data, data:newData}})
            // setAllPrices({...data,data:{...data.data, data:newData.slice(0,100)}})
            // setSearchLoading((prev:any)=>false)
            setIsLoadingCount(false);
        } catch (err: any) {
            console.log(err);
            setIsLoadingCount(false);
        }
    }, [
        selectedShops,
        noCategory,
        //uncategorized,
        searchField,
        sortArr,
        fillSelectSC,

        //setSearchLoading,

        //setPricesFirstInit,
        //pricePage,
        skuTextArea,
        existInXml,
        currentCountry,
        ourCateogry,
        condition,
        sfActive,
        unMatched,
        userFilter,
        //allPrices,
        setCountPrices,
        setIsLoadingCount,
    ]);

    useEffect(() => {
        /*allPrices.data &&*/ getAllPricesCount();
    }, [getAllPricesCount, /*currentPage,*/ sameParam, currentCountry /*setAllPrices,*/ /*setCountDone, */]);
    return { getAllPricesCount };
};
