import axios from "axios";

const baseUrl = process.env.REACT_APP_URL_API;

export const getUser = async () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token"); // check down
    const response = await axios.get(`${baseUrl}/auth/userinfo`);
    const data = response.data;
    const newData = {
        ...data,
        data: {
            ...data.data,
            key: data.data.id,
        },
    };
    //dispatch({ type: USER_GET, payload: newData });
    return newData;
    // } catch (err: any) {
    //     if (err.response) {
    //         if (authCheckStatus(err.response.status)) {
    //             dispatch({ type: USER_LOGOUT, payload: {} });
    //         }
    //     }
    // }
};

export const getAllUsers = async (active: boolean, role: string | boolean) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    axios.defaults.headers.common["withCredentials"] = false;

    const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/auth/users-list${active ? `?active=true` : "?active=false"}${role ? `&role=${role}` : ""}`);
    return data;
    // } catch (err: any) {
    //     if (err.response) {
    //         if (authCheckStatus(err.response.status)) {
    //             dispatch({ type: USER_LOGOUT, payload: {} });
    //         }
    //     }
    // }
};
