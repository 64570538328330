import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Row, Space } from "antd";
import React, { useState } from "react";
import axios from "axios";
import showMessage from "../../../../../../shared/MessagesInfo/message";
import { CountryType } from "../../../../../../shared/Context/type";

interface ButtonsBoxProps {
    productId: number | undefined;
    description: Record<string, string | number | null>;
    setDescription: React.Dispatch<React.SetStateAction<Record<string, string | number | null> | undefined>>;
    country: string;
    text: string;
}

const checkDescText = (flag: string | number | null) => {
    if (flag === 0 || flag === 2) {
        // 0 - empty, 2 - AI
        return true;
    }
    return false;
};

const ButtonsBox = (props: ButtonsBoxProps) => {
    const [isSaved, setIsSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDescText, setLoadingDescText] = useState(false);

    const { description, setDescription, productId, country /*, text*/ } = props;

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const clearDescription = () => {
        setDescription((curr) =>
            curr
                ? {
                      ...curr,
                      [`preview_text_${country}`]: null,
                      [`flag_${country}`]: 0,
                  }
                : undefined
        );
    };

    const getDescriptionText = async () => {
        setLoadingDescText(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/reviews/product-description?country_id=${localStorageCountries.find((c) => c.name === country)?.value}&product_id=${productId}`
            );
            const arrayDescCountry = localStorageCountries
                .map((c) =>
                    checkDescText(description[`flag_${c.locale_code}`])
                        ? {
                              [`preview_text_${[c.locale_code]}`]: data[c.theebox_locale_code] ?? null,
                              [`flag_${c.locale_code}`]: data[c.theebox_locale_code] ? 2 : 0,
                          }
                        : null
                )
                .filter((c) => c);
            let tempObj: { [key: string]: string } = {};
            arrayDescCountry.forEach((obj) => {
                for (let key in obj) {
                    tempObj[key] = obj[key];
                }
            });

            setDescription((curr) => (curr ? { ...curr, ...tempObj } : undefined));
            showMessage("Description is filled");
            setLoadingDescText(false);
        } catch (err) {
            console.log(err);
            setLoadingDescText(false);
        }
    };

    const saveNewDescription = async () => {
        const arrayDescCountry = localStorageCountries.map((c) => ({
            [`preview_text_${c.locale_code}`]: description[`preview_text_${c.locale_code}`],
            [`flag_${c.locale_code}`]:
                description[`flag_${c.locale_code}`] === 0 || description[`preview_text_${c.locale_code}`]?.toString()?.trim().length === 0 ? 0 : description[`flag_${c.locale_code}`],
        }));

        let tempObj: { [key: string]: string | number | null } = {};

        arrayDescCountry.forEach((obj) => {
            for (let key in obj) {
                tempObj[key] = obj[key];
            }
        });

        console.log(tempObj);

        const objToSend = {
            id: productId,
            ...tempObj,
        };
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
            setDescription((curr) =>
                curr
                    ? {
                          ...curr,
                          ...tempObj,
                          //[`preview_text_${country}`]: text,
                      }
                    : undefined
            );
            showMessage(data.message);
            setLoading(false);
            setIsSaved(true);
        } catch (err) {
            console.log(err);
        }
    };

    // console.log(description);

    return (
        <Row justify="space-between" style={{ marginTop: "1rem" }}>
            <Button type="primary" onClick={saveNewDescription} loading={loading} icon={isSaved && <CheckCircleOutlined />}>
                Save Description
            </Button>
            <Space>
                <Button loading={loadingDescText} onClick={getDescriptionText}>
                    Get Description text
                </Button>
                <Button danger onClick={() => clearDescription()}>
                    Clear {`${country.toUpperCase()}`}{" "}
                </Button>
            </Space>
        </Row>
    );
};

export default ButtonsBox;
