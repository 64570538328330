import { Col, Divider, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { getBrandList, getData } from "../api/apiData";
import { ProductType } from "../types/types";
import useColumns from "../hooks/useColumns";
import useKeyEvents from "../hooks/useKeyEvents";
import "../style/AllProducts.css";
import FiltersBox from "../components/FiltersBox";
import CorrectWordModal from "../components/CorrectWordModal";
import SkuModal from "../components/SkuModal";
import MatchingModal from "../components/MatchingModal";
import ApplyToAllModal from "../components/ApplyToAll/ApplyToAllModal";
import { getBrands } from "../api/apiFacets";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { BrandType, SelectedCategoryType } from "../types/filters";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import { getAllCategories, TreeSelectCategoryType } from "../../../shared/Api/category";
import { useAuth } from "../../../shared/Context/AuthContext";

const uncategorizedByCountry = (country: number) => {
    switch (Number(country)) {
        case 1:
            return "Nesortirano";
        case 6:
            return "Nerazvrščeno";
        case 5:
            return "Nerazvrstano";
        case 4:
            return "Nerazvrstano";
    }
};

const AllProducts = () => {
    const { currentCountry } = useAuth();
    const [products, setProducts] = useState<ProductType[]>([]);
    const [count, setCount] = useState<number | undefined>();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [selectedShops, setSelectedShops] = useState<string[]>([]);
    const [selectedShopCategories, setSelectedShopCategories] = useState<string[]>([]);
    const [brandList, setBrandsList] = useState<{ id: number; brand_name: string }[]>([]); //brand list in table header

    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedSort, setSelectedSort] = useState<string | undefined>("active_prices:desc");
    const [xml, setXml] = useState(true);
    const [selectedNew, setSelectedNew] = useState<string | undefined>();
    const [user, setUser] = useState(false);
    const [strictCategory, setStrictCategory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sameParam, setSameParam] = useState(0);
    const [skus, setSkus] = useState<string[]>([]);
    const pageSize = useWindowSize();

    const [loadCategory, setLoadCategory] = useState(true); // because use effect will be call api again

    const [selectedCategories, setSelectedCategories] = useState<SelectedCategoryType | undefined>();
    const [loadChangeCountry, setloadChangeCountry] = useState(false);

    //get categories for cateogry column and matching modal
    const [categories, setCategories] = useState<TreeSelectCategoryType[]>([]);

    useEffect(() => {
        getAllCategories(currentCountry.id, currentCountry.name).then((resp) => {
            setCategories(resp);
        });
    }, [currentCountry.id, currentCountry.name]);

    useEffect(() => {
        if (currentCountry.id) {
            setloadChangeCountry(true);
            setSelectedCategories({
                id: `30::${uncategorizedByCountry(currentCountry.id)}`,
                level: "1",
                fullCategory: `30::${uncategorizedByCountry(currentCountry.id)}--1`,
            });
            ///// reset all filters
            //setSearch("");
            setSelectedShops([]);
            setSelectedShopCategories([]);
            setSelectedBrands([]);
            setSelectedSort("active_prices:desc");
            setXml(true);
            setSelectedNew(undefined);
            setUser(false);
            setStrictCategory(false);
            setPage(1);
            /////
            setLoadCategory(false);
        }
    }, [currentCountry.id]);

    const [isModalCorrectWord, setIsModalCorrectWord] = useState(false);
    const [isModalSku, setIsModalSku] = useState(false);
    const [isModalMatching, setIsModalMatching] = useState(false);
    const [isModalApplyToAll, setIsModalApplyToAll] = useState(false);
    const [brands, setBrands] = useState<BrandType[]>([]); // for Apply to all

    useEffect(() => {
        !loadCategory && isModalApplyToAll && getBrands(setBrands, currentCountry.id, currentCountry.name, loadCategory);
    }, [currentCountry.id, currentCountry.name, loadCategory, isModalApplyToAll]);

    useEffect(() => {
        getBrandList(setBrandsList);
    }, []);

    useEffect(() => {
        // get data for table
        !loadCategory &&
            getData(
                setProducts,
                setLoading,
                currentCountry.name,
                currentCountry.id,
                search,
                selectedShops,
                selectedShopCategories,
                selectedBrands,
                selectedCategories,
                selectedSort,
                selectedNew,
                xml,
                user,
                strictCategory,
                setCount,
                page,
                skus,
                loadCategory
            ); // Get data for table
    }, [
        currentCountry.id,
        currentCountry.name,
        search,
        selectedShops,
        selectedShopCategories,
        selectedBrands,
        selectedCategories,
        selectedSort,
        selectedNew,
        xml,
        user,
        strictCategory,
        page,
        skus,
        loadCategory,
        sameParam,
    ]);

    const currentCountryName = currentCountry.name;
    const currentCountryId = currentCountry.id;

    const columns = useColumns({
        products,
        setProducts,
        currentCountryName,
        currentCountryId,
        count,
        search,
        brandList,
        categories,
    });

    useKeyEvents(currentCountry.name, currentCountry.id, products, setProducts, setSameParam, setIsModalCorrectWord, setIsModalSku, setIsModalMatching, selectedShops);

    const onPageChange = (e: number) => {
        setPage(e);
    };

    return (
        <Row justify="center" /*style={{ height: `${(pageSize.height * 95) / 1271}vh` }}*/>
            <Col
                span={24}
                style={{
                    margin: "1rem 0",
                    backgroundColor: "white",
                    //height: "80vh",
                }}
            >
                <HelmetTitle title="Data Entry App - All Products" />
                <Divider>All Products</Divider>
                <FiltersBox
                    products={products}
                    setProducts={setProducts}
                    search={search}
                    setSearch={setSearch}
                    selectedBrands={selectedBrands}
                    setSelectedBrands={setSelectedBrands}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    selectedShops={selectedShops}
                    setSelectedShops={setSelectedShops}
                    selectedShopCategories={selectedShopCategories}
                    setSelectedShopCategories={setSelectedShopCategories}
                    setSelectedSort={setSelectedSort}
                    xml={xml}
                    setXml={setXml}
                    selectedNew={selectedNew}
                    setSelectedNew={setSelectedNew}
                    user={user}
                    setUser={setUser}
                    setIsModalApplyToAll={setIsModalApplyToAll}
                    setPage={setPage}
                    currentCountryId={currentCountry.id}
                    currentCountryName={currentCountry.name}
                    loadCategory={loadCategory}
                    setloadChangeCountry={setloadChangeCountry}
                    loadChangeCountry={loadChangeCountry}
                    selectedSort={selectedSort}
                    strictCategory={strictCategory}
                    setStrictCategory={setStrictCategory}
                />
                {loading && (
                    <Spin tip="Loading" size="small" style={{ marginTop: "3rem" }}>
                        <div className="content" />
                    </Spin>
                )}
                {isModalCorrectWord && <CorrectWordModal isModalCorrectWord={isModalCorrectWord} setIsModalCorrectWord={setIsModalCorrectWord} currentCountryId={currentCountry.id} />}
                {isModalSku && <SkuModal isModalSku={isModalSku} setIsModalSku={setIsModalSku} currentCountryId={currentCountry.id} setSkus={setSkus} />}
                {isModalMatching && (
                    <MatchingModal
                        isModalMatching={isModalMatching}
                        setIsModalMatching={setIsModalMatching}
                        products={products}
                        setProducts={setProducts}
                        loadCategory={loadCategory}
                        categories={categories}
                    />
                )}

                {isModalApplyToAll && (
                    <ApplyToAllModal
                        isModalApplyToAll={isModalApplyToAll}
                        setIsModalApplyToAll={setIsModalApplyToAll}
                        selectedShops={selectedShops}
                        selectedCategories={selectedCategories}
                        selectedBrands={selectedBrands}
                        xml={xml}
                        selectedNew={selectedNew}
                        user={user}
                        count={count}
                        currentCountryName={currentCountry.name}
                        currentCountryId={currentCountry.id}
                        categories={categories}
                        search={search}
                        brands={brands}
                        setProducts={setProducts}
                    />
                )}

                <Table
                    bordered
                    dataSource={products}
                    rowKey={(record) => record.id}
                    pagination={{
                        current: /*loading ? undefined :*/ page,
                        pageSize: 100,
                        showSizeChanger: false,
                        size: "small",
                        total: count,
                        hideOnSinglePage: true,
                        onChange: onPageChange,
                    }}
                    columns={columns}
                    id="all-products-table"
                    style={{ margin: "0.5rem", border: "1px solid lightgray", borderRadius: "8px" }}
                    rowClassName={(record) => (record.isPrices || record.isImage ? "all-products-isDropdown no-row-hover" : record.isChecked ? "all-products-isChanged no-row-hover" : "")}
                    scroll={{ y: `${(pageSize.height * 70) / 1271}vh` }}
                />
            </Col>
        </Row>
    );
};

export default AllProducts;
