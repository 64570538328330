import moment from "moment";
import React from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import "../style/Home.css";
import { useAuth } from "../../../shared/Context/AuthContext";

const Home = () => {
    const { isLoggedIn, user } = useAuth();

    const showDate = (date: string | null) => {
        let textDate;
        if (date) {
            textDate = `Logged in: ${moment(new Date(JSON.parse(date))).format("DD. MMMM YYYY.")} ${moment(new Date(JSON.parse(date))).format("HH:mm a")}`;
        } else {
            textDate = `${moment(new Date()).format("DD. MMMM YYYY.")} ${moment(new Date()).format("HH:mm a")}`;
        }
        return textDate;
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Home" />
            <div className="wlc-container in-animation">
                <header id="wlc-header">
                    {user && isLoggedIn && <h5 style={{ textAlign: "center", position: "relative", top: "2rem", fontSize: "28px" }}> {`${user?.first_name} ${user?.last_name}`} </h5>}
                    <h6 style={{ textAlign: "center", position: "relative", top: "2rem", fontSize: "20px" }}>{showDate(localStorage.getItem("loggedInTime"))}</h6>
                    <div className="wlc-logo">
                        <span>
                            <img
                                src="assets/images/eponuda_logo.svg"
                                alt="eponuda-logo"
                                height=""
                                style={{
                                    marginLeft: "4rem",
                                    marginBottom: "3rem",
                                }} /*style={{ backgroundColor:'lightgray',padding:'0.2rem',marginLeft:'0.5rem', marginTop:'0.5rem', borderRadius:'5px'}}*/
                            />
                        </span>
                    </div>
                </header>
                <h3 className="wlc-h3">Welcome to ePonuda</h3>
                <h5 className="wlc-h4">Data Management Tool </h5>
            </div>
        </React.Fragment>
    );
};

export default Home;
