import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Empty, Layout, Radio, Row, Select } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import DatePickerFromToReports from "../../reports/components/DatePickerFromToReports";
import { DatePickerNewProducts } from "../../reports/components/NewShopProducts";
import { v4 as uuidv4 } from "uuid";
import "../style/UserAssignments.css";
import FadeIn from "react-fade-in";
import showMessage from "../../../shared/MessagesInfo/message";
import SuccessNotification from "../../../shared/MessagesInfo/SuccessNotification";
import { getAllUsers } from "../../../shared/Api/Users";
import { getShops } from "../../../shared/Api/shop";
const { Header } = Layout;

type Shops = {
    id: number;
    name: string;
} | null;

type UserAssignmentsType = {
    created_at: string;
    date: string;
    id: number;
    keywords: string[];
    shop_ids: Shops[];
    statuses: string[];
    updated_at: string;
    user_id: number;
    isWeekend: boolean;
};

type UserObj = {
    id: number;
    first_name: string;
    last_name: string;
};

type StatusType = {
    id: number;
    name: string;
};

type ObjToSendType = {
    user_id: number | undefined;
    shop_ids: any;
    date: string;
    msg?: boolean;
};

const formatDate = (x: string) => {
    const d = new Date(x);

    return moment(d).format("YYYY-MM-DD");
};

const formatDateFromTo = (start: Date, end: Date | null) => {
    if (moment(start).format("DD MMM YYYY") === moment(end).format("DD MMM YYYY")) {
        return `for ${moment(start).format("DD. MMM YYYY.")}`;
    }
    return `from ${moment(start).format("DD. MMM YYYY.")} to ${moment(end).format("DD. MMM YYYY.")}`;
};

//const statusOptions = ['Auto-matched RS', 'Auto-matched SI','Auto-matched HR','Katalozi','Rekategorizacija','Mapiranje kategorija','Opisi prodavnica','Prices difference','Odmor','Ne radi', 'Opisi kategorija', 'Atributi']

const showCountry = (text: string) => {
    switch (text) {
        case "Srbija":
            return "RS";
        case "Slovenija":
            return "SI";
        case "Hrvatska":
            return "HR";
        case "Bosna i Hercegovina":
            return "BA";
    }
};

const UserAssignments = () => {
    const [users, setUsers] = useState<any>();
    const [date, setDate] = useState<DatePickerNewProducts>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [shops, setShops] = useState<{ id: number; name: string; country_name: string }[]>([]);
    const [user, setUser] = useState<UserObj | null>(null);
    const [statuses, setStatuses] = useState<StatusType[]>([]);
    const [userAssignments, setUserAssignments] = useState<UserAssignmentsType[]>([]);

    useEffect(() => {
        // get all shops
        getShops(0, null).then((resp) => {
            setShops(resp.data.map((s) => ({ id: s.id, name: s.name, country_name: s.country_name })));
        });

        /////////
        const getStatuses = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shop/get-user-shop-statuses`);
                setStatuses(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getStatuses();
    }, []);

    useEffect(() => {
        getAllUsers(true, "data-entry").then((resp) => {
            setUsers(resp);
        });
    }, []);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }
        const getUserShops = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shop/get-user-shops?user_id=${user?.id}&from=${formTo.startDate}&to=${formTo.endDate}`);
                let daysOfYear = [];
                if (date.endDate) {
                    for (var d = new Date(`${date.startDate.getFullYear()}, ${date.startDate.getMonth() + 1}, ${date.startDate.getDate()}`); d <= date.endDate; d.setDate(d.getDate() + 1)) {
                        //if (new Date(d).getDay() !==6 && new Date(d).getDay() !==0 ) {

                        daysOfYear.push(moment(new Date(d)).format("DD. MMM YYYY."));
                        //}
                    }
                    const newData = daysOfYear.map((item) => {
                        const findData = data.data.find((d: { date: string }) => moment(new Date(d.date)).format("DD. MMM YYYY.") === item);
                        return findData
                            ? {
                                  ...findData,
                                  date: moment(new Date(findData.date)).format("DD. MMM YYYY."),
                                  isWeekend: new Date(findData.date).getDay() === 0 || new Date(findData.date).getDay() === 6 ? true : false,
                                  shop_ids: findData.shop_ids ? (findData.shop_ids.length > 0 ? findData.shop_ids : [null]) : [null],
                                  statuses: JSON.parse(findData.statuses) ? JSON.parse(findData.statuses) : [],
                                  keywords: JSON.parse(findData.keywords) ? JSON.parse(findData.keywords) : [],
                              }
                            : {
                                  created_at: "",
                                  date: moment(new Date(item)).format("DD. MMM YYYY."),
                                  isWeekend: new Date(item).getDay() === 0 || new Date(item).getDay() === 6 ? true : false,
                                  id: uuidv4(),
                                  keywords: [],
                                  shop_ids: [null],
                                  statuses: [],
                                  updated_at: "",
                                  user_id: null,
                              };
                    });
                    setUserAssignments(newData);
                }
            } catch (err) {
                console.log(err);
            }
        };

        user?.id && date.startDate && date.endDate && getUserShops();
    }, [user?.id, date]);

    const selectUser = (user: UserObj) => {
        setUser(user);
    };

    const addNewShopToList = (data: UserAssignmentsType) => {
        setUserAssignments((curr) =>
            curr.map((item) => {
                if (item.id === data.id) {
                    if (item.shop_ids.filter((s) => s === null).length === 0) {
                        return {
                            ...item,
                            shop_ids: [...item.shop_ids, null],
                        };
                    } else {
                        return item;
                    }
                }
                return item;
            })
        );
    };

    const updateUserAssignments = async (obj: ObjToSendType) => {
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_URL_API}/shop/insert-user-shops`,
                obj.shop_ids.length > 0 ? { ...obj, shop_ids: obj.shop_ids.filter((data: number) => data) } : { ...obj, shop_ids: null }
            );
            obj.msg ? showMessage(data.message) : SuccessNotification(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    const addNewShop = (e: number, data: UserAssignmentsType) => {
        const findShop = shops.find((shop: { id: number; name: string }) => shop.id === e)!;
        //console.log(findShop)
        if (e) {
            const findData = userAssignments.find((item) => item.id === data.id);
            const objToSend = {
                ...findData,
                user_id: user?.id,
                shop_ids:
                    findData?.shop_ids.length === 1 && findData.shop_ids[0] === null
                        ? [e]
                        : findData && findData.shop_ids.length > 1
                        ? [...findData.shop_ids.map((s) => s && s.id), e].filter((item) => item !== null)
                        : [],
                date: formatDate(data.date),
                msg: true,
            };
            //console.log(objToSend)
            updateUserAssignments(objToSend).then((resp) => {
                setUserAssignments((curr) =>
                    curr.map((item) => {
                        if (item.id === data.id) {
                            return {
                                ...item,
                                shop_ids: item.shop_ids.map((s_id) => (s_id === null ? { id: e, name: findShop.name } : s_id)),
                            };
                        }
                        return item;
                    })
                );
            });
        }
    };

    const resetSelectedShop = (data: UserAssignmentsType, shop_id: number | null) => {
        const findData = userAssignments.find((item) => item.id === data.id);
        const objToSend = {
            ...findData,
            user_id: user?.id,
            shop_ids: findData?.shop_ids.filter((s_id) => s_id?.id !== shop_id && s_id !== null).map((item) => item?.id),
            date: formatDate(data.date),
            msg: true,
        };
        updateUserAssignments(objToSend).then((resp) => {
            setUserAssignments((curr) =>
                curr.map((item) => {
                    if (item.id === data.id) {
                        if (item.shop_ids.length > 1) {
                            return {
                                ...item,
                                shop_ids: item.shop_ids.filter((s_id) => s_id?.id !== shop_id),
                            };
                        }
                        return {
                            ...item,
                            shop_ids: [null],
                        };
                    }
                    return item;
                })
            );
        });
        //console.log(objToSend)
    };

    const addNewAction = (e: any, data: UserAssignmentsType, type: string, msg: boolean) => {
        const findData = userAssignments.find((item) => item.id === data.id);
        const objToSend = {
            ...findData,
            user_id: user?.id,
            shop_ids: data.shop_ids.map((s) => s && s.id),
            [`${type}`]: e,
            date: formatDate(data.date),
            msg: msg,
        };

        updateUserAssignments(objToSend).then((resp) => {
            setUserAssignments((curr) =>
                curr.map((item) => {
                    if (item.id === data.id) {
                        return {
                            ...item,
                            [`${type}`]: e,
                        };
                    }
                    return item;
                })
            );
        });
    };

    const handleRadioChange = (e: string) => {
        if (e === "Active") {
            userAssignments.length > 0 &&
                userAssignments.forEach((data) => {
                    addNewAction([], data, "statuses", false);
                });
        }
        if (e === "O") {
            userAssignments.length > 0 &&
                userAssignments.forEach((data) => {
                    addNewAction(["Odmor"], data, "statuses", false);
                });
        }
        if (e === "N") {
            userAssignments.length > 0 &&
                userAssignments.forEach((data) => {
                    addNewAction(["Ne radi"], data, "statuses", false);
                });
        }
    };

    //console.log(statuses);

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - User's assignments" />
            <Row justify="center">
                <Col span={23} className="user-assignments-header">
                    <Divider>User 's assignments</Divider>
                    <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center" }}>
                        <DatePickerFromToReports date={date} setDate={setDate} isDate={true} />
                    </div>
                </Col>

                <Col span={23} style={{ backgroundColor: "white", minHeight: "75vh" }}>
                    <Layout style={{ backgroundColor: "white" }}>
                        <Row>
                            <Col
                                span={6}
                                style={{
                                    border: "1px solid #d9dad7",
                                    padding: "1rem",
                                    margin: "0 1rem",
                                    backgroundColor: "#fcfefe",
                                }}
                            >
                                <Header className="product-img-header user-list-assignments-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px" }}>
                                    <span className="user-list-assignments-title">
                                        {`Active Users`} <i style={{ fontSize: "16px" }}></i>
                                    </span>
                                </Header>
                                {users?.users.data && (
                                    <ul className="user-sssignments-user-list">
                                        <FadeIn delay={50} transitionDuration={100}>
                                            {users?.users.data /*.slice(0,20)*/
                                                .map((userObj: UserObj) => (
                                                    <li key={userObj.id} className={userObj.id === user?.id ? "user-assignments-selected-user" : ""} onClick={() => selectUser(userObj)}>
                                                        <span>{`${userObj.first_name} ${userObj.last_name}`} </span>
                                                    </li>
                                                ))}
                                        </FadeIn>
                                    </ul>
                                )}
                            </Col>
                            <Col span={17}>
                                <Header
                                    className="product-img-header"
                                    //style={{ margin: "0 0.8rem 0 0" }}
                                    style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.95rem", lineHeight: "30px" }}
                                >
                                    {user && date.startDate && date.endDate && (
                                        <span style={{ position: "relative", right: "1.4rem" }}>
                                            User: <i style={{ fontSize: "16px" }}>{`${user.first_name} ${user.last_name}`}</i>{" "}
                                            {date.startDate && date.endDate && (
                                                <span
                                                    style={{
                                                        marginLeft: date.startDate === date.endDate ? "60%" : "50%",
                                                    }}
                                                >
                                                    Assignments {formatDateFromTo(date.startDate, date?.endDate)}{" "}
                                                </span>
                                            )}
                                        </span>
                                    )}
                                    <Radio.Group
                                        onChange={(e) => handleRadioChange(e.target.value)}
                                        value={null}
                                        size="small"
                                        style={{
                                            position: "absolute",
                                            right: "1.1rem",
                                            top: "0.25rem",
                                        }}
                                    >
                                        <Radio.Button style={{ backgroundColor: "whitesmoke" }} value="Active">
                                            Active
                                        </Radio.Button>
                                        <Radio.Button
                                            style={{
                                                backgroundColor: "whitesmoke",
                                                marginLeft: "2px",
                                            }}
                                            value="O"
                                        >
                                            O
                                        </Radio.Button>
                                        <Radio.Button
                                            style={{
                                                backgroundColor: "whitesmoke",
                                                marginLeft: "2px",
                                            }}
                                            value="N"
                                        >
                                            N
                                        </Radio.Button>
                                    </Radio.Group>
                                </Header>
                                {user?.id && date.startDate && date.endDate ? (
                                    <ul className="user-sssignments-date-list">
                                        <FadeIn delay={100} transitionDuration={800}>
                                            {userAssignments?.map((data: UserAssignmentsType) => {
                                                return (
                                                    <li key={data.id}>
                                                        <div
                                                            className="user-sssignments-date-box"
                                                            style={{
                                                                backgroundColor: data.isWeekend ? "#668ba4" : "",
                                                            }}
                                                        >
                                                            {moment(new Date(data.date)).format("DD. MMM YYYY.")}
                                                        </div>
                                                        <div className="user-sssignments-item">
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                }}
                                                            >
                                                                {data.shop_ids.map((shop) => (
                                                                    <Select
                                                                        style={{
                                                                            width: "230px",
                                                                            marginTop: "0.3rem",
                                                                        }}
                                                                        showSearch
                                                                        allowClear
                                                                        placeholder="Select shop"
                                                                        onChange={(e) => addNewShop(e, data)}
                                                                        key={shop ? shop.id : "test"}
                                                                        value={shop ? shop.id : null}
                                                                        onClear={() => resetSelectedShop(data, shop ? shop.id : null)}
                                                                        size="small"
                                                                        filterOption={(input, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                    >
                                                                        {shops &&
                                                                            shops.map((item: { id: number; name: string; country_name: string }) => (
                                                                                <Select.Option
                                                                                    value={item.id}
                                                                                    key={"category_seller_" + item.id}
                                                                                    style={{
                                                                                        display: data.shop_ids.map((s) => s && s.id).includes(item.id) ? "none" : "",
                                                                                    }}
                                                                                >
                                                                                    {`${item.name} ${showCountry(item.country_name)}`}
                                                                                </Select.Option>
                                                                            ))}
                                                                    </Select>
                                                                ))}
                                                                <PlusCircleOutlined style={{ margin: "0.3rem 0" }} onClick={() => addNewShopToList(data)} />
                                                            </div>
                                                            <React.Fragment>
                                                                {["statuses", "keywords"].map((action) => (
                                                                    <div
                                                                        key={action}
                                                                        style={{
                                                                            width: "300px",
                                                                            marginTop: "0.3rem",
                                                                        }}
                                                                    >
                                                                        <Select
                                                                            mode="tags"
                                                                            style={{
                                                                                width: "200px",
                                                                                color: "green",
                                                                                marginLeft: "5px",
                                                                            }}
                                                                            onChange={(e) => addNewAction(e, data, action, true)}
                                                                            tokenSeparators={[","]}
                                                                            placeholder={action === "statuses" ? "Enter Status" : "Enter keyword"}
                                                                            value={data[`${action}` as keyof UserAssignmentsType]}
                                                                            size="small"
                                                                        >
                                                                            {action === "statuses" && statuses.map((status) => <Select.Option key={status.name}>{status.name}</Select.Option>)}
                                                                        </Select>
                                                                    </div>
                                                                ))}
                                                            </React.Fragment>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </FadeIn>
                                    </ul>
                                ) : (
                                    <Col>
                                        <div className="matching-seller-category-empty">
                                            <Empty description="Select user and choose date" />{" "}
                                        </div>{" "}
                                    </Col>
                                )}
                            </Col>
                        </Row>
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserAssignments;
