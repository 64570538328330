import { Col, Select } from "antd";
import React, { useState } from "react";
import { CateogoryL3L4 } from "../../../../shared/FormElements/CategorySelect";
import { AttributeType, ValuesType } from "../../types/AttributeType";
import CreateAttributePopover from "./CreateAttributePopover";
import CategorySelect from "./CategorySelect";
import { GroupType } from "../../pages/AttributeOverview";
import { TreeSelectCategoryType } from "../../../../shared/Api/category";

interface FilterBoxProps {
    currentCountryName: string;
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeType[]>>;
    selectedCategory: {
        id: number;
        name: string;
    } | null;
    setSelectedCateogry: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
        } | null>
    >;
    setSelectedGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
    setValues: React.Dispatch<
        React.SetStateAction<{
            name: string;
            id: number;
            data: ValuesType[];
            type: string;
        } | null>
    >;
    groups: GroupType[];
    selectedGroup: number | undefined;
    setGroups: React.Dispatch<React.SetStateAction<GroupType[]>>;
    setAllAtributes: React.Dispatch<React.SetStateAction<{ value: string; label: string; group_id: number; type: string }[]>>;
    allCategories: TreeSelectCategoryType[];
}

const FilterBox = (props: FilterBoxProps) => {
    const [categories, setCategories] = useState<CateogoryL3L4[]>([]);
    const [order, setOrder] = useState("1");
    //const [isMoveRow, setIsMoveRow] = useState(false);

    const { currentCountryName, setAttributeList, selectedCategory, setSelectedCateogry, setSelectedGroup, setValues, groups, selectedGroup, setGroups, setAllAtributes, allCategories } = props;

    const onCategoryChange = (e: string) => {
        setValues(null);
        if (e) {
            setSelectedGroup(0);
            const wordToArr = e.split("::");
            setSelectedCateogry({ id: Number(wordToArr[0]), name: wordToArr[1] });
        } else {
            setSelectedCateogry(null);
            setSelectedGroup(undefined);
            setAttributeList([]);
            setValues(null);
        }
    };

    return (
        <React.Fragment>
            <Col span={8}>
                {props.selectedCategory && (
                    <CreateAttributePopover
                        selectedCategory={selectedCategory}
                        categories={categories}
                        setCategories={setCategories}
                        currentCountryName={currentCountryName}
                        setAttributeList={setAttributeList}
                        groups={groups}
                        selectedGroup={selectedGroup}
                        setGroups={setGroups}
                        setAllAtributes={setAllAtributes}
                        allCategories={allCategories}
                    />
                )}
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
                <CategorySelect allCategories={allCategories} currentCountryName={currentCountryName} onCategoryChange={onCategoryChange} />
            </Col>
            <Col span={8} style={{ textAlign: "end" }}>
                <Select
                    defaultValue={order}
                    onChange={(e) => {
                        setOrder(e);
                        //setIsMoveRow(false);
                        // isDrag = e === "1" ? false : true;
                    }}
                    //className="attribute-overview-order-select "
                >
                    <Select.Option key="1">Order by Order</Select.Option>
                    <Select.Option key="2">Order by Count</Select.Option>
                </Select>
            </Col>
        </React.Fragment>
    );
};

export default FilterBox;
