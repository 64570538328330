import React, { useState } from "react";
import { Table, Input, Popconfirm, Form, Typography, Col, Row, Divider, Button, Select } from "antd";
import { useEffect } from "react";
import "../style/MainMaps.css";
import { parseDate } from "../../../shared/utils/parseDate";
import NewFieldMainMapModal from "../components/NewFieldModal";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { getMainMap, mainMapUpdateField } from "../../../shared/Api/maps";

interface Item {
    key: string;
    key_name: string;
    key_type: string;
    required: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "select" | "text" | "boolean";
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const { Option } = Select;

    const setType = (inputType: any) => {
        switch (inputType) {
            case "text":
                return (
                    <Form.Item style={{ marginTop: "22px" }} name="key_name">
                        <Input />
                    </Form.Item>
                );
            case "select":
                return (
                    <Form.Item style={{ marginTop: "22px" }} name="key_type">
                        <Select>
                            <Option value="boolean">boolean</Option>
                            <Option value="string">string</Option>
                            <Option value="integer">integer</Option>
                            <Option value="float">float</Option>
                        </Select>
                    </Form.Item>
                );
            case "boolean":
                return (
                    <Form.Item style={{ marginTop: "22px" }} name="required">
                        <Select>
                            <Option value="true">Yes</Option>
                            <Option value="false">No</Option>
                        </Select>
                    </Form.Item>
                );
            default:
                break;
        }
    };
    const inputNode = setType(inputType);
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please insert ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const MainMaps = () => {
    const [mainMap, setMainMap] = useState<any>();
    const [form] = Form.useForm();
    //const [ data, setData ] = useState<Item[]>([]);

    const [editingKey, setEditingKey] = useState("");
    const [options, setOptions] = useState<any>([]);
    /////////////////////////

    useEffect(() => {
        getMainMap().then((resp) => {
            setMainMap(resp);
        });
    }, []);

    ///////////////////

    const editData = async (singleData: any, row: any) => {
        const id = singleData[0].id;
        const changedRow = { ...row, required: row.required === "true" ? true : false };
        const objectToSend = { id: id, ...changedRow };
        //dispatch(mainMapUpdateField(objectToSend));
        mainMapUpdateField(objectToSend).then(() => {
            getMainMap().then((resp) => {
                setMainMap(resp);
            });
        });
    };
    const isEditing = (record: Item) => record.key === editingKey;

    const edit = (record: Partial<Item> & { key: React.Key }) => {
        form.setFieldsValue({ name: "", age: "", address: "", ...record });
        form.setFieldsValue({ key_name: record.key_name, key_type: record.key_type, required: String(record.required) });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as Item;

            const newData = [...mainMap.data];
            const index = newData.findIndex((item) => key === item.key);
            /////

            const singleData = newData.filter((item: any) => item.key === key);

            editData(singleData, row).then((data: any) => {
                if (mainMap.status) {
                    if (index > -1) {
                        const item = newData[index];

                        newData.splice(index, 1, {
                            ...item,
                            ...row,
                        });
                        //setData(newData);
                        setEditingKey("");
                        //window.location.reload(false)
                    } else {
                        newData.push(row);
                        //setData(newData);
                        setEditingKey("");
                    }
                }
            });
            /////
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            title: "Main key ID",
            dataIndex: "id",
            width: "5%",
            editable: false,
        },
        {
            title: "Key name",
            dataIndex: "key_name",
            width: "10%",
            editable: true,
        },
        {
            title: "Key type",
            dataIndex: "key_type",
            width: "10%",
            editable: true,
        },
        {
            title: "Required",
            dataIndex: "required",
            width: "10%",
            editable: true,
            render: (record: any) => (String(record) === "true" ? "Yes" : "No"),
        },
        {
            title: "Actions",
            dataIndex: "operation",
            width: "15%",
            render: (_: any, record: Item) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        {/*<a href="#" onClick={() => save(record.key)} style={{ marginRight: 8 }}>*/}
                        <Button danger onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                            Save
                        </Button>
                        {/*</a>*/}
                        <Popconfirm title="Are you sure?" onConfirm={cancel}>
                            <Button type={"default"}>Cancel</Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
                        <Button type={"primary"} className="u-button">
                            Edit
                        </Button>
                    </Typography.Link>
                );
            },
        },
        {
            title: "Created",
            dataIndex: "created_at",
            width: "15%",
            editable: false,
            render: (record: any) => parseDate(record),
        },
        {
            title: "Last updated",
            dataIndex: "updated_at",
            width: "15%",
            editable: false,
            render: (record: any) => parseDate(record),
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === "key_name" ? "text" : col.dataIndex === "key_type" ? "select" : "boolean",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Main maps" />
            <Row justify={"center"}>
                <Col span={20}>
                    <Divider>{mainMap?.message}</Divider>
                    <Button type="primary" onClick={showModal} className="u-button" style={{ marginTop: "1rem", marginBottom: 20 }}>
                        Create new field
                    </Button>
                    <NewFieldMainMapModal
                        showModal={showModal}
                        isModalVisible={isModalVisible}
                        handleCancel={handleCancel}
                        //addNewField={addNewField}
                        options={options}
                        setOptions={setOptions}
                        setIsModalVisible={setIsModalVisible}
                        getMainMap={getMainMap}
                        setMainMap={setMainMap}
                    />
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            id="main-map-table"
                            dataSource={mainMap?.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id)}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={{
                                onChange: cancel,
                                defaultPageSize: 30,
                            }}
                            rowKey="id"
                        />
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MainMaps;
