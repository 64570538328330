import axios from "axios";
import { useEffect } from "react";
import { useKeyEvents } from "../utils/KeyPressEvents";

const useKeyEventAllPrices = (
    allPrices: any,
    setAllPrices: any,
    currentCountryId: any,
    currentCountryName: any,
    mpnMultiSelect: any,
    //mpnItem:any,
    setMpnItem: any,
    //onSelectChange:any,
    setSameParam: any,
    noCategory: boolean,
    matchModel: any,
    matchBrandModel: any,
    matchMpn: any,
    matchBrandMpn: any,
    setIsModalPredefinedText: any,
    showSkuModal: any,
    setModalShortcuts: any,
    //addSpecification:any,
    notShowPrices: any,
    mpnModal: any,
    setDarkMode: any,
    setCorrectWordModal: any
) => {
    const {
        onlyFirstWordInModel,
        onlyLastWordInModel,
        deleteFirstWordInModel,
        deleteLastWordInModel,
        resetModel,
        setMultiMpn,
        //openDrawerAutoChecked,
        firstCharacterLowCase,
        resetAllCheched,
        setMpnField,
        removeSelectedRows,
        parenthesesOnly,
        allLetterSmall,
        setCheckedToChanged,
        addSellerModelToName,
        addSellerSkuToName,
        convertLatinWord,
        addMpnToEditableName,
        setMpnToEmpty,
    } = useKeyEvents(allPrices, setAllPrices, currentCountryId, currentCountryName, notShowPrices, setMpnItem);

    const getCorrectLatinWords = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/word-replacement/list-all?country_id=${currentCountryId}`);
            convertLatinWord(data.data);
        } catch (err) {
            console.log(err);
        }
    };
    const handleKeyPress = (e: any) => {
        switch (e.which) {
            case 112:
                e.preventDefault();
                onlyFirstWordInModel(); //----- F1 ------//
                break;
            case 113:
                e.preventDefault();
                onlyLastWordInModel(); //----- F2 ------//
                break;
            case 114:
                e.preventDefault();
                deleteFirstWordInModel(); //----- F3 ------//
                break;
            case 115:
                e.preventDefault();
                deleteLastWordInModel(); //----- F4 ------//
                break;
            case 116:
                e.preventDefault(); //----- F5 ------//
                resetModel();
                break;
            case 117:
                e.preventDefault(); //----- F6 ------//
                setMultiMpn();
                break;
            // case 118:
            //     e.preventDefault()
            //     !uncategorized && noCategory && openDrawerAutoChecked()      //----- F7 ------//
            //     break;
            case 119:
                e.preventDefault();
                firstCharacterLowCase(); //----- F8 ------//
                break;
            case 120:
                //////////////////
                allLetterSmall(); //----- F9 ------//
                break;
            case 121:
                e.preventDefault();

                if (window.confirm("Load data with the parameters. Are you sure?!")) setSameParam((prev: any) => prev + 1); //----- F10 ------//
                break;
            case 122:
                e.preventDefault();
                resetAllCheched(); //----- F11 ------//
                break;
            case 123:
                e.preventDefault();
                setModalShortcuts((prev: boolean) => !prev); //----- F12 ------//
                break;
            case 192:
                e.preventDefault(); //----- ` ------//
                setMpnField();
                break;
            // case 39:
            //     //e.preventDefault()                              //----- Space ------//
            //     /*mpnModal.visible && !modalProductLoading && selectTemp < multySelected.length &&*/ skipPrice()//: handleMpnModalOk()   //&& mpnModalNext()            ////??????????????????????
            //     break;
            default:
                break;
        }
        switch (
            e.ctrlKey // ------ CTRL -------
        ) {
            case true:
                if (e.which === 69) {
                    e.preventDefault(); // ctrl + E
                    setIsModalPredefinedText(true);
                } else if (e.which === 81) {
                    e.preventDefault(); // ctrl + Q
                    showSkuModal();
                } else if (e.which === 16) {
                    //ctrl + shift
                    e.preventDefault();
                    !mpnModal.visible && mpnMultiSelect();
                } else if (e.which === 97 || e.which === 49) {
                    //ctrl + 1
                    e.preventDefault();
                    removeSelectedRows();
                } else if (e.which === 98 || e.which === 50) {
                    //ctrl + 2
                    e.preventDefault();
                    parenthesesOnly();
                } else if (e.which === 99 || e.which === 51) {
                    //ctrl + 3
                    e.preventDefault();
                    getCorrectLatinWords();
                    //convertLatinWord()
                } else if (e.which === 100 || e.which === 52) {
                    //ctrl + 4
                    e.preventDefault();
                    matchBrandMpn();
                } else if (e.which === 101 || e.which === 53) {
                    //ctrl + 5
                    e.preventDefault();
                    matchMpn();
                } else if (e.which === 102 || e.which === 54) {
                    //ctrl + 6
                    e.preventDefault();
                    matchBrandModel();
                } else if (e.which === 103 || e.which === 55) {
                    //ctrl + 7
                    e.preventDefault();
                    matchModel();
                }
                break;
            default:
                break;
        }
        switch (e.altKey) {
            case true:
                if (e.which === 65) {
                    e.preventDefault(); // alt + A
                    setCheckedToChanged();
                }
                if (e.which === 77) {
                    e.preventDefault(); // alt + M
                    addSellerModelToName();
                }
                if (e.which === 83) {
                    e.preventDefault(); // alt + S
                    addSellerSkuToName();
                }
                if (e.which === 87) {
                    e.preventDefault(); // alt + W
                    setCorrectWordModal(true);
                }
                /////
                if (e.which === 79) {
                    e.preventDefault(); // alt + O (Dark mode)
                    //addSellerSkuToName()
                    setDarkMode((prev: boolean) => !prev);
                }
                if (e.which === 82) {
                    e.preventDefault(); // alt + L
                    addMpnToEditableName();
                }
                if (e.which === 68) {
                    e.preventDefault(); // alt + D
                    setMpnToEmpty();
                }
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    });
};

export default useKeyEventAllPrices;
