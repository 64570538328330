import { Badge, Button, Card, Checkbox, Col, Divider, Empty, Form, Image, Input, Modal, Popconfirm, Popover, Row, Spin, Tooltip, Typography } from "antd";
import { ProductType } from "../types/types";
import { AimOutlined, CloseOutlined, FileImageOutlined, FontSizeOutlined, Loading3QuartersOutlined, LoadingOutlined, ZoomInOutlined } from "@ant-design/icons";
import { HighlilghtProductName, words } from "../../../shared/HighlightWords/Highlilght";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { updateProduct } from "../api/apiUpdateProduct";
import CategorySelect from "./MatchingModal/CategorySelect";
import BrandSelect from "./MatchingModal/BrandSelect";
import ProgressBar from "./MatchingModal/ProgressBar";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import FixProduct from "./MatchingModal/FixProduct";
import numberWithCommas from "../../../shared/utils/numberUtils";
import { moveImage } from "../api/apiMatchingModal";
import { MatchingModalProps, ObjMpnType, UpdateType } from "../types/modals";
import { showNameByCountry } from "../utils/showNameByCountry";
import { CountryType } from "../../../shared/Context/type";
import { useAuth } from "../../../shared/Context/AuthContext";

const checForOutlet = (text: string) => words.find((w) => text?.toLowerCase().includes(w));

let noDataId: number[] = [];

const showPrice = (min: number | string | boolean | string[], max: number | string | boolean | string[]) => (min !== 0 || max !== 0 ? true : false);

const sortProducts = (objArr: ProductType[]) => {
    return objArr
        .sort((a: ProductType, b: ProductType) => b.active_prices - a.active_prices)
        .sort((a: ProductType, b: ProductType) => {
            if (a.category_l3_id.toString() === "1" && b.category_l3_id.toString() !== "1") {
                return 1;
            } else if (a.category_l3_id.toString() !== "1" && b.category_l3_id.toString() === "1") {
                return -1;
            } else {
                return 0;
            }
        });
};

const MatchingModal: React.FC<MatchingModalProps> = (props) => {
    const { currentCountry } = useAuth();
    const [products_for_matching, setProducts_for_matching] = useState<ProductType[]>([]);
    const [matchingIndex, setMatchingIndex] = useState<number>(0);
    const [matchingProducts, setMatchingProducts] = useState<ProductType[]>([]);
    const [noDataInfo, setNoDataInfo] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const scrollContainerRef = useRef<any>(null);

    const { setProducts, setIsModalMatching } = props;

    const productsRef = useRef(props.products);

    useEffect(() => {
        if (props.isModalMatching) {
            setProducts_for_matching(productsRef.current.filter((p) => p.isChecked).map((p) => ({ ...p, matched: false })));
        } else {
            setProducts_for_matching([]);
        }
    }, [props.isModalMatching]);

    const handleCancel = () => {
        props.setIsModalMatching(false);
        noDataId = [];
    };

    const matchingProduct = products_for_matching[matchingIndex];

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    useEffect(() => {
        const unChecItem = () => {
            setProducts((curr) => curr.map((p) => (p.product_id === matchingProduct.product_id ? { ...p, isChecked: false } : p)));
        };

        const getMatchingProducts = async () => {
            setLoading(true);
            setNoDataInfo(null);
            const baseUrl = process.env.REACT_APP_URL_API;
            try {
                const { data } = await axios.post(`${baseUrl}/product/search`, {
                    collection: "allProducts",
                    data: {
                        per_page: 100,
                        q: matchingProduct?.name,
                        filter_by: `product_id:!=${matchingProduct?.product_id}&&condition:=1`,
                    },
                });
                if (data.hits.length === 0) {
                    setNoDataInfo("No Data");
                    setLoading(false);
                    unChecItem();
                    if (!noDataId.includes(matchingProduct?.product_id)) {
                        noDataId.push(matchingProduct.product_id); /// ???
                        setTimeout(() => {
                            setNoDataInfo(null);
                            setMatchingProducts([]);
                            if (matchingIndex + 1 !== products_for_matching.length) {
                                setMatchingIndex((curr) => curr + 1);
                            } else {
                                setIsModalMatching(false);
                            }
                        }, 1000);
                    }
                    return;
                }
                setMatchingProducts(
                    data.hits.map((d: { document: any }) => {
                        const product = d.document;
                        const cateogry = product[`category_4_${currentCountry.name}` as keyof typeof product]
                            ? product[`category_4_${currentCountry.name}` as keyof typeof product]
                            : product[`category_${currentCountry.name}` as keyof typeof product];
                        return {
                            ...product,
                            name: showNameByCountry(product, currentCountry.name, currentCountry.id, JSON.parse(localStorage.getItem("countries")!) as CountryType[]),
                            brand_name: product.brand.split("::")[1],
                            // category_name: product[`category_${props.currentCountryName}` as keyof typeof product]
                            //     .toString()
                            //     .split("::")[1],
                            // category_l3_id: product[`category_${props.currentCountryName}` as keyof typeof product]
                            //     .toString()
                            //     .split("::")[0],
                            category_name: cateogry.split("::")[1],
                            category_l3_id: cateogry.split("::")[0],
                            isFixing: false,
                            productForFixing: false,
                        };
                    })
                );
                setLoading(false);
                // added for testing back scroll on start
                if (scrollContainerRef.current) {
                    scrollContainerRef.current.scrollLeft = 0;
                }
            } catch (err) {
                console.log(err);
            }
        };
        matchingProduct?.name && getMatchingProducts();
    }, [
        matchingProduct?.name,
        currentCountry.name,
        setProducts,
        setIsModalMatching,
        matchingProduct?.product_id,
        matchingIndex,
        products_for_matching.length,
        currentCountry.id,
        //localStorageCountries,
    ]);

    const selectNewMatch = (product_id: number | undefined, newProduct: ProductType, type: "select" | "unselect") => {
        setProducts_for_matching((curr) =>
            curr.map((p) =>
                p.product_id === product_id
                    ? {
                          ...p,
                          matched: type === "select" ? true : false,
                          new_prodcut_id: type === "select" ? newProduct.product_id : "",
                      }
                    : p
            )
        );
        props.setProducts((curr) =>
            curr.map((p) =>
                p.product_id === product_id
                    ? {
                          ...p,
                          isChecked: false,
                          isChanged: type === "select" ? true : false,
                          new_prodcut_id: type === "select" ? newProduct.product_id : "",
                          newProductId: newProduct.product_id,
                          newImage: newProduct.image,
                          newBrand: newProduct.brand,
                          newBrandId: newProduct.brand_id,
                          newBrandName: newProduct.brand_name,
                          newName: newProduct.name,
                          newMpn: newProduct.mpn,
                          [`newCategory_${currentCountry.name}` as keyof typeof newProduct]: newProduct[`category_${currentCountry.name}` as keyof typeof newProduct],
                          newCategoryName: newProduct.category_name,
                          newCategoryId: newProduct.category_id,
                          [`newLink_${currentCountry.name}` as keyof typeof newProduct]: newProduct[`link_${currentCountry.name}` as keyof typeof newProduct],
                      }
                    : p
            )
        );
        setMatchingProducts([]); // for scroll - to back to start
        if (matchingIndex < products_for_matching.length - 1) {
            type === "select" && setMatchingIndex((curr) => curr + 1);
        } else {
            type === "select" && props.setIsModalMatching(false);
        }
    };

    const handleNextBtn = (product_id: number | undefined, type: "finish" | "next") => {
        setProducts_for_matching((curr) =>
            curr.map((p) =>
                p.product_id === product_id
                    ? {
                          ...p,
                          isChecked: false,
                      }
                    : p
            )
        );
        props.setProducts((curr) =>
            curr.map((p) =>
                p.product_id === product_id
                    ? {
                          ...p,
                          isChecked: false,
                      }
                    : p
            )
        );
        if (type === "next") {
            setMatchingProducts([]); // for scroll - to back to start
            setMatchingIndex((curr) => curr + 1);
        } else {
            props.setIsModalMatching(false);
            noDataId = [];
        }
    };

    const handlePrevBtn = () => {
        setMatchingIndex((curr) => curr - 1);
        props.setProducts((curr) => curr.map((p) => (p.product_id === products_for_matching[matchingIndex - 1].product_id ? { ...p, isChecked: true } : p)));
    };

    ////////////////////////////////////////////////////////////////////
    const [form] = Form.useForm();
    const [productItem, setProductItem] = useState<{
        product_id: number;
        type: UpdateType;
    } | null>(null);

    const [updateLoading, setUpdateLoading] = useState(false);

    const mpnInput: any = useRef(null);
    const nameInput: any = useRef(null);
    const changeProduct = (product_id: number, type: UpdateType) => {
        if (type === "brand" || type === "category") {
            setProductItem({ product_id, type });
        }
        if (type === "mpn" || type === "name") {
            setProductItem({ product_id, type });
            const property = matchingProducts.find((p) => p.product_id === product_id);
            form.setFieldValue(type, property ? property[type] : "");
            setTimeout(() => {
                type === "mpn" && mpnInput?.current.focus();
                type === "name" && nameInput?.current.focus();
            }, 50);
        }
    };

    // useEffect(() => {
    //     getAllCategories(props.currentCountryId, props.currentCountryName).then((resp) => {
    //         setCategories(resp);
    //     });
    // }, [props.currentCountryId, props.currentCountryName]);

    const noChangeProduct = () => {
        setProductItem(null);
    };

    const saveNewMpn = async (e: { mpn: string }) => {
        if (e.mpn.trim().length < 4 && e.mpn.trim().length !== 0) {
            openNotification("Mpn have to be long at least 4 characters");
            return;
        }
        const obj: ObjMpnType = { mpn: e.mpn };
        setUpdateLoading(true);
        updateProduct(productItem?.product_id, "mpn", obj)
            .then(() => {
                setMatchingProducts((curr) => curr.map((p) => (p.product_id === productItem?.product_id ? { ...p, mpn: obj.mpn } : p)));
                setProductItem(null);
                setUpdateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setUpdateLoading(false);
                setProductItem(null);
            });
    };

    const saveNewName = async (e: { name: string }) => {
        if (e.name.trim().length === 0) {
            openNotification("Name length have to be longer that 1 characters");
            return;
        }
        const obj = {
            name: e.name,
        };
        setUpdateLoading(true);
        updateProduct(productItem?.product_id, "name", obj, currentCountry.name)
            .then(() => {
                setMatchingProducts((curr) => curr.map((p) => (p.product_id === productItem?.product_id ? { ...p, [`name_${currentCountry.name}`]: obj.name, name: obj.name } : p)));
                setProductItem(null);
                setUpdateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setUpdateLoading(false);
                setProductItem(null);
            });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Ovo sprečava novi red u TextArea
            form.submit();
        }
    };

    /////////////////////////////////////////////////////////
    const [products_for_fixing, setProducts_for_fixing] = useState<number[]>([]);

    const chooseProductForFixing = (product_id: number) => {
        const findProductForFixing = matchingProducts.find((p) => p.productForFixing);
        setProducts_for_fixing([]);
        setMatchingProducts((curr) =>
            curr.map((p) => (p.product_id === product_id ? { ...p, productForFixing: !p.productForFixing, isFixing: false } : { ...p, isFixing: findProductForFixing ? false : true }))
        );
        setFirstProductFix("no-fixing");
    };

    const addProductForFixing = (e: CheckboxChangeEvent, product_id: number) => {
        if (e.target.checked) {
            setProducts_for_fixing((curr) => [...curr, product_id]);
        } else {
            setProducts_for_fixing((curr) => curr.filter((id) => id !== product_id));
        }
    };

    const [firstProductFix, setFirstProductFix] = useState<"no-fixing" | "product-for-fixing" | "fixing">("no-fixing");

    const fixingFirstPorduct = () => {
        if (firstProductFix === "no-fixing") {
            setFirstProductFix("product-for-fixing");
            setMatchingProducts((curr) => curr.map((p) => ({ ...p, productForFixing: false, isFixing: true })));
            setProducts_for_fixing([]);
        } else if (firstProductFix === "product-for-fixing") {
            setFirstProductFix("no-fixing");
            setMatchingProducts((curr) => curr.map((p) => ({ ...p, isFixing: false })));
            setProducts_for_fixing([]);
        }
    };

    const linkPrefix = localStorageCountries.find((c) => c.name === currentCountry.name)?.link ?? "";

    const [imageID, setImageID] = useState<number | null>(null);

    const findSecondImg = matchingProducts.find((i) => i.product_id === imageID);

    const contentImages = (
        <div>
            {findSecondImg && (
                <>
                    {" "}
                    <img alt="firstImage" src={matchingProduct?.image} width="400" />
                    <img alt="secondImage" src={findSecondImg?.image} width="400" />
                </>
            )}
        </div>
    );

    const smallLetterInName = (product: ProductType) => {
        const wordToArray = product.name.toString().split(" ");
        const makeSmallLetter = wordToArray
            .map((word: string) => {
                if ((/^[a-zA-ZŠšČčĆćŽžĐđ]+$/.test(word) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(word)) && !/\d/g.test(word)) {
                    return word.toLocaleLowerCase();
                } else if (parseInt(word)) {
                    return word;
                } else {
                    return word;
                }
            })
            .join(" ");

        const obj = {
            name: makeSmallLetter,
        };

        updateProduct(product?.product_id, "name", obj, currentCountry.name)
            .then(() => {
                setMatchingProducts((curr) =>
                    curr.map((p) => {
                        return p.product_id === product.product_id ? { ...p, name: makeSmallLetter } : p;
                    })
                );
            })

            .catch((err) => {
                console.log(err);
            });
    };

    const deleteMpn = async (product_id: number, type: UpdateType) => {
        setProductItem({ product_id, type });
        setTimeout(() => {
            const obj: ObjMpnType = { mpn: "" };
            setUpdateLoading(true);
            updateProduct(product_id, "delete_mpn", obj)
                .then(() => {
                    setMatchingProducts((curr) => curr.map((p) => (p.product_id === product_id ? { ...p, mpn: obj.mpn } : p)));
                    setProductItem(null);
                    setUpdateLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setUpdateLoading(false);
                    setProductItem(null);
                });
        }, 1000);
    };

    const currency = localStorageCountries?.find((c: { currency: string; name: string }) => c.name === currentCountry.name)?.currency;

    return (
        <Modal open={props.isModalMatching} onCancel={handleCancel} width="100%" footer={false}>
            <Row>
                <ProgressBar matchingIndex={matchingIndex} products_for_matching={products_for_matching} />

                <Col span={2} offset={10}>
                    <Button onClick={handlePrevBtn} disabled={matchingIndex === 0 ? true : false}>
                        Prev
                    </Button>
                    <Button style={{ marginLeft: "0.5rem" }} danger onClick={() => handleNextBtn(matchingProduct?.product_id, matchingIndex === products_for_matching.length - 1 ? "finish" : "next")}>
                        {matchingIndex === products_for_matching.length - 1 ? "Finish" : "Next"}
                    </Button>
                </Col>
            </Row>
            <Divider style={{ margin: "8px 0", padding: 0 }} />
            <Row
                style={{
                    backgroundColor: "whitesmoke",
                    padding: "0.5rem",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {(matchingProducts.find((p) => p.productForFixing) || firstProductFix === "product-for-fixing") && (
                    <FixProduct
                        firstProductFix={firstProductFix}
                        matchingProduct={matchingProduct}
                        matchingProducts={matchingProducts}
                        products_for_fixing={products_for_fixing}
                        //setProducts_for_fixing={setProducts_for_fixing}
                        setMatchingProducts={setMatchingProducts}
                        setFirstProductFix={setFirstProductFix}
                        setProducts={props.setProducts}
                        matchingIndex={matchingIndex}
                        setIsModalMatching={props.setIsModalMatching}
                        products_for_matching={products_for_matching}
                        setProducts_for_matching={setProducts_for_matching}
                        setMatchingIndex={setMatchingIndex}
                        products={props.products}
                    />
                )}
            </Row>
            <Row gutter={16} style={{ flexFlow: "row", margin: "1rem 0" }}>
                <Card className="all-prices-card" style={{ backgroundColor: "beige" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Image width={90} src={matchingProduct?.image} alt="Product" />
                        <a
                            className="shop-link"
                            href={matchingProduct ? `${linkPrefix}${matchingProduct[`link_${currentCountry.name}` as keyof typeof matchingProduct]}` : ""}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() =>
                                window.open(
                                    matchingProduct ? `${linkPrefix}${matchingProduct[`link_${currentCountry.name}` as keyof typeof matchingProduct]}` : "",
                                    "",
                                    "width=900, height=1080, top=0, left=960"
                                )
                            }
                            style={{ marginTop: "24px", color: "#01b0f5", textDecoration: "underline" }}
                        >
                            Link
                        </a>
                    </div>
                    <div style={{ height: "300px", width: "235px", marginTop: "1.2rem" }}>
                        {!matchingProducts.find((p) => p.productForFixing) ? (
                            <AimOutlined
                                onClick={fixingFirstPorduct}
                                style={{
                                    cursor: "pointer",
                                    fontSize: firstProductFix === "product-for-fixing" ? "22px" : "20px",
                                    color: firstProductFix === "product-for-fixing" ? "#c20909" : "",
                                }}
                            />
                        ) : (
                            <Checkbox onChange={(e) => setFirstProductFix(e.target.checked ? "fixing" : "no-fixing")} className="fix-price-checkbox" style={{ height: "28px" }} />
                        )}
                        <div style={{ marginTop: "3px" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>Product ID</span> {matchingProduct?.product_id}
                        </div>
                        <div>
                            <span style={{ fontWeight: "bold" }}>Brand</span> {matchingProduct?.brand_name}
                        </div>
                        <div>
                            <span style={{ fontWeight: "bold" }}>Mpn</span> {matchingProduct?.mpn}
                        </div>
                        <div>
                            <span style={{ fontWeight: "bold" }}>Category </span>
                            {matchingProduct?.category_name}
                        </div>
                        <div>
                            <span style={{ fontWeight: "bold" }}>Name </span>
                            {HighlilghtProductName(matchingProduct?.name, matchingProduct ? matchingProduct[`name_${currentCountry.name}` as keyof ProductType].toString() : "")}
                        </div>
                        <div>
                            <span style={{ fontWeight: "bold" }}>Price </span>
                            {matchingProduct && (
                                <span style={{ color: "darkred" }}>
                                    {/* {numberWithCommas(
                                        String(
                                            matchingProduct[
                                                `min_price_${props.currentCountryName}` as keyof typeof matchingProduct
                                            ]
                                        ).replace(".", ",")
                                    )}{" "}
                                    -{" "}
                                    {numberWithCommas(
                                        String(
                                            matchingProduct[
                                                `max_price_${props.currentCountryName}` as keyof typeof matchingProduct
                                            ]
                                        ).replace(".", ",")
                                    )}{" "} */}
                                    {
                                        /*matchingProduct[
                                        `min_price_${props.currentCountryName}` as keyof typeof matchingProduct
                                    ] !== 0 ||
                                        (matchingProduct[
                                            `max_price_${props.currentCountryName}` as keyof typeof matchingProduct
                                        ] !== 0 && */
                                        showPrice(
                                            matchingProduct[`min_price_${currentCountry.name}` as keyof typeof matchingProduct],
                                            matchingProduct[`max_price_${currentCountry.name}` as keyof typeof matchingProduct]
                                        ) && (
                                            <span style={{ color: "darkred" }}>
                                                {`${numberWithCommas(String(matchingProduct[`min_price_${currentCountry.name}` as keyof typeof matchingProduct]).replace(".", ","))}
                                                 - 
                                                ${numberWithCommas(String(matchingProduct[`max_price_${currentCountry.name}` as keyof typeof matchingProduct]).replace(".", ","))} ${currency}`}
                                            </span>
                                        )
                                    }
                                </span>
                            )}
                        </div>
                    </div>
                </Card>
                {noDataInfo || loading ? (
                    <div
                        style={{
                            width: "400px",
                            height: "500px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {loading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No matching products"} />}
                    </div>
                ) : (
                    <div ref={scrollContainerRef} style={{ overflowX: "auto", display: "flex" }} className="matching-products-container">
                        {sortProducts(matchingProducts)
                            // .sort((a, b) => b.active_prices - a.active_prices)
                            .map((product) => (
                                <Card
                                    key={product.id}
                                    className="all-prices-card"
                                    style={{
                                        backgroundColor: "white",
                                        borderColor: matchingProduct?.new_prodcut_id === product.product_id ? "red" : "",
                                        padding: "21px!important",
                                    }}
                                >
                                    <div style={{ position: "relative", display: "flex" }}>
                                        <Image width={90} src={product.image} alt="Product" />
                                        <Popover
                                            //className="popover-images"
                                            placement="topRight"
                                            title="Compare images"
                                            content={contentImages}
                                            trigger="click"
                                        >
                                            <ZoomInOutlined
                                                style={{
                                                    fontSize: "20px",
                                                    marginLeft: "2rem",
                                                    marginTop: "0.4rem",
                                                }}
                                                onClick={() => {
                                                    setImageID(product.product_id);
                                                }}
                                            />
                                        </Popover>
                                        <div style={{ alignSelf: "end" }}>
                                            {localStorageCountries.map((country: { name: string; link: string }) => {
                                                const countryLink = product[`link_${country.name}` as keyof typeof product]
                                                    ? `${country.link}${product[`link_${country.name}` as keyof typeof product]}`
                                                    : "";
                                                return (
                                                    <a
                                                        key={country.name}
                                                        href={countryLink}
                                                        onClick={() => window.open(countryLink, "", "width=900, height=1080, top=0, left=960")}
                                                        className="shop-link"
                                                        style={{
                                                            marginLeft: "0.5rem",
                                                            color: product[`link_${country.name}` as keyof typeof product] ? "#01b0f5" : "gray",
                                                            textDecoration: "underline",
                                                            cursor: product[`link_${country.name}` as keyof typeof product] ? "pointer" : "default",
                                                            pointerEvents: product[`link_${country.name}` as keyof typeof product] ? "all" : "none",
                                                        }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {country.name.toUpperCase()}
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <Badge
                                        count={product.active_prices}
                                        style={{
                                            backgroundColor: "#1d9696",
                                            width: "33px",
                                            height: "25px",
                                            fontSize: "15px",
                                            paddingTop: "1.2px",

                                            position: "relative",
                                            top: "-5.2rem",
                                            left: "13rem",
                                            // right: "-100px",
                                        }}
                                    />

                                    <div style={{ height: "300px", width: "235px" }}>
                                        <Row justify="space-between">
                                            {!product.isFixing ? (
                                                <AimOutlined
                                                    onClick={() => chooseProductForFixing(product.product_id)}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontSize: product.productForFixing ? "22px" : "20px",
                                                        color: product.productForFixing ? "#c20909" : "",
                                                    }}
                                                />
                                            ) : (
                                                <Checkbox className="fix-price-checkbox" onChange={(e) => addProductForFixing(e, product.product_id)} />
                                            )}
                                            {/* {!product.image && ( */}
                                            <Popconfirm placement="top" title={"Are you sure?"} onConfirm={() => moveImage(matchingProduct, product, setMatchingProducts)} okText="Yes" cancelText="No">
                                                {" "}
                                                <FileImageOutlined
                                                    style={{
                                                        // position: "absolute",
                                                        // left: "45px",
                                                        // top: "122px",
                                                        fontSize: "18px",
                                                        marginLeft: "1rem",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Popconfirm>
                                            {/* )} */}
                                        </Row>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3px" }}>
                                            <div style={{ padding: "0 0.2rem" }}>
                                                <span style={{ fontWeight: "bold", marginRight: "3px" }}>Product ID</span>{" "}
                                                <span className="link-product" onClick={() => window.open(`/product/${product.product_id}/prices`, "_blank", "noopener,noreferrer")}>
                                                    {product.product_id}
                                                </span>
                                            </div>
                                            <Typography.Text copyable={{ text: product.product_id.toString(), tooltips: false }} />
                                        </div>
                                        <div
                                            className="all-products-card-item"
                                            onDoubleClick={() => changeProduct(product.product_id, "brand")}
                                            style={{
                                                backgroundColor: productItem?.type === "brand" && productItem?.product_id === product.product_id ? "whitesmoke" : "",
                                                borderRadius: "3px",
                                                padding: "0 0.2rem",
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold" }}>Brand</span>
                                            {productItem?.product_id === product.product_id && productItem.type === "brand" ? (
                                                <BrandSelect
                                                    setMatchingProducts={setMatchingProducts}
                                                    productItem={productItem}
                                                    setProductItem={setProductItem}
                                                    updateProduct={updateProduct}
                                                    noChangeProduct={noChangeProduct}
                                                    loadCategory={props.loadCategory}
                                                />
                                            ) : (
                                                <span style={{ marginLeft: "5px" }}>{` ${product.brand_name}`}</span>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div
                                                className="all-products-card-item"
                                                onDoubleClick={() => changeProduct(product.product_id, "mpn")}
                                                style={{
                                                    backgroundColor: productItem?.type === "mpn" && productItem?.product_id === product.product_id ? "whitesmoke" : "",
                                                    borderRadius: "3px",
                                                    padding: "0 0.2rem",
                                                    width: "100%",
                                                }}
                                            >
                                                <span style={{ fontWeight: "bold" }}>Mpn</span>
                                                {productItem?.type === "mpn" && productItem?.product_id === product.product_id ? (
                                                    <Form form={form} onFinish={saveNewMpn} style={{ width: "100%" }}>
                                                        <Form.Item name="mpn" style={{ margin: 0 }}>
                                                            <Input
                                                                onBlur={noChangeProduct}
                                                                ref={mpnInput}
                                                                size="small"
                                                                style={{ padding: 0 }}
                                                                suffix={
                                                                    productItem.type === "mpn" && updateLoading ? (
                                                                        <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} />
                                                                    ) : (
                                                                        <span />
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                ) : (
                                                    <span style={{ marginLeft: "5px" }}>{` ${product.mpn}`}</span>
                                                )}
                                            </div>
                                            <Tooltip title="Delete mpn" open={product.mpn.trim().length > 3 ? undefined : false}>
                                                {productItem?.type === "delete_mpn" && productItem.product_id === product.product_id ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <CloseOutlined
                                                        style={{ color: "#B8001F", cursor: "pointer", marginLeft: "5px" }}
                                                        onClick={() => (product.mpn.trim().length > 3 ? deleteMpn(product.product_id, "delete_mpn") : undefined)}
                                                    />
                                                )}
                                            </Tooltip>
                                        </div>
                                        <div
                                            className="all-products-card-item"
                                            onDoubleClick={() => changeProduct(product.product_id, "category")}
                                            style={{
                                                width: "100%",
                                                backgroundColor: productItem?.type === "category" && productItem?.product_id === product.product_id ? "whitesmoke" : "",
                                                borderRadius: "3px",
                                                padding: "0 0.2rem",
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold" }}>Category</span>
                                            {productItem?.type === "category" && productItem?.product_id === product.product_id ? (
                                                <Form form={form} /*onFinish={saveNewMpn} */ style={{ width: "100%" }}>
                                                    <Form.Item name="category" style={{ margin: 0 }}>
                                                        <CategorySelect
                                                            productItem={productItem}
                                                            setProductItem={setProductItem}
                                                            categories={props.categories}
                                                            noChangeProduct={noChangeProduct}
                                                            setMatchingProducts={setMatchingProducts}
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            ) : (
                                                <span style={{ marginLeft: "5px" }}>{` ${product.category_name}`}</span>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                backgroundColor: productItem?.type === "name" && productItem?.product_id === product.product_id ? "whitesmoke" : "",
                                                borderRadius: "3px",
                                                padding: "0 0.2rem",
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold" }}>Name</span>
                                            {productItem?.type === "name" && productItem?.product_id === product.product_id ? (
                                                <Form form={form} onFinish={saveNewName} style={{ width: "100%" }}>
                                                    <Form.Item name="name" style={{ margin: 0 }}>
                                                        <Input.TextArea
                                                            onBlur={noChangeProduct}
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            ref={nameInput}
                                                            size="small"
                                                            style={{ width: "100%", padding: 0 }}
                                                        />
                                                    </Form.Item>
                                                    {productItem.type === "name" && updateLoading && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                right: "15px",
                                                                transform: "translate(-50%, -50%)",
                                                            }}
                                                        >
                                                            <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} />
                                                        </div>
                                                    )}
                                                </Form>
                                            ) : (
                                                <>
                                                    <span
                                                        style={{ marginLeft: "5px" }}
                                                        className="all-products-card-item"
                                                        onDoubleClick={() => (product[`name_${currentCountry.name}` as keyof typeof product] ? changeProduct(product.product_id, "name") : {})}
                                                    >
                                                        {/* {` ${product.name}`}{" "}  */}
                                                        {HighlilghtProductName(matchingProduct?.name, product.name)}{" "}
                                                        {checForOutlet(product.name) ? <span className="mpn-modal-span-outlet">OUTLET</span> : ""}
                                                    </span>
                                                    <FontSizeOutlined className="mm-small-letter" onClick={() => smallLetterInName(product)} />
                                                </>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                padding: "0 0.2rem",
                                            }}
                                        >
                                            <span style={{ color: "darkred" }}>
                                                {showPrice(
                                                    product[`min_price_${currentCountry.name}` as keyof typeof product],
                                                    product[`max_price_${currentCountry.name}` as keyof typeof product]
                                                ) && (
                                                    <span style={{ color: "darkred" }}>
                                                        {`${numberWithCommas(String(product[`min_price_${currentCountry.name}` as keyof typeof product]).replace(".", ","))}
                                                 - 
                                                ${numberWithCommas(String(product[`max_price_${currentCountry.name}` as keyof typeof product]).replace(".", ","))} ${currency}`}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    {product.category_l3_id.toString() !== "1" && (
                                        <Button
                                            type={matchingProduct?.new_prodcut_id === product.product_id ? "primary" : "default"}
                                            shape="round"
                                            style={{
                                                position: "absolute",
                                                width: "140px",
                                                height: "40px",
                                                fontSize: "18px",
                                                bottom: "3rem",
                                                right: "4rem",
                                            }}
                                            danger
                                            onClick={() => selectNewMatch(matchingProduct?.product_id, product, matchingProduct?.new_prodcut_id === product.product_id ? "unselect" : "select")}
                                        >
                                            {matchingProduct?.new_prodcut_id === product.product_id ? "Unselect" : `Select`}
                                        </Button>
                                    )}
                                </Card>
                            ))}
                    </div>
                )}
            </Row>
        </Modal>
    );
};

export default MatchingModal;
