import React, { useState, useEffect, useCallback } from "react";
import valueCleaning from "../utils/valueCliningUS.json";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Alert, Button, Card, Checkbox, Col, Divider, Empty, Form, Input, InputNumber, List, Modal, Row, Select, Tooltip, Typography } from "antd";
import { ClockCircleOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import showMessage from "../../../shared/MessagesInfo/message";
import ModalUrl from "../components/UnivarsalScraper/ModalUrl";
import CreateScraper from "../components/UnivarsalScraper/CreateScraper";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { useHistory, useParams } from "react-router-dom";
import { getShops } from "../../../shared/Api/shop";
import { useAuth } from "../../../shared/Context/AuthContext";

type XPathDataType = {
    id: string;
    name: string;
    xpath: string;
    value: string;
    rules: { r_id: string; ruleName: string; parameters: { p_id: string; paramName: string; paramValue: string }[] }[];
    ["string"]?: any;
};

const cleanValue: any = [...valueCleaning];

const UniversalScraper = () => {
    const { currentCountry } = useAuth();
    const [shops, setShops] = useState<{ id: number; name: string }[]>([]);
    const [scraperList, setScraperList] = useState<{ id: number; website_name: string }[]>([]);
    const [xPathData, setXPathData] = useState<XPathDataType[]>([]);
    const [selectedShop, setSelectedShop] = useState<null | number>(null);
    const [loadingNewScraper, setLoadingNewScraper] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const historyParams = useHistory();
    const param = useParams() as { id: string };

    const [form] = Form.useForm();
    const [searchForm] = Form.useForm();

    const getAllScrapers = useCallback(async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/index`);
            setScraperList(data);
            !isNaN(Number(param.id)) && searchForm.setFieldValue("website", Number(param.id));
        } catch (err) {
            console.log(err);
        }
    }, [param.id, searchForm]);

    useEffect(() => {
        getAllScrapers();
        getShops(0, currentCountry.id).then((resp) => {
            setShops(resp.data.map((s) => ({ id: s.id, name: s.name })));
        });
    }, [currentCountry.id, getAllScrapers]);

    const [infoData, setInfoData] = useState({});

    const onShopChange = (value: number) => {
        setSelectedShop(value);
    };

    const onWebsiteClear = () => {
        form.resetFields();
        setXPathData([]);
    };

    const getScraper = useCallback(
        async (websiteId: number | string) => {
            form.resetFields();
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/get?id=${websiteId}`);
                const info = { ...data };
                delete info["parameters"];
                setInfoData(info);

                setXPathData(
                    JSON.parse(data.parameters)?.productXPath
                        ? JSON.parse(data.parameters).productXPath.map((d: any) => {
                              const rules: {
                                  r_id: string;
                                  ruleName: string;
                                  parameters: string | { p_id: string; paramName: string; paramValue: string }[];
                              }[] = [];
                              for (let el in d) {
                                  if (el !== "name" && el !== "xpath" && el !== "value" && el !== "id") {
                                      if (el === "split_string_reverse" || el === "replace" || el === "substring") {
                                          const param = d[el as keyof typeof d];
                                          const paramArr: { p_id: string; paramName: string; paramValue: string }[] = [];
                                          for (let p in param) {
                                              paramArr.push({ p_id: uuidv4(), paramName: p, paramValue: param[p] });
                                          }
                                          rules.push({
                                              r_id: uuidv4(),
                                              ruleName: el,
                                              parameters: paramArr,
                                          });
                                      } else {
                                          rules.push({
                                              r_id: uuidv4(),
                                              ruleName: el,
                                              parameters: [
                                                  {
                                                      p_id: uuidv4(),
                                                      paramName: el,
                                                      paramValue: d[el as keyof typeof d],
                                                  },
                                              ],
                                          });
                                      }
                                  }
                              }
                              return {
                                  name: d.name,
                                  xpath: d.xpath,
                                  value: d.value,
                                  id: uuidv4(),
                                  rules: rules,
                              };
                          })
                        : []
                );

                const dataInForm = JSON.parse(data.parameters);

                ///////////////////////////////////////////////
                if (JSON.parse(data.parameters)) {
                    JSON.parse(data.parameters).slowScrape && setSlowScraper(true);
                    form.setFieldsValue({
                        ...dataInForm,
                        parseProductPage: String(dataInForm.parseProductPage),
                        categoryIncrementOnFirstPage: String(dataInForm.categoryIncrementOnFirstPage),
                    });
                } else {
                    form.resetFields();
                }
            } catch (err) {
                console.log(err);
            }
        },
        [form]
    );

    useEffect(() => {
        if (!isNaN(Number(param.id))) {
            getScraper(Number(param.id));
        }
    }, [param.id, getScraper]);

    const parseProductPageWatch = Form.useWatch("parseProductPage", form);

    const handleSelectWebsite = (e: number) => {
        historyParams.replace(`/universal-scraper${e ? `/${e}` : ""}`);
    };

    const addNewScraper = async () => {
        setLoadingNewScraper(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/scraper/data/create`, {
                shop_id: selectedShop,
            });
            if (data) {
                showMessage("New Scraper created");
                setSelectedShop(null);
                setLoadingNewScraper(false);
                getAllScrapers();
            }
        } catch (err) {
            console.log(err);
        }
    };

    ///////////////////////////////////
    const addRule = (id: string) => {
        setXPathData((curr) =>
            curr.map((d) => {
                if (d.id === id) {
                    return {
                        ...d,
                        rules: [
                            ...d.rules,
                            {
                                ruleName: "",
                                r_id: uuidv4(),
                                parameters: [{ p_id: uuidv4(), paramName: "", paramValue: "" }],
                            },
                        ],
                    };
                }
                return d;
            })
        );
    };

    const deleteElement = (id: string) => {
        setXPathData((curr) => curr.filter((d) => d.id !== id));
    };

    const addElement = () => {
        setXPathData((curr) => [...curr, { id: uuidv4(), name: "", value: "", xpath: "", rules: [] }]);
    };

    const handleInputChange = (text: string, id: string, key: string) => {
        setXPathData((curr) =>
            curr.map((d) => {
                if (d.id === id) {
                    return {
                        ...d,
                        [key]: text,
                    };
                }
                return d;
            })
        );
    };

    const handleSelectRule = (xpathId: string, r_id: string, e: string) => {
        const newRules = xPathData.map((r) => {
            if (r.id === xpathId) {
                let obj: any = "";
                if (e === "split_string_reverse") {
                    obj = [
                        { p_id: uuidv4(), paramName: "split_by", paramValue: "" },
                        { p_id: uuidv4(), paramName: "element", paramValue: "" },
                    ];
                } else if (e === "replace") {
                    obj = [
                        { p_id: uuidv4(), paramName: "replace", paramValue: "" },
                        { p_id: uuidv4(), paramName: "replaceWith", paramValue: "" },
                    ];
                }
                //////
                else if (e === "substring") {
                    obj = [
                        { p_id: uuidv4(), paramName: "offset", paramValue: "" },
                        { p_id: uuidv4(), paramName: "length", paramValue: "" },
                    ];
                }

                /////
                else if (e === "remove") {
                    obj = [{ p_id: uuidv4(), paramName: "remove", paramValue: "" }];
                } else if (e === "prefix") {
                    obj = [{ p_id: uuidv4(), paramName: "prefix", paramValue: "" }];
                } else if (e === "ucfirst" || e === "trim") {
                    obj = true;
                }
                return {
                    ...r,
                    ruleName: e,
                    rules: r.rules.map((r) => (r.r_id === r_id ? { r_id: r.r_id, ruleName: e, parameters: obj } : r)),
                };
            }

            return r;
        });
        setXPathData(newRules);
    };
    const hadleRuleInput = (text: string, xPathId: string, r_id: string, p_id: string) => {
        setXPathData((curr) =>
            curr.map((d) => {
                if (d.id === xPathId) {
                    return {
                        ...d,
                        rules: d.rules.map((r) =>
                            r.r_id === r_id
                                ? {
                                      ...r,
                                      parameters: r.parameters.map((p) => (p.p_id === p_id ? { ...p, paramValue: text } : p)),
                                  }
                                : r
                        ),
                    };
                }
                return d;
            })
        );
    };

    const deleteRule = (id: string, r_id: string) => {
        setXPathData((curr) =>
            curr.map((d) => {
                if (d.id === id) {
                    return {
                        ...d,
                        rules: d.rules.filter((r) => r.r_id !== r_id),
                    };
                }
                return d;
            })
        );
    };

    ///////////////////////////////////////////////

    const checkFieldsAndSave = async () => {
        //form.resetFields(["productAttributeKeyXPath", "productAttributeValueXPath", "productAttributeXPath"]);
        const productAttributeKeyXPath = form.getFieldValue("productAttributeKeyXPath") ?? undefined;
        const productAttributeValueXPath = form.getFieldValue("productAttributeValueXPath") ?? undefined;
        const productAttributeXPath = form.getFieldValue("productAttributeXPath") ?? undefined;

        if ((productAttributeKeyXPath && productAttributeValueXPath && productAttributeXPath) || (!productAttributeKeyXPath && !productAttributeValueXPath && !productAttributeXPath)) {
            saveScraper();
        } else {
            openNotification("In form exist unfilled required field! Check: Attribute Key XPath, Attribute Value XPath, Attribute XPath");
        }
    };

    const saveScraper = () => {
        // checkField();
        const dataFromForm = form.getFieldsValue();
        const product_page_xpath_field = form.getFieldValue("productPageXPath");
        if (parseProductPageWatch === "true" && !product_page_xpath_field) {
            openNotification("Product Page XPath field have to be filed");
            return;
        }
        const transformedData = [...xPathData].map((d) => {
            let tempArr: any = {};
            d.rules.forEach((r) => {
                if (r.ruleName === "split_string_reverse") {
                    // for replace is the same !!!!!!!!!!!!
                    tempArr["split_string_reverse"] = {
                        [r.parameters[0].paramName]: r.parameters[0].paramValue,
                        [r.parameters[1].paramName]: r.parameters[1].paramValue,
                    };
                }
                if (r.ruleName === "replace") {
                    // for replace is the same !!!!!!!!!!!!
                    tempArr["replace"] = {
                        [r.parameters[0].paramName]: r.parameters[0].paramValue,
                        [r.parameters[1].paramName]: r.parameters[1].paramValue,
                    };
                }
                if (r.ruleName === "substring") {
                    // for replace is the same !!!!!!!!!!!!
                    tempArr["substring"] = {
                        [r.parameters[0].paramName]: r.parameters[0].paramValue,
                        [r.parameters[1].paramName]: r.parameters[1].paramValue,
                    };
                }

                if (r.ruleName === "ucfirst") {
                    tempArr["ucfirst"] = true;
                }
                if (r.ruleName === "trim") {
                    tempArr["trim"] = true;
                }

                if (r.ruleName === "remove" || r.ruleName === "prefix") {
                    r.parameters.forEach((p) => {
                        tempArr[p.paramName] = p.paramValue;
                    });
                }
            });

            const newObj = {
                name: d.name,
                value: d.value,
                xpath: d.xpath,
                ...tempArr,
            };

            let objToSend: any = {};

            for (let el in newObj) {
                if (el !== "rules") {
                    objToSend[el] = newObj[el as keyof typeof newObj];
                }
            }
            delete objToSend["ruleName"];
            delete objToSend["id"];
            return objToSend;
        });

        const dataF = {
            ...dataFromForm,
            categoryIncrementOnFirstPage: dataFromForm.categoryIncrementOnFirstPage === "false" ? false : true,
            parseProductPage: dataFromForm.parseProductPage === "false" ? false : true,
        };
        dataFromForm.slowScrape && !dataFromForm.slowScrapeSeconds && delete dataF["slowScrapeSeconds"];

        const parameters = JSON.stringify({ ...dataF, productXPath: transformedData });

        updateScraper({ ...infoData, parameters: parameters });
    };

    const updateScraper = async (obj: any) => {
        setLoadingSave(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/scraper/data/update`, {
                json: obj.parameters,
                scraper_id: obj.scraper_id,
            });
            setLoadingSave(false);
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    const [testUrl, setTestUrl] = useState("");
    const [testUrlResp, setTestUrlResp] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const submitTestUrl = async () => {
        form.getFieldValue("productElementXPath");

        const objToSend = {
            url: testUrl,
            element_xpath: form.getFieldValue("productElementXPath"),
            xpath_queries: xPathData.map((d) => {
                let ruleTemArr: { name: string; ruleValue: any }[] = [];

                if (d.rules.length > 0) {
                    d.rules.forEach((r) => {
                        if (r.ruleName === "prefix") {
                            ruleTemArr.push({
                                name: "prefix",
                                ruleValue: r.parameters[0]?.paramValue,
                            });
                        } else if (r.ruleName === "remove") {
                            ruleTemArr.push({
                                name: "remove",
                                ruleValue: r.parameters[0]?.paramValue,
                            });
                        } else if (r.ruleName === "ucfirst") {
                            ruleTemArr.push({
                                name: "ucfirst",
                                ruleValue: true,
                            });
                        } else if (r.ruleName === "trim") {
                            ruleTemArr.push({
                                name: "trim",
                                ruleValue: true,
                            });
                        } else if (r.ruleName === "split_string_reverse") {
                            ruleTemArr.push({
                                name: "split_string_reverse",
                                ruleValue: {
                                    [r.parameters[0].paramName]: r.parameters[0].paramValue,
                                    [r.parameters[1].paramName]: r.parameters[1].paramValue,
                                },
                            });
                        } else if (r.ruleName === "replace") {
                            ruleTemArr.push({
                                name: "replace",
                                ruleValue: {
                                    [r.parameters[0].paramName]: r.parameters[0].paramValue,
                                    [r.parameters[1].paramName]: r.parameters[1].paramValue,
                                },
                            });
                        } else if (r.ruleName === "substring") {
                            ruleTemArr.push({
                                name: "substring",
                                ruleValue: {
                                    [r.parameters[0].paramName]: r.parameters[0].paramValue,
                                    [r.parameters[1].paramName]: r.parameters[1].paramValue,
                                },
                            });
                        }
                    });
                }

                let temObj: any = {};
                ruleTemArr.forEach((rt) => (temObj[rt.name] = rt.ruleValue));
                return {
                    name: d.name,
                    xpath: d.xpath,
                    value: d.value,
                    ...temObj,
                };
            }),
        };

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/scraper/test-iterator`, objToSend);
            setTestUrlResp(data);
            setIsModalOpen(true);
        } catch (err) {
            console.log(err);
        }
    };

    const [urlScraperTest, setUrlScraperTest] = useState("");
    const [xpathScraperTest, setXpathScraperTest] = useState("");

    const [testScraperData, setTestScraperData] = useState([]);

    const [isModalTestScraper, setIsModalTestScraper] = useState(false);

    const handleModalOkTestScraper = () => {
        setIsModalTestScraper(false);
    };

    const handleModalCancelTestScraper = () => {
        setIsModalTestScraper(false);
    };

    const testScraper = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/test-xpath?url=${urlScraperTest}&xpath=${xpathScraperTest}`);
            setTestScraperData(data);
            setIsModalTestScraper(true);
        } catch (err) {
            console.log(err);
        }
    };

    const [isMiniFeed, setIsMiniFeed] = useState(false);
    const [miniFeedLoading, setMiniFeedLoading] = useState(false);
    const [miniFeedUrl, setMiniFeedUrl] = useState<undefined | string>();
    const [slowScraper, setSlowScraper] = useState(false);

    const checkMiniFeed = async () => {
        setMiniFeedLoading(true);
        const dataFromForm = form.getFieldsValue();
        const product_page_xpath_field = form.getFieldValue("productPageXPath");
        if (parseProductPageWatch === "true" && !product_page_xpath_field) {
            openNotification("Product Page XPath field have to be filed");
            return;
        }
        const transformedData = [...xPathData].map((d) => {
            let tempArr: any = {};
            d.rules.forEach((r) => {
                if (r.ruleName === "split_string_reverse") {
                    // for replace is the same !!!!!!!!!!!!
                    tempArr["split_string_reverse"] = {
                        [r.parameters[0].paramName]: r.parameters[0].paramValue,
                        [r.parameters[1].paramName]: r.parameters[1].paramValue,
                    };
                }
                if (r.ruleName === "replace") {
                    // for replace is the same !!!!!!!!!!!!
                    tempArr["replace"] = {
                        [r.parameters[0].paramName]: r.parameters[0].paramValue,
                        [r.parameters[1].paramName]: r.parameters[1].paramValue,
                    };
                }
                if (r.ruleName === "substring") {
                    // for replace is the same !!!!!!!!!!!!
                    tempArr["substring"] = {
                        [r.parameters[0].paramName]: r.parameters[0].paramValue,
                        [r.parameters[1].paramName]: r.parameters[1].paramValue,
                    };
                }

                if (r.ruleName === "ucfirst") {
                    tempArr["ucfirst"] = true;
                }
                if (r.ruleName === "trim") {
                    tempArr["trim"] = true;
                }

                if (r.ruleName === "remove" || r.ruleName === "prefix") {
                    r.parameters.forEach((p) => {
                        tempArr[p.paramName] = p.paramValue;
                    });
                }
            });

            const newObj = {
                name: d.name,
                value: d.value,
                xpath: d.xpath,
                ...tempArr,
            };

            let objToSend: any = {};

            for (let el in newObj) {
                if (el !== "rules") {
                    objToSend[el] = newObj[el as keyof typeof newObj];
                }
            }
            delete objToSend["ruleName"];
            delete objToSend["id"];
            return objToSend;
        });

        const dataF = {
            ...dataFromForm,
            minifeed: true,
            categoryIncrementOnFirstPage: dataFromForm.categoryIncrementOnFirstPage === "false" ? false : true,
            parseProductPage: dataFromForm.parseProductPage === "false" ? false : true,
        };

        const parameters = { ...dataF, productXPath: transformedData };

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/scraper/scrape-website`, parameters);
            setMiniFeedLoading(false);
            const url = process.env.REACT_APP_URL_API;
            setMiniFeedUrl(`${url?.slice(0, -3)}${`storage/scraper/${data.data}`}`);
        } catch (err) {
            console.log(err);
        }
    };

    const watchParseProductPage = Form.useWatch("parseProductPage", form);

    useEffect(() => {
        if (watchParseProductPage === "false") {
            form.resetFields(["productAttributeKeyXPath", "productAttributeValueXPath", "productAttributeXPath"]);
        }
    }, [watchParseProductPage, form]);

    // watch form

    return (
        <Row justify="center">
            <Col span={20} style={{ backgroundColor: "white", minHeight: "87vh", marginTop: "2rem" }}>
                <Row justify="center">
                    <Col span={22}>
                        <Divider>Universal Scraper</Divider>
                    </Col>
                    <Col span={24} style={{ display: "flex", justifyContent: "center", position: "relative" }}>
                        <Form form={searchForm}>
                            <Form.Item name="website" style={{ width: "300px" }}>
                                <Select
                                    allowClear
                                    onClear={onWebsiteClear}
                                    showSearch
                                    onChange={handleSelectWebsite}
                                    style={{ width: "250px", alignSelf: "center" }}
                                    filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                    options={scraperList.map((s) => ({ value: s.id, label: s.website_name }))}
                                    // value={selectedWebsite}

                                    placeholder="Select Website"
                                />
                            </Form.Item>
                        </Form>
                        <div style={{ position: "absolute", right: "2rem" }}>
                            <CreateScraper addNewScraper={addNewScraper} onShopChange={onShopChange} selectedShop={selectedShop} shops={shops} loadingNewScraper={loadingNewScraper} />
                        </div>
                    </Col>
                </Row>
                <ModalUrl isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} testUrlResp={testUrlResp.map((item: any, index) => ({ ...item, id: index }))} />
                {
                    /*selectedWebsite ||*/ param.id ? (
                        <div>
                            <Row
                                justify="center"
                                style={{
                                    border: "1px solid lightgray",
                                    borderRadius: "8px",
                                    margin: "2rem",
                                    padding: "1rem",
                                }}
                            >
                                <Col span={11} style={{ border: "1px solid lightgray", padding: "1rem", borderRadius: "8px" }}>
                                    <Modal title="Scraper Test Data" open={isModalTestScraper} onOk={handleModalOkTestScraper} onCancel={handleModalCancelTestScraper} width="400px" footer={false}>
                                        <List size="small" bordered dataSource={testScraperData} renderItem={(item) => <List.Item>{item}</List.Item>} />
                                    </Modal>
                                    <Card title="Test Scraper" style={{ backgroundColor: "#f8f8f8" }} size="small">
                                        <Card type="inner" style={{ textAlign: "center" }} size="small">
                                            <Input value={urlScraperTest} onChange={(e) => setUrlScraperTest(e.target.value)} placeholder="Url" style={{ width: "85%", marginTop: "0.3rem" }} />
                                            <Input value={xpathScraperTest} onChange={(e) => setXpathScraperTest(e.target.value)} placeholder="Xpath" style={{ width: "85%", marginTop: "0.3rem" }} />
                                            <Button
                                                style={{ width: "130px", marginTop: "0.3rem" }}
                                                onClick={() => testScraper()}
                                                disabled={urlScraperTest.trim() === "" || xpathScraperTest.trim() === "" ? true : false}
                                                danger
                                            >
                                                Test
                                            </Button>
                                        </Card>
                                    </Card>
                                    <Form layout="vertical" style={{ padding: "2rem" }} form={form} initialValues={{ slowScrapeSeconds: 1, slowScrape: false }}>
                                        <Form.Item label="Website Url" name="websiteUrl">
                                            <Input placeholder="Website Url" />
                                        </Form.Item>
                                        <Form.Item label="Categories XPath" name="categoriesXPath">
                                            <Input placeholder="Categories XPath" />
                                        </Form.Item>
                                        <Form.Item label="Pagination string" name="categoryPaginationString">
                                            <Input placeholder="?page=" />
                                        </Form.Item>
                                        <Form.Item label="Parse Product Page" name="parseProductPage" style={{ width: "150px" }}>
                                            <Select placeholder="Parse Product Page">
                                                <Select.Option key="true">Yes</Select.Option>
                                                <Select.Option key="false">No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        {parseProductPageWatch === "true" && ( //if it Parse Product Page selected to yes, this input will show
                                            <Form.Item
                                                label="Product Page XPath"
                                                name="productPageXPath"
                                                rules={[
                                                    {
                                                        required: parseProductPageWatch ? true : false,
                                                        message: `Please input Product Page XPath`,
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Product Page XPath" />
                                            </Form.Item>
                                        )}
                                        <Form.Item label="Category increment on first page" name="categoryIncrementOnFirstPage" style={{ width: "150px" }}>
                                            <Select placeholder="Category increment on first page">
                                                <Select.Option key="true">Yes</Select.Option>
                                                <Select.Option key="false">No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Product Element XPath" name="productElementXPath">
                                            <Input placeholder="Product Element XPath" />
                                        </Form.Item>
                                        <Form.Item name="slowScrape" valuePropName="checked">
                                            <Checkbox onChange={(e) => setSlowScraper(e.target.checked)}>Slow Scrape</Checkbox>
                                        </Form.Item>

                                        {slowScraper && (
                                            <Form.Item name="slowScrapeSeconds" /*initialValue={1} */>
                                                <InputNumber
                                                    min={1}
                                                    max={100}
                                                    style={{ width: "200px" }}
                                                    placeholder="Seconds"
                                                    // onChange={(e) => setMiniFeedInput(e)}
                                                    //value={miniFeedInput}
                                                    // defaultValue={1}
                                                    addonAfter={<ClockCircleOutlined />}
                                                />
                                            </Form.Item>
                                        )}
                                        <Form.Item>
                                            <Alert
                                                message="Mini Feed"
                                                description={
                                                    <Row justify="space-between">
                                                        <Col span={3} style={{ display: "flex", alignItems: "center", gap: "1rem", height: "40px" }}>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    setMiniFeedUrl("");
                                                                    setIsMiniFeed(e.target.checked);
                                                                }}
                                                            />

                                                            {isMiniFeed && (
                                                                <Button type="primary" size="small" onClick={checkMiniFeed} loading={miniFeedLoading}>
                                                                    Run
                                                                </Button>
                                                            )}
                                                        </Col>

                                                        <Button size="small" onClick={() => window.open(miniFeedUrl, "_blank", "noreferrer")} disabled={!miniFeedUrl}>
                                                            Open Mini Feed
                                                        </Button>
                                                    </Row>
                                                }
                                                type="info"
                                            />
                                            {watchParseProductPage === "true" && (
                                                <Alert
                                                    style={{ marginTop: "2rem" }}
                                                    type="info"
                                                    description={
                                                        <div>
                                                            <Form.Item
                                                                label="Attribute Key XPath"
                                                                name="productAttributeKeyXPath"
                                                                //rules={[{ required: watchAttributeValueXPath2 || watchAttributeXPath3, message: "" }]}
                                                                //validateStatus=""
                                                            >
                                                                <Input placeholder="Attribute Key XPath" />
                                                            </Form.Item>

                                                            <Form.Item
                                                                label="Attribute Value XPath"
                                                                name="productAttributeValueXPath"
                                                                //rules={[{ required: watchAttributeKeyXPath1 || watchAttributeXPath3, message: "" }]}
                                                                //validateStatus=""
                                                            >
                                                                <Input placeholder="Attribute Value XPath" />
                                                            </Form.Item>

                                                            <Form.Item
                                                                label="Attribute XPath"
                                                                name="productAttributeXPath"
                                                                //rules={[{ required: watchAttributeKeyXPath1 || watchAttributeValueXPath2, message: "" }]}
                                                                //validateStatus=""
                                                            >
                                                                <Input placeholder="Attribute XPath" />
                                                            </Form.Item>
                                                        </div>
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                        {/*not for showing on page */}
                                        <Form.Item label="" name="categoryIncrementStart" style={{ display: "none" }}>
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                        <Form.Item label="" name="categoryPaginationIncrement">
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                        <Form.Item label="" name="fileName" style={{ display: "none" }}>
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                        {/**    addWebsiteUrlToCategories  ,   debug  ,   productPageXPath  ,   responseMethod  */}
                                        <Form.Item label="" name="addWebsiteUrlToCategories" style={{ display: "none" }}>
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                        <Form.Item label="" name="debug" style={{ display: "none" }}>
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                        <Form.Item label="" name="productPageXPath" style={{ display: "none" }}>
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                        <Form.Item label="" name="responseMethod" style={{ display: "none" }}>
                                            <Input style={{ display: "none" }} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={1} />
                                <Col span={11}>
                                    <Row justify="center" style={{ border: "1px solid lightgray", borderRadius: "8px", padding: "1rem" }}>
                                        <Col span={16} style={{ marginBottom: "2rem" }}>
                                            <Card title="Test Url" style={{ backgroundColor: "#f8f8f8" }} size="small">
                                                <Card type="inner" style={{ textAlign: "center" }} size="small">
                                                    <Input onChange={(e) => setTestUrl(e.target.value)} value={testUrl} placeholder="Url" />
                                                    <Button onClick={submitTestUrl} disabled={testUrl.trim().length === 0 ? true : false} style={{ width: "130px", marginTop: "0.3rem" }} danger>
                                                        Test
                                                    </Button>
                                                </Card>
                                            </Card>
                                        </Col>
                                        <Col span={20}>
                                            <Row justify="center">
                                                <Divider />
                                                <Typography.Title level={5}>Elements</Typography.Title>
                                            </Row>
                                            {xPathData?.map((xPath) => {
                                                return (
                                                    <Col
                                                        key={xPath.id}
                                                        span={24}
                                                        style={{
                                                            padding: "2rem 1rem",
                                                            border: "1px solid lightgray",
                                                            borderRadius: "8px",
                                                            marginBottom: "1rem",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <Tooltip title="Delete Element">
                                                            <Button
                                                                onClick={() => deleteElement(xPath.id)}
                                                                danger
                                                                shape="circle"
                                                                size="small"
                                                                icon={<DeleteOutlined />}
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    position: "absolute",
                                                                    right: "5px",
                                                                    top: "5px",
                                                                }}
                                                            />
                                                        </Tooltip>

                                                        <Col style={{ display: "flex", alignItems: "center" }}>
                                                            <span style={{ width: "50px" }}>Name:</span>
                                                            <Input
                                                                onChange={(e) => handleInputChange(e.target.value, xPath.id, "name")}
                                                                defaultValue={xPath.name}
                                                                style={{ margin: "0.3rem 0" }}
                                                                placeholder="Name"
                                                            />
                                                        </Col>
                                                        <Col style={{ display: "flex", alignItems: "center" }}>
                                                            <span style={{ width: "50px" }}>XPath:</span>
                                                            <Input
                                                                onChange={(e) => handleInputChange(e.target.value, xPath.id, "xpath")}
                                                                defaultValue={xPath.xpath}
                                                                style={{ margin: "0.3rem 0" }}
                                                                placeholder="XPath"
                                                            />
                                                        </Col>
                                                        <Col style={{ display: "flex", alignItems: "center" }}>
                                                            <span style={{ width: "50px" }}>Value:</span>
                                                            <Input
                                                                onChange={(e) => handleInputChange(e.target.value, xPath.id, "value")}
                                                                defaultValue={xPath.value}
                                                                style={{ margin: "0.3rem 0" }}
                                                                placeholder="Value"
                                                            />
                                                        </Col>

                                                        {xPath.rules.length > 0 && (
                                                            <Row justify="center">
                                                                <Col
                                                                    span={12}
                                                                    style={{
                                                                        padding: "0.5rem",
                                                                        border: "1px solid lightgray",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: "whitesmoke",
                                                                        marginTop: "0.5rem",
                                                                    }}
                                                                >
                                                                    <Typography.Paragraph style={{ textAlign: "center" }} strong>
                                                                        Rules
                                                                    </Typography.Paragraph>
                                                                    {xPath.rules.length > 0 &&
                                                                        xPath.rules.map((rule, i) => {
                                                                            return (
                                                                                <div key={rule.r_id}>
                                                                                    <div
                                                                                        style={{
                                                                                            border: "1px solid lightgray",
                                                                                            backgroundColor: "white",
                                                                                            padding: "0.3rem",
                                                                                            borderRadius: "5px",
                                                                                            marginBottom: "0.4rem",
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        Rule
                                                                                        <Select
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                marginBottom: "10px",
                                                                                            }}
                                                                                            options={cleanValue.map((v: any) => ({
                                                                                                label: v.name,
                                                                                                value: v.name,
                                                                                            }))}
                                                                                            defaultValue={rule.ruleName ? rule.ruleName : null}
                                                                                            onChange={(e) => handleSelectRule(xPath.id, rule.r_id, e)}
                                                                                            placeholder="Select Rule"
                                                                                        />
                                                                                        {(rule.ruleName === "prefix" ||
                                                                                            rule.ruleName === "remove" ||
                                                                                            rule.ruleName === "split_string_reverse" ||
                                                                                            rule.ruleName === "replace" ||
                                                                                            rule.ruleName === "substring") && (
                                                                                            <div>
                                                                                                {rule?.parameters &&
                                                                                                    rule?.parameters?.map((p) => (
                                                                                                        <Input
                                                                                                            key={p.p_id}
                                                                                                            defaultValue={p.paramValue}
                                                                                                            addonBefore={<span>{p.paramName}</span>}
                                                                                                            style={{
                                                                                                                margin: "0.3rem 0",
                                                                                                                width: "100%",
                                                                                                            }}
                                                                                                            onBlur={(e) => hadleRuleInput(e.target.value, xPath.id, rule.r_id, p.p_id)}
                                                                                                        />
                                                                                                    ))}
                                                                                            </div>
                                                                                        )}
                                                                                        <Tooltip title="Delete Rule">
                                                                                            <Button
                                                                                                onClick={() => deleteRule(xPath.id, rule.r_id)}
                                                                                                shape="circle"
                                                                                                size="small"
                                                                                                icon={<DeleteOutlined />}
                                                                                                style={{
                                                                                                    marginLeft: "10px",
                                                                                                }}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <Row justify="center">
                                                            <Button onClick={() => addRule(xPath.id)} size="small" icon={<PlusOutlined />} style={{ marginTop: "15px" }} type="dashed" shape="round">
                                                                Add Rule
                                                            </Button>
                                                        </Row>
                                                    </Col>
                                                );
                                            })}
                                            {
                                                /*selectedWebsite ||*/ param.id && (
                                                    <Button onClick={() => addElement()} style={{ marginBottom: "2rem" }}>
                                                        <PlusOutlined /> Add Element
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider />
                                <Col span={6} style={{ margin: "2rem 0" }}>
                                    <Button block type="primary" onClick={checkFieldsAndSave} loading={loadingSave}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
                            <Empty
                                description={
                                    <span>
                                        No data,
                                        <br /> <span style={{ color: "darkblue" }}>Select Website</span>
                                    </span>
                                }
                            />
                        </div>
                    )
                }
            </Col>
        </Row>
    );
};

export default UniversalScraper;
