import { Button } from "antd";
import React from "react";
import ImageUploading from "react-images-uploading";
import { PictureOutlined } from "@ant-design/icons";
//import { editShop } from "../../../actions/shopAction/shopAction";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { editShop, getShop } from "../../../shared/Api/shop";

const LogoImage: React.FC<any> = ({ id, images, setImages, setShop, form }) => {
    const maxNumber = 69;

    const onChange = (imageList: any) => {
        setImages(imageList);
    };

    const saveLogo = () => {
        if (images.length > 0) {
            const type = images[0].file.type.split("/");
            if (type[1] === "jpeg" || type[1] === "jpg" || type[1] === "png") {
                const formData = new FormData();
                formData.append("id", id);
                formData.append("image", images[0].file);
                // dispatch(
                //     editShop(
                //         formData,
                //         true,
                //         () => {},
                //         () => {},
                //         true
                //     )
                // );
                editShop(
                    formData,
                    true,
                    () => {},
                    () => {},
                    true
                ).then(() => {
                    if (id) {
                        getShop(String(id)).then((resp) => {
                            setShop(resp);
                            form.setFieldsValue(resp.data);
                        });
                    }
                });

                setImages([]);
            } else {
                openNotification(`Allowed image extensions JPG, JPEG, and PNG`);
            }
        }
    };

    return (
        <div style={{ marginLeft: "1rem" }}>
            <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    // write your building UI
                    <div
                        /*className="upload__image-wrapper"*/ style={{
                            marginBottom: "0.4rem",
                            //marginLeft: "40%",

                            display: "flex",
                            justifyContent: "center",
                            //padding: "1rem",
                        }}
                    >
                        {imageList.map((image, index) => (
                            <div key={index} /*className="image-item"*/ style={{}}>
                                <div
                                    /*className="image-item__btn-wrapper"*/ style={
                                        {
                                            // margin: "15px 0",
                                            // width: "300px",
                                        }
                                    }
                                >
                                    <Button onClick={() => onImageUpdate(index)}>Update</Button>
                                    <Button danger onClick={() => onImageRemove(index)} style={{ marginTop: "5px", marginLeft: "3px" }}>
                                        Remove
                                    </Button>
                                </div>
                                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                                    <img src={image["data_url"]} alt="" width="100" />
                                </div>
                            </div>
                        ))}
                        {images.length === 0 && (
                            <Button type="primary" size="small" style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} {...dragProps}>
                                Change logo <PictureOutlined style={{ position: "relative", bottom: "3px" }} />
                            </Button>
                        )}
                        &nbsp;
                    </div>
                )}
            </ImageUploading>
            {images.length > 0 && (
                <Button type="primary" style={{ position: "relative", left: "1.1rem", bottom: "0.2rem" }} onClick={saveLogo}>
                    Save image
                    <PictureOutlined style={{ position: "relative", bottom: "3px" }} />
                </Button>
            )}
        </div>
    );
};
export default LogoImage;
