import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useRef, useState } from "react";
import { ProductType, UseColumnsParams } from "../types/types";
import { Badge, Button, Checkbox, Form, Image, Popover, Row, Select, TreeSelect } from "antd";
import ColumnName from "../components/ColumnName";
import { CheckOutlined, LinkOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ColumnMpn from "../components/ColumnMpn";
import { TreeCategoryType } from "../../prices/types/AllPricesTypes";
import numberWithCommas from "../../../shared/utils/numberUtils";
import EditableNamePopoverContent from "../components/EditableNamePopoverContent";
import PopupImages from "../components/PopupImages";
import PopupPrices from "../components/PopupPrices";
import { CountryType } from "../../../shared/Context/type";

const useColumns = ({ products, setProducts, currentCountryName, currentCountryId, count, search, brandList, categories }: UseColumnsParams) => {
    const [checkedIndex, setCheckIndex] = useState<number | undefined>();
    const [levelCategories, setLevelCategories] = useState<TreeCategoryType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [popoverVisible, setPopoverVisible] = useState(false);

    const [form] = Form.useForm();
    const inputRef = useRef([]);
    const inputRefMpn = useRef([]);
    const checkItem = (id: number) => {
        setProducts((curr) => curr.map((d) => (d.id === id ? { ...d, isChecked: !d.isChecked /*isMatching: false*/ } : d)));
    };

    const checkItemFromTo = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        if (checkedIndex === undefined) {
            // here set to 0 for cheching first row
            setCheckIndex(index);
        } else {
            setProducts((curr) => curr.map((d, i) => ((i >= checkedIndex && i <= index) || (i <= checkedIndex && i >= index) ? { ...d, isChecked: !d.isChecked } : d)));
            setCheckIndex(undefined);
        }
    };

    const handleCheckedPrices = (e: CheckboxChangeEvent) => {
        setProducts((curr) => curr.map((d) => ({ ...d, isChecked: e.target.checked })));
    };

    useEffect(() => {
        const newData: any = categories
            .map((level3) => ({
                value: `3::${level3.id}::${level3[`name_${currentCountryName}`]}`,
                title: (
                    <span>
                        {`(L3) ${level3.id}: ${level3[`name_${currentCountryName}`]}`}
                        <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>{` (${level3.l2_name})`}</span>
                    </span>
                ),
                children: level3.children
                    .map((level4) => ({
                        value: `4::${level4.id}::${level4[`name_${currentCountryName}`]}`,
                        title: <span>{`(L4) ${level4.id}: ${level4[`name_${currentCountryName}`]}`}</span>,
                    }))
                    .sort((a: { value: string }, b: { value: string }) => {
                        const firstItem = a.value.split("::")[2];
                        const secondItem = b.value.split("::")[2];
                        return firstItem.localeCompare(secondItem);
                    }),
            }))
            .sort((a: { value: string }, b: { value: string }) => {
                const firstItem = a.value.split("::")[2];
                const secondItem = b.value.split("::")[2];
                return firstItem.localeCompare(secondItem);
            });
        setLevelCategories(newData);
    }, [currentCountryName, categories]);

    const handleVisibleChange = (visible: boolean) => {
        setPopoverVisible(visible);
    };

    const onCategoryChange = (e: string) => {
        let prevCat: TreeCategoryType | undefined;

        levelCategories.forEach((l3) => {
            if (l3.value === e) {
                prevCat = l3;
                return;
            }
            l3.children.forEach((l4) => {
                if (l4.value === e) {
                    prevCat = l3;
                    return;
                }
            });
        });

        if (e) {
            const wordToArr = e.split("::");
            setProducts((curr) =>
                curr.map((p) =>
                    p.isChecked
                        ? {
                              ...p,

                              ...(wordToArr[0] === "3" && {
                                  category_id: Number(wordToArr[1]),
                                  [`category_4_${currentCountryName}`]: "",
                              }),
                              ...(wordToArr[0] === "4" && {
                                  [`category_4_${currentCountryName}`]: `${Number(wordToArr[1])}::${wordToArr[2]}`,
                              }),
                              category_name: wordToArr[2],
                              isChanged: true,
                          }
                        : p
                )
            );

            setLevelCategories((curr) => {
                const newData = curr.filter((c) => c.value !== prevCat?.value);
                return prevCat ? [prevCat, ...newData] : curr;
            });

            setSelectedCategory(Number(wordToArr[1]));
        } else {
            setSelectedCategory(undefined);
        }
    };

    const onBrandChange = (e: string) => {
        const selectedBrand = brandList.find((b) => b.id.toString() === e);
        if (e && selectedBrand) {
            setProducts((curr) =>
                curr.map((p) =>
                    p.isChecked
                        ? {
                              ...p,
                              brand_id: selectedBrand.id,
                              brand_name: selectedBrand.brand_name,
                              brand: `${selectedBrand.id}::${selectedBrand.brand_name}`,
                          }
                        : p
                )
            );
        }
    };

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const countryLink = localStorageCountries.find((c: { currency: string; name: string }) => c.name === currentCountryName)?.link;

    const columns: ColumnsType<ProductType> = [
        {
            title: <Checkbox onChange={handleCheckedPrices} checked={products.find((product) => !product.isChecked) || products.length === 0 ? false : true} />,
            dataIndex: "id",
            key: "id",
            align: "center" as "center",
            render: (text, record, index) => (
                <div
                    onClick={() => checkItem(text)}
                    onContextMenu={(e) => checkItemFromTo(e, index)}
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                    }}
                >
                    {
                        <CheckOutlined
                            style={{
                                opacity: record.isChecked || index === checkedIndex ? "1" : "0",
                                ...(index === checkedIndex && {
                                    backgroundColor: index === checkedIndex ? "lightgray" : "",
                                    padding: index === checkedIndex ? "0.1rem" : "",
                                    borderRadius: "3px",
                                }),
                            }}
                        />
                    }
                </div>
            ),
            width: "50px",
        },
        {
            title: "Product Id",
            children: [
                {
                    title: <Badge count={products.filter((price: ProductType) => price.isChecked === true).length} overflowCount={100} style={{ backgroundColor: "#1d9696" }} />,
                    dataIndex: "product_id",
                    align: "center" as "center",
                    width: "150px",
                    render: (_, record) => (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div
                                style={{ backgroundColor: record.isChanged === true ? "lightgreen" : "" }}
                                onClick={() => window.open(`/product/${record.new_prodcut_id ? record.newProductId : record.product_id}/prices`, "_blank")}
                                className="text-link"
                            >
                                {record.new_prodcut_id ? record.newProductId : record.product_id}
                            </div>
                            {record.condition > 1 && (
                                <div
                                    style={{
                                        fontSize: "18px",
                                        width: "8px",
                                        height: "8px",
                                        borderRadius: "4px",
                                        backgroundColor: "#1f4d7e",
                                    }}
                                />
                            )}
                        </div>
                    ),
                },
            ],
        },
        {
            title: "Image",
            children: [
                {
                    dataIndex: "image",
                    align: "center" as "center",
                    width: "100px",
                    // className: "all-products-table-image-cell",
                    render: (text, record, index) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginLeft: "1rem",
                            }}
                        >
                            <Popover
                                content={<Image src={text} alt="product" preview={false} />}
                                style={{ display: products.find((p) => p.isImage) ? "none" : "" }}
                                overlayInnerStyle={{ display: products.find((p) => p.isImage) ? "none" : "" }}
                                overlayStyle={{ display: products.find((p) => p.isImage) ? "none" : "" }}
                                trigger="hover"
                                placement="rightTop"
                            >
                                <div>
                                    <Image src={record.new_prodcut_id ? record.newImage : record.image} alt="product" width={39} loading="lazy" preview={false} />
                                </div>
                            </Popover>
                            <PopupImages setProducts={setProducts} record={record} />
                        </div>
                    ),
                },
            ],
        },
        {
            title: "Brand",
            children: [
                {
                    title: (
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Brand"
                            tokenSeparators={[","]}
                            onSelect={onBrandChange}
                            style={{ width: "100%" }}
                            filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                            options={brandList.map((b) => ({ value: b.id.toString(), label: b.brand_name }))}
                        />
                    ),
                    dataIndex: "brand_name",
                    key: "brand_name",
                    align: "center" as "center",
                    width: "200px",
                    render: (_, record) => (
                        <strong
                        // style={{
                        //     color: record.isNewProduct ? "red" : "",
                        // }}
                        >
                            {record.new_prodcut_id ? record.newBrandName : record.brand_name}
                        </strong>
                    ),
                },
            ],
        },
        {
            title: (
                <Popover
                    content={
                        <EditableNamePopoverContent
                            popoverVisible={popoverVisible}
                            setPopoverVisible={setPopoverVisible}
                            selectedCategory={selectedCategory}
                            setProducts={setProducts}
                            currentCountryId={currentCountryId}
                            currentCountryName={currentCountryName}
                        />
                    }
                    style={{ paddingTop: "20px" }}
                    onOpenChange={handleVisibleChange}
                    open={popoverVisible}
                    placement="bottom"
                    title={
                        <Row justify="end">
                            <Button size="small" onClick={() => setPopoverVisible(false)}>
                                X
                            </Button>
                        </Row>
                    }
                    trigger="click"
                >
                    <span style={{ cursor: "pointer" }}>Editable name</span>
                </Popover>
            ),
            children: [
                {
                    dataIndex: "name",
                    //width: "45%",
                    // className: "all-products-editable-name",
                    render: (text, record, index) => <ColumnName index={index} form={form} record={record} products={products} setProducts={setProducts} inputRef={inputRef} search={search} />,
                },
            ],
        },
        {
            title: `Mpn`,
            //width: windowSize.width < 2000 ? '10%':'',
            children: [
                {
                    dataIndex: "mpn",
                    width: "200px",
                    //render: (record: string) => <span style={{ marginLeft: "10px" }}>{record}</span>,
                    render: (text, record, index) => (
                        <div
                            style={{
                                cursor: "pointer",
                                height: "100%",
                                //padding: "10px",
                            }} /*onDoubleClick={() => ignorePrice(text)}*/ /*onClick={()=>checkItem(text)}*/
                        >
                            <ColumnMpn index={index} form={form} record={record} products={products} setProducts={setProducts} inputRefMpn={inputRefMpn} search={search} />
                        </div>
                    ),
                },
            ],
        },
        {
            title: "Category",
            children: [
                {
                    title: (
                        <TreeSelect
                            showSearch
                            style={{ width: "100%" }}
                            dropdownStyle={{ overflow: "auto" }}
                            placeholder="Select Category"
                            allowClear
                            onChange={onCategoryChange}
                            treeData={levelCategories}
                            treeDefaultExpandAll={false}
                        />
                    ),
                    dataIndex: "category_name",
                    align: "center" as "center",
                    width: "350px",
                    render: (text, record) => <strong>{record.new_prodcut_id ? record.newCategoryName : record.category_name}</strong>,
                },
            ],
        },
        {
            title: "Prices",
            children: [
                {
                    dataIndex: "product_id",
                    width: "70px",
                    align: "center" as "center",
                    render: (_, record) => <PopupPrices record={record} products={products} setProducts={setProducts} currentCountryId={currentCountryId} currentCountryName={currentCountryName} />,
                },
            ],
        },
        {
            title: "Count",
            children: [
                {
                    title: <span style={{ color: "darkblue" }}>{count !== undefined ? numberWithCommas(String(count).replace(".", ",")) : "-"}</span>,
                    dataIndex: "id",
                    align: "center" as "center",
                    width: "70px",
                    render: (_: string, record: (typeof products)[0]) => (
                        <div
                            onClick={() => {
                                window.open(
                                    `${countryLink}/${
                                        record.new_prodcut_id ? record[`newLink_${currentCountryName}` as keyof typeof record] : record[`link_${currentCountryName}` as keyof typeof record]
                                    }`,
                                    "",
                                    "width=900, height=1080, top=0, left=960"
                                );
                            }}
                            style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                                padding: "10px",
                            }}
                        >
                            <LinkOutlined style={{ fontSize: "20px" }} />
                        </div>
                    ),
                },
            ],
        },
    ];
    return columns;
};

export default useColumns;
