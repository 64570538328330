import { Col, Divider, Row, Layout, Table, Button } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import DatePickerFromToReports from "../../reports/components/DatePickerFromToReports";
import { DatePickerNewProducts } from "../../reports/components/NewShopProducts";
import TableDataItem from "../components/UserAssignmentsSchedule/TableDataItem";
import "../style/UserAssignmentsSchedule.css";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../../shared/Api/Users";

type Shops = {
    id: number;
    name: string;
};

type UserAssignmentType = {
    created_at: string;
    date: string;
    id: number;
    keywords: string;
    shop_ids: Shops[];
    statuses: string;
    updated_at: string;
    user_id: number;
};

type AssignmentType = {
    dayMounthYear: string;
    //isSunday:boolean
    data: UserAssignmentType[];
};

type UserType = {
    id: number;
    first_name: string;
    last_name: string;
    user_id?: number;
};

const UserAssignmentsSchedule = () => {
    const [users, setUsers] = useState<any>();
    const [date, setDate] = useState<DatePickerNewProducts>({ startDate: new Date(), endDate: new Date() });
    const [allAssignments, setAllAssignments] = useState<AssignmentType[]>([]);

    useEffect(() => {
        getAllUsers(true, "data-entry").then((resp) => {
            setUsers(resp);
        });
    }, []);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }

        const getUserShops = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shop/get-user-shops?&from=${formTo.startDate}&to=${formTo.endDate}`);

                let daysOfYear = [];
                if (date.endDate) {
                    for (var d = new Date(`${date.startDate.getFullYear()}, ${date.startDate.getMonth() + 1}, ${date.startDate.getDate()}`); d <= date.endDate; d.setDate(d.getDate() + 1)) {
                        //if (new Date(d).getDay() !==6 /*&& new Date(d).getDay() !==0*/ ) {
                        daysOfYear.push(moment(new Date(d)).format("DD MMM YYYY"));
                        //}
                    }
                    const newData = daysOfYear.map((item) => {
                        const findData = data.data.filter((d: { date: string }) => moment(new Date(d.date)).format("DD MMM YYYY") === item);

                        return {
                            dayMounthYear: moment(new Date(item)).format("DD. MMM YYYY."),
                            //isSunday: new Date(item).getDay() === 0 ? true:false,

                            data: findData.map((item: UserAssignmentType) => {
                                return {
                                    ...item,
                                    shop_ids: item.shop_ids ? item.shop_ids.map((shop: { name: string }) => shop.name) : [],
                                    statuses: item.statuses ? JSON.parse(item.statuses) : [],
                                    keywords: item.keywords ? JSON.parse(item.keywords) : [],
                                };
                            }),
                        };
                    });
                    setAllAssignments(newData);
                }
            } catch (err) {
                console.log(err);
            }
        };

        date.startDate && date.endDate && getUserShops();
    }, [date]);

    const columns =
        users?.users.data && users?.users.data
            ? [
                  {
                      title: "",
                      dataIndex: "dayMounthYear",
                      align: "center" as "center",
                      fixed: "left",
                      width: "1%",
                      render: (text: string, record: AssignmentType) => (
                          <div style={{ width: "100px" }}>
                              {" "}
                              <p
                                  style={{
                                      color: new Date(record.dayMounthYear).getDay() === 0 || new Date(record.dayMounthYear).getDay() === 6 ? "#89969b" : "#264e70",
                                      fontWeight: "bold",
                                      marginTop: "0.6rem",
                                      marginLeft: "0.4rem",
                                  }}
                              >
                                  {record.dayMounthYear}
                              </p>
                          </div>
                      ),
                  },
                  ...users?.users.data.map((user: UserType, index: number) => {
                      return {
                          title: `${user.first_name} ${user.last_name}`,
                          dataIndex: "isSunday",
                          align: "center" as "center",
                          width: "6%",
                          render: (text: boolean, record: { data: UserType[] }, index: number) => {
                              const findData = record.data.find((item: UserType) => item.user_id === user.id); //UserType is not corect here, to check it later
                              return text ? (
                                  ""
                              ) : findData ? (
                                  <div style={{ height: text ? "0" : "" }}>
                                      {["shop_ids", "statuses", "keywords"].map((item, index) => (
                                          <TableDataItem key={index} data={findData[item as keyof UserType]} title={item} isStatus={item === "statuses" ? true : false} />
                                      ))}
                                  </div>
                              ) : (
                                  <div> - </div>
                              );
                          },
                      };
                  }),
              ]
            : [];

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - User assignments schedule" />
            <Row justify="center" style={{ margin: "0 1rem" }}>
                <Col span={24} className="user-assignments-header">
                    <Divider>User's assignments schedule</Divider>
                    <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center" }}>
                        <DatePickerFromToReports date={date} setDate={setDate} isDate={true} />
                        <Link to="/user-assignments">
                            <Button style={{ position: "absolute", right: "40px", marginTop: "5px", color: "gray" }} size="small">
                                Create Assignments
                            </Button>
                        </Link>
                    </div>
                </Col>
                <Col span={24}>
                    <Layout style={{ backgroundColor: "white", paddingBottom: "4rem", minHeight: "75vh" }}>
                        {/* <Header className='product-img-header user-list-assignments-header' style={{ margin:'0px', backgroundColor:'#5a5a5a' }}> 
          <span className='user-list-assignments-title' >
            {`Users Assignments Schedule`} 
          </span> 
        </Header> */}
                        <Table
                            columns={columns}
                            dataSource={allAssignments}
                            loading={allAssignments.length === 0}
                            rowKey={(record) => record.dayMounthYear}
                            bordered
                            className={`user-assignments-schedule-table`}
                            rowClassName={(record) =>
                                `${new Date(record.dayMounthYear).getDay() === 0 || new Date(record.dayMounthYear).getDay() === 6 ? "is-sunday-row" : ""} ${
                                    new Date(record.dayMounthYear).getDay() === 6 ? "isSaturday" : ""
                                } ${new Date(record.dayMounthYear).getDay() === 0 ? "isSunday" : ""}`
                            }
                            //pagination={ allAssignments.length<10 ? undefined:{pageSize:10}}
                            pagination={{ hideOnSinglePage: true, pageSize: 50 }}
                        />
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserAssignmentsSchedule;
