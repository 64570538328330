import { CountryType } from "../../../shared/Context/type";

type ProductsType = {
    brand_name: string;
    default_image_url: string;
    id: number;
    shop_name: string;
    name_rs?: string;
    name_si?: string;
    category_name_rs?: string;
    category_name_si?: string;
    category_name_hr?: string;
};

export const showNameByCaountry = (record: any, currentCountryName: string, countries?: CountryType[]) => {
    const countriesArr = countries ? countries.sort((a, b) => a.langorder - b.langorder) : [];
    if (record[`name_${currentCountryName}`]) {
        return record[`name_${currentCountryName}`];
    } else {
        const index = countriesArr.findIndex((lng) => record[`name_${lng.name}`]);
        return record[`name_${countriesArr[index]?.name}`];
    }
};

export const showNameCategoryByCountry = (record: ProductsType, currentCountryName: string) => {
    if (currentCountryName === "rs") {
        return record.category_name_rs ? record.category_name_rs : record.category_name_si;
    }
    if (currentCountryName === "si") {
        return record.category_name_si ? record.category_name_si : record.category_name_rs;
    }
};
