import { CloseOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import React, { useState } from "react";
import { CateogoryL3L4 } from "../../../../shared/FormElements/CategorySelect";
import { AttributeType } from "../../types/AttributeType";
import { GroupType } from "../../pages/AttributeOverview";
import CreateAttribute from "./CreateAttribute";
import { TreeSelectCategoryType } from "../../../../shared/Api/category";

interface CreateAttributePopoverProp {
    selectedCategory: {
        id: number;
        name: string;
    } | null;

    categories: CateogoryL3L4[];
    setCategories: React.Dispatch<React.SetStateAction<CateogoryL3L4[]>>;
    currentCountryName: string;

    setAttributeList: React.Dispatch<React.SetStateAction<AttributeType[]>>;
    groups: GroupType[];
    selectedGroup: number | undefined;
    setGroups: React.Dispatch<React.SetStateAction<GroupType[]>>;
    setAllAtributes: React.Dispatch<React.SetStateAction<{ value: string; label: string; group_id: number; type: string }[]>>;
    allCategories: TreeSelectCategoryType[];
}

const CreateAttributePopover = (props: CreateAttributePopoverProp) => {
    const { categories, setCategories, selectedCategory, currentCountryName, setAttributeList, groups, selectedGroup, setGroups, setAllAtributes, allCategories } = props;
    const [visible, setVisible] = useState(false);
    const hideCreatePopover = () => {
        setVisible(false);
    };
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };
    return (
        <Popover
            title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Create new attribute</span>
                    <CloseOutlined style={{ marginTop: "5px", cursor: "pointer" }} onClick={hideCreatePopover} />
                </div>
            }
            trigger="click"
            placement="bottomRight"
            open={visible}
            onOpenChange={handleVisibleChange}
            content={
                <CreateAttribute
                    selectedCategory={selectedCategory}
                    categories={categories}
                    setCategories={setCategories}
                    currentCountryName={currentCountryName}
                    setVisible={setVisible}
                    setAttributeList={setAttributeList}
                    groups={groups}
                    selectedGroup={selectedGroup}
                    setGroups={setGroups}
                    setAllAtributes={setAllAtributes}
                    allCategories={allCategories}
                />
            }
        >
            <Button>Create Attribute</Button>
        </Popover>
    );
};

export default CreateAttributePopover;
