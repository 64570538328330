import axios from "axios";
import showMessage from "../MessagesInfo/message";

const baseUrl = process.env.REACT_APP_URL_API;

export const getShop = async (id: string | undefined, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading && setLoading(true);
    const { data } = await axios.get(`${baseUrl}/shop/show?id=${id}`);
    setLoading && setLoading(false);
    return data;
};

export const editShop = async (shop: any, setImg: boolean, handleDrawerEditShopOk: () => void, setCounter: React.Dispatch<React.SetStateAction<number>>, isLogoEdit?: boolean) => {
    const { data } = await axios.post(`${baseUrl}/shop/edit`, shop);
    showMessage(data.message);
    handleDrawerEditShopOk();
    setCounter((curr) => curr + 1);
    return data.data;
};

export const createShop = async (shop: any) => {
    const { data } = await axios.post(`${baseUrl}/shop/create`, shop);
    showMessage(data.message);
    return data.data;
};

// get all shops

export type ShopType = {
    country_name: string;
    created_at: string;
    description: string | null;
    feed_url: string | null;
    homepage_url: string;
    id: number;
    last_generated_feed: number | null;
    mapping_type: string;
    name: string;
    phone: string | null;
    seller_delivery: null;
    seller_payment: null;
    shop_maps_exist: number;
    shop_rules_exist: number;
    updated_at: string;
    url_link: string;
};

export const getShops = async (sfActive: number, currentCountryId: number | null, setLoading?: React.Dispatch<React.SetStateAction<boolean>>): Promise<{ data: ShopType[] }> => {
    setLoading && setLoading(true);
    const { data } = await axios.get(`${baseUrl}/shop/list?active=${sfActive}${currentCountryId ? `&country_id=${currentCountryId}` : ""}`);
    setLoading && setLoading(false);
    return data;
    // } catch (err) {
    //     //errorMessage(err.response.data.message)
    //     console.log(err);
    //     setLoading && setLoading(false);
    // }
};
