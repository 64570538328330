import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import showMessage from "../../../../shared/MessagesInfo/message";
import "../../style/Names.css";
import { EditCateogyType } from "../EditCategory";
import Spinner from "../../../../images/spinner-products.gif";
import { CategoryType } from "../../pages/AllCategories";
import { ColumnTypes, editableCellcomponents, mappedColumns } from "../../../../shared/FormElements/EditableCell";

interface DataType {
    key: React.Key;
    id: number | undefined;
    name: string;
    value: string | undefined | null;
    parent_id: any;
    level_id: number;
    isLoading: boolean;
}

interface NamesProps {
    data: EditCateogyType | null;
    selectedCateogry: CategoryType | null;
    countries: string[];
}

const MetaKeywords: React.FC<NamesProps> = ({ data, selectedCateogry, countries }) => {
    const [category, setCategory] = useState<DataType[]>([]);
    useEffect(() => {
        let arr: DataType[] = [];

        for (let el in data?.translate) {
            if (countries.map((country) => `meta_keywords_${country}`).includes(el)) {
                arr.push({
                    name: el,
                    value: data?.translate[el],
                    id: data?.id,
                    key: uuidv4(),
                    parent_id: Number(selectedCateogry?.type[1]) === 1 ? 0 : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry?.type[1]) - 1}_id` as keyof CategoryType],
                    level_id: Number(selectedCateogry?.type[1]),
                    isLoading: false,
                });
            }
        }

        setCategory(arr);
    }, [data?.translate, data?.id, selectedCateogry, countries]);

    //const countriesArr =  ['rs', 'si','hr']
    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "Country",
            dataIndex: "name_rs",
            width: "160px",
            align: "center" as "center",
            render: (text, record, index) => `Meta Keywords ${countries[index]}`,
        },
        {
            title: <span style={{ marginLeft: "0.9rem" }}>Name</span>, //<div style={{ display:'flex', justifyContent:'center' }}> <span>Name</span></div>,
            dataIndex: "value",
            width: "320px",
            editable: true,
            render: (text: string, record: any, index: number) =>
                record.isLoading ? (
                    <div style={{ height: "22px" }}>
                        <img src={Spinner} style={{ position: "absolute", marginLeft: "18px", top: "14px" }} alt="Spinner" width={46} />
                    </div>
                ) : (
                    category.find((c) => c.name === `meta_keywords_${countries[index]}`)?.value
                ),
        },
    ];

    const handleSave = async (row: DataType) => {
        //saveChanges(row)
        setCategory((curr) =>
            curr.map((d) => {
                console.log(d);
                return d.key === row.key ? { ...d, isLoading: true } : d;
            })
        ); ////////////////////////////here
        const objToSend = {
            id: row.id,
            level_id: row.level_id,
            parent_id: row.parent_id,
            [`${row.name}`]: row.value,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, objToSend);
            showMessage(data.message);
            const newData = [...category];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setCategory(newData);
            setCategory((curr) => curr.map((d) => ({ ...d, isLoading: false })));
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Table
            components={editableCellcomponents}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={category}
            columns={mappedColumns(defaultColumns, handleSave, true) as ColumnTypes}
            style={{ marginTop: "4rem" }}
            pagination={false}
            loading={category.length === 0 ? true : false}
        />
    );
};

export default MetaKeywords;
