import { Col, Divider, Layout, Row, Table, Image, Button, Typography, Form, TreeSelect } from "antd";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import "../style/ProductNameMassEditing.css";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { ColumnsType } from "antd/es/table";
import { CheckSquareOutlined, LinkOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import showMessage from "../../../shared/MessagesInfo/message";
import TagSelect from "../components/ProductNameMassEditing/TagSelect";
import InputElement from "../components/ProductNameMassEditing/InputElement";
import { getAllCategories, TreeSelectCategoryType } from "../../../shared/Api/category";
import { useAuth } from "../../../shared/Context/AuthContext";

type ProductType = {
    default_image_url: string;
    id: number;
    name: string;
    link: string;
    isChecked: boolean;
};

const removeWord = (text: string, words: string[]) =>
    text
        ? text
              .split(" ")
              .filter((w) => !words.includes(w.toLowerCase().trim()))
              .join(" ")
        : "";
const disableSubmitBtn = (keyWord: { prefix: string; suffix: string }, removedWords: string[]) => {
    return keyWord.prefix.trim().length === 0 && keyWord.suffix.trim().length === 0 && removedWords.length === 0 ? true : false;
};

const ProductNameMassEditing = () => {
    const { currentCountry } = useAuth();
    const [products, setProducts] = useState<ProductType[]>([]);
    const [categories, setCategories] = useState<TreeSelectCategoryType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [allowedKeywords, setAllowedKeywords] = useState<string[]>([]);
    const [forbiddenKeywords, setForbiddenKeywords] = useState<string[]>([]);
    const [removedWords, setRemovedWords] = useState<string[]>([]);
    const [keyWord, setKeyword] = useState({ prefix: "", suffix: "" });
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const getData = useCallback(async () => {
        setLoading(true);
        const keywords_in = allowedKeywords.map((kw) => `&keywords_in[]=${kw.trim().toLowerCase()}`).join("");
        const keywords_out = forbiddenKeywords.map((kw) => `&keywords_out[]=${kw.trim().toLowerCase()}`).join("");
        const params = `?category_l3_id=${selectedCategory}&country_id=${currentCountry.id}${keywords_in ? `${keywords_in}` : ``}${keywords_out ? `${keywords_out}` : ``}`;
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/list-products${params}`); //&keywords_out[]=sr
            setProducts([]); //?????
            setProducts(data.data.map((p: ProductType) => ({ ...p, isChecked: false })));
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }, [allowedKeywords, forbiddenKeywords, selectedCategory, currentCountry.id]);

    useEffect(() => {
        selectedCategory && getData();
    }, [selectedCategory, /*allowedKeywords, forbiddenKeywords,*/ getData]);

    useEffect(() => {
        getAllCategories(currentCountry.id, currentCountry.name).then((resp) => {
            setCategories(resp);
        });
    }, [currentCountry]);

    const onCategoryChange = (value: number) => {
        ////////////////   choosen item on first place
        const findCategoryL3 = categories.find((category3) => category3.id === value);
        let oneCategory: typeof findCategoryL3;
        if (findCategoryL3) {
            oneCategory = findCategoryL3;
        } else {
            categories.forEach((category3) => {
                category3.children &&
                    category3.children.forEach((category4) => {
                        if (category4.id === value) {
                            oneCategory = category3;
                        }
                    });
            });
        }
        const filteredCategories = categories.filter((category) => category.id !== oneCategory?.id);
        oneCategory && setCategories([oneCategory, ...filteredCategories]); //set category on first place on arr
        setSelectedCategory(value ? value : null);
    };

    const handleCheckedPrices = (id: number) => {
        setProducts((current) => current.map((product) => (product.id === id ? { ...product, isChecked: !product.isChecked } : product)));
    };

    const columns: ColumnsType<ProductType> = [
        {
            title: "",
            dataIndex: "id",
            align: "center" as "center",
            width: "2%",
            render: (_, record) => (
                <div>
                    {record.isChecked ? (
                        <MinusSquareOutlined onClick={() => handleCheckedPrices(record.id)} style={{ fontSize: "23px", color: "red", padding: "30px 50px", cursor: "pointer" }} />
                    ) : (
                        <CheckSquareOutlined onClick={() => handleCheckedPrices(record.id)} style={{ fontSize: "23px", color: "green", padding: "30px 50px", cursor: "pointer" }} />
                    )}
                </div>
            ),
        },
        {
            title: "Image",
            dataIndex: "default_image_url",
            align: "center" as "center",
            width: "100px",
            render: (record) => <Image width={80} src={`https://img.ep-cdn.com/i/500/500/${record}.webp`} />,
        },
        {
            title: "Original Name",
            dataIndex: "name",
            width: "40%",
            render: (record) => <div style={{ marginLeft: "10px" }}>{record}</div>,
        },
        {
            title: "New Name",
            dataIndex: "name",
            render: (_, record) => <div style={{ marginLeft: "10px" }}>{!record.isChecked ? `${keyWord.prefix} ${removeWord(record.name, removedWords)} ${keyWord.suffix}` : record.name}</div>,
        },
        {
            title: "Link",
            dataIndex: "link",
            align: "center" as "center",
            width: "100px",
            render: (record) => (
                <div>
                    <span onClick={() => window.open(record, "", "width=900,height=1080")}>
                        <Button size="small" icon={<LinkOutlined className="product-name-mass-editing-link" style={{ color: "#007cb9", cursor: "pointer" }} />} />
                    </span>
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "name",
            align: "center" as "center",
            width: "140px",
            render: (_, record) => (
                <Link to={`/product/${record.id}/prices`} target="_blank">
                    <Button type="default" size="small">
                        Edit Product
                    </Button>
                </Link>
            ),
        },
    ];

    let delayTimer: any;

    const handleKeyword = (e: string, key: string) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setKeyword((curr) => ({ ...curr, [`${key}`]: e.trim() }));
        }, 1000); // Wi
    };

    const handleKeysContain = (e: string[], type: string) => {
        const words = e.map((w) => w.toLowerCase().trim());
        if (type === "in") {
            setAllowedKeywords(words);
        } else if (type === "out") {
            setForbiddenKeywords(words);
        } else {
            setRemovedWords(words);
        }
    };

    const saveNewProductNames = async () => {
        setLoadingSubmit(true);
        const obj = {
            country_id: currentCountry.id,
            ids: products.filter((p) => !p.isChecked).map((p) => p.id),
            ...(keyWord.prefix && { prefix: keyWord.prefix }),
            ...(keyWord.suffix && { suffix: keyWord.suffix }),
            //suffix: keyWord.suffix ? keyWord.suffix : null,
            keywords_removed: removedWords,
        };
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/item/update-multiple-product-name`, obj);
            showMessage(data.message);
            setProducts([]);
            getData();
            setLoadingSubmit(false);
            form.resetFields();
            setKeyword({ prefix: "", suffix: "" });
        } catch (err) {
            console.log(err);
        }
    };

    const handleClearShopList = () => {
        setProducts([]);
    };

    const [form] = Form.useForm();

    const treeData = categories.map((l3) => ({
        value: `${l3.id}`,
        title: (
            <span>
                {`${l3.id}: ${l3[`name_${currentCountry.name}` as keyof typeof l3]}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>({l3.l2_name})</span>{" "}
            </span>
        ),
        l2_name: l3.l2_name,
        children: l3.children.map((l4) => ({
            value: `${l4.id}`,
            title: `${l4.id}: ${l4[`name_${currentCountry.name}` as keyof typeof l4]}`,
        })),
    }));

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Product Name Mass Editing" />
            <Col span={23} className="catalog-list-box">
                <Divider>Product Name Mass Editing</Divider>
                <Row justify="center">
                    <Col span={17}>
                        <div className="product-img-info-bar mass-editing-filters">
                            <Row className="mass-editing-filters-box">
                                <Typography.Paragraph>Filters</Typography.Paragraph>
                                <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                                    <Col span={6} style={{ textAlign: "end", paddingRight: "5px" }}>
                                        <span>Category L3/L4</span>
                                    </Col>
                                    <Col span={18}>
                                        <TreeSelect
                                            showSearch
                                            style={{ width: "100%", textAlign: "start" }}
                                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                            placeholder="Select Category"
                                            allowClear
                                            onChange={onCategoryChange}
                                            treeData={treeData}
                                            treeDefaultExpandAll={false}
                                            onClear={handleClearShopList}
                                            filterTreeNode={(search: string, item: any) => item.value.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                                        />
                                    </Col>
                                </Row>
                                <TagSelect handleKeysContain={handleKeysContain} name="Contains" type="in" span={18} />
                                <TagSelect handleKeysContain={handleKeysContain} name="Don't Contain" type="out" span={18} />
                            </Row>
                            <Row className="mass-editing-filters-box">
                                <Typography.Paragraph>Keyword</Typography.Paragraph>
                                <Form
                                    form={form}
                                    //labelCol={{ span: 6 }}
                                    //wrapperCol={{ span: 16 }}
                                >
                                    <InputElement
                                        handleKeyword={handleKeyword}
                                        type="prefix"
                                        //keyWord={ keyWord }
                                    />
                                    <InputElement
                                        handleKeyword={handleKeyword}
                                        type="suffix"
                                        //keyWord={ keyWord }
                                    />
                                    <TagSelect
                                        handleKeysContain={handleKeysContain}
                                        name="remove"
                                        type="remove"
                                        //span={ 20 }
                                    />
                                </Form>
                            </Row>
                            <Row
                                style={{
                                    display: "flex",

                                    alignItems: "center",
                                }}
                            >
                                <div style={{ width: "60px" }}>
                                    <Button type="primary" onClick={saveNewProductNames} loading={loadingSubmit} disabled={disableSubmitBtn(keyWord, removedWords)}>
                                        Save
                                    </Button>
                                </div>
                            </Row>
                        </div>
                        <Row justify="center">
                            <Col span={24}>
                                <Layout style={{ margin: "0.6rem 1.4rem" }}>
                                    <Layout.Header className="mass-editing-header" />
                                    <Table
                                        bordered
                                        columns={columns}
                                        dataSource={products}
                                        rowKey={(record) => record.id}
                                        className="product-name-mass-editing"
                                        pagination={{ pageSize: 100, showSizeChanger: false }}
                                        loading={loading}
                                        rowClassName={(record) => ` ${record.isChecked ? "product-name-mass-editing-row" : ""}`}
                                    />
                                </Layout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ProductNameMassEditing;
