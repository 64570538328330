import { Button, Col, Divider, Layout, Row, Select, Spin, Table, DatePicker, Input, Badge } from "antd";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import "../style/CatalogList.css";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useAuth } from "../../../shared/Context/AuthContext";

type CatelogsType = {
    shop: string;
    valid_from: string;
    valid_to: string;
    title: string;
    url: { id: number; shop_id: number; url: string };
    id: number;
    catalogsHistory: {
        shop: { id: number; name: string };
        valid_from: string;
        valid_to: string;
        title: string;
        url: { id: number; shop_id: number; url: string };
        id: number;
    };
    historyLoading: boolean;
    status_ready: boolean;
};

const { RangePicker } = DatePicker;
const { Search } = Input;

const CatalogList = () => {
    const { currentCountry } = useAuth();
    const [catalogs, setCatelogs] = useState<CatelogsType[]>([]);
    const [shops, setShops] = useState<{ name: string; id: number }[]>([]);
    //---- for filters -----
    const [status, setStatus] = useState(null);
    const [sleectedDate, setSelectedDate] = useState<{ from: string; to: string } | null>(null);
    const [selectedShop, setSelectedShop] = useState<string | null>(null);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    // --------- for expand rows -------
    const [tempKeyArr, setTempKeyArr] = useState<number[]>([]);

    useEffect(() => {
        const parameters = [];
        status && parameters.push({ name: "status", value: status });
        title && parameters.push({ name: "title", value: title });
        selectedShop && parameters.push({ name: "shop_id", value: selectedShop });
        sleectedDate && parameters.push({ name: "valid_from", value: sleectedDate.from });
        sleectedDate && parameters.push({ name: "valid_to", value: sleectedDate.to });
        const params = parameters.map((p, i) => `${i === 0 ? "?" : "&"}${p.name}=${p.value}`).join("");

        const getAllCateloges = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/catalogues/catalogue/all${params}`);
                setTempKeyArr([]);
                setCatelogs(data.data.map((item: CatelogsType) => ({ ...item, catalogsHistory: [], historyLoading: false })));
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        getAllCateloges();
    }, [status, sleectedDate, title, selectedShop]);

    useEffect(() => {
        const getShops = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shop/list?active=0&country_id=${currentCountry.id}`);
                setShops(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getShops();
    }, [currentCountry.id]);

    const columns: ColumnsType<CatelogsType> = [
        {
            title: "Shop",
            dataIndex: "shop",
            sorter: (a, b) => a.shop.localeCompare(b.shop),
        },
        {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
                <div className="catalog-list-table-badge">
                    <Badge status={record.status_ready ? "success" : "error"} />
                    <span style={{ marginLeft: "5px" }}>{record.title}</span>
                </div>
            ),
        },
        {
            title: "From",
            dataIndex: "valid_from",
            render: (record) => `${moment(record).format("DD MMM YYYY")}`,
            align: "center" as "center",
        },
        {
            title: "To",
            dataIndex: "valid_to",
            render: (record) => `${moment(record).format("DD MMM YYYY")}`,
            align: "center" as "center",
        },
        {
            title: "Link",
            dataIndex: "catalogue_url",
            render: (record) => (
                <div style={{ cursor: "pointer" }} onClick={() => window.open(`https://www.eponuda.com/katalog-akcije/${record}`, "_blank", "noreferrer")}>
                    {record}
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "id",
            render: (text, record) =>
                new Date(moment().format("YYYY-MM-DD")) <= new Date(record.valid_to) ? (
                    <Link to={`/change-catalog/${text}/edit`} target="_blank">
                        <Button type="default" size="small">
                            Edit
                        </Button>
                    </Link>
                ) : (
                    ""
                ),
            align: "center" as "center",
        },
        {
            title: "",
            dataIndex: "id",
            render: (text, record) => (
                <Link to={`/change-catalog/${text}/new`} target="_blank">
                    <Button type="default" size="small">
                        Add New
                    </Button>
                </Link>
            ),
            align: "center" as "center",
        },
    ];

    const getCatalogHistory = async (id: number) => {
        setCatelogs((curr) => curr.map((c) => (c.id === id ? { ...c, historyLoading: true } : c)));
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/catalogues/catalogue/history/${id}`);
            //setCatelogsHistory(data.data)
            setCatelogs((curr) => curr.map((catalog) => (catalog.id === id ? { ...catalog, catalogsHistory: data.data } : catalog)));
            setCatelogs((curr) => curr.map((c) => (c.id === id ? { ...c, historyLoading: false } : c)));
        } catch (err) {
            console.log(err);
            setCatelogs((curr) => curr.map((c) => (c.id === id ? { ...c, historyLoading: false } : c)));
        }
    };

    const onChangeDateChange = (value: any, dateString: [string, string] | string) => {
        if (value) {
            setSelectedDate({
                from: dayjs(value[0]).format("YYYY/MM/DD"),
                to: dayjs(value[1]).format("YYYY/MM/DD"),
            });
        } else {
            setSelectedDate(null);
        }
    };

    const onSearchTitle = (e: string) => {
        setTitle(e);
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Catalog List" />
            <Col span={23} className="catalog-list-box">
                <Divider>Catalog List</Divider>
                <Row justify="center">
                    <Col span={19}>
                        <div className="product-img-info-bar catalog-list-filters">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "300px" }}
                                placeholder={`Select Shop`}
                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                onChange={(e) => setSelectedShop(e)}
                                options={shops.map((shop) => ({
                                    value: shop.id,
                                    label: shop.name,
                                }))}
                            />
                            <Select style={{ width: "300px" }} value={status} onChange={(e) => setStatus(e)} allowClear placeholder="Select Status">
                                <Select.Option key="expiresToday">Expires Today</Select.Option>
                                <Select.Option key="active">Active</Select.Option>
                                <Select.Option key="expired">Expired</Select.Option>
                                <Select.Option key="createdToday">Created Today</Select.Option>
                            </Select>
                            <RangePicker format="DD-MM-YYYY" onChange={onChangeDateChange} style={{ width: "300px" }} />
                            <Search placeholder="Search catalog by Name" onSearch={onSearchTitle} style={{ width: 300 }} allowClear enterButton />
                        </div>
                        <Layout style={{ margin: "0.6rem 1.4rem" }}>
                            <Layout.Header className="catalog-list-header" />
                            <Table
                                bordered
                                dataSource={catalogs}
                                rowKey={(record) => record.id}
                                columns={columns}
                                className="catalog-list-main-table"
                                loading={loading}
                                //size='middle'
                                expandable={{
                                    expandedRowRender: (record: any) =>
                                        record.historyLoading ? (
                                            <Row justify="center">
                                                <Spin />
                                            </Row>
                                        ) : record.catalogsHistory.length > 0 ? (
                                            <div className="catalog-list-expand-row">
                                                <Divider>History</Divider>
                                                <Table
                                                    bordered
                                                    dataSource={record.catalogsHistory}
                                                    rowKey={(record) => record.id}
                                                    columns={columns}
                                                    pagination={{ pageSize: 10, size: "small", style: { marginTop: "0.5rem" }, showSizeChanger: false }}
                                                />
                                            </div>
                                        ) : (
                                            <Row justify="center" style={{ color: "darkred" }}>
                                                No Data
                                            </Row>
                                        ),
                                    rowExpandable: (record) => /*record.name !== 'Not Expandable'*/ true,
                                    onExpand: (expanded, record: CatelogsType) => {
                                        setTempKeyArr((curr) => (expanded ? [...curr, record.id] : curr.filter((id) => id !== record.id)));
                                        expanded && getCatalogHistory(record.id);
                                    },
                                    expandedRowKeys: tempKeyArr,
                                }}
                                pagination={{ pageSize: 100, showSizeChanger: false }}
                            />
                        </Layout>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default CatalogList;
