import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { getBrands } from "../../api/apiFacets";
import { BrandSelectProps, ObjBrandType } from "../../types/modals";
import { BrandType } from "../../types/filters";
import { useAuth } from "../../../../shared/Context/AuthContext";

const BrandSelect: React.FC<BrandSelectProps> = (props) => {
    const { currentCountry } = useAuth();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [brands, setBrands] = useState<BrandType[]>([]);

    useEffect(() => {
        !props.loadCategory && getBrands(setBrands, currentCountry.id, currentCountry.name, props.loadCategory);
    }, [currentCountry.id, currentCountry.name, props.loadCategory]);

    const onBrandChange = async (e: string) => {
        if (e) {
            const wordToArr = e.split("::");
            const obj: ObjBrandType = {
                brand: e,
                brand_id: Number(wordToArr[0]),
                brand_name: wordToArr[1],
            };
            setUpdateLoading(true);
            props
                .updateProduct(props.productItem?.product_id, "brand", obj)
                .then(() => {
                    props.setMatchingProducts((curr) => curr.map((p) => (p.product_id === props.productItem?.product_id ? { ...p, ...obj } : p)));
                    props.setProductItem(null);
                    setUpdateLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <Select
            showSearch
            allowClear
            placeholder="Select Brand"
            tokenSeparators={[","]}
            onSelect={onBrandChange}
            style={{ width: "100%" }}
            onBlur={() => props.noChangeProduct()}
            size="small"
            autoFocus
            defaultOpen
            loading={props.productItem?.type === "brand" && updateLoading ? true : false}
            filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={brands.map((b) => ({
                value: b.id.toString(),
                label: b.brand_name,
            }))}
        />
    );
};

export default BrandSelect;
