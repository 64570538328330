import React, { useState } from "react";
import { Button, Checkbox, Divider, Form, Input, Select, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { AttributeType } from "../../types/AttributeType";
import showMessage from "../../../../shared/MessagesInfo/message";
import { CateogoryL3L4 } from "../../../../shared/FormElements/CategorySelect";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import CategorySelect from "./CategorySelect";
import { GroupType } from "../../pages/AttributeOverview";
import { TreeSelectCategoryType } from "../../../../shared/Api/category";
import { CountryType } from "../../../../shared/Context/type";

interface CreateAttributeProp {
    selectedCategory: { id: number; name: string } | null;
    categories: CateogoryL3L4[];
    setCategories: React.Dispatch<React.SetStateAction<CateogoryL3L4[]>>;
    currentCountryName: string;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeType[]>>;
    groups: GroupType[];
    selectedGroup: number | undefined;
    setGroups: React.Dispatch<React.SetStateAction<GroupType[]>>;
    setAllAtributes: React.Dispatch<React.SetStateAction<{ value: string; label: string; group_id: number; type: string }[]>>;
    allCategories: TreeSelectCategoryType[];
}

const CreateAttribute: React.FC<CreateAttributeProp> = ({
    selectedCategory,
    categories,
    setCategories,
    currentCountryName,
    setVisible,
    setAttributeList,
    groups,
    selectedGroup,
    setGroups,
    setAllAtributes,
    allCategories,
}) => {
    const [selectedCatCreate, setSelectedCatCreate] = useState<{ id: number; name: string } | null>(null);
    const [changeSelectedCategory, setChangeSelectedCategory] = useState(false);
    const [translateLoading, setTranslateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const saveNewAttribute = async (e: any) => {
        const objToSend = {
            ...e,
            category_l3_id: selectedCategory?.id ? selectedCategory?.id : selectedCatCreate?.id, //selectedCategory?.id ? selectedCatCreate?.id:selectedCategory?.id,
            show_filter: e.show_filter ? 1 : 0,
            show_specification: e.show_specification ? 1 : 0,
            min_value: e.type === "range" ? Number(e.min_value) : 0,
            max_value: e.type === "range" ? Number(e.max_value) : 0,
            sort_type: e.sort_type,
        };

        if (e.group_id === 0) {
            delete objToSend.group_id;
        }
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute`, objToSend);
            setVisible(false);
            setTimeout(() => {
                form.resetFields();
            }, 300);
            selectedCategory?.id &&
                selectedCatCreate === null &&
                setAttributeList((curr) => [...curr, { ...data.data, id: data.data.attribute_id, key: data.data.attribute_id.toString(), order: 0, count: 0, primary: false }]);
            setChangeSelectedCategory(false);
            setSelectedCatCreate(null);
            showMessage(data.message);
            e.group_id !== 0 &&
                setGroups((curr) => curr.map((g) => (g.id === e.group_id ? { ...g, attribute_count: typeof g.attribute_count === "number" ? g.attribute_count + 1 : g.attribute_count } : g)));
            setAllAtributes((curr) => [...curr, { value: data.data.attribute_id.toString(), label: e[`name_${currentCountryName}`], group_id: Number(e.group_id), type: e.type }]);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const onCategoryChange = (e: string) => {
        if (e) {
            const wordToArr = e.split("::");
            setSelectedCatCreate({ id: Number(wordToArr[0]), name: wordToArr[1] });
        } else {
            setSelectedCatCreate(null);
        }
    };
    const translateAttribute = async () => {
        if (form.getFieldValue("name_rs")?.trim()) {
            setTranslateLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/translator/translate?text=${form.getFieldValue("name_rs").trim()}`);
                const obj: Record<string, string> = {};
                localStorageCountries.forEach((c) => {
                    if (c.name !== "rs") {
                        obj[`name_${c.name}`] = data.data[c.name];
                    }
                });
                form.setFieldsValue({
                    ...obj,
                    name_en: data.data.en,
                });
                setTranslateLoading(false);
            } catch (err) {
                console.log(err);
                setTranslateLoading(false);
            }
        } else {
            openNotification('Field "Name RS" is required');
        }
    };

    const watchType = Form.useWatch("type", form);

    return (
        <Form
            style={{
                width: "400px",
                border: "1px solid lightgray",
                borderRadius: "3px",
                padding: "2.5rem 2.5rem 0.8rem 2.5rem",
            }}
            form={form}
            name="control-hooks"
            onFinish={saveNewAttribute}
            labelCol={{ span: 8 }}
        >
            {!selectedCategory?.id || changeSelectedCategory ? (
                <React.Fragment>
                    {selectedCatCreate?.id && (
                        <span
                            style={{
                                color: "red",
                                fontSize: "19px",
                                position: "absolute",
                                marginTop: "4px",
                                marginRight: "1px",
                            }}
                        >
                            *
                        </span>
                    )}
                    <Form.Item name="category_l3_id" label="Category" rules={[{ required: selectedCatCreate?.id ? false : true }]} style={{ marginLeft: selectedCatCreate?.id && "0.7rem" }}>
                        <CategorySelect allCategories={allCategories} currentCountryName={currentCountryName} onCategoryChange={onCategoryChange} />
                    </Form.Item>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <p>
                        Selected Category:
                        <span style={{ fontWeight: "bold", marginLeft: "6px" }}>{selectedCategory?.name} </span>
                        <Tooltip placement="top" title="Change Selected Category">
                            <EditOutlined onClick={() => setChangeSelectedCategory(true)} style={{ cursor: "pointer" }} />
                        </Tooltip>
                    </p>
                </React.Fragment>
            )}

            <Form.Item name="group_id" label="Group" rules={[{ required: true }]} initialValue={selectedGroup}>
                <Select options={groups.map((g) => ({ label: g[`name_${currentCountryName}`], value: g.id }))} />
            </Form.Item>

            {localStorageCountries.map((c) => (
                <React.Fragment key={c.name}>
                    <Form.Item
                        name={`name_${c.name}`}
                        label={`Name ${c.name.toUpperCase()}`}
                        style={c.name === "rs" ? { marginBottom: "4px", width: "320px" } : { marginBottom: "8px" }}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={`Name ${c.name.toUpperCase()}`} />
                    </Form.Item>
                    {c.name === "rs" && (
                        <Form.Item style={{ marginBottom: "2px", marginLeft: "20px" }}>
                            <Button size="small" style={{ marginLeft: "5.3rem" }} onClick={translateAttribute} type="primary" shape="round" loading={translateLoading}>
                                Translate
                            </Button>
                        </Form.Item>
                    )}
                </React.Fragment>
            ))}
            <Form.Item name="name_en" label="Name EN" rules={[{ required: true }]}>
                <Input placeholder="Name EN" />
            </Form.Item>
            <Form.Item
                name="type"
                label="Type"
                style={{
                    marginTop: "0rem",
                    width: "156px",
                    margin: "0 0 0 43%",
                    marginLeft: "51%",
                    padding: "0rem 0 0.5rem 0",
                }}
                rules={[{ required: true }]}
                initialValue="select"
            >
                <Select>
                    <Select.Option key="select">Select</Select.Option>
                    <Select.Option key="multiselect">Multiselect</Select.Option>
                    <Select.Option key="range">Range</Select.Option>
                    <Select.Option key="yesno" value="yesno">
                        YesNo
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="sort_type"
                label="Sort"
                style={{
                    marginTop: "0rem",
                    width: "156px",
                    margin: "0 0 0 43%",
                    marginLeft: "51%",
                    padding: "0rem 0 0.5rem 0",
                }}
                rules={[{ required: true }]}
                initialValue="numerical"
            >
                <Select>
                    <Select.Option key="numerical">Numerical</Select.Option>
                    <Select.Option key="alphabetical">Alphabetical</Select.Option>
                </Select>
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                <Form.Item name="min_value" label="Min" rules={[{ required: watchType === "range" }]}>
                    <Input style={{ width: "90%" }} type="number" disabled={watchType !== "range"} />
                </Form.Item>
                <Form.Item name="max_value" label="Max" rules={[{ required: watchType === "range" }]}>
                    <Input style={{ width: "90%", marginLeft: "0.7rem" }} type="number" disabled={watchType !== "range"} />
                </Form.Item>
            </div>
            <div style={{ backgroundColor: "#fafafa", padding: "0.4rem", paddingRight: "0.5rem", marginTop: "2px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        //backgroundColor:'whitesmoke',
                        alignItems: "center",
                        padding: "0rem",
                        border: "1px solid #e0ebeb",
                        borderRadius: "3px",
                    }}
                >
                    <Form.Item
                        name="show_filter"
                        //label="Filter"
                        valuePropName="checked"
                        className="create-shop-allow-update"
                        initialValue={true}
                        style={{ margin: "0" }}
                    >
                        <Checkbox style={{ color: "#22559c" }}>Filter</Checkbox>
                    </Form.Item>
                    <Form.Item name="show_specification" valuePropName="checked" className="create-shop-allow-update" initialValue={true} style={{ margin: "0" }}>
                        <Checkbox style={{ color: "#22559c" }}>Specification</Checkbox>
                    </Form.Item>
                </div>
            </div>
            <Divider />
            <Form.Item style={{ marginTop: "1rem" }}>
                <Button htmlType="submit" type="primary" block loading={loading}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateAttribute;
