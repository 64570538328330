import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import ImageUploading from "react-images-uploading";
import "../style/CategoryImage.css";

const CategoryImage = ({ image, setImage, id, imageForL3 }: any) => {
    const maxNumber = 69;
    if (id === "dynamic_form_nest_item_categories_0_createimg") {
        id = 7855;
    }
    const onChange = (imageList: any, addUpdateIndex: any) => {
        const img = imageList[0];
        setImage((curr: any) => [...curr, { ...img, id: /*id === 'dynamic_form_nest_item_categories_0_createimg' ? 0:*/ id }]);
    };

    const removeImage = (id: string) => {
        setImage((curr: any) => curr.filter((i: any) => i.id !== id));
    };
    return (
        <div>
            <ImageUploading value={image} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {!image.find((i: any) => i.id === id) ? (
                            <Button type="dashed" onClick={onImageUpload} {...dragProps} style={{ backgroundColor: "#eeeeee" }} disabled={!imageForL3}>
                                Click or Drop Image <UploadOutlined />
                            </Button>
                        ) : (
                            <div key={id} className="image-item">
                                <img src={image.find((i: any) => i.id === id)?.data_url} alt="" style={{ position: "absolute", top: "-10px", maxWidth: "57px", maxHeight: "57px" }} />
                                <div className="image-item__btn-wrapper">
                                    <CloseCircleOutlined onClick={() => removeImage(id)} className="category-remove-img" />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

export default CategoryImage;
