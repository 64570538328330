import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, List, Modal, Row } from "antd";
import axios from "axios";
import React, { useState } from "react";

interface TransformModalInfoProps {
    transformState: any;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    downloadFeed: (path: string) => void;
    setTransformState: React.Dispatch<React.SetStateAction<{ transform: {} }>>;
    isError: boolean;
    setIsError: React.Dispatch<React.SetStateAction<boolean>>;
}

const TransformModalInfo: React.FC<TransformModalInfoProps> = ({ transformState, setTransformState, isModalOpen, setIsModalOpen, downloadFeed, isError, setIsError }) => {
    const [products, setProducts] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
        setTransformState({ transform: {} });
        setProducts(null);
        setIsError(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setTransformState({ transform: {} });
        setProducts(null);
        setIsError(false);
    };

    const insetProducts = async (id: number) => {
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/check-for-new-products?shop_id=${id}`);
            setProducts(data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };
    //console.log(products)
    return (
        <Modal
            width={1600}
            title={
                !isError ? (
                    `Transformation Info`
                ) : (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", position: "relative" }}>
                            <p>Error Occured </p>
                            <ExclamationCircleOutlined style={{ position: "relative", bottom: "-2px", left: "3px", color: "darkred" }} />
                        </div>
                        <p style={{ marginRight: "1.5rem", marginTop: "0.3rem" }}>Status: Error</p>
                    </div>
                )
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            style={{ padding: "1rem 0" }}
        >
            {transformState.transform.length > 0 && !isError ? (
                <Row justify={"center"}>
                    <Col span={6} offset={6}>
                        {transformState.transform[0].status === true ? (
                            <React.Fragment>
                                <Divider>Store id {transformState.transform[0].data.store_id}</Divider>
                                <List
                                    size="small"
                                    header={
                                        <div>
                                            <p>
                                                Status: <strong>{transformState.transform[0].data.status}</strong>
                                            </p>
                                            <p>
                                                Transformation number: <strong>{transformState.transform[0].data.id}</strong>
                                            </p>
                                            <p>
                                                Store id: <strong>{transformState.transform[0].data.store_id}</strong>
                                            </p>
                                        </div>
                                    }
                                    dataSource={[
                                        <p>
                                            Bucket: <strong>{transformState.transform[0].data.bucket}</strong>
                                        </p>,
                                        <>
                                            <p>Original File Bucket Path: </p>{" "}
                                            <Button size="small" type="primary" onClick={() => downloadFeed(transformState.transform[0].data.original_file_bucket_path)}>
                                                Download <DownloadOutlined />
                                            </Button>
                                        </>,
                                        <>
                                            <p>Parsed File Bucket Path: </p>
                                            <Button size="small" type="primary" onClick={() => downloadFeed(transformState.transform[0].data.parsed_file_bucket_path)}>
                                                Download
                                                <DownloadOutlined />
                                            </Button>
                                        </>,
                                        <>
                                            <p>Log File Bucket Path: </p>
                                            <Button size="small" type="primary" onClick={() => downloadFeed(transformState.transform[0].data.log_file_bucket_path)}>
                                                Download
                                                <DownloadOutlined />
                                            </Button>
                                        </>,
                                        <p>
                                            Error:{" "}
                                            <strong style={{ color: transformState.transform[0].data.error === null ? "#64a832" : "red" }}>
                                                {transformState.transform[0].data.error === null ? "none" : transformState.transform[0].data.error === null}
                                            </strong>
                                        </p>,
                                    ]}
                                    footer={
                                        <div>
                                            <h5>Stats:</h5>
                                            <p>
                                                Failed Count: <strong>{transformState.transform[0].data.stats.failedCount}</strong>
                                            </p>
                                            <p>
                                                Successful Count: <strong>{transformState.transform[0].data.stats.successfulCount}</strong>
                                            </p>
                                            <p>
                                                Total Products Count <strong>{transformState.transform[0].data.stats.totalProductsCount} </strong>
                                            </p>
                                            <h6>Transformation Time</h6>

                                            <p>
                                                <strong>
                                                    {transformState.transform[0].data.file_type === "xml"
                                                        ? transformState.transform[0].data.stats.xmlTransformationTime.hours
                                                        : transformState.transform[0].data.stats.csvTransformationTime.hours}{" "}
                                                </strong>
                                                hours
                                            </p>
                                            <p>
                                                <strong>
                                                    {transformState.transform[0].data.file_type === "xml"
                                                        ? transformState.transform[0].data.stats.xmlTransformationTime.minutes
                                                        : transformState.transform[0].data.stats.csvTransformationTime.minutes}{" "}
                                                </strong>
                                                minutes
                                            </p>
                                            <p>
                                                <strong>
                                                    {transformState.transform[0].data.file_type === "xml"
                                                        ? transformState.transform[0].data.stats.xmlTransformationTime.seconds
                                                        : transformState.transform[0].data.stats.csvTransformationTime.seconds}{" "}
                                                </strong>
                                                seconds
                                            </p>
                                            <p>
                                                <strong>
                                                    {transformState.transform[0].data.file_type === "xml"
                                                        ? transformState.transform[0].data.stats.xmlTransformationTime.ms
                                                        : transformState.transform[0].data.stats.csvTransformationTime.ms}{" "}
                                                </strong>
                                                ms
                                            </p>
                                        </div>
                                    }
                                    bordered
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Divider>Store id {transformState.transform[0].store_id}</Divider>
                                <List
                                    size="small"
                                    header={
                                        <div>
                                            <p>
                                                Status: <strong>{transformState.transform[0].status}</strong>
                                            </p>
                                            <p>
                                                Transformation number: <strong>{transformState.transform[0].id}</strong>
                                            </p>
                                            <p>
                                                Store id: <strong>{transformState.transform[0].store_id}</strong>
                                            </p>
                                        </div>
                                    }
                                    dataSource={[
                                        <p>
                                            Status: <strong>{transformState.transform[0].status}</strong>
                                        </p>,
                                    ]}
                                    footer={
                                        <div>
                                            <p>
                                                Error: <strong>{transformState.transform[0].error}</strong>
                                            </p>
                                        </div>
                                    }
                                    bordered
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />
                            </React.Fragment>
                        )}
                    </Col>

                    <Col span={6} offset={2}>
                        <Button
                            //type="primary"
                            style={{ marginTop: "3.5rem" }}
                            onClick={() => insetProducts(Number(transformState.transform[0].data.store_id))}
                            loading={loading}
                            danger
                        >
                            Insert new products
                        </Button>
                        {products && (
                            <React.Fragment>
                                <List
                                    style={{ backgroundColor: "whitesmoke", marginBottom: "10px", marginTop: "1.3rem" }}
                                    size="large"
                                    //header={<div>Info</div>}
                                    bordered
                                    dataSource={[
                                        `ean_matches: ${products.ean_matches}`,
                                        `execution_time: ${products.execution_time}`,
                                        `new_prices: ${products.new_prices}`,
                                        `ean_matches: ${products.ean_matches}`,
                                        `ean_matches: ${products.ean_matches}`,
                                    ]}
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />

                                <List
                                    size="large"
                                    header={<div>New Products</div>}
                                    bordered
                                    dataSource={[
                                        `matched_brands: ${products.new_products && products.new_products.matched_brands}`,
                                        `new_brands: ${products.new_products && products.new_products.new_brands}`,
                                        `new_eans: ${products.new_products && products.new_products.new_eans}`,
                                        `new_prices: ${products.new_products && products.new_products.new_prices}`,
                                        `new_products: ${products.new_products && products.new_products.new_products}`,
                                    ]}
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            ) : (
                <Row justify="center">
                    <Col>Error</Col>
                </Row>
            )}
            <Divider />
            <Button style={{ position: "absolute", right: "2.5rem", bottom: "0.4rem" }} type="primary" danger={isError} onClick={handleOk}>
                Ok
            </Button>
        </Modal>
    );
};

export default TransformModalInfo;
