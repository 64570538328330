import React from "react";
import { TreeSelect } from "antd";
import { TreeSelectCategoryType } from "../Api/category";
const { TreeNode } = TreeSelect;

export type CateogoryL4 = {
    id: number;
    category_l2_id: number;
    parent_id: number;
    created_at: string;
    updated_at: string;
    default_image_url: string;
    table_name: string;
    shoptok_id: number;
    disabled: number;
    name_rs: string;
};

export type CateogoryL3L4 = {
    id: number;
    category_l2_id: number;
    name_rs: string;
    parent_id: number;
    l2_name: string;
    children: CateogoryL4[];
};

interface CategorySelectProps {
    onCategoryChange: ((value: any, labelList: React.ReactNode[]) => void) | undefined;
    categoryL3Options: TreeSelectCategoryType[];
    currentCountry: string;
    allowClear?: boolean;
    style?: any;
    onClear?: any;
}

const CategorySelect: React.FC<CategorySelectProps> = ({ onCategoryChange, categoryL3Options, currentCountry, style, allowClear, onClear }) => (
    <TreeSelect
        showSearch
        treeDataSimpleMode
        placeholder="Select category"
        onClear={onClear}
        style={{
            width: "100%",
            marginBottom: 6,
            ...style,
        }}
        onChange={onCategoryChange}
        filterTreeNode={(search: string, item: any) => item.title.props?.children[0]?.toLowerCase().indexOf(search.toLowerCase()) >= 0}
        allowClear={allowClear}
    >
        {categoryL3Options &&
            categoryL3Options.map((category) =>
                category.children.length === 0 ? (
                    <TreeNode
                        key={category.id}
                        value={category.id}
                        title={
                            <>
                                {`${category.id}: ${category[`name_${currentCountry}`]}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}> ({category.l2_name}) </span>
                            </>
                        }
                    />
                ) : (
                    <TreeNode
                        key={category.id}
                        value={category.id}
                        title={
                            <>
                                {`${category.id}: ${category[`name_${currentCountry}`]}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}> ({category.l2_name}) </span>
                            </>
                        }
                    >
                        {category.children.map((itemChild: any) => (
                            <TreeNode key={itemChild.id} value={itemChild.id} title={<>{`${itemChild.id}: ${itemChild[`name_${currentCountry}`]} `} </>} />
                        ))}
                    </TreeNode>
                )
            )}
    </TreeSelect>
);

export default CategorySelect;
