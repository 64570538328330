import React, { useCallback, useEffect, useState } from "react";
import { EditTwoTone, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { Col, Divider, Row, Layout, Modal, Button, Select, Checkbox, Spin, Tooltip } from "antd";
import CreateCategory from "../components/CreateCategory";
import { DefaultOptionType } from "antd/lib/select";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import showMessage from "../../../shared/MessagesInfo/message";
import EditCategory, { EditCateogyType } from "../components/EditCategory";
import { CategoryType } from "./AllCategories";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useAuth } from "../../../shared/Context/AuthContext";
const { Header } = Layout;
let categoryId = 0;

type SelectedCatType = {
    id: number;
    ["key"]: string;
    type: string;
};

type SelectedCateogries = {
    cat1: SelectedCatType | null;
    cat2: SelectedCatType | null;
};

const ReCategorizing = () => {
    const { currentCountry } = useAuth();
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [modalCreate, setModalCreate] = useState(false);
    const [counter, setCounter] = useState(1);
    const [loading, setLoading] = useState(false);
    const [toL4, setToL4] = useState(false);
    const [toL3, setToL3] = useState(false);
    const [productTransform, setProductTransform] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<SelectedCateogries | any>({ cat1: null, cat2: null });

    const getAllCategoriesArray = useCallback(async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-all-categories?country_id=${currentCountry.id}`);
            const level1 = data.data[0]
                .map((category1: any) => {
                    categoryId += 1;
                    return {
                        ...category1,
                        categoryId: categoryId,
                        category_l1_id: category1.id,
                        [`category_l1_name_${currentCountry.name}`]: [`category1.name_${currentCountry.name}`],
                        isMetaTitle: false,
                        isMetaKeywords: false,
                        isMetaDescription: false,
                        inputError: false,
                    };
                })
                .sort((a: any, b: any) => a.id - b.id);
            const level2 = data.data[1]
                .map((category2: any) => {
                    categoryId += 1;
                    return {
                        ...category2,
                        categoryId: categoryId,
                        category_l2_id: category2.id,
                        [`category_l2_name_${currentCountry.name}`]: [`category2.name_${currentCountry.name}`],
                        isMetaTitle: false,
                        isMetaKeywords: false,
                        isMetaDescription: false,
                        inputError: false,
                    };
                })
                .sort((a: any, b: any) => a.id - b.id);
            const level3 = data.data[2]
                .map((category3: any) => {
                    categoryId += 1;
                    return {
                        ...category3,
                        categoryId: categoryId,
                        category_l3_id: category3.id,
                        [`category_l3_name_${currentCountry.name}`]: [`category3.name_${currentCountry.name}`],
                        isMetaTitle: false,
                        isMetaKeywords: false,
                        isMetaDescription: false,
                    };
                })
                .sort((a: any, b: any) => a.id - b.id);
            const level4 = data.data[3]
                .map((category4: any) => {
                    categoryId += 1;
                    return {
                        ...category4,
                        categoryId: categoryId,
                        category_l4_id: category4.id,
                        [`category_l4_name_${currentCountry.name}`]: [`category4.name_${currentCountry.name}`],
                        isMetaTitle: false,
                        isMetaKeywords: false,
                        isMetaDescription: false,
                    };
                })
                .sort((a: any, b: any) => a.id - b.id);

            setCategories([...level1, ...level2, ...level3, ...level4]);
        } catch (err) {
            console.log(err);
        }
    }, [currentCountry]);

    useEffect(() => {
        getAllCategoriesArray();
    }, [getAllCategoriesArray, counter]);

    const setSourceCategory = (e: any, option: any, cat: string) => {
        const elements = option.value.split("-");
        const id = elements[0];
        const level = elements[1];
        const findCategory = categories.find((cat) => cat.id === Number(id) && cat.type === level);
        setSelectedCategories((curr: SelectedCateogries) => ({ ...curr, [cat]: findCategory }));
    };

    const saveChanges = async () => {
        setLoading(true);
        if (selectedCategories.cat1?.id === selectedCategories.cat2?.id && selectedCategories.cat1?.type === selectedCategories.cat2?.type) {
            openNotification("Selection two same categories is not allowed!");
            setLoading(false);
        } else {
            const targetLevel = selectedCategories.cat2?.type[1];
            try {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_URL_API}/product/category/transfer?id=${selectedCategories.cat1?.id}&level=${selectedCategories.cat1?.type[1]}&parent_id=${
                        toL4 ? selectedCategories.cat2?.id : 1
                    }&target_level=${toL4 ? "4" : toL3 ? "3" : targetLevel}${toL4 || toL3 ? "" : `&target_id=${selectedCategories.cat2?.id}`}&recategorize_products=${productTransform ? 1 : 0}`
                );
                showMessage(data.message);
                setSelectedCategories({ cat1: null, cat2: null });
                setToL3(false);
                setToL4(false);
                setCounter((curr) => curr + 1);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
    };
    const [selectedEditCat, setSelectedEditCat] = useState<any>(null);
    const [editCategory, setEditCateogry] = useState<EditCateogyType | null>(null);
    const [modalEdit, setModalEdit] = useState(false);

    const openEditModal = (record: any) => {
        setModalEdit(true);
        setSelectedEditCat(record);
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Re-categorizing" />
            <Row justify="center">
                <Col span={16} style={{ backgroundColor: "white", paddingBottom: "0.7rem" }}>
                    <Divider>ReCategorizing</Divider>
                    <Layout style={{ margin: "0rem 1rem", height: "88vh" }}>
                        <button className="u-button" style={{ backgroundColor: "#1dad9b", position: "absolute", right: "2rem", top: "7rem" }} onClick={() => setModalCreate(true)}>
                            <PlusOutlined style={{ marginRight: "9px" }} />
                            Create
                        </button>
                        <Header className="product-img-header" style={{ backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px" }}>
                            <span></span>{" "}
                        </Header>
                        <Modal title="Create new Category" centered open={modalCreate} onOk={() => setModalCreate(false)} onCancel={() => setModalCreate(false)} width={1800} footer={false}>
                            <CreateCategory
                                categories={categories}
                                setModalCreate={setModalCreate}
                                currentCountryName={currentCountry.name}
                                currentCountryId={currentCountry.id}
                                setCounter={setCounter}
                                counter={counter}
                                modalCreate={modalCreate}
                                // countries={countryState?.countries}
                                //setCounter={setCounter}
                            />
                        </Modal>
                        <Row justify="center" style={{ marginTop: "18rem" }}>
                            <Col span={/*windowSize.width > 1800 ? 9 :10*/ 9} offset={/*windowSize.width > 1800 ? 7:6*/ 7}>
                                <div
                                    style={{
                                        backgroundColor: "whitesmoke",
                                        border: "1px solid skyblue",
                                        borderRadius: "4px",
                                        padding: "2.5rem 2.3rem 0.6rem 3.8rem",
                                        minWidth: "490px",
                                        maxWidth: "500px",
                                    }}
                                >
                                    {["cat1", "cat2"].map((catItem: any) => (
                                        <div key={catItem} style={{ width: "440px" }}>
                                            <label style={{ marginTop: "1rem" }}>{`${catItem === "cat1" ? "Source Category" : `${toL4 ? "Parent" : "Target"} Category`}`}</label>
                                            <div style={{ display: "flex" }}>
                                                <Select
                                                    allowClear
                                                    onClear={() =>
                                                        setSelectedCategories((curr: SelectedCateogries) => ({
                                                            ...curr,
                                                            [catItem]: null,
                                                        }))
                                                    }
                                                    showSearch
                                                    placeholder={`Select ${catItem === "cat1" ? "Source" : "Target"} Category`}
                                                    optionFilterProp="label"
                                                    filterOption={(input, option) =>
                                                        option?.children ? (option!.children[1] as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                                                    }
                                                    style={{
                                                        width: "390px",
                                                        marginTop: "0.5rem",
                                                        marginBottom: "1rem",
                                                    }}
                                                    onSelect={(e: string, option: DefaultOptionType) => setSourceCategory(e, option, catItem)}
                                                    value={selectedCategories[catItem] ? `${selectedCategories[catItem].id}-${selectedCategories[catItem].type}` : null}
                                                    disabled={catItem === "cat2" && toL3 ? true : false}
                                                >
                                                    {categories
                                                        .filter((item) => (catItem === "cat1" ? item : toL4 ? item.type === "l3" : item))
                                                        .map(
                                                            (cat) =>
                                                                cat.disabled === 0 &&
                                                                (catItem === "cat2" ? cat.id !== selectedCategories.cat1?.id : cat.id !== selectedCategories.cat2?.id) && (
                                                                    <Select.Option key={`${cat.id} ${cat[`name_${currentCountry.name}` as keyof CategoryType]}`} value={`${cat.id}-${cat.type}`}>
                                                                        <span
                                                                            style={{
                                                                                //color:'blue',
                                                                                marginRight: "6px",
                                                                                color: catItem === "cat2" && toL3 ? "lightgray" : "blue",
                                                                            }}
                                                                        >
                                                                            {cat.type?.toUpperCase()} -
                                                                        </span>
                                                                        {`${cat.id}: ${cat[`name_${currentCountry.name}` as keyof CategoryType]}`}
                                                                    </Select.Option>
                                                                )
                                                        )}
                                                </Select>
                                                {catItem === "cat1" && (
                                                    <Tooltip placement="top" title={"Edit cateogry"}>
                                                        <Button
                                                            type="default"
                                                            size="small"
                                                            style={{
                                                                borderRadius: "4px",
                                                                left: "0.6rem",
                                                                top: "0.6rem",
                                                                zIndex: 600,
                                                                display: selectedCategories.cat1 ? "" : "none",
                                                            }}
                                                            onClick={() => openEditModal(selectedCategories.cat1)}
                                                        >
                                                            <EditTwoTone style={{ color: "#385170" }} />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            {catItem === "cat1" && selectedCategories.cat1?.type === "l3" && (
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setToL4(e.target.checked);
                                                        setSelectedCategories((curr: SelectedCateogries) => ({
                                                            ...curr,
                                                            cat2: null,
                                                        }));
                                                    }}
                                                    style={{}}
                                                >
                                                    {" "}
                                                    To L4{" "}
                                                </Checkbox>
                                            )}
                                            {catItem === "cat1" && selectedCategories.cat1?.type === "l4" && (
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setToL3(e.target.checked);
                                                        setSelectedCategories((curr: SelectedCateogries) => ({
                                                            ...curr,
                                                            cat2: null,
                                                        }));
                                                    }}
                                                    style={{}}
                                                >
                                                    {" "}
                                                    To L3{" "}
                                                </Checkbox>
                                            )}
                                        </div>
                                    ))}
                                    <Button
                                        type="primary"
                                        style={{ marginTop: "1.6rem" }}
                                        disabled={(selectedCategories.cat1 && selectedCategories.cat2) || (selectedCategories.cat1 && toL3) ? false : true}
                                        onClick={saveChanges}
                                        //loading={loading}
                                    >
                                        Transform
                                    </Button>
                                    {loading && <Spin style={{ marginLeft: "1rem" }} />}
                                    {((selectedCategories.cat1?.type === "l3" && (selectedCategories.cat2?.type === "l3" || selectedCategories.cat2?.type === "l4")) ||
                                        (selectedCategories.cat1?.type === "l4" && (selectedCategories.cat2?.type === "l3" || selectedCategories.cat2?.type === "l4"))) &&
                                        !toL4 && (
                                            <Checkbox onChange={(e) => setProductTransform(e.target.checked)} style={{ marginLeft: "60%", position: "relative", bottom: "2rem" }}>
                                                {" "}
                                                Transfer Products{" "}
                                            </Checkbox>
                                        )}
                                </div>
                            </Col>
                            <Col span={8}></Col>
                            <EditCategory
                                modalEdit={modalEdit}
                                setModalEdit={setModalEdit}
                                selectedCateogry={selectedEditCat}
                                setSelectedEditCat={setSelectedEditCat}
                                editCategory={editCategory}
                                setEditCateogry={setEditCateogry}
                                counter={counter}
                                setCounter={setCounter}
                                currentCountryName={currentCountry.name}
                                categories={categories}
                                currentCountryId={currentCountry.id}
                                // countries={countryState?.countries}
                                setSelectedCategories={setSelectedCategories} //here
                            />
                        </Row>
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ReCategorizing;
