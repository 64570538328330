import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import showMessage from "../MessagesInfo/message";
import { AuthContextType, CountryType } from "./type";
import { getUser } from "../Api/Users";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const changeCountryData = (datas: CountryType[]): CountryType[] =>
    datas
        .map((d) => ({
            active: d.active,
            currency: /*"€"*/ d.currency,
            datatok_language_order: d.datatok_language_order,
            datatok_show_order: d.datatok_show_order,
            id: d.id,
            langorder: d.langorder,
            link: d.link,
            locale_code: d.locale_code,
            name: d.name,
            order: d.order,
            theebox_locale_code: d.theebox_locale_code,
            value: d.value,
        }))
        .sort((a, b) => a.order - b.order);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // const [token, setToken] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentCountry, setCurrentCountry] = useState(JSON.parse(localStorage.getItem("currentCountry")!) as { id: number; name: string });
    const [user, setUser] = useState<{ first_name: string; last_name: string } | undefined>();
    const [loadingData, setLoadingData] = useState<boolean>(true);

    // refresh page
    useEffect(() => {
        //let isMounted = true; // Flag za proveru da li je komponenta montirana
        // const storedToken = localStorage.getItem("token");
        if (localStorage.getItem("token")) {
            // const selectedCountry = localStorage.getItem("currentCountry");
            setCurrentCountry(/*(curr) => (selectedCountry ?*/ JSON.parse(localStorage.getItem("currentCountry")!) /*: curr*)*/);
            setUser(JSON.parse(localStorage.getItem("user")!));
            setIsLoggedIn(true);
            setLoadingData(false);
        } else {
            setLoadingData(false);
        }
        // const getCountries = async () => {
        //     try {
        //         const countriesData = await axios.get<{ data: CountryType[] }>(
        //             `${process.env.REACT_APP_URL_API}/product/country/datatok` /*{
        //             headers: {
        //                 Authorization: "Bearer " + storedToken,
        //                 "Content-Type": "application/json",
        //             },
        //             withCredentials: false,
        //         }*/
        //         );

        //         isMounted && localStorage.setItem("countries", JSON.stringify(changeCountryData(countriesData.data.data)));

        //         //setCountries(changeCountryData(countriesData.data.data));
        //     } catch (err) {
        //         console.log(err);
        //     }
        // };

        // getCountries();
        // return () => {
        //     isMounted = false; // Očisti flag kada se komponenta demontira check if is it ok
        // };
    }, []); // Ovo se pokreće samo pri prvom renderovanju

    //

    const login = async (user: { name: string; password: string }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_API}/auth/login`, user);
            const data = response.data;
            if (data.success) {
                const receivedToken = data.success.token;
                //setToken(receivedToken); // is it good idea to save token in context
                if (receivedToken) {
                    setIsLoggedIn(true);
                }

                localStorage.setItem("token", receivedToken);
                axios.defaults.headers.common["Authorization"] = "Bearer " + receivedToken;
                axios.defaults.headers.common["withCredentials"] = false;
                const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 10);
                localStorage.setItem("tokenExpirationDate", JSON.stringify(tokenExpirationDate));
                localStorage.setItem("loggedInTime", JSON.stringify(new Date()));
                //////
                getUser().then((resp) => {
                    //console.log(resp.data);
                    localStorage.setItem("user", JSON.stringify({ first_name: resp.data.first_name, last_name: resp.data.last_name }));
                    setUser({ first_name: resp.data.first_name, last_name: resp.data.last_name });
                });
                //////

                setLoadingData(false);
                const countriesData = await axios.get<{ data: CountryType[] }>(`${process.env.REACT_APP_URL_API}/product/country/datatok`, {
                    headers: {
                        Authorization: "Bearer " + receivedToken,
                        "Content-Type": "application/json",
                    },
                    withCredentials: false,
                });
                localStorage.setItem("countries", JSON.stringify(changeCountryData(countriesData.data.data)));
                localStorage.setItem("currentCountry", JSON.stringify({ id: 1, name: "rs" }));
                setCurrentCountry({ id: 1, name: "rs" });
            } else {
                throw new Error(data.message);
            }
            showMessage(data.message);
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("countries");
        localStorage.removeItem("currentCountry");
        localStorage.removeItem("tokenExpirationDate");
        localStorage.removeItem("loggedInTime");
        localStorage.removeItem("user");

        setUser(undefined);
        setIsLoggedIn(false);
    };

    return <AuthContext.Provider value={{ isLoggedIn, login, logOut, loadingData, currentCountry, setCurrentCountry, user }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
