import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Spin, Typography } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import { SegmentedValue } from "antd/es/segmented";
import openNotification from "../../../../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import showMessage from "../../../../../../shared/MessagesInfo/message";
import { PicturesType } from "../../../../types/type";
import ReviewForm from "./ReviewForm";
import YoutubeBox from "./YoutubeBox";
import CountriesTabs from "./CountriesTabs";
import { CountryType } from "../../../../../../shared/Context/type";

const checkYoutubeLength = (text: string) => text.split(":").length - 1;

const urlToSec = (text: string) => {
    if (text) {
        const min = Number(text) / 60;
        return `${Math.floor(min) < 10 ? 0 : ""}${Math.floor(min)}:${Number(text) % 60 < 10 ? 0 : ""}${Number(text) % 60}`;
    } else {
        return "";
    }
};

interface ReviewProps {
    review: Record<string, string> | undefined;
    setReview: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
    productId: number | undefined;
    productInfo: { name: string; value: string | PicturesType[] }[];
    loading: boolean;
}

const Review = (props: ReviewProps) => {
    const [country, setCountry] = useState("rs");
    const [loadingSave, setLoadingSave] = useState(false);
    const [text, setText] = useState("");
    const [isSaved, setIsSaved] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { review, productId, setReview, productInfo, loading } = props;
    const init: any = review && review[`review_content_${country}`] ? review[`review_content_${country}`] : undefined;
    const [form] = Form.useForm();

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    useEffect(() => {
        form.setFieldsValue({
            title: review?.review_title_rs ?? "",
            youtube_url: review?.youtube_url_rs ?? "",
            youtube_length: urlToSec(review ? review?.youtube_length_rs : ""),
        });
    }, [review, form]);

    const changeCountryValue = (e: SegmentedValue) => {
        setCountry(e.toString());
    };

    const youtube_url = Form.useWatch(`youtube_url`, form);

    const saveReview = async () => {
        const eventObj = form.getFieldsValue();
        const url = String(eventObj[`youtube_length`]);
        if (!url.split("").includes(":") || checkYoutubeLength(url) > 1) {
            openNotification("YouTube length need to be formatted with : between minutes and seconds.");
            return;
        }

        setLoadingSave(true);
        const urlArr = url.split(":");
        const urlToSeconds = Number(urlArr[0]) * 60 + Number(urlArr[1]);

        let tempArr: any = {};

        localStorageCountries.forEach((c) => {
            const prodObj = { ...review };

            tempArr[`review_title_${c.locale_code}`] = eventObj[`title`] ? eventObj[`title`] : null;
            tempArr[`youtube_url_${c.locale_code}`] = eventObj[`youtube_url`] ? eventObj[`youtube_url`] : null;
            tempArr[`youtube_length_${c.locale_code}`] = urlToSeconds ? urlToSeconds : null;

            if (c.locale_code === country) {
                tempArr[`review_content_${c.locale_code}`] = text; // editorText ? editorText : null;
            } else {
                tempArr[`review_content_${c.locale_code}`] = prodObj[`review_content_${c.locale_code}` as keyof typeof prodObj]
                    ? prodObj[`review_content_${c.locale_code}` as keyof typeof prodObj]
                    : null;
            }
        });

        tempArr[`review_content_en`] = review ? (review[`review_content_en` as keyof typeof review] ? review[`review_content_en` as keyof typeof review] : null) : null;

        const obj = { product_id: productId, ...tempArr };

        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/product-review/upsert`, obj);
            setLoadingSave(false);
            showMessage(data.message);
            setReview((curr) => ({ ...curr, ...obj, id: data.data.id })); // added obj here to set in state what i sent to api
            setIsSaved(true);
        } catch (err) {
            console.log(err);
        }
    };

    const onEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setText(data);
    };

    const changeDataOnBlur = (event: any, editor: ClassicEditor) => {
        const newText = editor.getData();
        const clearInit = init?.toString() ?? "";

        if (clearInit.trim() === newText.trim()) {
            return;
        }
        const formObj = form.getFieldsValue();

        const url = String(formObj[`youtube_length`]);
        if (!url.split("").includes(":") || checkYoutubeLength(url) > 1) {
            openNotification("YouTube length need to be formatted with : between minutes and seconds.");
            return;
        }

        const urlArr = url.split(":");
        const urlToSeconds = Number(urlArr[0]) * 60 + Number(urlArr[1]);

        let tempArr: { [key: string]: string | number | null | undefined } = {};

        localStorageCountries.forEach((c) => {
            // console.log(formObj);
            if (c.locale_code === country) {
                tempArr[`review_content_${c.locale_code}`] = newText; // editorText ? editorText : null;
                tempArr[`review_title_${c.locale_code}`] = formObj[`title`] ? formObj[`title`] : null;
                tempArr[`youtube_url_${c.locale_code}`] = formObj[`youtube_url`] ? formObj[`youtube_url`] : null;
                tempArr[`youtube_length_${c.locale_code}`] = urlToSeconds ? urlToSeconds : null;
            } else {
                tempArr[`review_content_${c.locale_code}`] = review ? review[`review_content_${c.locale_code}` as keyof typeof review] : "";
                /////////////////////
                tempArr[`review_title_${c.locale_code}`] = formObj[`title`] ? formObj[`title`] : null;
                tempArr[`youtube_url_${c.locale_code}`] = formObj[`youtube_url`] ? formObj[`youtube_url`] : null;
                tempArr[`youtube_length_${c.locale_code}`] = urlToSeconds ? urlToSeconds : null;
            }
        });

        setReview((curr: any) =>
            curr
                ? {
                      ...curr,
                      ...tempArr,
                      [`review_content_${country}`]: newText,
                  }
                : tempArr
        );
    };

    const deleteAllReviews = async () => {
        if (review?.id) {
            setDeleteLoading(true);
            try {
                const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/product-review/delete?id=${review?.id}`);
                showMessage(data.message);
                form.resetFields();
                setReview(undefined);
                setDeleteLoading(false);
            } catch (err) {
                console.log(err);
            }
        } else {
            openNotification("There is no reviews for this product");
        }
    };

    return (
        <div style={{ width: "100%", padding: "0.5rem", borderRadius: "8px", border: "1px solid lightgray", backgroundColor: "#EBF4F6", marginRight: "0.5rem" }}>
            <Col span={24}>
                <Row justify="space-between" align="middle">
                    <div></div>
                    <Typography.Title level={5}>Review</Typography.Title>
                    <Col>
                        <CountriesTabs changeCountryValue={changeCountryValue} />
                    </Col>
                </Row>
                <Row style={{ border: "1px solid lightgray", padding: "1rem", borderRadius: "8px" }}>
                    <Col span={12}>
                        <ReviewForm form={form} loading={loadingSave} productInfo={productInfo} setLoading={setLoadingSave} setReview={setReview} youtube_url={youtube_url} />
                    </Col>
                    <Col span={12}>
                        <YoutubeBox youtube_url={youtube_url} />
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ marginTop: "1rem" }} className="product-review-container">
                {loading && <Spin style={{ position: "absolute", top: "50%", left: "45%", zIndex: "200" }} />}
                <CKEditor editor={ClassicEditor} data={init ? init : ""} onChange={onEditorChange} onBlur={changeDataOnBlur} />
                <Row style={{ marginTop: "1rem" }} justify="space-between">
                    <Button onClick={saveReview} loading={loadingSave} icon={isSaved && <CheckCircleOutlined />}>
                        Save Review
                    </Button>
                    <Button danger type="primary" onClick={deleteAllReviews} loading={deleteLoading} disabled={review?.id ? false : true}>
                        Delete
                    </Button>
                </Row>
            </Col>
        </div>
    );
};

export default Review;
