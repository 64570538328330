import { Button, Card, Col, Divider, Input, Layout, Row, Select, Statistic, Table } from "antd";
import React, { useState, useEffect } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { ColumnType } from "antd/es/table";
import "../style/EditProductMpn.css";
import EanModal from "../components/EanCleaning/EanModal";
import axios from "axios";
import numberWithCommas from "../../../shared/utils/numberUtils";
import showMessage from "../../../shared/MessagesInfo/message";
import "../style/EanCleaning.css";
import { getAllCategories, TreeSelectCategoryType } from "../../../shared/Api/category";
import { useAuth } from "../../../shared/Context/AuthContext";
const { Header } = Layout;

export type EanCleaningType = {
    code: string;
    prices_count: number;
    product_count: number;
    delete_loading: boolean;
    showEanDataLoading: boolean;
};

export type EanProductType = {
    id: number;
    product_id: number;
    product_name: string;
    product_url: string;
    seller_name: string;
    seller_url: string;
    shop_name: string;
    detach_price_loading: boolean;
    product_image: string;
};

const EanCleaning = () => {
    const { currentCountry } = useAuth();
    const [categories, setCategories] = useState<TreeSelectCategoryType[]>([]);
    const [eanList, setEanList] = useState<EanCleaningType[]>([]);
    const [count, setCount] = useState<number | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [countLoading, setCountLoading] = useState(false);
    const [eanData, setEanData] = useState<{ ean: string; data: EanProductType[] } | null>(null);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setPage(1);
    }, [currentCountry]);

    useEffect(() => {
        const getEanData = async () => {
            setLoading(true);
            try {
                const baseUrl = process.env.REACT_APP_URL_API;
                const pageQuery = `?page=${page}`;
                const categoryQuery = selectedCategory ? `&category_l3_id=${selectedCategory}` : "";
                const countryQuery = `&country_id=${currentCountry.id}`;
                const searchQuery = search.trim().length > 0 ? `&search=${search}` : "";
                const url = `${baseUrl}/product/ean/report${pageQuery}${categoryQuery}${countryQuery}${searchQuery}`;
                const { data } = await axios.get(url);
                setEanList(data.data.map((d: EanCleaningType) => ({ ...d, delete_loading: false, showEanDataLoading: false })));
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };
        getEanData();
    }, [selectedCategory, currentCountry.id, search, page]);

    useEffect(() => {
        const getCount = async () => {
            setCountLoading(true);
            try {
                const baseUrl = process.env.REACT_APP_URL_API;
                const countryQuery = `?country_id=${currentCountry.id}`;
                const categoryQuery = selectedCategory ? `&category_l3_id=${selectedCategory}` : "";
                const searchQuery = search.trim().length > 0 ? `&search=${search}` : "";
                const url = `${baseUrl}/product/ean/report-count${countryQuery}${categoryQuery}${searchQuery}`;
                const { data } = await axios.get(url);
                setCount(data.data);
                setCountLoading(false);
            } catch (err) {
                console.log(err);
                setCountLoading(false);
            }
        };
        getCount();
    }, [currentCountry.id, selectedCategory, search]);

    useEffect(() => {
        getAllCategories(currentCountry.id, currentCountry.name).then((resp) => {
            setCategories(resp);
        });
    }, [currentCountry]);

    const showEanData = async (ean: string) => {
        //  showEanDataLoading
        setEanData(null);
        setEanList((curr) => curr.map((e) => ({ ...e, showEanDataLoading: e.code === ean ? true : false })));
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const codeQuery = `?code=${ean}`;
            const countryQuery = `&country_id=${currentCountry.id}`;
            const url = `${baseUrl}/product/ean/list-ean-products${codeQuery}${countryQuery}`;
            const { data } = await axios.get(url);
            setEanData({ ean: ean, data: data.data.map((d: EanProductType) => ({ ...d, detach_price_loading: false })) });
            setEanList((curr) => curr.map((e) => ({ ...e, showEanDataLoading: false })));
            setIsModalOpen(true);
        } catch (err) {
            setEanList((curr) => curr.map((e) => ({ ...e, showEanDataLoading: false })));
            console.log(err);
        }
    };

    const columns: (ColumnType<EanCleaningType> & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "Ean",
            dataIndex: "code",
            align: "center" as "center",
            width: "100px",
        },
        {
            title: "Product Count",
            dataIndex: "product_count",
            align: "center" as "center",
            width: "200px",
        },

        {
            title: "",
            dataIndex: "id",
            align: "center" as "center",
            width: "350px",
            render: (_, record) => (
                <Button size="small" onClick={() => showEanData(record.code)} type={record.showEanDataLoading ? "dashed" : "default"} danger={record.showEanDataLoading}>
                    Show Products
                </Button>
            ),
        },
        {
            title: "",
            dataIndex: "ean",
            align: "center" as "center",
            width: "350px",
            render: (_, record) => (
                <Button onClick={() => removeEan(record.code)} size="small" type="primary" danger loading={record.delete_loading}>
                    Remove EAN from all products
                </Button>
            ),
        },
    ];

    const onPaginateChange = (e: number) => {
        setPage(e);
    };

    const changeCategory = (e: string) => {
        setSelectedCategory(e);
        setPage(1);
    };

    const removeEan = async (ean: string) => {
        setEanList((curr) => curr.map((e) => (e.code === ean ? { ...e, delete_loading: true } : e)));
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const codeQuery = `?code=${ean}`;

            const url = `${baseUrl}/product/ean/delete${codeQuery}`;
            const { data } = await axios.delete(url);
            setEanList((curr) => curr.filter((e) => e.code !== ean));
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let delayTimer: any;
    const handleSearchChange = (value: string) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setSearch(value);
            setPage(1);
        }, 1000);
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Ean Cleaning" />
            <Col span={12} className="automatched-prices-box" style={{ minHeight: "90vh", marginBottom: "2rem" }}>
                <Divider>Ean Cleaning</Divider>
                <Row justify="space-between" align="middle" className="ean-cleaning-box">
                    <div className="ean-cleaning-filter-box">
                        <Select
                            style={{ width: "360px", marginTop: "10px" }}
                            showSearch
                            allowClear
                            placeholder="Select Category"
                            filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={changeCategory}
                        >
                            {categories.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                    {`${c.id}: ${c[`name_${currentCountry.name}`]}`}
                                </Select.Option>
                            ))}
                        </Select>
                        <Input placeholder="Search by Ean" style={{ width: "360px", marginTop: "10px" }} onChange={(e) => handleSearchChange(e.target.value)} allowClear />
                    </div>
                    <Card className="ean-cleaning-card-statistic">
                        <Statistic title="Total" value={count !== null ? numberWithCommas(count) : ""} valueStyle={{ color: "green" }} loading={countLoading} />
                    </Card>
                </Row>
                <EanModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} eanData={eanData} setEanData={setEanData} />
                <Layout style={{ margin: "0.6rem 1rem" }}>
                    <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px", position: "relative" }} />
                    <Table
                        columns={columns}
                        dataSource={eanList}
                        bordered
                        rowKey={(record) => record.code}
                        loading={loading}
                        rowClassName={(record) => (record.delete_loading ? "ean-cleaning-row-delete" : "")}
                        pagination={{
                            position: ["topRight"],
                            pageSize: 100,
                            current: page,
                            showSizeChanger: false,
                            total: count ? count : undefined,
                            onChange: onPaginateChange,
                            size: "small",
                        }}
                    />
                </Layout>
            </Col>
        </Row>
    );
};

export default EanCleaning;
