import axios from "axios";
const baseUrl = process.env.REACT_APP_URL_API;

export const getCategoryL3L4 = async () => {
    const { data } = await axios.get(`${baseUrl}/product/category/list-l34`);

    for (var i = 0; i < data.data.length; i++) {
        data.data[i]["key"] = data.data[i].id + ".category.l3.l4";
        data.data[i]["label"] = data.data[i].name.split(":")[1].trim();
        data.data[i]["value"] = data.data[i].id;
        delete data.data[i].name;
        delete data.data[i].id;
    }

    return data;
};

export type TreeSelectCategoryType = {
    id: number;
    l2_name: string;
    children: { [key: string]: string | number }[];
    [key: string]: string | number | { [key: string]: string | number }[];
};

export const getAllCategories = async (country_id: number, currentCountryName: string): Promise<TreeSelectCategoryType[]> => {
    const { data } = await axios.get<{ data: TreeSelectCategoryType[] }>(`${process.env.REACT_APP_URL_API}/product/category/list-l3?country_id=${country_id}`);
    return data.data.sort((a, b) =>
        a[`name_${currentCountryName}` as keyof TreeSelectCategoryType] > b[`name_${currentCountryName}` as keyof TreeSelectCategoryType]
            ? 1
            : b[`name_${currentCountryName}` as keyof TreeSelectCategoryType] > a[`name_${currentCountryName}` as keyof TreeSelectCategoryType]
            ? -1
            : 0
    );
};
