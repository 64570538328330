import { Button, Col, Divider, Row, Select, Statistic, Switch } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import showMessage from "../../../shared/MessagesInfo/message";
import FaqForm from "../components/FAQ/FaqForm";
import "../style/FAQ.css";
import { useAuth } from "../../../shared/Context/AuthContext";
import { CountryType } from "../../../shared/Context/type";

export type FAQItemType = {
    id?: number | null;
    isLoading: boolean;
    isLoadingDelete: boolean;
    isNewFaq: boolean;
    date: Date;
    [key: string]: string | number | boolean | null | undefined | Date;
};

type CategoryType = {
    count: number;
    id: number;
    name: string;
    missing_faq_count: number;
};

const FAQ = () => {
    const { currentCountry } = useAuth();
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [faqItems, setFaqItems] = useState<FAQItemType[]>([]);
    const [isFaqLoading, setIsFaqLoading] = useState(false);
    const [faqCount, setFaqCount] = useState<{ loading: boolean; count: number | null }>({ loading: true, count: null });
    const [missingQuestions, setMissingQuestions] = useState(false);
    const [itemToSave, setItemToSave] = useState<{ id: number; country: string } | null>(null);
    const [callApi, setCallApi] = useState(1);

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const createItemObj: FAQItemType = { id: null, date: new Date(), isLoading: false, isLoadingDelete: false, isNewFaq: false }; //create new obj to add on list
    localStorageCountries
        .map((c) => c.locale_code)
        .forEach((c) => {
            createItemObj[`answer_${c}`] = null;
            createItemObj[`question_${c}`] = null;
            createItemObj[`btn_name_${c}`] = null;
            createItemObj[`btn_link_${c}`] = null;
        });

    useEffect(() => {
        const getCategories = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-l3-faq?lang=${currentCountry.name}`);
                //const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/faq/list?category_l3_id=69&lang=rs`)
                setCategories(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        const getCountFaq = async () => {
            //setFaqCount(curr => ({...curr,loading: true}))
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/faq/count?lang=${currentCountry.name}`);
                setFaqCount({ loading: false, count: data.data });
            } catch (err) {
                console.log(err);
            }
        };
        getCategories();
        getCountFaq();
    }, [currentCountry.name, callApi]);

    useEffect(() => {
        const getFaqByCategory = async () => {
            setIsFaqLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/faq/list?category_l3_id=${selectedCategory}&lang=${currentCountry.name}${missingQuestions ? "&missing=true" : ""}`);
                //setSelectedCategory(e)
                const newData: FAQItemType[] = data.data.map((d: FAQItemType) => ({ ...d, isLoading: false, isLoadingDelete: false, isNewFaq: false }));
                setFaqItems(
                    newData.sort((a, b) => {
                        if (new Date(a.date).toISOString() < new Date(b.date).toISOString()) return -1;
                        if (new Date(b.date).toISOString() < new Date(a.date).toISOString()) return 1;
                        return 0;
                    })
                );
                setIsFaqLoading(false);
            } catch (err) {
                setIsFaqLoading(false);
                console.log(err);
            }
        };

        selectedCategory && getFaqByCategory();
    }, [selectedCategory, currentCountry.name, missingQuestions, callApi]);

    const addNewItem = () => {
        !faqItems.find((f) => f.id === null) &&
            setFaqItems((curr) => [
                ...curr,
                {
                    id: null,
                    date: new Date(),
                    isLoading: false,
                    isLoadingDelete: false,
                    isNewFaq: true,
                },
            ]);
    };

    const onFinish = async (values: FAQItemType) => {
        const id = values.id;
        setItemToSave({ id: values.id as number, country: values.country as string });
        if (!values.id) {
            values = { ...values, category_l3_id: selectedCategory };
            values.id === null && delete values["id"];
        }
        delete values["country"];
        setFaqItems((curr) => curr.map((f) => (f.id === id ? { ...f, ...values, isLoading: true } : f)));
        try {
            const { data } = await axios[values.id ? "put" : "post"](`${process.env.REACT_APP_URL_API}${values.id ? "/faq/update" : "/faq/add"}`, values);
            showMessage(data.message);
            setFaqItems((curr) => curr.map((f) => (f.id === id ? { ...f, isLoading: false } : f)));
            if (id) {
                //-------------- check this ----------------
                setFaqItems((curr) => curr.map((f) => (f.id === id ? { ...f, ...values } : f)));
            } else {
                setFaqItems((curr) => curr.map((f) => ({ ...f, isLoading: false, ...(!id && f.id === id && { ...createItemObj, ...data.data }) })));
            } //-------------- check this ----------------
            //!id && setCategories( curr => curr.map( c => c.id === selectedCategory ? ({...c, count:c.count + 1 }):c))
            setItemToSave(null);
            // if (country === currentCountryName) {
            setCallApi((curr) => curr + 1);

            // }
        } catch (err) {
            console.log(err);
            setItemToSave(null);
        }
    };

    const deleteItemFaq = async (id: number | null | undefined) => {
        if (id) {
            setFaqItems((curr) => curr.map((f) => ({ ...f, isLoadingDelete: f.id === id ? true : false })));
            try {
                const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/faq/delete?id=${id}`);
                showMessage(data.message);
                setFaqItems((curr) => curr.filter((f) => f.id !== id));
                //setCategories( curr => curr.map( c => c.id === selectedCategory ? ({...c, count:c.count - 1 }):c))
                setCallApi((curr) => curr + 1);
            } catch (err) {
                console.log(err);
            }
        } else {
            setFaqItems((curr) => curr.filter((f) => f.id));
        }
    };

    const deleteOneFaq = async (values: FAQItemType, country: string) => {
        setItemToSave({ id: values.id as number, country: country as string });
        setFaqItems((curr) => curr.map((f) => (f.id === values.id ? { ...f, isLoadingDelete: true } : f)));
        const obj = {
            id: values.id,
            [`answer_${country}`]: null,
            [`question_${country}`]: null,
            [`btn_name_${country}`]: null,
            [`btn_link_${country}`]: null,
        };
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/faq/update`, obj);
            showMessage(data.message);
            setFaqItems((curr) => curr.map((f) => (f.id === values.id ? { ...f, ...obj, isLoadingDelete: false } : f)));

            setItemToSave(null);
            // if ( country === currentCountryName ) {
            setCallApi((curr) => curr + 1);
            // }
        } catch (err) {
            console.log(err);
            setItemToSave(null);
        }
    };

    const removeCategory = () => {
        setSelectedCategory(null);
        setFaqItems([]);
    };

    return (
        <Row justify="center" style={{ backgroundColor: "white", paddingBottom: "2rem" }}>
            <Divider>FAQ</Divider>
            <div style={{ backgroundColor: "whitesmoke", width: "100%", margin: "1rem", border: "1px solid lightgray", height: "70px" }}>
                <Row justify={"center"} align="middle">
                    <Col span={14} style={{ display: "flex", justifyContent: "end" }}>
                        <Select
                            style={{ width: "400px" }}
                            showSearch
                            allowClear
                            placeholder="Select Category"
                            onChange={(e) => setSelectedCategory(e)}
                            key={"category_selected"}
                            value={selectedCategory}
                            onClear={() => removeCategory()}
                            filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {categories
                                .sort((a, b) => (!missingQuestions ? b.count - a.count : b.missing_faq_count - a.missing_faq_count))
                                .filter((c) => (!missingQuestions ? true : c.missing_faq_count > 0))
                                .map((category) => (
                                    <Select.Option value={category.id} key={"category_seller_" + category.id}>
                                        {`${category.id}: ${category.name} - ${category.count}${missingQuestions ? `  (${category.missing_faq_count ? category.missing_faq_count : 0})` : ""}`}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Col>
                    <Col span={7}>
                        <Switch
                            defaultChecked
                            checkedChildren="Missing Questions"
                            unCheckedChildren="All Questions"
                            style={{ alignSelf: "center", marginLeft: "2rem" }}
                            checked={missingQuestions}
                            onChange={(e) => setMissingQuestions(e)}
                        />
                    </Col>
                    <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                        <Statistic title="All Questions" value={faqCount.count ? faqCount.count : 0} loading={faqCount.loading} />
                    </Col>
                </Row>
            </div>
            {faqItems.map((formData, index) => (
                <Col span={24} key={index} style={{ marginTop: "0.5rem" }}>
                    <Row
                        style={{
                            backgroundColor: formData.isNewFaq ? "#dee1ec" : "white" /*"#fafafa"*/,
                            border: `1px solid ${formData.isLoadingDelete && !itemToSave ? "#ff7875" : formData.isNewFaq ? "#ff7875" : "white"}`,
                            borderRadius: "8px",
                            margin: "1rem",
                            paddingRight: "2rem",
                        }}
                    >
                        {formData.isNewFaq && <div className="new-faq-item">New </div>}
                        {localStorageCountries.map((c, i) => (
                            <Col span={8} style={{ padding: "1rem 0.5rem 1rem 0.5rem", marginTop: formData.isNewFaq ? "1.5rem" : "" }} key={`${formData.id}${c.locale_code}`}>
                                <FaqForm c={c.locale_code} formData={formData} index={index} onFinish={onFinish} deleteOneFaq={deleteOneFaq} itemToSave={itemToSave} />
                            </Col>
                        ))}
                        <div style={{ position: "absolute", top: "350px", right: "1.5rem" }}>
                            <Button icon={<DeleteOutlined />} size="small" onClick={() => deleteItemFaq(formData.id)} danger loading={formData.isLoadingDelete && !itemToSave} />
                        </div>
                    </Row>
                </Col>
            ))}
            {selectedCategory && !isFaqLoading && (
                <Button icon={<PlusOutlined />} block type="primary" size="large" style={{ marginTop: 20, marginLeft: "2rem", marginRight: "2rem", marginBottom: "2rem" }} onClick={() => addNewItem()}>
                    Add
                </Button>
            )}
        </Row>
    );
};

export default FAQ;
