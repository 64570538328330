import React from "react";
import "../../style/UserAssignmentsSchedule.css";

const showTextArr = (data: any) => {
    //console.log(data)
    let text = "";
    data.forEach((item: string) => {
        text += `${item} / `;
    });
    return text; /*.slice(0,-2)*/
};

interface TableDataItemInterface {
    data: any;
    firstItem?: boolean;
    title: string;
    isStatus: boolean;
}

const TableDataItem: React.FC<TableDataItemInterface> = (props) => <span style={{ fontWeight: props.isStatus ? "bold" : "" }}>{props.data.length !== 0 && showTextArr(props.data)}</span>;

export default TableDataItem;
