import React, { useContext, useMemo, useState } from "react";
import { DeleteTwoTone, EditOutlined, HolderOutlined, PlusOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Alert, Button, Row, Table, Form, Popconfirm, Spin } from "antd";
import type { ColumnsType } from "antd/es/table";
import AttributeGroupModal from "./AttributeGroupModal";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { GroupType } from "../../pages/AttributeOverview";

export interface DataType {
    [key: string]: string | number;
    id: number;
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: "move" }} ref={setActivatorNodeRef} {...listeners} />;
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}

const RowTable: React.FC<RowProps> = (props) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
        id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(() => ({ setActivatorNodeRef, listeners }), [setActivatorNodeRef, listeners]);

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

interface AttributeGroupTestProps {
    selectedCategory: {
        id: number;
        name: string;
    } | null;
    currentCountryName: string;
    selectedGroup: number | undefined;
    setSelectedGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
    groups: GroupType[];
    setGroups: React.Dispatch<React.SetStateAction<GroupType[]>>;
    loadingGroup: boolean;
}

const AttributeGroup = (props: AttributeGroupTestProps) => {
    const [isCreateGroup, setIsCreateGroup] = useState(false);
    const [isDrug, setIsDrug] = useState(false);
    const [form] = Form.useForm();

    const { selectedCategory, currentCountryName, selectedGroup, setSelectedGroup, groups, setGroups, loadingGroup } = props;

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setIsDrug(true);
            setGroups((prevState) => {
                const activeIndex = prevState.findIndex((record) => record.key === active?.id);
                const overIndex = prevState.findIndex((record) => record.key === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    };

    // const selectGroup = async (id: number) => {
    //     // chech if this api has to be deleted !!!
    //     try {
    //         await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-attribute-groups-by-l3?l3_id=${selectedCategory?.id}`);
    //         setSelectedGroup(id);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    const deleteGropup = async (id: number) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-attribute-group?group_id=${id}`);
            setGroups((curr) => curr.filter((d) => d.id !== id));
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    const editGroup = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number, group: string | number) => {
        e?.stopPropagation();
        form.setFieldValue("group_name", group);
        form.setFieldsValue(groups.find((g) => g.id === id));
        setIsCreateGroup(true);
    };

    const saveNewOrder = async () => {
        const obj = groups
            .filter((c) => c.id !== 0)
            .map((g) => {
                return {
                    group_id: g.id,
                    order: groups.findIndex((d) => d.id === g.id) + 1,
                };
            });
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/update-group-order`, obj);
            showMessage(data.message);
            setIsDrug(false);
        } catch (err) {
            console.log(err);
        }
    };

    const columns: ColumnsType<DataType> = [
        {
            // title: isDrug ? <Button size="small">Save Order</Button> : "",
            key: "sort",
            align: "center",
            width: 50,
            render: (_, record) => (record.id === -1 ? null : <DragHandle />),
        },
        {
            title: (
                <>
                    <Row justify="space-between" align="middle">
                        Groups
                        {isDrug ? (
                            <Button size="small" onClick={() => saveNewOrder()}>
                                Save Order
                            </Button>
                        ) : (
                            ""
                        )}
                    </Row>
                </>
            ),
            dataIndex: `name_${currentCountryName}`,
            render: (text, record, index) => (
                <Alert
                    type={selectedGroup === record.id ? "success" : "info"}
                    message={<span style={{ fontWeight: record.id === -1 ? 600 : "normal" }}>{`${text} ${index !== 0 ? `(${record.attribute_count})` : ""}`}</span>}
                    style={{ cursor: "pointer", margin: "0 0.1rem" }}
                    onClick={() => /*selectGroup(record.id)*/ setSelectedGroup(record.id)}
                    action={
                        record.id !== 0 &&
                        record.id !== -1 && (
                            <Row>
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => {
                                        deleteGropup(record.id);
                                    }}
                                >
                                    <DeleteTwoTone style={{ fontSize: "16px", cursor: "pointer" }} onClick={(e) => e?.stopPropagation()} />
                                </Popconfirm>
                                <EditOutlined style={{ fontSize: "16px", marginLeft: "10px", cursor: "pointer" }} onClick={(e) => editGroup(e, record.id, record.group)} />
                            </Row>
                        )
                    }
                />
            ),
        },
    ];

    return (
        <div>
            <AttributeGroupModal isCreateGroup={isCreateGroup} setIsCreateGroup={setIsCreateGroup} setGroups={setGroups} form={form} selectedCategory={selectedCategory} />
            <div className="attribute-overview-group-box tiny-scroll">
                {groups.length > 0 ? (
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext items={groups.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                            <Table
                                rowKey="key"
                                components={{ body: { row: RowTable } }}
                                columns={columns}
                                dataSource={groups}
                                pagination={false}
                                className="attribute-group-table"
                                loading={loadingGroup}
                            />
                        </SortableContext>
                    </DndContext>
                ) : (
                    <div style={{ marginTop: "8rem", textAlign: "center" }}>{loadingGroup && <Spin />}</div>
                )}
                {selectedCategory && !loadingGroup && (
                    <Row justify="center">
                        <Button style={{ marginTop: "2rem" }} size="small" onClick={() => setIsCreateGroup((curr) => !curr)} shape="round" icon={<PlusOutlined />}>
                            Create Group
                        </Button>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default AttributeGroup;
