import { Button, Form, Modal, Row, Spin, Switch, TreeSelect } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import DescriptionEditor from "./DescriptionEditor";
import { CategoryArrType } from "../../pages/AllBrands";
import { CountryType } from "../../../../shared/Context/type";
const { TreeNode } = TreeSelect;

interface PrevTextProps {
    descriptionBrand: { id: number; name: string } | null;
    setDescriptionBrand: React.Dispatch<React.SetStateAction<{ id: number; name: string } | null>>;
    setDescriptionVisible: React.Dispatch<React.SetStateAction<boolean>>;
    descriptionVisible: boolean;
    currentCountryName: string;
    currentCountryId: number;
    categories: CategoryArrType[];
    getCategories: () => Promise<void>;
    setShowAllDescription: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedCategory: React.Dispatch<React.SetStateAction<{ id: number; name: string } | null>>;
    selectedCategory: { id: number; name: string } | null;
}

export type BrandDescStateType = {
    country: string;
    [key: string]: string;
};

const AllBrandsDescription: React.FC<PrevTextProps> = ({
    descriptionBrand,
    setDescriptionBrand,
    setDescriptionVisible,
    descriptionVisible,
    categories,
    getCategories,
    setShowAllDescription,
    setSelectedCategory,
    selectedCategory,
}) => {
    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];
    const [loading, setLoading] = useState(false);
    const initDescState = localStorageCountries.map((c) => ({ country: c.name, [`title_${c.name}`]: "", [`h1_${c.name}`]: "", [`description_${c.name}`]: "" }));
    const [brandDescriptionState, setBrandDescriptionState] = useState<BrandDescStateType[]>(initDescState);
    const [saveDescriptionLoading, setSaveDescriptionLoading] = useState(false);
    const [clearTextEditor, setClearTextEditor] = useState(1);
    const [form] = Form.useForm();
    const [openEditor, setOpenEditor] = useState<string | undefined>();

    const checkDescTexts = useCallback(
        async (id: number) => {
            setLoading(true);
            setClearTextEditor((curr) => curr + 1);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/show-description?brand_id=${id}${selectedCategory ? `&category_l3_id=${selectedCategory.id}` : ""}`);
                const newData = (JSON.parse(localStorage.getItem("countries")!) as CountryType[]).map((c) => ({
                    country: c.name,
                    [`title_${c.name}`]: data.data ? data.data[`title_${c.name}`] : null,
                    [`h1_${c.name}`]: data.data ? data.data[`h1_${c.name}`] : null,
                    [`description_${c.name}`]: data.data ? data.data[`description_${c.name}`] : null,
                }));
                setBrandDescriptionState(newData);
                setLoading(false);
                setDescriptionVisible(true);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        },
        [setDescriptionVisible, selectedCategory]
    );

    useEffect(() => {
        descriptionBrand?.id && checkDescTexts(descriptionBrand?.id);
    }, [descriptionBrand, checkDescTexts]);

    const closeDesc = () => {
        setDescriptionVisible(false);
        setDescriptionBrand(null);
        setSelectedCategory(null);
    };

    const saveDescription = async (clear?: boolean, clearCountry?: string) => {
        let tempObj: Record<string, string> = brandDescriptionState.reduce((acc: Record<string, string>, obj) => {
            return { ...acc, ...obj };
        }, {});

        tempObj && delete tempObj["country"];

        const objectToSend = {
            id: descriptionBrand?.id,
            category_l3_id: selectedCategory?.id,
            ...(!clearCountry ? { ...tempObj } : { [`description_${clearCountry}`]: null, [`title_${clearCountry}`]: null, [`h1_${clearCountry}`]: null }),
        };
        !clear && setSaveDescriptionLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, objectToSend);
            !clear && setSaveDescriptionLoading(false);
            getCategories();
            showMessage(!clear ? data.message : `Description ${clearCountry?.toUpperCase()} deleted`);

            setOpenEditor(undefined);
        } catch (err) {
            console.log(err);
            !clear && setSaveDescriptionLoading(false);
        }
    };

    const handleSwitchChange = (e: boolean) => {
        setShowAllDescription(e);
    };

    const onCategoryChange = (e: number) => {
        const findCateogry = categories.find((c) => c.id === e)?.name as string;

        const onlyL4 = categories.filter((c) => c.children.length > 0).map((c) => c.children);
        let emptyArr: { name: string; id: number; brand_description_flag: boolean }[] = [];
        const flattenedArray = emptyArr.concat(...onlyL4);
        const findL4Category = flattenedArray.find((c) => c.id === e)?.name || "";

        if (e) {
            setSelectedCategory({
                id: e,
                name: findCateogry ? findCateogry : findL4Category,
            });
        } else {
            setSelectedCategory(null);
        }
    };

    return (
        <Modal
            title={`${descriptionBrand?.name}${selectedCategory ? ` & ${selectedCategory.name}` : ""}`}
            centered
            open={descriptionVisible}
            onCancel={() => {
                closeDesc();
                form.resetFields();
            }}
            width="100%"
            footer={false}
            style={{ height: "100vh", paddingBottom: "3rem", marginTop: "4rem" }}
        >
            <div style={{ border: "1px solid lightgray", borderRadius: "8px", padding: "1rem", backgroundColor: "whitesmoke" }}>
                <Row justify="center" align="middle">
                    <div style={{ width: "50px" }}>{loading && <Spin style={{ marginLeft: "1rem" }} />}</div>
                    <div style={{ width: "500px" }}>
                        <TreeSelect
                            showSearch
                            treeDataSimpleMode
                            placeholder="Select Category"
                            allowClear
                            style={{
                                width: "100%",
                            }}
                            value={selectedCategory?.id}
                            onChange={onCategoryChange}
                            filterTreeNode={(search: string, item: any) => item.title.props?.children[0]?.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                        >
                            {categories
                                .sort((a, b) => (a.brand_description_flag === b.brand_description_flag ? 0 : a.brand_description_flag ? -1 : 1))
                                .map((category: CategoryArrType) =>
                                    category.children.length === 0 ? (
                                        <TreeNode
                                            key={category.id}
                                            value={category.id}
                                            title={
                                                <div style={{ fontWeight: category.brand_description_flag ? "bold" : "" }}>
                                                    {`${category.id}: ${category.name}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}> ({category.name}) </span>
                                                </div>
                                            }
                                            st
                                        />
                                    ) : (
                                        <TreeNode
                                            key={category.id}
                                            value={category.id}
                                            title={
                                                <div style={{ fontWeight: category.brand_description_flag ? "bold" : "" }}>
                                                    {`${category.id}: ${category.name}`} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}> ({category.name}) </span>
                                                </div>
                                            }
                                        >
                                            {typeof category.children !== "string" &&
                                                category.children.map((itemChild) => (
                                                    <TreeNode
                                                        key={itemChild.id}
                                                        value={itemChild.id}
                                                        title={<div style={{ fontWeight: itemChild.brand_description_flag ? "bold" : "" }}>{`${itemChild.id}: ${itemChild.name} `} </div>}
                                                    />
                                                ))}
                                        </TreeNode>
                                    )
                                )}
                        </TreeSelect>
                    </div>
                    <Switch checkedChildren="All" unCheckedChildren="Missing" defaultChecked style={{ marginLeft: "1rem", alignSelf: "start", marginTop: "0.3rem" }} onChange={handleSwitchChange} />
                </Row>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {localStorageCountries.map((c) => (
                        <DescriptionEditor
                            country={c.name}
                            loading={loading}
                            brandDescriptionState={brandDescriptionState}
                            setBrandDescriptionState={setBrandDescriptionState}
                            key={c.name}
                            clearTextEditor={clearTextEditor}
                            saveDescription={saveDescription}
                            form={form}
                            openEditor={openEditor}
                            setOpenEditor={setOpenEditor}
                        />
                    ))}
                </div>
                <Button type="primary" style={{ marginTop: "1rem" }} onClick={() => saveDescription()} loading={saveDescriptionLoading}>
                    Save Description
                </Button>
            </div>
        </Modal>
    );
};

export default AllBrandsDescription;
