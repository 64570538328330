import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import openNotification from "../../../../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import { FormInstance } from "antd/lib/form/Form";
import { PicturesType } from "../../../../types/type";
import { CountryType } from "../../../../../../shared/Context/type";

interface ReviewFormProps {
    form: FormInstance<any>;
    productInfo: { name: string; value: string | PicturesType[] }[];
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setReview: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
    youtube_url: any;
}

const ReviewForm = (props: ReviewFormProps) => {
    const [loadingReviewText, setLoadingReviewText] = useState(false);

    const { form, productInfo, loading, setLoading, setReview, youtube_url } = props;

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const setYouTubeUrl = (e: string) => {
        if (e.includes("?v=")) {
            if (typeof e === "string" && e.length > 0) {
                const textArr = e.split("?v=");
                form.setFieldValue(`youtube_url`, textArr[1]);
            }
        } else {
            form.setFieldValue(`youtube_url`, e);
        }
    };

    const showShortCuntryCode = (dataToState: any, youtubeUrl: string, youtubeLength: string) => {
        const urlArr = youtubeLength.split(":");
        const urlToSeconds = Number(urlArr[0]) * 60 + Number(urlArr[1]); // ...(someCondition && {b: 5})
        const newArr = localStorageCountries
            .map((c) =>
                dataToState[`${c.theebox_locale_code}`]
                    ? {
                          [`review_content_${c.locale_code}`]: dataToState[`${c.theebox_locale_code}`],
                          [`review_title_${c.locale_code}`]: `${productInfo.find((p) => p.name === "brand")?.value}${
                              productInfo.find((p) => p.name === "mpn")?.value ? " " + productInfo.find((p) => p.name === "mpn")?.value : ""
                          } video test`,
                          [`youtube_url_${c.locale_code}`]: youtubeUrl,
                          [`youtube_length_${c.locale_code}`]: urlToSeconds,
                      }
                    : null
            )
            .filter((c) => c);
        var combinedObject: any = {};

        newArr.forEach((item: any) => {
            Object.keys(item).forEach((key) => {
                if (!combinedObject[key]) {
                    combinedObject[key] = "";
                }
                combinedObject[key] += item[key];
            });
        });
        combinedObject[`review_content_en`] = dataToState[`en`];

        return combinedObject;
    };

    const getReviewText = async () => {
        setLoadingReviewText(true);
        const youtubeLength = form.getFieldValue(`youtube_length`);

        if (!youtubeLength || !youtube_url) {
            openNotification("Youtube url and Youtube lenght are required!");
            setLoadingReviewText(false);
            setLoading(false);
            return;
        }

        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/reviews/summary?youtubeId=${youtube_url}`);
            if (!data.success) {
                openNotification(`This video doesn't have review text`);
                setLoadingReviewText(false);
                setLoading(false);
                return;
            }
            const dataObj = data.data;
            setReview(() => ({
                ...showShortCuntryCode(dataObj, youtube_url, youtubeLength),
            }));
            setLoadingReviewText(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Form form={form} className="product-review-form" labelCol={{ span: 7 }}>
            <Form.Item label="Title" name="title">
                <Input
                    style={{
                        border: `1px solid ${loading ? "#00bbf0" : "transparent"}`,
                    }}
                    placeholder="Title"
                />
            </Form.Item>
            <Form.Item label="Youtube Url" name="youtube_url">
                <Input
                    onChange={(e) => setYouTubeUrl(e.target.value)}
                    style={{
                        border: `1px solid ${loading ? "#00bbf0" : "transparent"}`,
                    }}
                    placeholder="Youtube Url"
                />
            </Form.Item>
            <Form.Item label="Text">
                <Button size="small" onClick={getReviewText} loading={loadingReviewText}>
                    Get review text
                </Button>
            </Form.Item>
            <Form.Item label="YouTube Length" name="youtube_length">
                <Input
                    style={{
                        width: "80px",
                        border: `1px solid ${loading ? "#00bbf0" : "transparent"}`,
                    }}
                    placeholder="Length"
                />
            </Form.Item>
        </Form>
    );
};

export default ReviewForm;
