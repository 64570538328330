import { Button, Col, Modal, Popconfirm, Row, Select, Statistic, Table, Form } from "antd";
import axios from "axios";
import { useState } from "react";
import CategorySelect from "../../../shared/FormElements/CategorySelect";
import showMessage from "../../../shared/MessagesInfo/message";
import "../style/ApplyToAll.css";
import { ShopType } from "../types/AllPricesTypes";
import { TreeSelectCategoryType } from "../../../shared/Api/category";

interface ApplyToAllModalProps {
    applyToAll: boolean;
    setApplyToAll: React.Dispatch<React.SetStateAction<boolean>>;
    selectedShops: string;
    noCategory: boolean;
    searchField: string;
    sortArr: string | null;
    fillSelectSC: never[];
    existInXml: boolean;
    ourCateogry: any;
    condition: string;
    sfActive: number;
    unMatched: number;
    userFilter: boolean;
    currentPage: number;
    brandList: { id: number; name: string }[];
    shopList: ShopType[][];
    dataLength: string;
    setSameParam: React.Dispatch<React.SetStateAction<number>>;
    categories: TreeSelectCategoryType[];
    currentCountryName: string;
    currentCountryId: number;
}

const objToArr = (props: ApplyToAllModalProps) => {
    const keyValueArr: { name: string; value: string }[] = [];
    for (let el in props) {
        if (el !== "applyToAll" && el !== "setApplyToAll" && el !== "brandList" && el !== "currentPage" && el !== "state" && el !== "shopList" && el !== "dataLength" && el !== "setSameParam") {
            keyValueArr.push({ name: el, value: props[el as keyof typeof props] });
        }
    }
    return keyValueArr;
};

const ApplyToAllModal: React.FC<ApplyToAllModalProps> = (props) => {
    const [form] = Form.useForm();
    const [selectedCategory, setSelectedCateogry] = useState<{ id: number; name: string } | null>(null);
    const [selectedBrand, setSelectBrand] = useState<{ id: number; name: string } | null>(null);

    const showData = (record: { name: string; value: string | string[] | number | boolean }) => {
        if (typeof record.value === "boolean") {
            return record.value ? "YES" : "NO";
        }
        if (record.name === "sfActive" /*|| record.name === 'unMatched' */) {
            return record.value === 1 ? "Yes" : "NO";
        }
        if (record.name === "selectedShops") {
            let shops_list = "";
            Array.isArray(record.value) &&
                record.value.forEach((shopId, i) => {
                    const shops = [...props.shopList[0], ...props.shopList[0]];
                    const findShop = shops.find((s) => s.id === Number(shopId));
                    shops_list += findShop?.name + `${Array.isArray(record.value) && i < record.value.length - 1 ? " | " : " "} `;
                });
            return shops_list;
        }
        if (record.name === "fillSelectSC") {
            let shops_list = "";
            Array.isArray(record.value) && record.value.forEach((shop, i) => (shops_list += shop + `${Array.isArray(record.value) && i < record.value.length - 1 ? " | " : " "} `));
            return shops_list;
        }
        if (record.name === "ourCateogry") {
            return props.ourCateogry ? `l${props.ourCateogry.level}_id=${props.ourCateogry.id}` : "";
        }
        if (record.name === "currentCountryName") {
            return record.value.toString().toUpperCase();
        }
        if (record.name === "condition") {
            const type = {
                0: "ALL",
                1: "NEW",
                2: "REFURBISHED",
                3: "USED",
            };
            return type[record.value as keyof typeof type];
        }
        if (record.name === "unMatched") {
            const type = {
                0: "ALL",
                1: "AUTO INSERTED",
            };
            return !props.noCategory ? type[record.value as keyof typeof type] : "";
        }
        return record.value;
    };

    const showName = (name: string) => {
        switch (name) {
            case "selectedShops":
                return "Selected Shops";
            case "noCategory":
                return "Uncategorized"; //switch for automatced
            case "searchField":
                return "Search Field";
            case "fillSelectSC":
                return "Seller Categories";
            case "currentCountryName":
                return "Country";
            // case 'uncategorized':
            //     return 'uncategorized'
            case "existInXml":
                return "Exist In Xml";
            case "ourCateogry":
                return "Our Cateogry";
            case "sfActive":
                return "Salesforce Active";
            case "unMatched":
                return "Unmatched";
            case "userFilter":
                return "User Filter";
            case "sortArr":
                return "Sort";
            case "condition":
                return "Condition";
        }
        return name;
    };

    const selectSCToStirng = props.fillSelectSC ? JSON.stringify(props.fillSelectSC.map((d) => encodeURIComponent(d))) : [];

    const saveApplyToAll = async () => {
        const obj = `${process.env.REACT_APP_URL_API}/prices/check-all-prices?shop_ids=[${props.selectedShops}]&skus=${
            ""
            //JSON.stringify(props.skuTextArea)
        }&limit=100&page=${props.currentPage}&search=${props.searchField}${props.sortArr ? `&sort=${props.sortArr}` : ""}&seller_categories=${selectSCToStirng}&country_id=${props.currentCountryId}${
            props.existInXml ? "" : "&available=1"
        }&uncategorised=${
            !props.noCategory ? "" : 1 //props.uncategorized ? 1 :2
        }${props.ourCateogry && `&l${props.ourCateogry.level}_id=${props.ourCateogry.id}`}&condition=${props.condition}&user_filter=${props.userFilter ? 1 : 0}&sf_active=${props.sfActive}${
            !props.noCategory && props.unMatched === 1 ? `&unmatched=1` : ""
        }${selectedCategory ? `&update_category_l3_id=${selectedCategory.id}` : ""}${selectedBrand ? `&brand_id=${selectedBrand.id}` : ""}`;

        try {
            const { data } = await axios.put(obj);
            showMessage(data.message);
            setSelectBrand(null);
            setSelectedCateogry(null);
            form.resetFields();
            props.setApplyToAll(false);
            props.setSameParam((curr) => curr + 1);
        } catch (err) {
            console.log(err);
        }
    };

    // useEffect(() => {
    //     setCategories(
    //         props.state.categoriesL3.data &&
    //         props.state.categoriesL3.data.sort((a: CateogoryL3L4, b: CateogoryL3L4) =>
    //           a[`name_${currentCountryName}` as keyof CateogoryL3L4] >
    //           b[`name_${currentCountryName}` as keyof CateogoryL3L4]
    //             ? 1
    //             : b[`name_${currentCountryName}` as keyof CateogoryL3L4] >
    //               a[`name_${currentCountryName}` as keyof CateogoryL3L4]
    //             ? -1
    //             : 0
    //         )
    //     );
    //   }, [props.state.categoriesL3.data, currentCountryName]);
    const onCategoryChange = (e: number) => {
        if (e) {
            //console.log(state.categoriesL3.data)
            props.categories.forEach((category3: any) => {
                if (category3.id === e) {
                    setSelectedCateogry({
                        id: category3.id,
                        name: category3[`name_${props.currentCountryName}`],
                    });
                    return;
                }
                category3?.children.length > 0 &&
                    category3.children.forEach((categoryl4: any) => {
                        if (categoryl4.id === e) {
                            setSelectedCateogry({
                                id: categoryl4.id,
                                name: categoryl4[`name_${props.currentCountryName}`],
                            });
                            return;
                        }
                    });
            });
        } else {
            setSelectedCateogry(null);
        }
    };

    const handleBrandChange = (e: number) => {
        const findBrand = props.brandList.find((b) => b.id === e);
        findBrand &&
            setSelectBrand({
                id: findBrand?.id,
                name: findBrand?.name,
            });
    };

    //console.log(selectedBrand)

    const closeModal = () => {
        props.setApplyToAll(false);
        setSelectBrand(null);
        setSelectedCateogry(null);
        form.resetFields();
    };

    return (
        <Modal title="Apply to All" open={props.applyToAll} footer={false} onCancel={closeModal} width={1300}>
            <Row justify="center" style={{ border: "1px solid lightgray", padding: "1rem", borderRadius: "6px" }}>
                <Col span={12}>
                    <Table
                        style={{ width: "400px" }}
                        size="small"
                        columns={[
                            {
                                title: "Parameters",
                                dataIndex: "name",
                                render: (_, record) => <span>{showName(record.name)}</span>,
                            },
                            {
                                title: "Value",
                                dataIndex: "value",
                                render: (_, record) => <span>{typeof showData(record) !== "object" ? showData(record) : ""}</span>,
                            },
                        ]}
                        rowKey={(record) => record.name}
                        dataSource={objToArr(props)}
                        pagination={false}
                    />
                </Col>
                <Col span={8} style={{ border: "1px solid lightgray", padding: "1rem", borderRadius: "6px", backgroundColor: "#faf9f9" }}>
                    <Statistic title="Number of Prices" value={props.dataLength} style={{ backgroundColor: "white", width: "140px", padding: "0.2rem", borderRadius: "4px", marginBottom: "1rem" }} />
                    <Form form={form}>
                        <Form.Item label="Brand" name="brand_id">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "384px", marginBottom: "0.5rem" }}
                                placeholder="Select brand"
                                value={selectedBrand ? selectedBrand.id : null}
                                defaultActiveFirstOption
                                onClear={() => setSelectBrand(null)}
                                onSelect={handleBrandChange}
                                className="all-prices-column-brand"
                                filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {props.brandList &&
                                    props.brandList.map((brand: { id: number; name: string }) => (
                                        <Select.Option key={brand.id} value={brand.id} style={{ display: !brand.name ? "none" : "" }}>
                                            {brand.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Category" name="update_category_l3_id">
                            <CategorySelect categoryL3Options={props.categories} currentCountry={props.currentCountryName} onCategoryChange={onCategoryChange} style={{ width: "384px" }} allowClear />
                        </Form.Item>
                        <Popconfirm
                            title={
                                <span>
                                    Apply to <span style={{ color: "darkred" }}> {props.dataLength} </span>prices
                                </span>
                            }
                            //description={`Are you sure you want to change ${ `${selectedCategory?.name} category` }  `}
                            description={
                                <span>
                                    Are you sure you want to apply <span>{selectedCategory && "category "}</span>
                                    <span style={{ color: "darkred" }}>{selectedCategory && selectedCategory?.name}</span> <br />
                                    <span>{selectedCategory && selectedBrand && " and "}</span>
                                    <span>{selectedBrand && "brand "}</span>
                                    <span style={{ color: "darkred" }}>{selectedBrand && selectedBrand?.name}</span>
                                </span>
                            }
                            onConfirm={selectedCategory || selectedBrand ? saveApplyToAll : undefined}
                            disabled={!selectedCategory && !selectedBrand ? true : false}
                            //onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                //onClick={saveApplyToAll}
                                style={{ position: "absolute", bottom: "20px", right: "20px" }}
                                disabled={!selectedCategory && !selectedBrand ? true : false}
                            >
                                Apply
                            </Button>
                        </Popconfirm>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};

export default ApplyToAllModal;
