import { Badge, Button, Col, Divider, Row } from "antd";
import React, { useRef, useState } from "react";
import TextEditor from "./TextEditor";
import { CheckCircleOutlined } from "@ant-design/icons";
import showMessage from "../../../../shared/MessagesInfo/message";
import { DescriptionReviewType, ExpandRowDataType, FlagStatus, showStatus } from "../../pages/DescriptionReview";
import axios from "axios";
import { OpenEditorType } from "./ExpandedRowContent";
import { CountryType } from "../../../../shared/Context/type";

interface AllDescriptionsProps {
    record: DescriptionReviewType;
    expandRowData: ExpandRowDataType | null;
    setExpandRowData: React.Dispatch<React.SetStateAction<ExpandRowDataType | null>>;
    getCategories: () => Promise<void>;
    setDescRevData: React.Dispatch<React.SetStateAction<DescriptionReviewType[]>>;
    currentCountryName: string;
    openEditor: OpenEditorType;
    setOpenEditor: React.Dispatch<React.SetStateAction<OpenEditorType>>;
}
const AllDescriptions: React.FC<AllDescriptionsProps> = (props) => {
    const { record, expandRowData, setExpandRowData, getCategories, setDescRevData, currentCountryName, openEditor, setOpenEditor } = props;
    const [descriptionLoading, setDescriptionLoading] = useState(false);
    const [descriptionDeleteLoading, setDescriptionDeleteLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isDescDeleted, setIsDescDeleted] = useState(false);
    const [descriptionTextLoading, setDescriptionTextLoading] = useState(false);

    const containerRef = useRef(null);

    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];

    const saveNewDescription = async () => {
        const arrayDescCountry = localStorageCountries.map((c) => ({
            ...(expandRowData && {
                [`preview_text_${c.name}`]: expandRowData.preview[`preview_text_${c.name}`],
            }),
            ...(expandRowData && {
                [`flag_${c.name}`]: expandRowData.preview[`flag_${c.name}`],
            }),
        }));
        let tempObj: { [key: string]: string | number | null } = {};
        arrayDescCountry.forEach((obj) => {
            for (let key in obj) {
                tempObj[key] = obj[key];
            }
        });
        const objToSend = {
            id: record.id,
            ...tempObj,
        };
        setDescriptionLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
            showMessage(data.message);
            setDescriptionLoading(false);
            setIsSaved(true);
            getCategories();
            const newDescriptionFlag = objToSend[`flag_${currentCountryName}` as keyof typeof objToSend];
            setDescRevData((curr) => curr.map((d) => (d.id === record.id ? { ...d, description_flag: newDescriptionFlag } : d)));
            setOpenEditor(undefined);
        } catch (err) {
            console.log(err);
        }
    };
    const deleteDescripions = async () => {
        const arrayDescCountry = localStorageCountries.map((c) => ({
            ...(expandRowData && { [`preview_text_${c.name}`]: null }),
            ...(expandRowData && { [`flag_${c.name}`]: FlagStatus.NOT_CHANGED }),
        }));
        let tempObj: { [key: string]: string | number | null } = {};
        arrayDescCountry.forEach((obj) => {
            for (let key in obj) {
                tempObj[key] = obj[key];
            }
        });
        const objToSend = {
            id: record.id,
            ...tempObj,
        };
        setDescriptionDeleteLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
            setExpandRowData((curr) => (curr ? { ...curr, description: { ...curr.preview, ...objToSend } } : null));
            showMessage(data.message);
            setDescriptionDeleteLoading(false);
            setExpandRowData((curr) => (curr ? { ...curr, preview: { ...curr.preview, ...tempObj } } : null));
            setIsDescDeleted(true);
            setOpenEditor(undefined);
        } catch (err) {
            console.log(err);
        }
    };
    const clearDescription = (country: string) => {
        setExpandRowData((curr) =>
            curr
                ? {
                      ...curr,
                      preview: {
                          ...curr.preview,
                          [`preview_text_${country}`]: null,
                          [`flag_${country}`]: FlagStatus.NOT_CHANGED,
                      },
                  }
                : null
        );
    };

    const getDescriptionText = async () => {
        setDescriptionTextLoading(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/reviews/product-description?product_id=${record.id}`);
            const arrayDescCountry = localStorageCountries
                .map((c) => ({
                    [`preview_text_${[c.name]}`]: data[c.theebox_locale_code] ?? null,
                    [`flag_${c.name}`]: data[c.theebox_locale_code] ? FlagStatus.AI : FlagStatus.NOT_CHANGED,
                }))
                .filter((c) => c);
            let tempObj: { [key: string]: string } = {};
            arrayDescCountry.forEach((obj) => {
                for (let key in obj) {
                    tempObj[key] = obj[key];
                }
            });
            setExpandRowData((curr) => (curr ? { ...curr, preview: { ...curr.preview, ...tempObj } } : null));
            showMessage("Description is filled");
            setDescriptionTextLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const onOpenEditor = (countryName: string) => {
        setOpenEditor({ type: "description", country: countryName });
    };
    return (
        <Row
            justify="center"
            style={{
                border: "1px solid lightgray",
                borderRadius: "8px",
                marginTop: "1rem",
            }}
        >
            <Col span={24}>
                <Divider>Description</Divider>
                <Button style={{ marginLeft: "0.5rem" }} onClick={getDescriptionText} loading={descriptionTextLoading}>
                    Get Description Text
                </Button>
            </Col>
            {localStorageCountries.map((c, i) => (
                <Col span={6} style={{ padding: "1rem 0.5rem 1rem 0.5rem" }} key={`${c.locale_code}`}>
                    <div
                        style={{
                            backgroundColor: openEditor?.type === "description" && openEditor?.country === c.name ? "#D4EBF8" : "#EAF6F6",
                            border: `1px solid ${openEditor?.type === "description" && openEditor?.country === c.name ? "gray" : "lightblue"}`,
                            borderRadius: "8px",
                        }}
                    >
                        <div className="desc-rev-country-header" style={{ backgroundColor: openEditor?.type === "description" && openEditor?.country === c.name ? "#D4EBF8" : "" }}>
                            {c.name.toUpperCase()}
                        </div>
                        <Col span={24} style={{ border: "1px solid whitesmoke", borderRadius: "8px" }}>
                            <Row align="middle" justify="center" className="desc-rev-card">
                                <Badge
                                    status={typeof expandRowData?.preview[`flag_hr`] === "number" ? showStatus(Number(expandRowData?.preview[`flag_${c.name}`])) : undefined}
                                    style={{ margin: "0 0.5rem 0.5rem 0" }}
                                    className="descRev-badge-dot"
                                />
                                <h5 style={{ textAlign: "center", fontSize: "20px" }}>Description</h5>
                                <Col span={24}>
                                    {openEditor?.type === "description" && openEditor?.country === c.name ? (
                                        <TextEditor
                                            country={c.name}
                                            type="preview"
                                            expandRowData={expandRowData}
                                            setExpandRowData={setExpandRowData}
                                            containerRef={containerRef}
                                            // setOpenEditor={setOpenEditor}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <div className="desc-rev-open-editor-text">Click on text editor to open it.</div>
                                            {expandRowData ? (
                                                <div
                                                    onClick={() => onOpenEditor(c.name)}
                                                    className="desc-rev-rich-text"
                                                    dangerouslySetInnerHTML={{ __html: expandRowData.preview[`preview_text_${c.name}`] as string }}
                                                ></div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Col>
                                <Col
                                    span={24}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "1rem",
                                    }}
                                >
                                    <Button onClick={() => clearDescription(c.name)} danger style={{ width: "80px" }}>
                                        {`Clear ${c.name.toUpperCase()}`}
                                    </Button>
                                    {openEditor?.type === "description" && openEditor?.country === c.name && <Button onClick={() => setOpenEditor(undefined)}>Close editor</Button>}
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Col>
            ))}
            <Button style={{ margin: "0.5rem 1rem" }} type="primary" onClick={saveNewDescription} loading={descriptionLoading} icon={isSaved && <CheckCircleOutlined />}>
                Save Description
            </Button>
            <Button style={{ margin: "0.5rem 1rem" }} danger type="primary" onClick={deleteDescripions} loading={descriptionDeleteLoading} icon={isDescDeleted && <CheckCircleOutlined />}>
                Delete All Descriptions
            </Button>
        </Row>
    );
};
export default AllDescriptions;
