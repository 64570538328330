import { Button, Col, Collapse, Divider, Row, Select, Table, Layout, Switch } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "../components/DatePickerFromToReports";
import { DatePickerNewProducts } from "../components/NewShopProducts";
import DatePickerFromToReports from "../components/DatePickerFromToReports";
import { CaretDownOutlined, CheckOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import "../style/Feeds.css";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import { getShops } from "../../../shared/Api/shop";
import { useAuth } from "../../../shared/Context/AuthContext";
const { Option } = Select;
const { Header, Content } = Layout;
const { Panel } = Collapse;

type FeedType = {
    id: number;
    feed: string;
    file: string;
    original_file: string;
    created_at: string;
    store_id: number;
    shop_name: string;
    dateSeparator: string;
    dateItem: string;
    isChecked: boolean;
    feedType: boolean;
    data: any;
    successful_products_count: number;
    error_products_count: number;
};

const Feeds = () => {
    const { currentCountry } = useAuth();
    const [shops, setShops] = useState<{ id: number; name: string }[]>([]);
    const [date, setDate] = useState<DatePickerNewProducts>({ startDate: new Date(), endDate: new Date() });
    const [shop, setShop] = useState<string | null>(null);
    const [feeds, setFeeds] = useState<FeedType[]>([]);
    const [loading, setLoading] = useState(false);
    const { getColumnSearchProps } = useSearchState(feeds);
    const windowSize = useWindowSize();

    useEffect(() => {
        getShops(0, currentCountry.id).then((resp) => {
            setShops(resp.data.map((s) => ({ id: s.id, name: s.name })));
        });
    }, [currentCountry.id]);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }
        const getAllFeeds = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/feed-parser/list-feeds?from=${formTo.startDate}&to=${formTo.endDate}${shop ? `&shop_id=${shop}` : ""}`);
                const newData = data.data.map((item: FeedType) => {
                    const date = item.created_at.split(" ");
                    return {
                        ...item,
                        dateSeparator: date[0],
                        isChecked: false,
                    };
                });

                const newArr = newData.reduce(function (acc: any, obj: any) {
                    let key = obj["dateSeparator"];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
                let tempArr: any = [];
                for (let el in newArr) {
                    tempArr.push({ dateItem: el, feedType: true, data: newArr[`${el}`] });
                }
                setFeeds(tempArr);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };
        if (date.startDate && date.endDate) {
            getAllFeeds();
        }
    }, [shop, date]);

    const handleSelectedShops = (value: string) => {
        setShop(value);
    };

    const sortTable = (a: string, b: string, type: string) => {
        if (type === "date") {
            if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
            if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
            return 0;
        }
        if (a < b) return -1;
        if (b < a) return 1;
        return 0;
    };
    const checkItem = (dateSeparator: number, file: string) => {
        const findArr = feeds.find((feed: { dateItem: string }) => feed.dateItem === String(dateSeparator));
        const checedData =
            findArr &&
            findArr.data.map((data: any) => {
                if (data.file === file) {
                    return {
                        ...data,
                        isChecked: !data.isChecked,
                    };
                }
                return data;
            });
        const objToInsert = { dateItem: `${dateSeparator}`, feedType: findArr?.feedType, data: checedData };
        const findObj: any = feeds.map((feed: { dateItem: string }) => {
            if (feed.dateItem === objToInsert.dateItem) {
                return objToInsert;
            }
            return feed;
        });
        setFeeds(findObj);
    };

    const handleSwitchFeedType = (e: boolean, dateItem: any) => {
        const objToInsert = { ...dateItem, feedType: e };
        //console.log(objToInsert)

        const newData = feeds.map((feed) => {
            if (feed.dateItem === objToInsert.dateItem) {
                return objToInsert;
            }
            return feed;
        });
        setFeeds(newData);
    };

    const columns: any = [
        {
            title: "",
            dataIndex: "dateItem",
            key: "dateItem",
            align: "center" as "center",
            render: (text: any, record: any, index: any) => (
                <div style={{ cursor: "pointer" }} onClick={() => checkItem(record.dateSeparator, record.file)}>
                    <CheckOutlined style={{ width: "40px", textAlign: "center", opacity: record.isChecked ? "1" : "0" }} />
                </div>
            ),
            width: "4%",
        },
        {
            title: "Shop Id",
            dataIndex: "store_id",
            align: "center" as "center",
            ...getColumnSearchProps("store_id"),
        },
        {
            title: "Shop",
            dataIndex: "shop_name",
            align: "center" as "center",
            sorter: (a: FeedType, b: FeedType) => a.shop_name.localeCompare(b.shop_name),
            ...getColumnSearchProps("shop_name"),
        },
        {
            title: "Processed File",
            dataIndex: "file",
            align: "center" as "center",
            render: (record: string) => (
                <a href={record ? `${process.env.REACT_APP_URL_API}/feed-parser/feed-download?feed_file=${record}` : "#"} className="download-feed-btn">
                    Download <DownloadOutlined className="download-feed-icon" />
                </a>
            ),
        },
        {
            title: "Original File",
            dataIndex: "original_file",
            align: "center" as "center",
            render: (record: string) => (
                <a href={`${process.env.REACT_APP_URL_API}/feed-parser/feed-download?feed_file=${record}`} className="download-feed-btn">
                    Download <DownloadOutlined className="download-feed-icon" />
                </a>
            ),
        },
        {
            title: "Error Products Count",
            dataIndex: "error_products_count",
            align: "center" as "center",
            sorter: (a: FeedType, b: FeedType) => a.error_products_count - b.error_products_count,
        },
        {
            title: "Successful Products Count",
            dataIndex: "successful_products_count",
            align: "center" as "center",
            sorter: (a: FeedType, b: FeedType) => a.successful_products_count - b.successful_products_count,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            align: "center" as "center",
            render: (record: string) => <span>{` ${moment(record).format("HH:mm:ss")}`}</span>,
            sorter: (a: FeedType, b: FeedType) => sortTable(a.created_at, b.created_at, "date"),
        },
    ];
    const downloadMultipleFeeds = async (dateSeparator: string, data: FeedType[], feedType: any) => {
        const newArr = data.flatMap((item) => {
            return item.isChecked === true ? (feedType.feedType ? item.original_file : item.file) : [];
        });

        const interval = setInterval(download, 300, newArr);
        function download(newArr: any) {
            let url = newArr.pop();
            const a = document.createElement("a");
            a.setAttribute("href", `${process.env.REACT_APP_URL_API}/feed-parser/feed-download?feed_file=${url}`);
            a.setAttribute("download", "");
            a.setAttribute("target", "_self");
            a.click();
            if (newArr.length === 0) {
                clearInterval(interval);
                const findArr = feeds.find((feed: { dateItem: string }) => feed.dateItem === String(dateSeparator));
                const checedData =
                    findArr &&
                    findArr.data.map((data: any) => {
                        return {
                            ...data,
                            isChecked: false,
                        };
                    });
                const objToInsert = { dateItem: `${dateSeparator}`, data: checedData };
                const findObj: any = feeds.map((feed: { dateItem: string }) => {
                    if (feed.dateItem === objToInsert.dateItem) {
                        return objToInsert;
                    }
                    return feed;
                });
                setFeeds(findObj);
            }
        }
    };

    return (
        <Row justify="center">
            <Col span={windowSize.width > 1625 ? 16 : 20}>
                <Divider>Feeds</Divider>
                <div style={{ border: "1px solid gray", padding: "2rem" }} id="feed-files-box">
                    <div style={{ padding: "1rem 2rem", backgroundColor: "#ebeced", border: "1px solid lightgray", borderRadius: "3px", marginBottom: "1.3rem" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <DatePickerFromToReports date={date} setDate={setDate} isDate={true} />
                            <Select
                                suffixIcon={<CaretDownOutlined />}
                                showSearch
                                //mode="multiple"
                                allowClear
                                style={{ width: "430px" }}
                                placeholder="Select shop"
                                value={undefined}
                                onChange={handleSelectedShops}
                                filterOption={(input, option) => String(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                disabled={loading}
                            >
                                {shops
                                    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                    .map((shop) => (
                                        <Option key={shop.id} value={shop.id}>
                                            {shop.name}
                                        </Option>
                                    ))}
                            </Select>{" "}
                        </div>
                    </div>
                    <Layout style={{ margin: "0.6rem 1rem" }}>
                        <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px", position: "relative" }}>
                            <span> List of Feeds </span>{" "}
                        </Header>
                        <Content style={{ backgroundColor: "white" }}></Content>
                        <Collapse /*onChange={callback}*/ accordion bordered>
                            {feeds.map((data: FeedType) => (
                                <Panel header={moment(data.dateItem).format("DD MMM YYYY")} key={data.dateItem}>
                                    <Button
                                        style={{ margin: "0.5rem" }}
                                        disabled={feeds.find((feed) => feed.dateItem === data.dateItem)?.data.filter((feed: any) => feed.isChecked === true).length > 0 ? false : true}
                                        danger
                                        onClick={() => downloadMultipleFeeds(data.dateItem, data.data, data)}
                                    >
                                        Multiple Download
                                    </Button>
                                    <Switch
                                        onChange={(e) => handleSwitchFeedType(e, data)}
                                        checkedChildren="Original"
                                        unCheckedChildren="Processed"
                                        defaultChecked={data.feedType}
                                        //className="shop-list-switch-btn"
                                        size="small"
                                    />
                                    <Table
                                        bordered
                                        className="feeds-table"
                                        rowKey={(record) => record.file}
                                        columns={columns}
                                        dataSource={data.data}
                                        loading={loading}
                                        pagination={{
                                            showSizeChanger: false,
                                            size: "small",
                                        }}
                                    />
                                </Panel>
                            ))}
                        </Collapse>
                    </Layout>
                </div>
            </Col>
        </Row>
    );
};

export default Feeds;
