import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Divider, Popconfirm, Row, Table } from "antd";
import showMessage from "../../../shared/MessagesInfo/message";
import "../style/ShopTokPages.css";
import ShopTokPagesModal from "../components/ShopTokPagesModal";
import { PlusOutlined } from "@ant-design/icons";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useAuth } from "../../../shared/Context/AuthContext";

export type ModalDataType = {
    content: string;
    country_id: number;
    created_at: string;
    id: number;
    meta_description: string;
    meta_title: string;
    type: string;
    title: string;
    active: boolean;
};
export type TypePageUrl = {
    id: number;
    type: string;
};

let initContent: "";

const ShopTokPages = () => {
    const { currentCountry } = useAuth();
    const [dataPages, setDataPages] = useState<ModalDataType[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState<ModalDataType | null>(null);
    const [createNew, setCreateNew] = useState(false);
    const [urlForUpdate, setUrlForUpdate] = useState("");

    //////////////urls
    const [allUrls, setAllUrls] = useState<TypePageUrl[]>([]);
    useEffect(() => {
        const getAllUrls = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shoptok/pages/list-types?country_id=${currentCountry.id}`);
                setAllUrls(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getAllUrls();
    }, [currentCountry.id]);

    ///////////////////

    useEffect(() => {
        const getAllPages = async () => {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shoptok/pages/list?country_id=${currentCountry.id}`);
            setDataPages(data.data);
        };
        getAllPages();
    }, [currentCountry.id, createNew]);

    const openPageModal = async (id: number) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/shoptok/pages/show?id=${id}`);
            setModalData(data.data);
            setUrlForUpdate(data.data.type);
            initContent = data.data.content;
            setModalVisible(true);
        } catch (err) {
            console.log(err);
        }
    };
    const closeModal = () => {
        setModalData(null);
        initContent = "";
        setCreateNew(false);
        setModalVisible(false);
    };

    const DeletePage = async (id: number) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/shoptok/pages/delete?id=${id}`);
            showMessage(data.message);
            setDataPages((currentData) => currentData.filter((data) => data.id !== id));
            setAllUrls((currentData) => currentData.filter((data: any) => data.type !== urlForUpdate));
        } catch (err) {
            console.log(err);
        }
    };

    const saveTextChanges = async () => {
        const urlArr = allUrls.map((url: any) => {
            if (url.type !== urlForUpdate) {
                return url.type;
            } else {
                return null;
            }
        });
        if (urlArr && urlArr.includes(modalData?.type.trim())) {
            openNotification(`Url page ${modalData?.type} already exists`);
        } else {
            try {
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/shoptok/pages/update`, modalData !== null ? modalData : "");
                showMessage(data.message);
                initContent = "";
                setDataPages((currentData) =>
                    currentData.map((data) => {
                        if (data.id === modalData?.id) {
                            return modalData;
                        } else {
                            return data;
                        }
                    })
                );

                setAllUrls((currentData) =>
                    currentData.map((data: any) => {
                        if (data.type === urlForUpdate) {
                            return {
                                ...data,
                                type: modalData?.type,
                            };
                        } else {
                            return data;
                        }
                    })
                );
                setModalData(null);
                setModalVisible(false);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const createPage = () => {
        setCreateNew(true);
        setModalData({
            content: "",
            country_id: currentCountry.id,
            created_at: "",
            id: 0,
            meta_description: "",
            meta_title: "",
            type: "",
            title: "",
            active: true,
        });
        initContent = "";
        setModalVisible(true);
    };
    const columns = [
        {
            title: "Active",
            dataIndex: "active",
            render: (record: boolean) => <p>{record ? "Yes" : "No"}</p>,
        },
        {
            title: "Url",
            dataIndex: "type",
            render: (record: number) => <b>{record}</b>,
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Meta Title",
            dataIndex: "meta_title",
        },
        {
            title: "",
            dataIndex: "id",
            align: "center" as "center",
            render: (record: number) => (
                <button className="u-button" style={{ fontSize: "12px", padding: "6px 10px" }} onClick={() => openPageModal(record)}>
                    Change page data
                </button>
            ),
        },
        {
            title: (
                <button className="u-button" style={{ backgroundColor: "#1dad9b", marginRight: "40%" }} onClick={createPage}>
                    <PlusOutlined style={{ marginRight: "9px" }} /> Create
                </button>
            ),
            dataIndex: "id",
            render: (record: number) => (
                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => DeletePage(record)}>
                    <button className="u-button" style={{ backgroundColor: "rgb(214, 54, 69)", fontSize: "12px", padding: "6px 10px" }}>
                        Delete
                    </button>
                </Popconfirm>
            ),
            align: "center" as "center",
            width: "3%",
        },
    ];
    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Shoptok pages" />

            <Row justify="center">
                <Col span={14}>
                    <Divider>Shoptok pages</Divider>
                    <div className="shop-pages-box">
                        {modalVisible && (
                            <ShopTokPagesModal
                                allUrls={allUrls}
                                setAllUrls={setAllUrls}
                                modalData={modalData}
                                setModalData={setModalData}
                                modalVisible={modalVisible}
                                setModalVisible={setModalVisible}
                                closeModal={closeModal}
                                initContent={initContent}
                                saveTextChanges={saveTextChanges}
                                /////for create
                                createNew={createNew}
                                setCreateNew={setCreateNew}
                            />
                        )}
                        <Table bordered dataSource={dataPages} rowKey={(record: { id: number }) => record && record.id} columns={columns} />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ShopTokPages;
