import { Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { CountryType } from "../../../../../../shared/Context/type";

interface CountriesTabsProps {
    changeCountryValue: (e: SegmentedValue) => void;
}

const CountriesTabs = (props: CountriesTabsProps) => {
    const { changeCountryValue } = props;
    const localStorageCountries = JSON.parse(localStorage.getItem("countries")!) as CountryType[];
    return (
        <Segmented
            options={localStorageCountries.map((country) => ({
                label: <span>{country.locale_code.toUpperCase()}</span>, //
                value: country.locale_code,
            }))}
            onChange={(e) => changeCountryValue(e)}
            size="large"
            style={{ marginRight: "1rem", marginBottom: "0.5rem" }}
        />
    );
};

export default CountriesTabs;
