import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { FiltersType, ShopType } from "../../pages/ScrapersOverview";
// import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
const { RangePicker } = DatePicker;

type FormFields = "shop_id" | "name" | "form_to" | "order";

interface FiltersProps {
    shops: ShopType[];
    setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
}

const Filters = (props: FiltersProps) => {
    const { setFilters, shops } = props;
    const [form] = Form.useForm();
    let delayTimer: ReturnType<typeof setTimeout>;

    const onFieldsChange = (e: any) => {
        const formField = e[0].name[0] as FormFields;
        const formFieldValue = e[0].value;
        if (formField === "name") {
            clearTimeout(delayTimer);
            delayTimer = setTimeout(function () {
                setFilters((curr) => ({ ...curr, [formField]: formFieldValue }));
            }, 1000);
        } else {
            setFilters((curr) => ({ ...curr, [formField]: formFieldValue }));
        }
    };

    const handleSubmit = (e: any) => {
        setFilters(e);
    };

    return (
        <Form form={form} onFieldsChange={onFieldsChange} onFinish={handleSubmit}>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item name="shop_id" style={{ margin: 0 }}>
                        <Select placeholder="Select Shop" options={shops} optionFilterProp="label" showSearch allowClear />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="name" style={{ margin: 0 }}>
                        <Input placeholder="Name" allowClear />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="from_to" style={{ margin: 0 }}>
                        <RangePicker
                            placeholder={["From", "To"]}
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            //disabledDate={(current:any) => current.isBefore(moment().subtract(1,"day"))}
                        />
                    </Form.Item>
                </Col>
                {/* <Col span={12}>
                    <Form.Item name="order" style={{ margin: 0 }}>
                        <Select
                            options={[
                                {
                                    value: 0,
                                    label: (
                                        <div>
                                            Ascending
                                            <ArrowUpOutlined style={{ marginLeft: "0.8rem" }} />
                                        </div>
                                    ),
                                },
                                {
                                    value: 1,
                                    label: (
                                        <div>
                                            Descending <ArrowDownOutlined />
                                        </div>
                                    ),
                                },
                            ]}
                            placeholder="Order"
                            allowClear
                        />
                    </Form.Item>
                </Col> */}
            </Row>
        </Form>
    );
};

export default Filters;
