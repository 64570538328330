import { CloseCircleOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Checkbox, Col, Empty, Input, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { ShopRulesType } from "../../shops/pages/ShopList";
import "../style/CreateShopMap.css";
import { addNewFieldToShopMap, getMainMapOptions } from "../../../shared/Api/maps";

const { Header } = Layout;
type OptionsKeysType = {
    id: number;
    label: string;
    value: string;
    shopKeyText: string;
    tag_delimiter: string;
    multiple_tags: boolean;
};
let selectedDataIds: number[] = [];

interface CreateShopMapProps {
    shopMap: ShopRulesType | null;
    handleModalShopMapOk: () => void;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const CreateShopMaps: React.FC<CreateShopMapProps> = ({ shopMap, handleModalShopMapOk, setCounter }) => {
    const [options, setOptions] = useState<OptionsKeysType[]>([]);
    const [optionsInit, setOptionsInit] = useState<OptionsKeysType[]>([]);
    const [selectedItems, setSelectedItems] = useState<OptionsKeysType[]>([]);
    const history = useHistory();

    useEffect(() => {
        getMainMapOptions(setOptions, setOptionsInit);
    }, [shopMap?.id]);

    useEffect(() => {
        if (shopMap?.id) {
            setOptions([]);
            setOptionsInit([]);
            setSelectedItems([]);
        }
    }, [shopMap?.id]);

    const changeKeys = (value: string) => {
        const newSearch = optionsInit.filter((data) => data.label.includes(value.trim()) && !selectedDataIds.includes(data.id));
        setOptions(newSearch);
    };

    const handleInputChange = (id: number, text: string) => {
        const newItems = selectedItems.map((option) => {
            if (option.id === id) {
                return {
                    ...option,
                    shopKeyText: text,
                };
            } else {
                return option;
            }
        });
        setSelectedItems(newItems); //????? need to set init dummy data
    };

    const addToList = (id: number) => {
        const filteredData = options.filter((data) => data.id !== id);
        const findData = options.find((data) => data.id === id);
        findData && selectedDataIds.push(findData.id);
        setOptions(filteredData);
        findData && setSelectedItems((current) => [...current, { ...findData, tag_delimiter: "", multiple_tags: false }]);
    };

    const removeFromList = (id: number) => {
        const findItem = selectedItems.find((data) => data.id === id);
        /////
        findItem && setOptions((current) => [...current, { ...findItem, shopKeyText: "" }]);
        setSelectedItems((currnet) => currnet.filter((data) => data.id !== id));
        const newSelected = selectedDataIds.filter((data) => data !== id);
        selectedDataIds = newSelected;
    };

    const headers: any = {
        "Content-Type": "application/json",
    };

    const createShopMap = () => {
        const findEmptyInput = selectedItems.filter((data) => data.shopKeyText.trim() === "");
        if (findEmptyInput.length > 0) {
            openNotification("Empty fields are not allowed!");
        } else {
            const tempObjects = selectedItems.map((item) => {
                return {
                    main_key_id: item.id,
                    key: item.shopKeyText,
                    multiple_tags: item.multiple_tags,
                    ...(item.tag_delimiter.length > 0 && typeof item.tag_delimiter === "string" && { tag_delimiter: item.tag_delimiter }),
                };
            });
            addNewFieldToShopMap(shopMap?.id, tempObjects, headers, history, handleModalShopMapOk);
            setCounter((curr) => curr + 1);
        }
    };

    const setMultipleTags = (id: number) => {
        setSelectedItems((curr) => curr.map((item) => (item.id === id ? { ...item, multiple_tags: !item.multiple_tags } : item)));
    };

    const setTagDelimiterInput = (e: string, id: number) => {
        setSelectedItems((curr) => curr.map((item) => (item.id === id ? { ...item, tag_delimiter: e } : item)));
    };

    return (
        <Row justify="center" style={{ height: shopMap?.status === "Create" ? "75vh" : "" }}>
            <Col span={24} /*span={windowSize.width > 1740 ? 14: windowSize.width > 1380 ?  18 :20}*/ className="shop-map-lists-box" style={{ backgroundColor: "white", height: "100%" }}>
                <Layout style={{ margin: "0.6rem 0rem", width: "100%!important", backgroundColor: "white", overflowY: "scroll" }}>
                    <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px" }} />
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ backgroundColor: "white", position: "relative" }}>
                            <Input
                                size="small"
                                style={{ marginBottom: "1rem", width: "200px", marginTop: "1.1rem", position: "absolute", top: "-2.75rem" }}
                                placeholder="Search map key"
                                onChange={(e) => changeKeys(e.target.value)}
                            />
                            <ul style={{ width: "320px", marginTop: "1rem" }}>
                                {options.map((data) => (
                                    <li key={data.id} className="shop-map-list1-item">
                                        <p style={{ width: "140px" }}>{data.label}</p>
                                        <PlusSquareFilled onClick={() => addToList(data.id)} style={{ color: "#3baea0", fontSize: "22px", position: "relative", top: "0.4rem", cursor: "pointer" }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div style={{ width: "580px", marginTop: "0.9rem", marginLeft: "1rem" }}>
                            <p style={{ textAlign: "center", marginBottom: "1rem" }}>Selected Items</p>
                            {selectedItems.length > 0 ? (
                                <div className="shop-map-list2-box">
                                    {selectedItems.map((data) => (
                                        <div key={data.id} className="shop-map-list2-card">
                                            <span style={{ width: "100px" }}>{data.label}</span>
                                            <div>
                                                <Input placeholder="Enter Shop Key" style={{ width: "275px" }} value={data.shopKeyText} onChange={(e) => handleInputChange(data.id, e.target.value)} />
                                                <CloseCircleOutlined
                                                    onClick={() => removeFromList(data.id)}
                                                    style={{ color: "darkred", position: "relative", bottom: "0.2rem", marginLeft: "0.4rem", cursor: "pointer" }}
                                                />
                                                <div style={{ marginTop: "1rem" }}>
                                                    <Checkbox onChange={() => setMultipleTags(data.id)}>Multiple tags</Checkbox>
                                                    <Input style={{ width: "50px" }} size="small" onChange={(e) => setTagDelimiterInput(e.target.value, data.id)} /> Tag delimiter
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <hr />
                                    <button className="u-button" style={{ margin: "1rem 0", marginLeft: "11rem" }} onClick={() => createShopMap()}>
                                        Create shop map
                                    </button>
                                </div>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </div>
                    </div>
                </Layout>
            </Col>
        </Row>
    );
};

export default CreateShopMaps;

// import React from 'react'

// const CreateShopMap = () => {
//   return (
//     <div>CreateShopMap</div>
//   )
// }

// export default CreateShopMap
