import { Button, Col, Popover, Row, Select } from "antd";
import React from "react";

interface CreateScraperType {
    onShopChange: (value: number) => void;
    selectedShop: number | null;
    shops: { id: number; name: string }[];
    addNewScraper: () => Promise<void>;
    loadingNewScraper: boolean;
}

const CreateScraper: React.FC<CreateScraperType> = ({ onShopChange, selectedShop, shops, addNewScraper, loadingNewScraper }) => {
    return (
        <Popover
            content={
                <Row justify="center">
                    <Col span={24}>
                        <Select
                            style={{ width: "250px" }}
                            showSearch
                            allowClear
                            placeholder="Select shop"
                            onChange={onShopChange}
                            key={"shop_select"}
                            value={selectedShop}
                            //onClear={resetSelectedShop}
                            filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {shops &&
                                shops.map((shop: { name: string; id: number }) => (
                                    <Select.Option value={shop.id} key={"category_seller_" + shop.id}>
                                        {`${shop.name}`}
                                    </Select.Option>
                                ))}
                        </Select>

                        <Button style={{ marginLeft: "10px" }} type="primary" onClick={() => addNewScraper()} loading={loadingNewScraper}>
                            Save
                        </Button>
                    </Col>
                </Row>
            }
            placement="bottom"
            title="Crete new Screper"
            trigger="click"
            //style={{ width:'300px' }}
        >
            <Button>Create Scraper</Button>
        </Popover>
    );
};

export default CreateScraper;
