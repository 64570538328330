import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Divider, Table, Popconfirm, Button, Select, Badge, Modal, Radio, RadioChangeEvent } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import "../style/AllBrands.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import CreateBrand from "../components/AllBrands/CreateBrand";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import AllBrandsDescription from "../components/AllBrands/AllBrandsDescription";
import EditableCell, { EditableRow } from "../components/AllBrands/EditableCell";
import { ColumnType } from "antd/es/table";
import { BrandType, deleteBrand, getBrands } from "../../../shared/Api/brands";
import { useAuth } from "../../../shared/Context/AuthContext";

const { Option } = Select;
let allBrandsInit: BrandType[];

export type CategoryArrType = {
    name: string;
    id: number;
    brand_description_flag: boolean;
    children: {
        name: string;
        id: number;
        brand_description_flag: boolean;
    }[];
};

const AllBrands: React.FC = () => {
    const { currentCountry } = useAuth();
    const [brandMapModal, setBrandMapModal] = useState(false);
    const [mainBrand, setMainBrand] = useState("");
    const [brands, setBrands] = useState<BrandType[]>([]); // to change that and delete it
    const [allBrands, setAllBrands] = useState<BrandType[]>([]);
    const [typeMapping, setTypeMapping] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [mapBrand, setMapBrand] = useState("");
    const [filterBrands, setFilterBrands] = useState("all");
    //---------- for description -------
    const [descriptionBrand, setDescriptionBrand] = useState<{ id: number; name: string } | null>(null);
    const [descriptionVisible, setDescriptionVisible] = useState(false);
    const [categories, setCategories] = useState<CategoryArrType[]>([]);
    const [showAllMissingDesc, setShowAllDescription] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState<{ id: number; name: string } | null>(null);
    const { getColumnSearchProps } = useSearchState(allBrands);
    //  counter for api call - tep.solutin, to remove
    const [counter, setCounter] = useState(1); // to change that and remove it

    const windowSize = useWindowSize();
    const text = "Are you sure?";

    // useEffect(() => {
    //     let newData: any;
    //     if (filterBrands === "redirect_brand_name") {
    //         newData = brandState.brands.data.filter((brand: any) => brand.redirect_brand_name);
    //     } else if (filterBrands === "mapping_brand_name") {
    //         newData = brandState.brands.data.filter((brand: any) => brand.mapping_brand_name);
    //     } else {
    //         newData = brandState.brands.data;
    //     }
    //     setAllBrands(newData);
    //     allBrandsInit = brandState.brands.data;
    // }, [brandState.brands.data, filterBrands]);

    useEffect(() => {
        let newData: any;
        if (filterBrands === "redirect_brand_name") {
            newData = brands.filter((brand: any) => brand.redirect_brand_name);
        } else if (filterBrands === "mapping_brand_name") {
            newData = brands.filter((brand: any) => brand.mapping_brand_name);
        } else {
            newData = brands;
        }
        setAllBrands(newData);
        allBrandsInit = brands;
    }, [brands, filterBrands]);

    /////////////////////////////

    useEffect(() => {
        getBrands().then((resp) => {
            setBrands(resp);
        });
    }, [counter]);

    const getCategories = useCallback(async () => {
        try {
            //const { data } = await axios.get(`${process.env.REACT_APP_URL_API }/product/category/list-l3-brand-description?country_id=${ currentCountryId }&brand_id=${ descriptionBrand?.id }`)
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/product/category/list-l3-brand-description?country_id=${currentCountry.id}&brand_id=${
                    descriptionBrand?.id
                }&missing_descriptions=${!showAllMissingDesc}`
            );
            const newData = data.data
                .map((c: CategoryArrType) => ({ ...c, children: typeof c.children === "string" ? JSON.parse(c.children) : [] }))
                .sort((a: CategoryArrType, b: CategoryArrType) => a.name.toString().localeCompare(b.name.toString()));
            setCategories(newData);

            // clear state for selected cateogry

            if (!showAllMissingDesc) {
                const findCateogryL3 = data.data.find((c: { id: number }) => c.id === selectedCategory?.id);
                if (!findCateogryL3) {
                    setSelectedCategory(null);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }, [currentCountry.id, descriptionBrand, showAllMissingDesc, selectedCategory?.id]);

    useEffect(() => {
        descriptionBrand && getCategories();
    }, [getCategories, descriptionBrand]);

    const showModal = (type: string) => {
        setBrandMapModal(true);
        setTypeMapping(type);
    };

    const handleCancel = () => {
        setBrandMapModal(false);
        setShowInput(false);
    };

    // useEffect(() => {
    //     dispatch(getAllBrands());
    // }, [dispatch]);

    const handleDeleteClick = (id: number) => {
        deleteBrand(id).then((resp) => {
            // console.log(resp);
            allBrandsInit = allBrandsInit.filter((brand) => brand.id !== id);
            setAllBrands((curr) => curr.filter((b) => b.id !== id));
        });
    };

    const checkItem = (id: number) => {
        const checedItem = allBrands.map((brand) => {
            if (brand.id === id) {
                return {
                    ...brand,
                    checkedForMap: !brand.checkedForMap,
                };
            }
            return brand;
        });
        setAllBrands(checedItem);
    };
    const saveMapBrand = async (onlyChecked: any) => {
        const obj = {
            redirect_brand_id: mainBrand,
            brands: onlyChecked,
        };
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/brand/brand-${typeMapping === "map" ? "mapping" : "redirect"}`, obj);
            showMessage(data.message);
            // dispatch(getAllBrands());
            setCounter((curr) => curr + 1);
            setMainBrand("");
            setMapBrand("");
            setBrandMapModal(false);
        } catch (err) {
            console.log(err);
        }
    };
    const findChecked = allBrands && allBrands.filter((brand) => brand.checkedForMap === true);
    const mapBrands = () => {
        let onlyChecked: any;
        if (typeMapping === "map" && mapBrand) {
            onlyChecked = [mapBrand];
        }
        if (typeMapping === "map" && mapBrand.trim() === "") {
            onlyChecked = findChecked.map((brand: any) => brand.name);
        }
        if (typeMapping === "redirect") {
            onlyChecked = findChecked.map((brand: any) => brand.id);
        }
        saveMapBrand(onlyChecked);
    };
    const handleBrandChange = (value: string) => {
        setMainBrand(value);
    };
    const demappingBrands = async () => {
        const findChecked = allBrands.filter((brand) => brand.checkedForMap === true);
        const onlyName = findChecked.map((brand) => brand.name);
        const obj = {
            brands: onlyName,
        };
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/brand/brand-demapping`, obj);
            showMessage(data.message);
            // dispatch(getAllBrands());
            setCounter((curr) => curr + 1);
            setMainBrand("");
        } catch (err) {
            console.log(err);
        }
    };

    const openDescModal = async (id: number, name: string) => {
        setDescriptionBrand({ id: id, name: name });
        setDescriptionVisible(true);
    };

    const defaultColumns: (ColumnType<BrandType> & { editable?: boolean; dataIndex: any })[] = [
        {
            title: "",
            dataIndex: "id",
            key: "id",
            className: "all-brands-first-column",
            render: (text, record) => (
                <div style={{ cursor: "pointer", height: "100%", display: "flex" }} onClick={() => checkItem(text)}>
                    {
                        <span style={{ height: "50px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CheckOutlined style={{ opacity: record.checkedForMap ? "1" : "0", fontSize: "20px" }} />
                        </span>
                    }
                </div>
            ),
            width: "5%",
        },
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            className: "redirect-center",
            align: "center" as "center",
            sorter: (a, b) => a.id - b.id,
            ...getColumnSearchProps("id"),
        },
        {
            title: "Product count",
            dataIndex: "products_count",
            key: "products_count",
            align: "center" as "center",
            sorter: (a, b) => a.products_count - b.products_count,
            className: "redirect-center",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "30%",
            sorter: (a, b) => a.name.localeCompare(b.name),
            editable: true,
            ...getColumnSearchProps("name"),
        },
        {
            title: "Mapping",
            dataIndex: "mapping_brand_name",
            key: "mapping_brand_name",
            className: "redirect-center",
            align: "center" as "center",
            render: (record) => (record ? record : <span>-</span>),
        },
        {
            title: "Redirect",
            dataIndex: "redirect_brand_name",
            key: "redirect_brand_name",
            className: "redirect-center",
            align: "center" as "center",
            render: (record) => (record ? record : <span>-</span>),
        },
        {
            title: "Logo",
            dataIndex: "logo",
            editable: true,
            align: "center" as "center",
            sorter: (a, b) => {
                if (a.logo_url === null && b.logo_url !== null) {
                    return 1;
                } else if (a.logo_url !== null && b.logo_url === null) {
                    return -1;
                } else if (a.logo_url && b.logo_url) {
                    return a.logo_url?.localeCompare(b.logo_url);
                }
                return 0;
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            align: "center" as "center",
            render: (_, record) => (
                <Button size="small" onClick={() => openDescModal(record.id, record.name)}>
                    Description
                </Button>
            ),
        },
        {
            title: "Actions",
            dataIndex: "id",
            key: "id",
            className: "redirect-center",
            align: "center" as "center",
            render: (text, record) => (
                <Popconfirm
                    title={
                        <div>
                            Deleting brand <b>{record.name}</b>?
                        </div>
                    }
                    onConfirm={() => (record.products_count === 0 ? handleDeleteClick(text) : {})}
                    disabled={record.products_count !== 0 ? true : false}
                >
                    <Button danger size="small" style={{ marginTop: "2px" }} disabled={record.products_count === 0 && !record.mapping_brand_name && !record.redirect_brand_name ? false : true}>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    const countChecked = allBrands && allBrands.filter((price) => price.checkedForMap === true).length;

    const onBrandFilterChange = (e: RadioChangeEvent) => {
        setFilterBrands(e.target.value);
    };

    useEffect(() => {
        if (filterBrands === "valid") {
            setAllBrands(allBrandsInit.filter((brand) => brand.mapping_brand_name === null && brand.redirect_brand_name === null && brand.products_count === 0));
        } else if (filterBrands === "all") {
            setAllBrands(allBrandsInit);
        } else if (filterBrands === "mapping_brand_name") {
            setAllBrands(allBrandsInit.filter((brand) => brand[`mapping_brand_name` as keyof BrandType]));
        } else if (filterBrands === "redirect_brand_name") {
            setAllBrands(allBrandsInit.filter((brand) => brand[`redirect_brand_name` as keyof BrandType]));
        }
    }, [filterBrands]);

    const handleSave = (row: BrandType) => {
        const newData = [...allBrands];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        setAllBrands((curr) => curr.map((b) => (b.id === row.id ? row : b)));
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: BrandType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                setAllBrands: setAllBrands,
                handleSave,
            }),
        };
    });

    // console.log(allBrands);

    if (!allBrands) {
        return <div className="loading"> Loading&#8230;</div>;
    }
    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - All brands" />
            <Row justify={"center"}>
                <Col span={windowSize.width > 1460 ? 16 : 20} className="all-brands-box">
                    <Divider>All Brands</Divider>
                    <CreateBrand currentCountryName={currentCountry.name} setCounter={setCounter} />
                    {
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <span>Checked items</span>
                                {countChecked > 0 ? (
                                    <Badge count={countChecked} overflowCount={10000} style={{ backgroundColor: "#1d9696", position: "relative", bottom: "3px", left: "3px" }} />
                                ) : (
                                    <span style={{ color: "darkred" }}> 0</span>
                                )}
                                <Radio.Group onChange={onBrandFilterChange} style={{ marginLeft: "1.5rem" }} value={filterBrands}>
                                    <Radio value="all">All</Radio>
                                    <Radio value="redirect_brand_name">Redirected</Radio>
                                    <Radio value="mapping_brand_name">Mapped</Radio>
                                    <Radio value="valid">Valid - no products</Radio>
                                </Radio.Group>
                            </div>
                            <div>
                                <Button style={{ marginBottom: "7px", marginRight: "3px" }} onClick={() => showModal("map")}>
                                    Map Brands
                                </Button>
                                <Button style={{ marginBottom: "7px" }} onClick={() => showModal("redirect")} disabled={countChecked === 0 ? true : false}>
                                    Redirect Brands
                                </Button>
                                <Popconfirm placement="top" title={text} onConfirm={demappingBrands} okText="Yes" cancelText="No" disabled={countChecked === 0 ? true : false}>
                                    <Button style={{ marginBottom: "7px", marginLeft: "7px", backgroundColor: "beige" }} disabled={countChecked === 0 ? true : false} danger>
                                        Demapping Brands
                                    </Button>
                                </Popconfirm>
                            </div>
                        </div>
                    }
                    <Modal title="Brand Mapping" open={brandMapModal} onCancel={handleCancel} footer={false} width={700}>
                        {findChecked.length === 0 && typeMapping === "map" ? (
                            <div>
                                {!showInput ? (
                                    <Button style={{ width: "400px", marginBottom: "0.5rem" }} onClick={() => setShowInput(true)}>
                                        Insert Brand for mapping
                                    </Button>
                                ) : (
                                    <input
                                        style={{ width: "400px", marginBottom: "0.5rem" }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMapBrand(e.target.value)}
                                        placeholder="Enter brand for mapping"
                                    />
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                        <br />
                        <Select
                            showSearch
                            allowClear={undefined}
                            style={{ width: "400px", marginBottom: "0.5rem" }}
                            placeholder="Select Brand"
                            value={mainBrand}
                            onSelect={handleBrandChange}
                            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {allBrands &&
                                allBrands
                                    .sort((a, b) => b.products_count - a.products_count)
                                    .map((brand) => <Option key={brand.id} value={brand.id}>{`(${brand.id}) ${brand.name} - ${brand.products_count}`}</Option>)}
                        </Select>
                        <Button style={{ marginLeft: "10px" }} onClick={mapBrands} type="primary" disabled={mainBrand ? false : true}>
                            {typeMapping === "map" ? "Save brand mapping" : "Save brand redirect"}
                        </Button>
                    </Modal>

                    {
                        <AllBrandsDescription
                            descriptionBrand={descriptionBrand}
                            setDescriptionBrand={setDescriptionBrand}
                            setDescriptionVisible={setDescriptionVisible}
                            currentCountryName={currentCountry.name}
                            currentCountryId={currentCountry.id}
                            descriptionVisible={descriptionVisible}
                            categories={categories}
                            getCategories={getCategories}
                            setShowAllDescription={setShowAllDescription}
                            setSelectedCategory={setSelectedCategory}
                            selectedCategory={selectedCategory}
                        />
                    }
                    {allBrands.length > 0 && (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={allBrands}
                            rowKey={(record) => record.id}
                            className="brand-list-logo-column"
                            id="all-brands-table"
                            rowClassName={(record) => (record.checkedForMap ? "all-brands-selected-row  no-row-hover" : "")}
                            size="small"
                            pagination={{ pageSize: 100, showSizeChanger: false }}
                            components={components}
                        />
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AllBrands;
