import React from "react";
import { Form, Select, Modal } from "antd";
import InputE from "../../../shared/FormElements/Input";
import ButtonE from "../../../shared/FormElements/Button";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import { useHistory } from "react-router";

const CretePredefinedTextModal: React.FC<any> = ({ isModalPredefinedText, setIsModalPredefinedText, currentCountryName, categories }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { Option } = Select;

    const createPredefinedText = async (obj: any) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/predefined-word/create?country_id=1&l3_l4_id=${obj.level_id}&${`name_${currentCountryName}`}=${obj.name}`, obj);
            showMessage(data.message);
            history.push("/price-list");
            form.resetFields();
        } catch (err: any) {
            console.log(err);
        }
    };

    const handleSubmit = (e: any) => {
        createPredefinedText(e);
        setIsModalPredefinedText(false);
    };
    //----for modal
    const handleCancel = () => {
        setIsModalPredefinedText(false);
    };

    return (
        <div>
            <Modal title="Create predefined text" open={isModalPredefinedText} onCancel={handleCancel} footer={false}>
                <Form form={form} name="create-category-form" labelCol={{ span: 8 }} onFinish={handleSubmit}>
                    <Form.Item label="Category name" name="level_id" rules={[{ required: true, message: "Missing Level id" }]}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: "180px", marginBottom: "0.5rem" }}
                            placeholder="Select category"
                            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {categories.map((option: any) => (
                                <Option key={option.id} value={option.id}>
                                    {option[`name_${currentCountryName}`]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <InputE label="Name" name={`name_${currentCountryName}`} rules={[{ required: true, message: "Missing name" }]} />
                    <ButtonE type="primary" htmlType="submit" title="Create new predefined text" wrapperCol={{ offset: 8, span: 16 }} />
                </Form>
            </Modal>
        </div>
    );
};

export default CretePredefinedTextModal;
