import React, { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { Col, Divider, Layout, Row, Statistic } from "antd";
import axios from "axios";
import HistoryModal from "../components/ScrapersOverview/HistoryModal";
import ScrapersOverviewTable from "../components/ScrapersOverview/ScrapersOverviewTable";
import Filters from "../components/ScrapersOverview/Filters";
import dayjs from "dayjs";

export type ScraperType = {
    id: number;
    last_run: string;
    run_at: string;
    shop_id: number;
    website_name: string;
    website_url: string;
    red_flag: 0 | 1;
    execution_time: number;
    scraper_type: string;
};

export type HistoryType = {
    id: number;
    number_of_products: number;
    number_of_categories: number;
    execution_time: string;
    created_at: string;
};

export type FiltersType = { shop_id?: string; name?: string; from_to?: string; order?: string } | undefined;

export type ShopType = { value: number; label: string };

interface ApiResponse {
    data: {
        shop_id: number;
        name: string;
    }[];
}

const ScrapersOverview: React.FC = () => {
    const [scrapers, setScrapers] = useState<ScraperType[]>([]);
    const [count, setCount] = useState(0);
    const [shops, setShops] = useState<ShopType[]>([]);
    const [history, setHistory] = useState<HistoryType[]>([]);
    // const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState<FiltersType>(undefined);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const getShops = async () => {
            try {
                const { data } = await axios.get<ApiResponse>(`${process.env.REACT_APP_URL_API}/shop/get-shop-scrapers`);
                setShops(data.data.map((d) => ({ value: d.shop_id, label: d.name })));
            } catch (err) {
                console.log(err);
            }
        };
        getShops();
    }, []);

    useEffect(() => {
        const parameters = [];
        // parameters.push({ name: "page", value: page });
        filters?.shop_id && parameters.push({ name: "shop_id", value: filters?.shop_id });

        filters?.name && parameters.push({ name: "name", value: filters?.name });
        filters?.from_to && parameters.push({ name: "from", value: dayjs(filters?.from_to && filters?.from_to[0]).format("YYYY/MM/DD") });
        filters?.from_to && parameters.push({ name: "to", value: dayjs(filters?.from_to && filters?.from_to[1]).format("YYYY/MM/DD") });
        filters?.order !== undefined && parameters.push({ name: "order", value: filters?.order });
        const params = parameters.map((p, i) => `${i === 0 ? "?" : "&"}${p.name}=${p.value}`).join("");

        const getScrapers = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/list${params}`);
                setScrapers(data);
            } catch (err) {
                console.log(err);
            }
        };
        getScrapers();
    }, [/*page,*/ filters]);

    useEffect(() => {
        const parameters = [];
        filters?.shop_id && parameters.push({ name: "shop_id", value: filters?.shop_id });
        filters?.name && parameters.push({ name: "name", value: filters?.name });
        filters?.from_to && parameters.push({ name: "from", value: dayjs(filters?.from_to && filters?.from_to[0]).format("YYYY/MM/DD") });
        filters?.from_to && parameters.push({ name: "to", value: dayjs(filters?.from_to && filters?.from_to[1]).format("YYYY/MM/DD") });
        const params = parameters.map((p, i) => `${i === 0 ? "?" : "&"}${p.name}=${p.value}`).join("");

        const getCount = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/count${params}`);
                setCount(data);
            } catch (err) {
                console.log(err);
            }
        };
        getCount();
    }, [filters?.shop_id, filters?.name, filters?.from_to]);

    // useEffect(() => {
    //     setPage(1);
    // }, [filters]);

    const showHistory = async (id: number) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/history?id=${id}`);
            setHistory(data);

            setIsModalOpen(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Scrapers Overview" />
            <HistoryModal handleCancel={handleCancel} history={history} isModalOpen={isModalOpen} />
            <div className="content-wrapper">
                <Divider>Scrapers Overview</Divider>
                <Row justify="center">
                    <Col span={20}>
                        <Row justify="space-between" className="filter-box">
                            <Col span={10}>
                                <Filters shops={shops} setFilters={setFilters} />
                            </Col>
                            <Col span={1}>
                                <Statistic title="Total" value={count} valueStyle={{ color: "green" }} />
                            </Col>
                        </Row>
                        <Layout>
                            <Layout.Header className="table-dark-header"></Layout.Header>
                            <ScrapersOverviewTable scrapers={scrapers} setScrapers={setScrapers} showHistory={showHistory} /*page={page} setPage={setPage} count={count}*/ />
                        </Layout>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default ScrapersOverview;
