import axios from "axios";
import { ProductType } from "../types/types";
import showMessage from "../../../shared/MessagesInfo/message";
const baseUrl = process.env.REACT_APP_URL_API;

export const setMpnFromPrices = async (products: ProductType[], setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>) => {
    const ids = products.filter((p) => p.isChecked).map((p) => p.product_id);

    if (ids.length > 0) {
        try {
            const { data } = await axios.get(`${baseUrl}/prices/list-prices-mpns?product_ids=${JSON.stringify(ids)}`);
            const pricesMpns: { product_id: number; mpn: string }[] = data.data;

            setProducts((curr) =>
                curr.map((p) => {
                    const findMPn = pricesMpns.find((pm) => pm.product_id === p.product_id);
                    return findMPn ? { ...p, mpn: findMPn.mpn } : p;
                })
            );
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    }
};

export const setSkuFromPrices = async (products: ProductType[], setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>, selectedShops: string[], currentCountryId: number) => {
    const ids = products.filter((p) => p.isChecked).map((p) => p.product_id);

    const shopIds = selectedShops.length > 0 ? selectedShops.map((shop) => parseInt(shop.split("::")[0])) : [];

    if (ids.length > 0) {
        try {
            const { data } = await axios.get(
                `${baseUrl}/prices/list-prices-skus?product_ids=${JSON.stringify(ids)}${shopIds.length > 0 ? `&shop_ids=${JSON.stringify(shopIds)}` : ""}&country_id=${currentCountryId}`
            );
            const pricesSkus: { product_id: number; sku: string }[] = data.data;

            setProducts((curr) =>
                curr.map((p) => {
                    const findSku = pricesSkus.find((ps) => ps.product_id === p.product_id);
                    return findSku ? { ...p, name: p.name + " " + findSku.sku } : p;
                })
            );
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    }
};

export const setModelFromPrices = async (products: ProductType[], setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>, selectedShops: string[]) => {
    const ids = products.filter((p) => p.isChecked).map((p) => p.product_id);

    const shop_id = selectedShops.length === 1 ? selectedShops[0].split("::")[0] : undefined;

    if (ids.length > 0) {
        try {
            const { data } = await axios.get(`${baseUrl}/prices/list-prices-fields?product_ids=${JSON.stringify(ids)}&field=product_model${shop_id ? `&shop_id=${Number(shop_id)}` : ""}`);
            const models: { product_id: number; product_model: string }[] = data.data;

            setProducts((curr) =>
                curr.map((p) => {
                    const findModel = models.find((m) => m.product_id === p.product_id);
                    return findModel ? { ...p, name: `${p.name} ${findModel.product_model ?? ""}` } : p;
                })
            );
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    }
};
