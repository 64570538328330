import { Button, Col, Divider, Form, Modal, Row, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InputE from "../../../shared/FormElements/Input";
import { getShops } from "../../../shared/Api/shop";
import { useAuth } from "../../../shared/Context/AuthContext";

type ModelDataType = {
    model_change: number;
    price_change: number;
    product_url_change: number;
    sku_change: number;
    status: boolean;
};
const FeedChecker = () => {
    const { currentCountry } = useAuth();
    const [shops, setShops] = useState<{ id: number; name: string }[]>([]);
    const [modalData, setModalData] = useState<ModelDataType | null>(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        getShops(0, currentCountry.id).then((resp) => {
            setShops(resp.data.map((s) => ({ id: s.id, name: s.name })));
        });
    }, [currentCountry.id]);

    // const onShopChange = (value: number ) => {
    //   const findShop = stateR.shops.data.find((item:any) => item.id === value)
    //     if (value) {
    //       //getShopMappedCategories(value)
    //       setShop({
    //         id:value,
    //         name:findShop.name
    //       })
    //       //setCurrentPage(1)
    //     }
    //   }
    //   const resetSelectedShop = () => {
    //     setShop(null)

    //   }

    const handleSubmit = async (e: any) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/intelligence/feed-changes-stats?shop_id=${e.shop_id}&feed_url=${e.feed_url}&sku=${e.sku}&product_url=${
                e.product_url
            }&column=${e.column}&price=${e.price}${e.model ? `&model=${e.model}` : ""}${e.decimal_separator ? `&decimal_separator=${e.decimal_separator}` : ""}${
                e.thousand_separator ? `&thousand_separator=${e.thousand_separator}` : ""
            }
        `);
            setModalData(data);
            setIsModalVisible(true);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalData(null);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalData(null);
    };
    return (
        <Row justify="center">
            <Col span={18} style={{ backgroundColor: "white", padding: "1rem", marginTop: "1rem", height: "86vh" }}>
                <Divider>Feed Checker</Divider>
                <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center", height: "90%" }}>
                    <Form form={form} name="create-shop-form" onFinish={handleSubmit} id="form-add-store">
                        <Form.Item label="Shop" name="shop_id" rules={[{ required: true, message: "Missing Shop" }]} style={{ width: "350px" }}>
                            <Select
                                style={{ width: "300px" }}
                                showSearch
                                allowClear
                                placeholder="Select shop"
                                //onChange={onShopChange}
                                key={"shop_select"}
                                //value={shopID}
                                //onClear={resetSelectedShop}
                                filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {shops.map((item: { id: number; name: string }, index: number) => (
                                    <Select.Option value={item.id} key={"category_seller_" + item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <InputE label="feed_url" name="feed_url" rules={[{ required: true, message: "Missing feed url" }]} />
                        <InputE label="Sku" name="sku" rules={[{ required: true, message: "Missing sku" }]} />
                        <InputE label="Product Url" name="product_url" rules={[{ required: true, message: "Missing product url" }]} />
                        <InputE label="Column" name="column" rules={[{ required: true, message: "Missing column" }]} />
                        <InputE label="Price" name="price" rules={[{ required: true, message: "Missing price" }]} />
                        <InputE label="Decimal Separator" name="decimal_separator" rules={[{ required: false, message: "Missing decimal separator" }]} />
                        <InputE label="Thousand separator" name="thousand_separator" rules={[{ required: false, message: "Missing thousand separator" }]} />
                        <hr style={{ marginTop: "1rem" }} />
                        <Button type="primary" style={{ marginTop: "0.5rem", marginLeft: "80%" }} htmlType="submit" loading={loading}>
                            Show
                        </Button>
                    </Form>
                    <Modal title="Feed report" open={isModalVisible} onCancel={handleCancel} footer={false}>
                        {modalData && (
                            <div>
                                <p>
                                    <b>Model Change:</b> <span>{modalData.model_change} %</span>
                                </p>
                                <p>
                                    <b>Price Change:</b> <span>{modalData.price_change} %</span>
                                </p>
                                <p>
                                    <b>Product Url Change:</b> <span>{modalData.product_url_change} %</span>
                                </p>
                                <p>
                                    <b>Sku Change:</b> <span>{modalData.sku_change} %</span>
                                </p>
                                <Divider></Divider>
                                <Button type="primary" onClick={closeModal}>
                                    Close
                                </Button>
                            </div>
                        )}
                    </Modal>
                </div>
            </Col>
        </Row>
    );
};

export default FeedChecker;
