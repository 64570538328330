import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Layout, Typography, Row, Pagination, Select, Popover, Input, Button, InputNumber } from "antd";
import { CaretDownOutlined, CloseCircleFilled, CloseSquareOutlined, PlusCircleFilled, StarFilled } from "@ant-design/icons";
import axios from "axios";
import "../style/ProductImages.css";
import showMessage from "../../../shared/MessagesInfo/message";
import Spinner from "../../../shared/Spinner/Spinner";
import { valueType } from "antd/es/statistic/utils";
import { getShops } from "../../../shared/Api/shop";
import { getAllCategories, TreeSelectCategoryType } from "../../../shared/Api/category";
import CategoryTreeSelect from "../../../shared/FormElements/CategoryTreeSelect";
import { useAuth } from "../../../shared/Context/AuthContext";
const { Header, Content } = Layout;
const { Paragraph } = Typography;
const { Option } = Select;

type ProductImagesArr = {
    width: number;
    height: number;
    imageHover: boolean;
    name: string;
    object_id: string;
    primaryimage: boolean;
    imgQuality: null | number;
    reference: boolean;
};

type ProductImagesObject = {
    [category_name_: string]: any;
    product_id: number;
    prices_count: number;
    images: ProductImagesArr[];
    isPopover: boolean;
    image_slug: string;
};

let pages: number;

const ProductImages = () => {
    const { currentCountry } = useAuth();
    const [categories, setCategories] = useState<TreeSelectCategoryType[]>([]);
    const [shops, setShops] = useState<{ id: number; name: string }[]>([]);
    const [productImages, setProductImages] = useState<ProductImagesObject[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [allPages, setAllPages] = useState(0);
    const [search, setSearch] = useState<valueType | null>(null);
    const [selectedShop, setSelectedShop] = useState<null | string>(null);
    const [selectedL3, setSelectedL3] = useState<number | null>(null);
    const [imageUrl, setImageUrl] = useState(""); //for adding image

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCategories([]);
        getAllCategories(currentCountry.id, currentCountry.name).then((resp) => {
            setCategories(resp);
        });
        getShops(0, currentCountry.id).then((resp) => {
            setShops(resp.data.map((s) => ({ id: s.id, name: s.name })));
        });
    }, [currentCountry]);

    useEffect(() => {
        const getProductImages = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/product/image/list?page=${currentPage}${search ? `&search=${search}` : ""}${
                        pages && !selectedL3 && !selectedShop && !search ? `&pages=${pages}` : ""
                    }${selectedL3 ? `&category_l3_id=${selectedL3}` : ""}${selectedShop ? `&shop_id=${selectedShop}` : ""}&country_id=${currentCountry.id}`
                );
                if (pages !== 0 && !selectedL3 && !selectedShop && !search) pages = data.pages;
                setAllPages(data.pages);

                const newData = data.data.map((firstData: typeof data.data) => {
                    const findReference =
                        JSON.parse(firstData.images).length > 0
                            ? JSON.parse(firstData.images).sort((a: ProductImagesArr, b: ProductImagesArr) => a.width && a.height && b.width && b.height && b.width * b.height - a.width * a.height)
                            : [];
                    const images = JSON.parse(firstData.images)
                        .sort((a: ProductImagesArr, b: ProductImagesArr) => a.width && a.height && b.width && b.height && b.width * b.height - a.width * a.height)
                        .map((secondData: typeof firstData.images) => {
                            return {
                                ...secondData,
                                imageHover: false,
                                imgQuality: secondData.height && secondData.width ? secondData.height * secondData.width : null,
                                reference: findReference[0] && secondData.name === findReference[0].name ? true : false,
                            };
                        });
                    return {
                        ...firstData,
                        images: images,
                        imagesLength: images.length,
                        isPopover: false,
                    };
                });
                setProductImages(newData);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };
        getProductImages();
    }, [currentPage, search, selectedL3, currentCountry.id, selectedShop]);

    const onPaginateChange = (e: number) => {
        setCurrentPage(e);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const hoverImage = (product_id: number, imageDataId: string) => {
        const showDelete = productImages.map((data) => {
            if (data.product_id === product_id) {
                const newImgData = data.images.map((img) => {
                    if (img.name === imageDataId) {
                        return {
                            ...img,
                            imageHover: true,
                        };
                    }
                    return {
                        ...img,
                        imageHover: false,
                    };
                });
                return {
                    ...data,
                    images: newImgData,
                };
            }
            return data;
        });
        setProductImages(showDelete);
    };
    const removeHoverImage = () => {
        const showDelete = productImages.map((data) => {
            const newImgData = data.images.map((img) => {
                return {
                    ...img,
                    imageHover: false,
                };
            });
            return {
                ...data,
                images: newImgData,
            };
        });
        setProductImages(showDelete);
    };

    let delayTimer: NodeJS.Timeout;
    const handleSearchChange = (e: valueType | null) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setSearch(e ? e : null);
            setCurrentPage(1);
        }, 700);
    };

    const onCategoryChange = (value: number) => {
        setSelectedL3(value ? value : null);
        setCurrentPage(1);
    };

    const handleSelectedShops = (value: string) => {
        setSelectedShop(value ? value : null);
        setCurrentPage(1);
    };
    const setDefaultImage = async (productId: number, name: string) => {
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/image/set-default-image?product_id=${productId}&name=${name}`);
            showMessage(data.message);
            const newImages = productImages.map((item) => {
                const images = item.images.map((imageData) => {
                    if (item.product_id === productId) {
                        if (imageData.name === name) {
                            return {
                                ...imageData,
                                primaryimage: true,
                            };
                        }
                        return {
                            ...imageData,
                            primaryimage: false,
                        };
                    }
                    return imageData;
                });
                return {
                    ...item,
                    images: images,
                };
            });
            setProductImages(newImages);
        } catch (err) {
            console.log(err);
        }
    };

    const deleteImage = async (productId: number, name: string) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/image/delete-image?product_id=${productId}&name=${name}`);
            showMessage(data.message);
            const newData = productImages.map((item) => {
                const images = item.images.filter((imageData) => imageData.name !== name);
                return {
                    ...item,
                    images: images,
                };
            });
            setProductImages(newData);
        } catch (err) {
            console.log(err);
        }
    };

    const saveNewImage = async (product_id: number) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/image/add-image?product_id=${product_id}&image=${imageUrl}`); //??????????????

            setProductImages((curr) => curr.map((d) => (d.product_id === product_id ? { ...d, isPopover: false, images: [...d.images, { ...data.data, name: data.data.name.slice(3) }] } : d)));
            showMessage(data.message);
            setImageUrl(" ");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Row justify="center">
            <Col span={20} style={{ backgroundColor: "white", padding: "1rem", marginTop: "1rem" }}>
                <Divider>Product Images</Divider>
                <div className="product-img-info-bar" style={{ display: "flex", alignItems: "center" }}>
                    <InputNumber style={{ width: 252 }} onChange={(e) => handleSearchChange(e)} controls={false} placeholder="Search Image by Product" />
                    <Select
                        suffixIcon={<CaretDownOutlined />}
                        showSearch
                        allowClear
                        style={{ width: "190px", marginLeft: "30px" }}
                        placeholder="Select shop"
                        value={undefined}
                        onChange={handleSelectedShops}
                        filterOption={(input, option) => String(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {shops
                            .sort((a: { name: string }, b: { name: string }) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                            .map((shop: { id: number; name: string }) => (
                                <Option key={shop.id} value={shop.id}>
                                    {shop.name}
                                </Option>
                            ))}
                    </Select>{" "}
                    <div style={{ marginLeft: "1rem" }}>
                        <CategoryTreeSelect allCategories={categories} currentCountryName={currentCountry.name} onCategoryChange={onCategoryChange} selectedCategory={selectedL3 ?? undefined} />
                    </div>
                </div>
            </Col>
            <Col span={20} style={{ backgroundColor: "white" }}>
                <Layout style={{ margin: "0.6rem 1rem" }}>
                    <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px" }}>
                        <span> List of Images </span>{" "}
                    </Header>
                    <Content style={{ backgroundColor: "white" }}>
                        {!loading ? (
                            <React.Fragment>
                                {productImages &&
                                    productImages.map((item) => (
                                        <div className="product-image-row" key={item.product_id}>
                                            <Card className="" style={{ backgroundColor: "#dfebed" }}>
                                                <Popover
                                                    content={
                                                        <div>
                                                            <Input.Group compact>
                                                                <Input size="small" style={{ width: 300 }} placeholder="Image url" onChange={(e) => setImageUrl(e.target.value)} value={imageUrl} />
                                                                <Button
                                                                    disabled={imageUrl.trim().length === 0 ? true : false}
                                                                    size="small"
                                                                    type="primary"
                                                                    danger
                                                                    onClick={() => saveNewImage(item.product_id)}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Input.Group>
                                                        </div>
                                                    }
                                                    title={
                                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <CloseSquareOutlined
                                                                onClick={() => {
                                                                    setProductImages((curr) => curr.map((d) => ({ ...d, isPopover: false })));
                                                                    setImageUrl("");
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    style={{ zIndex: 1000 }}
                                                    open={item.isPopover}
                                                >
                                                    <PlusCircleFilled
                                                        onClick={() => setProductImages((curr) => curr.map((d) => ({ ...d, isPopover: d.product_id === item.product_id ? true : false })))}
                                                        style={{ position: "absolute", right: "14px", top: "1rem", fontSize: "22px", marginBottom: "12px", color: "#154c79", cursor: "pointer" }}
                                                    />
                                                </Popover>

                                                <div style={{ width: "220px" }}>
                                                    <b>Product ID</b> <span>{item.product_id}</span> <br></br>
                                                    <b>Name </b>
                                                    <span>{item[`name_${currentCountry.name}`]}</span> <br></br>
                                                    <b>Valid Prices</b> <span>{item.prices_count}</span> <br></br>
                                                    <b>
                                                        <a
                                                            target="_blank"
                                                            className="front-link"
                                                            style={{ color: item[`url_${currentCountry.name}`] ? "#01b0f5" : "gray" }}
                                                            onClick={() =>
                                                                item[`url_${currentCountry.name}`] && window.open(item[`url_${currentCountry.name}`], "", "width=900, height=1080, top=0, left=960")
                                                            }
                                                            rel="noreferrer"
                                                            href={item[`url_${currentCountry.name}`]}
                                                        >
                                                            Link
                                                        </a>
                                                    </b>{" "}
                                                    <br></br>
                                                </div>
                                            </Card>
                                            <div style={{ width: "1rem", borderRight: "1px solid lightgray" }}></div>
                                            <div style={{ display: "flex", overflowX: "auto", width: "100%", marginLeft: "0.8rem" }}>
                                                {item.images.map((imageData) => (
                                                    <div
                                                        key={imageData.name}
                                                        onMouseEnter={() => hoverImage(item.product_id, imageData.name)}
                                                        onMouseLeave={() => removeHoverImage()}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <img
                                                            onClick={() => setDefaultImage(item.product_id, imageData.name)}
                                                            src={`https://img.ep-cdn.com/i/160/160/${imageData.name.slice(0, 2)}/${imageData.name}${item.image_slug}.webp`}
                                                            alt={`product-${imageData.name}`}
                                                            width={120}
                                                            className={`product-img-card ${imageData.primaryimage === true ? "default-img-border" : ""}  ${
                                                                imageData.primaryimage === false ? "product-img-card-h" : ""
                                                            }`}
                                                        />
                                                        <div className="check-default-image" style={{ zIndex: "200" }}>
                                                            <CloseCircleFilled onClick={() => deleteImage(item.product_id, imageData.name)} className="product-img-delete-icon" />
                                                        </div>

                                                        <Paragraph type="secondary" style={{ textAlign: "center", marginLeft: "3rem", marginTop: "0.2rem", fontSize: "12px" }}>
                                                            {imageData.width === null || imageData.width === null ? "" : `${imageData.width} x ${imageData.height}`}
                                                        </Paragraph>
                                                        {imageData.reference && (
                                                            <div className="recommendation" style={{ textAlign: "center" }}>
                                                                <StarFilled style={{ color: "#42b883" }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                {productImages.length > 0 && (
                                    <Pagination total={allPages * 100} pageSize={100} onChange={onPaginateChange} current={currentPage} showSizeChanger={false} style={{ margin: "1rem 0" }} />
                                )}
                            </React.Fragment>
                        ) : (
                            <Spinner />
                        )}
                    </Content>
                </Layout>
            </Col>
        </Row>
    );
};

export default ProductImages;
